
import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { CountContainer, CartNameSmall, ButtonUpdateQty, AmountInput} from './styled';
import {AiOutlineMinusCircle, AiOutlinePlusCircle} from "react-icons/ai";
import Modal from 'ui/Modal';
import { Button } from 'ui/Buttons';
import Input from 'ui/TextInput';

interface Props {
    onUpdateQuantity: (quantity: number) => void;
    quantity: number;
    hideText?: boolean;
    allowNegative?: boolean;
}

const Counter: React.FC<Props> = ({ onUpdateQuantity, quantity, hideText, allowNegative }) => {
    const {t} = useTranslation();

    const [showModal, setShowModal] = useState(false);


    return (
        <CountContainer>
                {
                !hideText && <CartNameSmall>{t('quantity')}</CartNameSmall>
                }
            <ButtonUpdateQty  onClick={ () => {

                if (allowNegative && quantity > 0) {
                    onUpdateQuantity(quantity - 1)

                } else if (quantity > 1) {
                    onUpdateQuantity(quantity - 1)
                }
            }} >
                <AiOutlineMinusCircle color={"#5C7C9A"} size={window.innerWidth < 500 ? 20:25}/>
            </ButtonUpdateQty>
            <AmountInput 
                onClick={() => setShowModal(true)}
                sizeW={window.innerWidth < 500 ? "1.8rem":"4rem"} 
                sizeH={window.innerWidth < 500 ? "1.5rem":"2rem"} 
                value={quantity}/>
            <ButtonUpdateQty onClick={ () => {
                onUpdateQuantity(quantity + 1)
            }}>
                <AiOutlinePlusCircle  color={"#5C7C9A"} size={window.innerWidth < 500 ? 20:25}/>
            </ButtonUpdateQty>
            <Modal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                styleContent={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                }}
                >
                <h2>{t('enter_quantity')}</h2>
                <Input
                    type="number" 
                    value={quantity} 
                    label={t('quantity')}
                    onChange={(e) => {
                        if (Number(e.target.value) && Number(e.target.value) >= 0) {
                            onUpdateQuantity(Number(e.target.value))
                        }
                    }}
                />
                <Button onClick={() => setShowModal(false)}>{t('accept')}</Button>
                </Modal>
        </CountContainer>
    );
};

export default Counter;
