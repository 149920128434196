import { ModalContent, ModalWrapper } from './styled';
import CloseIcon from '@mui/icons-material/Close';
import { THEME } from 'constants/theme/theme';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  styleContent?: React.CSSProperties;
  hideCloseIcon?: boolean;
  whiteBackground?: boolean;
  title?: string;
}

const Modal = ({ isOpen, onClose, children, styleContent, hideCloseIcon, whiteBackground, title }: Props) => {
  return (
    <ModalWrapper whiteBackground={whiteBackground} isOpen={isOpen} onClick={onClose}>
      <ModalContent style={styleContent} onClick={(e) => e.stopPropagation()}>
        {
          !hideCloseIcon && (
            <div style={{ display: 'flex', justifyContent: title? 'space-between' : 'flex-end' }}>
              {title && <h3
                style={{
                  color: THEME.colors.black100,
                  fontWeight: 500,
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >{title}</h3>}
              <CloseIcon style={{ cursor: 'pointer', color: 'black' }} onClick={onClose} />
            </div>
          )
        }
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
