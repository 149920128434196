import * as Yup from 'yup';

// typeService: '',
// countryOrigin: '',
// countryDestination: '',
// additionalComments: '',
// createdAt: new Date().toISOString(),
// status: 'pending',
// phone: '',
// fullName: '',


export const validationSchema = Yup.object({
    typeService: Yup.string().required('Debes seleccionar un tipo de servicio logístico.'),
    countryOrigin: Yup.string().required('Debes seleccionar un país.'),
    countryDestination: Yup.string(),
    fullName: Yup.string().required('El nombre es importatante para poder contactarte.'),
    phone: Yup.string().min(8, 'Debe ser un numero valido de almenos 8 caracteres').required('El teléfono es importante para poder contactarte.'),
    additionalComments: Yup.string(),
});
