import styled from "@emotion/styled";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem 0rem;

    @media (max-width: 768px) {
        padding: .5rem 0rem;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.2rem 1.2rem 2rem 1.2rem;

    @media (max-width: 768px) {
        margin: 0.2rem .5rem 2rem .5rem;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    border-radius: 1rem;
`;
