import {FC} from 'react';
import {Container, ItemGrid} from './styled';
import { THEME } from 'constants/theme/theme';
import { useTranslation } from "react-i18next";


interface UrgencyLevelInputProps {
    value: string;
    onChange: (value: string) => void;
}

const UrgencyLevelInput:FC<UrgencyLevelInputProps> = (props: UrgencyLevelInputProps) => {
    const {value, onChange} = props;
    const { t } = useTranslation();

    return (
        <Container>
            <ItemGrid  
                bgColor={(!value || value === 'high') ? THEME.colors.alert100 : '#e5e5e5'}
                onClick={() => onChange('high')}
            >
                {t('very_urgent')}
            </ItemGrid>
            <ItemGrid 
                bgColor={(!value ||  value === 'medium') ? THEME.colors.services : '#e5e5e5'}
                onClick={() => onChange('medium')}
            >
                {t('medium_priority')}
            </ItemGrid>
            <ItemGrid 
                bgColor={(!value || value === 'low') ? THEME.colors.positive60 : '#e5e5e5'}
                onClick={() => onChange('low')}
            >
                {t('little_urgent')}
            </ItemGrid>
        </Container>
    )

}

export default UrgencyLevelInput;