
import Modal from 'ui/Modal';
import {Button, CancelButton} from 'ui/Buttons';
import { Container, Title, Description, ButtonContainer } from './styled';
import { THEME } from 'constants/theme/theme';
import { useTranslation } from "react-i18next";

interface ModalConfirmProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    title: string;
    description: string;
    titleButton: string;
}

export const ModalConfirm = (props: ModalConfirmProps) => {

    const { t } = useTranslation();
    const { isOpen, onClose, onSubmit, title, description, titleButton } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Container>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <ButtonContainer>
                    <CancelButton style={{width: '200px'}} onClick={onClose}>{t('cancel')}</CancelButton>
                    <Button backgroundColor={THEME.colors.alert100} onClick={onSubmit}>{titleButton}</Button>
                </ButtonContainer>
            </Container>
        </Modal>
    )
}

export default ModalConfirm;
