import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem  1rem 4rem 1rem;
  flex: 1;
  

  @media (min-width: 768px) {
    padding: 1rem  0rem 1rem 0rem;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 700px;
  min-height: 200px;
  max-height: 580px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-y: scroll;
  margin-bottom: 20px;
  padding: 10px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #FBFBFB;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #5100D6;
    border-radius: 10px;
  }

`;

export const UserMessage = styled.p`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  /* background-color: #E7EDF5; */
  color: black;
  align-self: flex-end;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 80%;
  margin-right: 1rem;
`;

export const BotMessage = styled.p`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 1rem;
  background-color: #615EE2;//#809FB8;
  color: white;
  max-width: 80%;
  align-self: flex-start;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  margin-left: 1rem;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  gap: 10px;

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 52px;

  @media (max-width: 420px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Button = styled.button`
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;
