import { functions } from 'config/firebase';
import { httpsCallable,  } from "firebase/functions";
import { YachtInquiryType } from 'types/services';

const createYachtInquiry = async (data:  YachtInquiryType ) => {
    const createYachtInquiry = httpsCallable(functions, 'createYachtInquiry');
    const response = await createYachtInquiry(data);
    return response.data as YachtInquiryType 
}

export default createYachtInquiry;

