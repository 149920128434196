
import MainLayout from "views/layout/MainLayout";
import { useNavigate } from "react-router-dom";


const Tools = () => {
    const navigate = useNavigate();


    return (
        <MainLayout>
        <div>
            <h1>Tools</h1>

            <div>
                <p 
                    onClick={() => navigate('/tools/remove-bg')}
                >
                    Elimina fondo
                </p>
            </div>
        </div>
        </MainLayout>
    );
    }

export default Tools;

