
const MenuIcon = () => {
    return (    
        <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0484 7.98593H2.95164C2.69925 7.98593 2.4572 7.88132 2.27873 7.6951C2.10026 7.50888 2 7.25632 2 6.99297C2 6.72962 2.10026 6.47705 2.27873 6.29083C2.4572 6.10462 2.69925 6 2.95164 6H21.0484C21.3007 6 21.5428 6.10462 21.7213 6.29083C21.8997 6.47705 22 6.72962 22 6.99297C22 7.25632 21.8997 7.50888 21.7213 7.6951C21.5428 7.88132 21.3007 7.98593 21.0484 7.98593Z" fill="#F5F5F6"/>
            <path d="M21.0483 12.9928H2.95164C2.69925 12.9928 2.4572 12.8882 2.27873 12.7019C2.10026 12.5157 2 12.2632 2 11.9998C2 11.7365 2.10026 11.4839 2.27873 11.2977C2.4572 11.1115 2.69925 11.0068 2.95164 11.0068H21.0483C21.3007 11.0068 21.5428 11.1115 21.7213 11.2977C21.8997 11.4839 22 11.7365 22 11.9998C22 12.2632 21.8997 12.5157 21.7213 12.7019C21.5428 12.8882 21.3007 12.9928 21.0483 12.9928Z" fill="#F5F5F6"/>
            <path d="M21.0483 18H2.95164C2.69925 18 2.4572 17.8954 2.27873 17.7091C2.10026 17.5229 2 17.2704 2 17.007C2 16.7437 2.10026 16.4911 2.27873 16.3049C2.4572 16.1187 2.69925 16.014 2.95164 16.014H21.0483C21.3007 16.014 21.5428 16.1187 21.7213 16.3049C21.8997 16.4911 22 16.7437 22 17.007C22 17.2704 21.8997 17.5229 21.7213 17.7091C21.5428 17.8954 21.3007 18 21.0483 18Z" fill="#F5F5F6"/>
        </svg>
    );
};

export default MenuIcon;