import axios from "axios";

const PIXIAN_APP_ID = process.env.WDYN_PIXIAN_APP_ID;
const PIXIAN_API_SECRET = process.env.WDYN_PIXIAN_API_SECRET;
const PIXIAN_API_URL = 'https://api.pixian.ai/api/v2/remove-background'

async function removeBgPixian(file: File) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('size', 'auto');
    formData.append('bg_color', 'auto');

    try {
        const response = await axios.post(PIXIAN_API_URL, formData, {
          auth: {
            username: PIXIAN_APP_ID as any,
            password: PIXIAN_API_SECRET as any,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Basic cHhyd3EybjZ3Yzg3a21uOmlndmYwdm4yYXJqdThtdHNrcGFpOWh1MXAyc3VuY2x2OW1jMHFwMTdhc2cxZXEzN3N0MHM=`,

          },
        });
    
        console.log('response.data: remove bg', response.data);
        return response.data;
      } catch (error) {
        console.error('Error al quitar el fondo de la imagen:', error);
        throw error;
      }


}

export default removeBgPixian;
    
    
