import { useEffect } from 'react';
import { getI18n } from 'react-i18next';
import { useSelector } from 'react-redux';
import  { getLanguage} from 'redux/common';

export const useLanguage = () => {
    const i18n  = getI18n();
    const language = useSelector(getLanguage);

    useEffect(() => {
        i18n.changeLanguage(language);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);
};
