import { useState } from "react";
import Carousel from "react-multi-carousel";
import { Container, FlexColumn, Titles} from "./styled";
import ExtraCard from "components/cards/services/extraServices";
import ModalInfo from "./modal";
import SkeletonComponent from "./skeleton";
import { useTranslation } from "react-i18next";

const ItemSkeletons = new Array(24).fill(0);


const ExtraServicesYachts = ({ data, title, backgroundColor, hasLink, path = '', type = '' }) => {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleClick = (subService: any) => {
    setIsOpenModal(true);
    setSelectedService(subService);
  };
    

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1023, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

  return (
    <Container background={backgroundColor} pav="2rem" >
      <FlexColumn background={backgroundColor} pav={"2rem"}>
        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
          <Titles alignM="center" size="1.5rem">
            {title}
          </Titles>
        </div>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          arrows={false}
        >
          {data !== null ? (
            data.map((subService, index) => (
              <div style={{ marginBottom: "1rem" }} key={index}>
                <ExtraCard 
                  textButton={t('view_more') || ''} 
                  data={subService} 
                  onClick={() => { handleClick(subService)}} 
                  showContentOnModal 
                />
              </div>
            ))
          ) : (
            ItemSkeletons.map((item) => (
              <div key={item}>
                <div>
                  <SkeletonComponent />
                </div>
              </div>
            ))
          )}
        </Carousel>
        <ModalInfo
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          data={selectedService}
        />

        {/* {hasLink && (
          <div className="my-2">
            <Link href="/services/tourism">Ver todas</Link>
          </div>
        )} */}
      </FlexColumn>
    </Container>
  );
};

export default ExtraServicesYachts;
