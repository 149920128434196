import { Container, Cuad, Titles, WrapperTitle } from "./styled";
import Skeleton from "@mui/material/Skeleton";
import { SponsorBuy } from "../Extras/styled";
import ExpandCard from "components/cards/expand-card";
import { useTranslation } from "react-i18next";


export const ListTours = (props) => {
  const { tours } = props;
  const ItemSkeletons = [1, 2, 3, 4];
  const { t } = useTranslation();

  return (
    <Container fondo="#E7F7F6" pav="2rem" pah="2rem">
      <WrapperTitle>
        <Titles alignM={"center"} size={"1.5rem"} className={"w-100"}>
          {t('get_to_know_our_tours')}
        </Titles>
      </WrapperTitle>
      <Cuad>
        {tours != null
          ? tours.map((item, index) => {

            return (
              <ExpandCard
                key={index}
                data={item}
                textButton={`${t('view_tour')}`}
                background="#FFFFFF"
                hasRedirectToDetail={true}
              />
            );

          })
          : ItemSkeletons.map((item) => {
            return (
              <div key={item} style={{ height: "100%", width: "100%" }}>
                <Skeleton variant={"rounded"} width={"95%"} height={200} />
                <Skeleton variant={"text"} width={"20%"} />
                <Skeleton variant={"text"} width={"100%"} />
                <SponsorBuy>
                  <Skeleton variant={"rounded"} width={"25%"} height={25} />
                </SponsorBuy>
              </div>
            );
          })}
      </Cuad>
    </Container>
  );
};
