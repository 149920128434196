

import CarouselGeneral from "components/carousel-general";
import Carousel from "react-multi-carousel";
import Skeleton from "@mui/material/Skeleton";
import Classified from "components/cards/Classifieds/Classifieds";
import { CarouselClassifiedsContainer } from "../ContentClassifieds/styled";
import { ThemeProvider, createTheme } from "@mui/material";


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    }, 
};

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});


interface ICarouselClassifieds {
    data: any;
}

const ItemSkeletons = [1, 2, 3, 4, 5, 6];

const CarouselClassifieds = (props: ICarouselClassifieds) => {
    const { data } = props;

    return (
        <CarouselClassifiedsContainer>
                            {
                                data.length > 0 ? <CarouselGeneral items={data} renderItem={Classified} itemsToShow={5} itemsMobile={2} />
                                    :
                                    (
                                        <Carousel
                                            swipeable
                                            draggable
                                            showDots={false}
                                            responsive={responsive}
                                            ssr={true}
                                            infinite={true}
                                            autoPlay={true}
                                            autoPlaySpeed={3000}
                                            keyBoardControl={true}
                                            transitionDuration={500}
                                            containerClass="carousel-container"
                                            itemClass="carousel-item-padding-40-px"
                                            arrows={false}
                                        >
                                            {ItemSkeletons.map((item) => (
                                                <div key={item}>
                                                    <ThemeProvider theme={lightTheme}>
                                                        <Skeleton variant={"rounded"} height={200} width={"90%"} style={{ margin: "0 auto" }} />
                                                        <div style={{ width: "90%", margin: "0 auto" }}>
                                                            <Skeleton variant={"text"} width={"20%"} />
                                                            <Skeleton variant={"text"} width={"60%"} />
                                                            <Skeleton variant={"text"} width={"30%"} />
                                                        </div>
                                                    </ThemeProvider>
                                                </div>
                                            ))}
                                        </Carousel>
                                    )
                            }

                        </CarouselClassifiedsContainer>
    )

}

export default CarouselClassifieds;