import { useEffect } from 'react';
import MainLayout from 'views/layout/MainLayout'
import { Container, Content } from "./styled";
import CartContainer from "views/containers/Cart";


const Cart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainLayout type='main'>
      <Container>
        <Content>
          <CartContainer />
        </Content>
      </Container>
    </MainLayout>
  )
}

export default Cart
