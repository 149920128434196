import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #CBFDFD;
  padding: 50px 5%;
  border-radius: 10px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  text-align: center;
  padding: 20px;
`;

export const TextWrapper = styled.div`
  padding-bottom: 20px;
`;

export const FormWrapper = styled.div`
  flex: 1;
  padding: 20px;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 10px;
`;

export const H1 = styled.h1`
  font-weight: 700;
  font-family: 'Montserrat', sans-serif; /* Asegúrate de que Montserrat esté importada en tu proyecto */
  font-size: 2.5rem;
  line-height: 3rem;
  color: rgb(0, 0, 0);

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 38px;
  }

  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 2px solid #69727F;
  border-radius: 15px;
  font-size: 14px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 2px solid #69727F;
  border-radius: 15px;
  font-size: 14px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
