import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  flex-direction: column;
`
export const ContainerBar = styled.div`
  width: 100%;
`

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const LoginWrap = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-top: -1.4rem;
  @media (max-width: 992px) {
   
  }
`

export const LoginImgWrap = styled.div`
  width: 50%;

  @media (max-width: 992px) {
    width: 100%;
    height: 50%;
  }
  @media (max-width: 768px) {
    margin-top: -64px;
    min-height: 360px;
  }
`

export const LoginImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const LoginForm = styled.div`
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  padding: 2rem 1rem;
  box-shadow: 0 8px 20px 5px rgba(2, 64, 154, 0.4);
  width: 40%;
  background: white;
  border-radius: 2rem;
  @media (min-width: 992px) {
    height: 80%;
  }

  @media (max-width: 992px) {
    margin-top: -10rem;
    padding: 0rem 0rem 2rem 0rem;
    width: 100%;
    box-shadow: none;
    border-radius: 2rem 2rem 0 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0;
    border-radius: 2rem 2rem 0 0;
    padding: 0rem 0rem 3rem 0rem;
  }
`

export const FormTitle = styled.span`
  ${props => props.theme.typography.heading.sm.bold};
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  margin: 20px 0;
  @media (max-width: 768px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }
`

export const LoginSocial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  // shadow
  box-shadow: 0 8px 10px 5px rgba(2, 64, 154, 0.1);
  max-width: 300px;
  border-radius: 2rem;
  align-self: center;
  padding: .3rem 1.1rem;
`

export const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  cursor: pointer;

  &:hover {
    border: 1px solid #3A36DB;
    transform: scale(1.1);
  }
`

export const PlatformLogo = styled.img`
  object-fit: contain;
  width: 40px;
  height: 40px;
`
export const FormSubtitle = styled.span`
  ${props => props.theme.typography.subheading.sm.semibold};
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    ${props => props.theme.typography.subheading.xs.semibold};
  }
`

export const TransButton = styled.button`
  display: block;
  margin: 0 auto 1rem auto;
  background: ${props => props.color === "#ffffff" ? "#3A36DB" : "#ffffff"};
  color: ${props => props.color === "#ffffff" ? "#ffffff" : "#406385"};
  border-radius: 50px;
  padding: .6rem 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.lg.bold};
  cursor: pointer;
`
export const SpanMiddle = styled.span`
  vertical-align: middle;
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.typography.body.md.bold};
  justify-content: space-evenly;
  margin: 25px 10% 0px 10%;

  @media (max-width: 480px) {
    font-size: 0.70rem;
  }

`

export const Link = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  cursor:pointer;
  @media (max-width: 768px) {
    ${props => props.theme.typography.body.md.bold};
  }
`
export const InputForm = styled.div`
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  border-radius: 25px;
  padding: 0 30px 0 68px;
  outline: none;
  border: none;

  // max 480
  @media (max-width: 480px) {
    padding: 0 10px;
  }


  &:focus {
    border-color: transparent !important;
  }
`

export const LoginWrapButton = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
`

export const LoginButton = styled.button`
  outline: none !important;
  border: none;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #57b846;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;

  &:hover {
    background: #333333;
    cursor: pointer;
  }
`
export const FooterWrap = styled.div`
  margin: -15rem 0;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
`

export const Paragraph = styled.p`
  ${props => props.theme.typography.body.md.regular};
  text-align: center;
`

export const Disclaimer = styled.p`
  ${props => props.theme.typography.body.md.regular};
  text-align: center;
  @media (max-width: 768px) {
    ${props => props.theme.typography.label.xs.regular};
  }
`

// login selected

export const InputLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: #000000;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #ffffff;
`

// export const InputForm = styled.div`
//   margin: 0 20%;
// `

export const InputWrap = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

export const Input = styled.input`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  ${props => props.theme.typography.body.lg.input};
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
  align-self: center;
`

export const TextError = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;