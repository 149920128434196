import {FC} from "react";
import { TextContainer, Description, Content, Container, Title } from "./styled";
import { useTranslation } from "react-i18next";

export const BannerInnovation: FC = () => {
  const { t } = useTranslation();


  return (
    <Container pav="3rem" pah="8rem">
      <Content>
        <TextContainer>
          <Title family={"Outfit, monospace"} weight={650} color={"#0a0caa"}>
            {t("we_are_personalized_platform")}
          </Title>
          <Description>
            {t("that_offers_solution_to_your_needs")}
          </Description>
        </TextContainer>
      </Content>
    </Container>
  );
};
