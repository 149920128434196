import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Cart,
  CartTitle,
  ContentDiscount,
  ContentItems,
  ContentProductsContainer,
  CuponSmall,
  HideSmall,
  SmallBack,
  SpaceBetween,
  SpaceBetweenS,
  SpanMiddle,
  Total,
  TotalText,
  SecurityContainer,
  CheckoutContainer,
  PrincipalRow,
  SecurityContainerTitle,
  PaymentsMethods,
  WarrantyTitle,
  WarrantyParagraph,
  SecurityContainerSection,
  DeleteButton,
} from "./styled";
import CartItem from "ui/Cart";
import { MdArrowBack } from "react-icons/md";
import { createTheme, ThemeProvider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "libs/theme";
import Skeleton from "@mui/material/Skeleton";
import { Button } from "ui/Buttons";
import {
  removeFromCartSagasTrigger,
  updateCartItemSagasTrigger,
} from "redux/cart/reducer/reducer-cart";
import { totalCart, itemsCart, getCartSagasTrigger } from "redux/cart";
import { getLanguage } from "redux/common";
import { useNotification } from "libs/context/AlertContext";
import CartIcons from "assets/icons/cart-icons";
import LockIcon from "assets/icons/lock-icon";
import OutlineDelete from "assets/icons/outline-delete";

const CartContainer: React.FC = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { showWarning } = useNotification();
  const total = useSelector(totalCart);
  const items = useSelector(itemsCart);
  const { t } = useTranslation();
  const language = useSelector(getLanguage);

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const [selectedItemIds, setSelectedItemIds] = useState<Set<string | number>>(
    new Set()
  );

  const handleDeleteItem = (id: string | number) => {
    dispatch(removeFromCartSagasTrigger.run({ id }));
    setSelectedItemIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(id);
      return newSet;
    });
  };

  const handleDeleteSelectedItems = () => {
    selectedItemIds.forEach((id) => {
      dispatch(removeFromCartSagasTrigger.run({ id }));
    });
    setSelectedItemIds(new Set());
  };

  const handleSelectItem = (id: string | number) => {
    setSelectedItemIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const handleUpdateQuantity = (idItem: string | number, quantity: number) => {
    dispatch(updateCartItemSagasTrigger.run({ id: idItem, count: quantity }));
  };

  useEffect(() => {
    // @ts-ignore
    dispatch(getCartSagasTrigger.run());
  }, [total, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Cart>
        <PrincipalRow>
          <CartTitle>
            <MdArrowBack
              style={{ cursor: "pointer", marginRight: "6px" }}
              onClick={() => navigate(-1)}
            />
            <SpanMiddle>{t("shopping_cart")}</SpanMiddle>
          </CartTitle>
          {selectedItemIds.size > 0 && (
            <DeleteButton onClick={handleDeleteSelectedItems}>
              <OutlineDelete />
            </DeleteButton>
          )}
        </PrincipalRow>
        <ContentProductsContainer>
          <ContentItems>
            {items.length > 0 ? (
              items.map((item, index) => (
                <CartItem
                  image={item.image}
                  id={item.id}
                  quantity={item.count}
                  name={item.name[language] || item.name}
                  price={item.price}
                  key={index}
                  onDeleteItem={(e: string | number) => handleDeleteItem(e)}
                  onUpdateQuantity={(e: number) => {
                    handleUpdateQuantity(item.id, e);
                  }}
                  isSelected={selectedItemIds.has(item.id)}
                  onSelect={handleSelectItem}
                />
              ))
            ) : (
              <ThemeProvider theme={lightTheme}>
                <h2
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    minHeight: "10vh",
                  }}
                >
                  {t("no_products_in_cart")}
                </h2>
              </ThemeProvider>
            )}
          </ContentItems>
          <CheckoutContainer>
            <ContentDiscount>
              <TotalText>{t("summary")}</TotalText>
              <HideSmall>
                <SpaceBetween>
                  <TotalText>{t("total_of_items")}:</TotalText>
                  {items.length > 0 ? (
                    <Total>
                      {items.reduce((acc, item) => {
                        return acc + item.count;
                      }, 0)}
                    </Total>
                  ) : (
                    <Total>0</Total>
                  )}
                </SpaceBetween>
                <hr />
                <SpaceBetween>
                  <TotalText>SubTotal:</TotalText>
                  {items.length > 0 ? (
                    <Total>
                      ${parseFloat(total.toFixed(2)) || "Error at price"}
                    </Total>
                  ) : (
                    <Total>$0</Total>
                  )}
                </SpaceBetween>
                <Button
                  disabled={!items.length}
                  onDisabledClick={() =>
                    showWarning("No hay productos en el carrito")
                  }
                  onClick={() => navigate("/checkout")}
                >
                  {t("checkout")}
                </Button>
              </HideSmall>
            </ContentDiscount>
            <SecurityContainer>
              <SecurityContainerTitle>
                <LockIcon />
                <TotalText>
                  {!isMobile ? t("security_and_privacy") : t("secure_payments")}
                </TotalText>
              </SecurityContainerTitle>
              <SecurityContainerSection>
                <PaymentsMethods>
                  <CartIcons />
                  <WarrantyTitle>
                    {t("guarantee_your_product_and_money")}
                  </WarrantyTitle>
                </PaymentsMethods>
                <WarrantyParagraph>
                  {t("all_transactions_secure")}
                </WarrantyParagraph>
              </SecurityContainerSection>
            </SecurityContainer>
          </CheckoutContainer>
          <CuponSmall>
            <SmallBack>
              <SpaceBetweenS>
                <TotalText>SubTotal:</TotalText>
                <ThemeProvider theme={lightTheme}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Skeleton variant={"rounded"} width={"100%"} height={50} />
                    <Skeleton variant={"rounded"} width={"100%"} height={50} />
                    <Skeleton variant={"rounded"} width={"100%"} height={50} />
                  </div>
                </ThemeProvider>
                {items.length > 0 ? (
                  <Total>${parseFloat(total.toFixed(2))}</Total>
                ) : (
                  <Total>$0</Total>
                )}
              </SpaceBetweenS>
              <Button
                onClick={() => navigate("/checkout")}
                onDisabledClick={() =>
                  showWarning("No hay productos en el carrito")
                }
                disabled={!items.length}
                style={{ alignSelf: "center" }}
              >
                {t("checkout")}
              </Button>
            </SmallBack>
          </CuponSmall>
        </ContentProductsContainer>
      </Cart>
    </ThemeProvider>
  );
};

export default CartContainer;
