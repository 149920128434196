import styled from "styled-components";

interface OverlayProps {
  green?: boolean; // Propiedad opcional para controlar el color del Overlay
}

export const ContainerSlider = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(253, 253, 254);
    padding: 40px 1.2rem;

    @media (max-width: 992px) {
      padding: 20px 1.2rem;
  }

  @media (max-width: 767px) {
      padding: 20px 0;
  }
`

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(253, 253, 254);
    padding: 0 1.2rem 40px 1.2rem;

  @media (max-width: 992px) {
    padding: 0 1.2rem;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(253, 253, 254);
    padding: 40px 1.2rem;

    @media (max-width: 992px) {
      padding: 20px 1.2rem;
  }

  @media (max-width: 767px) {
      padding: 20px 0;
  }
`

export const Section = styled.section`
  padding: 0 1.2rem;
`;

export const SectionSky = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #CBFDFD;
  padding: 50px 5%;
  border-radius: 10px;
  flex-direction: column;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px 20px;

  @media (max-width: 600px) {
    padding: 10px 0;
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  align-content: end;
  text-align: center;
  height: 100%;
  min-height: 70px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; /* Espacio entre las columnas */
  margin: 0;

  @media (max-width: 992px) {
    grid-template-columns: 1fr; /* Diseño de una columna en pantallas pequeñas */
  }
`;

export const Column = styled.div`
  box-sizing: border-box; /* Incluye padding y border en el ancho total */
  border-radius: 15px;
`;

export const ContentTopWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade un box-shadow para dar efecto de profundidad */
  overflow: hidden;
  margin-bottom: 40px;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div<{ gradient?: string }>`
  padding: 0 5% 5px 5%;
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
  background: ${({ gradient }) => gradient || '#000'};
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25)); /* Sombra con los valores especificados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade un box-shadow para dar efecto de profundidad */
  overflow: hidden;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;

  img {
    width: 50%;
    height: auto;
    max-height: 535px;
    object-fit: contain;
  }
`;

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: 65%;
  height: 100%; /* Hace que el overlay ocupe toda la altura del contenedor */
  background-color: #000000;
  display: flex; /* Usa flexbox para centrar el contenido */
  align-items: center; /* Alinea el contenido verticalmente al centro */
  justify-content: flex-start; /* Alinea el contenido horizontalmente a la izquierda */
  padding: 0 3%;
  border-radius: 15px;
  box-sizing: border-box;
`;

export const TextOverlay = styled.div`
  color: white;
  text-align: left;
`;

export const H4 = styled.h4`
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    margin-top: 0;
    margin-bottom: .5rem;

  @media (max-width: 1500px) {
    font-size: 1.1rem;
    line-height: 1.3;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 1.3;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 1.2;
  }

  @media (max-width: 992px) {
    font-size: 1.4rem;
    line-height: 1.2;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.2;
  }
`;

export const TextCardWrapper = styled.div`
  text-align: center;
  bottom: 0; /* Pega el contenedor al fondo */
  left: 0; /* Asegura que el contenedor ocupe todo el ancho */
  width: 100%;
  height: 100%;
  color: #fff;
  margin-bottom: 20px;
  z-index: 10; /* Asegura que esté por encima del ImageWrapper */
  display: flex;
  flex-direction: column; /* Alinea el texto y el botón en columna */
  align-items: center;
  justify-content: space-between;

  @media (min-width: 992px) {
    height: 272px;
  }

  @media (min-width: 1024px) {
    height: 275px;
  }

  @media (min-width: 1200px) {
    height: 225px;
  }

  @media (min-width: 1350px) {
    height: 195px;
  }
`;

export const ButtonCardContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
`;

export const Button = styled.button`
  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: #000;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  height: 40px;
  padding: 8px 16px;
  min-width: 120px;
  max-width: 500px;

  &:hover {
    //background-color: darkblue;
  }
`;

export const FormWrapper = styled.div`
  display: block;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const PaySafeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(58, 54, 219);
  position: relative;
  width: 100%;
  padding: 40px 1% 20px 1%;
  margin-top: 10px;
  border-radius: 15px;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

export const PayTextWrapper = styled.div`
  flex: 1 20%;
  text-align: left;
  padding: 10px 5%;
`;

export const PayImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px 5%;

  img {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
`;

