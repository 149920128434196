import styled from "styled-components";

interface IFooterProps {
  background?: string;
}

export const Footer = styled.footer`
  width: 100%;
  background: ${(props: IFooterProps) => props.background || "#3A36DB"};
  color: #ffffff;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  font-size: .8rem;
  line-height: ${props=>props.line || '1.2rem'};
  @media only screen and (max-width: 768px) {
    text-align: left!important;
  }
`
export const Link = styled.li`
margin: 6px 0px;

cursor: 'pointer';

:hover {
 opacity: 0.8;
}

`

export const ListItem = styled.li`
  font-weight: ${props => props.negrita || "400"};
`
export const Copyright = styled.div`
  background: #000;
  width: 100%;
  font-size: 75%;
  text-align: center;
  padding: 5px 0;
`

export const FooterWrap = styled.div`
  padding: 2rem 2rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const FooterS1 = styled.div`
  width: 30%;
  font-size: .875rem;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 22px;
  font-family: 'Outfit', monospace;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Titles = styled.h2`
  font-weight: 700;
  font-family: Outfit, monospace;
  line-height: 25.2px;
  font-size: 1.25rem;
`

export const FooterS2 = styled.div`
  width: 20%;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const FooterS3 = styled.div`
  width: 20%;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    align-self: self-start;
  }
`

export const FooterS4 = styled.div`
  width: 20%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const FooterS4F = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

export const Logo = styled.img`
  width: 200px;
  margin: 0 0 0 1em;
  object-fit: contain;
  @media (max-width: 992px) {
    width: 150px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const SocialLinksContainer = styled.div`
  width: 30%;
  display: flex;
  gap: .2rem;

  @media (max-width: 380px) {
    width: 50%;
  }
`

export const WrapperPaymentMethods = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self: flex-end;
  margin-bottom: -1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const ListPaymentMethods = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background: #E7EDF5;
  list-style: none;
  padding: 5px 32px;
  font-size: .8rem;
  line-height: ${props=>props.line || '1.2rem'};
  @media only screen and (max-width: 768px) {
    text-align: left!important;
  }
`;

export const TitleMethods = styled.h2`
  color: #000000;
  font-weight: 700;
  font-family: Outfit, monospace;
  line-height: 25.2px;
  font-size: 1.25rem;
  margin: 0 4% 0 0;
`

export const ListItemPayment = styled.li`
  display: flex;
  margin: 0 0.5em 0 0;

  @media (max-width: 768px) {
    max-height: 60px;
  }
`;

export const PaymentLogo = styled.img`
  width: 100%;
  padding: 0.5em;
  object-fit: contain;
  max-width: 60px;
`;

export const AddressLi = styled.li`
  list-style: none;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 0!important;
`;

export const AddressUl = styled.ul`
  padding: 0;
`;
