import styled from 'styled-components';
import {THEME} from 'constants/theme/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
`;

export const InputWrap = styled.div`
  position: relative;
  width: ${props => props.size || "100%"};
  z-index: 1;
  margin-bottom: -10px;

  @media only screen and (max-width: 768px) {
   width: 100%;
  }
`

export const Label = styled.p`
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 0.7rem;
  color: ${THEME.colors.black100};
  margin: .2rem 0rem .2rem .2rem;
`;

export const Error = styled.p`
  color: #F1851F;
  ${props => props.theme.typography.body.sm.regular};
  margin: 8px 0px 10px 0px;
`;
