import { httpsCallable } from "firebase/functions";
import { ServiceResponse } from "types";
import { functions } from "config/firebase";
import { PaymentsProps } from "types";

const paymentCheckout = async (props: PaymentsProps): Promise<ServiceResponse> => {
  const paymentCheckoutService = httpsCallable(
    functions,
    "processPaymentCheckout"
  ); 

  try {
    const response = await paymentCheckoutService(props);
    return {
      status: "OK",
      data: response.data,
      success: true,
    };
  } catch (error: any) {
    return {
      status: error?.status || "ERROR",
      message: error?.message || "Error al crear el usuario",
      code: error?.code || 500,
    };
  }
};

export default paymentCheckout;
