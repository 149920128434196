import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { createSagasTrigger } from 'lib/redux/sagas/trigger';
import { Address, OfflineAddress } from 'types';

export type InitialState = typeof initialState;

interface IState {
  uid: string | null;
  shippingType: 'fast' | 'free' | '' | string;
  address?: Address;
  lastStep?: number | null;
  offlineAddress?: OfflineAddress;
  paymentMethod?: string;
  isPaidSuccess?: boolean;
  loading?: boolean;
  error?: boolean;
}

export const initialState: IState = {
  uid: null,
  shippingType: '',
  lastStep: null,
};

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
      setLoading(state, action) {
        state.loading = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
      },
      setUidCheckout(state, action: PayloadAction<string | null>) {
        state.uid = action.payload;
      },
      setShippingType(state, action: PayloadAction<'fast' | 'free' | '' | string>) {
        state.shippingType = action.payload;
      },
      setAddress(state, action: PayloadAction<Address>) {
        state.address = action.payload;
      },
      setOfflineAddress(state, action: PayloadAction<OfflineAddress>) {
        state.offlineAddress = action.payload;
      },
      setPaymentMethod(state, action: PayloadAction<string>) {
        state.paymentMethod = action.payload;
      },
      setPaidSuccess(state, action: PayloadAction<boolean>) {
        state.isPaidSuccess = action.payload;
      },
      setLastStep(state, action: PayloadAction<number>) {
        state.lastStep = action.payload;
      },
      clearCheckout(state) {
        Object.assign(state, initialState);
        state.lastStep = null;
      }
    }
  });
  
  export const { setLoading, setError, setShippingType, setAddress, setOfflineAddress, setPaidSuccess, setPaymentMethod, setLastStep, clearCheckout, setUidCheckout } = checkoutSlice.actions;

  // export const addToCartSagasTrigger = createSagasTrigger<{ id: string | number; count: number, name: string, price: string, image: string }>('add_to_cart_sagas');
  
  
  export const checkoutReducer = checkoutSlice.reducer;