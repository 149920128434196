import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// data translations
import translationEN from './en.json';
import translationES from './es.json';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      es: {
        translation: translationES,
      },
    },
    lng: 'es', // Idioma por defecto
    fallbackLng: 'es', // Idioma de respaldo
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
