import { useState, useEffect } from 'react'
import MainLayout from 'views/layout/MainLayout/main-layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetProductsByMultiplesCategories } from 'hooks/products/useGetProductsByMultipleCategories';
import CategoriesCarousel from 'views/containers/SliderCategories'
import ContentProducts from "views/containers/Products/ContentProducts";
import useCategories from 'hooks/useGetCategories/useGetCategories';
import { useGetFirestoreDoc } from 'hooks/useGetDocumentFirestore';
import { Container, Content } from "./styled"
import { DBCountryReference } from 'types';
import { DB_ROUTES } from "constants/routes";
import { STORE_WHOLESALE_PRODUCTS_CATEGORIES } from 'constants/index';
import useAnalytics from 'hooks/useAnalytics/useAnalytics';

const { CATEGORIES_PRODUCTS_WHOLESALE } = DB_ROUTES
const LIST_CATEGORIES = STORE_WHOLESALE_PRODUCTS_CATEGORIES.map((category) => category.id);

const COLLECTION_PATH = `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_WHOLESALE}`

const WholesaleProducts = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const registerLog = useAnalytics();

    const { docData: bannersData, fetchDoc: fetchBanners } = useGetFirestoreDoc();
    const {  productsByMultipleCategories } = useGetProductsByMultiplesCategories({
        ids: LIST_CATEGORIES,
        collectionPath: COLLECTION_PATH
    });
    const { categories } = useCategories(`${DBCountryReference.PAN}${CATEGORIES_PRODUCTS_WHOLESALE}`);

    const { selRoute, selRouteEs } = location?.state || "";

    const [category, setCategory] = useState("");
    const [, setTypeCategory] = useState(0);
    const [filters, setFilters] = useState({});
    const [, setApplyfilter] = useState(new Date().getTime());
    const [bannerImages, setBannerImages] = useState<string[]>([]);

    const handleSelectCategory = (category: string) => {
        navigate(`/products/category?uid=${category}`)
    }

    useEffect(() => {

    }, [productsByMultipleCategories]);

    useEffect(() => {
        if (selRoute !== undefined && selRoute !== "") {
            setCategory(selRouteEs);
            setTypeCategory(selRoute);
        }

        if (bannersData) {
            const imagesByResolution = () => {
                if (window.innerWidth < 768) {
                    return bannersData.imagesMobile
                } else {
                    return bannersData.imagesDesktop
                }
            }
            // @ts-ignore
            setBannerImages(imagesByResolution())
        } else {
            fetchBanners({ path: `${DBCountryReference.PAN}/Banners/ProductsBanners/Es/Main` })
        }

        registerLog({ screenName: 'Products wholesale', pageTitle: 'Products wholesale of whatdoyouneed7.com', pagePath: window.location.pathname, pageLocation: window.location.href });
        // eslint-disable-next-line
    }, [bannersData]);


    return (
        <MainLayout type='products'>
            <Container>
                <CategoriesCarousel
                    category={category}
                    onSelectCategory={handleSelectCategory}
                    handleType={setTypeCategory}
                    background={"linear-gradient(180deg, #B218FF 0%, #8900CE 100%)"}
                    type={"Productos"}
                    data={categories}
                    images={bannerImages}
                    hideSlideCategories
                />
                <Content>
                    <ContentProducts
                        setRoute={setCategory}
                        categories={categories}
                        setApply={setApplyfilter}
                        setFilters={setFilters}
                        data={productsByMultipleCategories}
                        titleSection='Productos al mayor'
                        type='wholesale'
                        filters={filters}
                    />
                </Content>
            </Container>
        </MainLayout>
    )
}

export default WholesaleProducts
