import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Image,
  ContainerItemFeature,
  ItemFeature,
  PriceContainer,
  TextFrom,
  Title,
  Description,
  TextItemFeature,
  Content,
  ContainerItemFeatureText,
  QuantityItemFeature,
  YachtInfoContainer,
  ContainerImage,
  InfoContainer,
  LinkButton,
} from "./styled";
import { Link } from "react-router-dom"
import { Button } from "ui/Buttons";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BedIcon from "@mui/icons-material/Bed";
import PriceComponent from "components/Price";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import { useDispatch, useSelector } from "react-redux";
import { setServiceDetail } from "modules/services/store";
import { MAIN_ROUTES } from "constants/index";
// import { setCurrentYacht } from "redux/user";
import { getCountry, getLanguage } from "redux/common";
import parse from "utils/parseHtmlToText";

const Features = (props) => {
  const { properties } = props;
  const { t } = useTranslation();

  return (
    <ContainerItemFeature>
      {
        (properties && properties?.peopleCapacity) ? (<>
          <ItemFeature isExtraCard={props?.isExtraCard ? "48%" : "32%"}>
            <DirectionsBoatIcon style={{ color: "#406385", fontSize: "16px" }} />
            <ContainerItemFeatureText>
              <QuantityItemFeature>{properties?.size}</QuantityItemFeature>
              <TextItemFeature>{t('feet')}</TextItemFeature>
            </ContainerItemFeatureText>
          </ItemFeature>
          <ItemFeature isExtraCard={props?.isExtraCard ? "48%" : "32%"}>
            <EmojiPeopleIcon style={{ color: "#406385", fontSize: "16px" }} />
            <ContainerItemFeatureText>
              <QuantityItemFeature>
                {properties?.peopleCapacity}
              </QuantityItemFeature>
              <TextItemFeature>{t('people')}</TextItemFeature>
            </ContainerItemFeatureText>
          </ItemFeature>
          {!props?.isExtraCard && (
            <ItemFeature>
              <BedIcon style={{ color: "#406385", fontSize: "16px" }} />
              <ContainerItemFeatureText>
                <QuantityItemFeature>{properties?.rooms || 0}</QuantityItemFeature>
                <TextItemFeature>{t('cabins')}</TextItemFeature>
              </ContainerItemFeatureText>
            </ItemFeature>
          )}
        </>) : null
      }
    </ContainerItemFeature>
  );
};

// MAIN COMPONENT
const YachtCard = (props) => {
  const country = useSelector(getCountry);
  const language = useSelector(getLanguage);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = props;
  const { carousel } = props;

  const havePrice = data?.priceOptions?.find(
    (priceOption) => priceOption.isMain === true
  )?.value
    ? true
    : false;

  return (
    <Container carousel={carousel}>
      <Content>
        <ContainerImage isExtraCard={props?.isExtraCard}>
          <Link to={`/${MAIN_ROUTES.Yatchs}/detail?id=${data.id}`}>
            <Image isExtraCard={props?.isExtraCard} src={data?.images[0]} loading="lazy" alt={data?.name[language]} title={data?.name[language]} />
          </Link>
        </ContainerImage>

        <YachtInfoContainer>
          {props?.isExtraCard && (
            <Title style={{ marginTop: "0.5rem" }}>{data?.name[language]}</Title>
          )}
          <Features
            properties={data?.properties}
            isExtraCard={props?.isExtraCard}
          />
          <InfoContainer>
            {props?.isExtraCard && (
              <Description>
                {parse(!data ? "" : data?.shortDescription[language])}
              </Description>
            )}
            {!props?.isExtraCard && (
              <>
                {havePrice && (
                  <PriceContainer>
                    <TextFrom>{t('price')} {t('from')}</TextFrom>
                    <PriceComponent
                      value={parseFloat(data?.price)}
                      currencySymbol={data?.currency}
                    />
                  </PriceContainer>
                )}
                <Title style={{ marginTop: havePrice ? "0rem" : "1rem" }}>
                  {data?.name[language]}
                </Title>
                <Description>
                  {parse(!data ? "" : data?.shortDescription[language])}
                </Description>
              </>
            )}
          </InfoContainer>
          {!props?.isExtraCard && (
            <>
              <Button
                onClick={() => {
                  navigate(`/${MAIN_ROUTES.Yatchs}/detail?id=${data.id}`, {
                    state: { route: `${country}/Services/Posts` },
                  });
                  dispatch(setServiceDetail(data));
                }}
                style={{
                  height: "35px",
                  marginTop: "10px",
                  width: "100%",
                  maxWidth: "none",
                }}
              >
                {t('view_detail')}
                <DirectionsBoatFilledIcon
                  style={{ marginLeft: "10px", fontSize: "16px" }}
                />
              </Button>
            </>
          )}
        </YachtInfoContainer>
        {props?.isExtraCard && (
          <LinkButton
            onClick={() => {
              navigate(`/${MAIN_ROUTES.Yatchs}/detail?id=${data.id}`, {
                state: { route: `${country}/Services/Posts` },
              });
              dispatch(setServiceDetail(data));
              // dispatch(setCurrentYacht(data?.name[language]))
            }}
          >
            {t('reserve_yacht')}
          </LinkButton>
        )}
      </Content>
    </Container>
  );
};

export default YachtCard;
