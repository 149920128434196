import * as Yup from "yup";

export function getCardType(cardNumber: string): string {
    // Asigna el tipo de tarjeta como "desconocido" por defecto
    let cardType = 'unknown';
  
    // Elimina espacios en blanco y separadores de grupo de dígitos
    cardNumber = cardNumber.replace(/\s+|-/g, '');
  
    // Si el número de la tarjeta comienza con "34" o "37", es una tarjeta American Express
    if (/^(34|37)/.test(cardNumber)) {
      cardType = 'American Express';
    }
    // Si el número de la tarjeta comienza con "62", es una tarjeta UnionPay
    else if (/^62/.test(cardNumber)) {
      cardType = 'UnionPay';
    }
    // Si el número de la tarjeta comienza con "4", es una tarjeta Visa
    else if (/^4/.test(cardNumber)) {
      cardType = 'Visa';
    }
    // Si el número de la tarjeta comienza con "5", es una tarjeta Mastercard
    else if (/^5/.test(cardNumber)) {
      cardType = 'Mastercard';
    }
    // Si el número de la tarjeta comienza con "6011" o un número que comience con "65", es una tarjeta Discover
    else if (/^6011|^65/.test(cardNumber)) {
      cardType = 'Discover';
    }
    return cardType;
  }

  export const imageCardType = (cardType: string) => {
    switch (cardType) {
      case 'Visa':
        return 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Flogos%2Fvisa.png?alt=media&token=8e11bfad-e870-4aa2-a71a-64a0140de70b'
      case 'Mastercard':
        return 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Flogos%2Fmastercard-logo.png?alt=media&token=3c570802-d190-4f9d-8343-4315d6f28a03';
      case 'American Express':
        return 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Flogos%2Famerican-express.png?alt=media&token=c111dccb-5e8a-43e6-a71e-c5ad200e3b9a'
      case 'Discover':
        return 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Flogos%2Fpng-transparent-discover-card-discover-financial-services-credit-card-bank-finance-credit-card-text-orange-payment.png?alt=media&token=388f2408-9294-411a-b977-fda9026d4b10';
      case 'UnionPay':
        return 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Flogos%2Funionpay.png?alt=media&token=e1ed4d22-c6cb-4aaa-9e44-503f9413cee1';
      default:
        return null;
    }
  };

 export  const expirationDateSchema = Yup.string().test(
    "expirationDate",
    "Formato de fecha no válido. Debe ser MM/AA",
    function (value) {
      if (typeof value === "string") {
        const [month, year] = value.split("/");
        if (!month || !year || month.length !== 2 || year.length !== 2) {
          return false;
        }
        const monthNum = parseInt(month, 10);
        const yearNum = parseInt(year, 10);
        if (monthNum < 1 || monthNum > 12) {
          return false;
        }
        const currentYear = new Date().getFullYear() % 100;
        if (yearNum < currentYear) {
          return false;
        }
        return true;
      }
      return false;
    }
  );

  export const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    cardNumber: Yup.string().required("Campo requerido"),
    expirationDate: expirationDateSchema,
    cvv: Yup.string().required("Campo requerido"),
  });
      
  export interface AuthAndCapturePaguelofacil {
    firstName: string;
    firstSurname: string;
    cardNumber: string;
    expirationYear: string;
    expirationMonth: string;
    cvv: string;
    amount: number;
    description: string;
    taxAmount: number;
    email: string;
    phone: string;
    concept: string;
    lang: string;
  }


  // TYPES PAGUELO_FACIL

  interface DataBinInfoCreditCardIssuerPaguelofacil {
    name: string;
  }

  interface DataBinInfoCreditCardPaguelofacil {
    country: string;
    issuer: DataBinInfoCreditCardIssuerPaguelofacil;
  }
  interface DataBinInfoPaguelofacil {
    risk_score: number;
    credit_card: DataBinInfoCreditCardPaguelofacil;
  }

  interface DataResponsePaguelofacil {
    authStatus: string;
    binInfo: DataBinInfoPaguelofacil;
    cardToken: string;
    cardType: string;
    copOper: string;
    date: string;
    description: string;
    displayNum: string;
    email: string;
    idUsr: number;
    inRevision: boolean;
    isExternalUrl: boolean;
    merchantDescriptor: string;
    messageSys: string;
    name: string;
    operationType: string;
    requestAmount: number;
    returnUrl: string;
    revisionLevel: null | any;
    revisionOptions: null | any;
    status: number;
    totalPay: string
    txDescriptor: string;
    type: string;
    userLogn: string;
    userName: string;
  }

  interface HeaderStatusPaguelofacil {
    code: number;
    description: string;
  }

  export interface ResponsePaguelofacil {
    data: {
      data: DataResponsePaguelofacil,
      headerStatus: HeaderStatusPaguelofacil,
      requestId: string;
      serverTime: string;
      success: boolean;
    },
    status?: string;
  }


  export interface CreditCardAuthCaptureProps {
    onSuccessfulPayment: (data: ResponsePaguelofacil) => void;
    onFailedPayment: () => void;
    setChecked: () => void;
    onBeginPayment?: () => void;
    checked: boolean;
    infoTransaction: {
      firstName: string;
      firstSurname: string;
      email: string;
      phone: string;
      amount: number;
      description?: string;
      concept?: string;
      taxAmount?: number;
      lang?: string;
    };
  }

  