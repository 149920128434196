import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Modal from "ui/Modal";
import Switch from "ui/Switch/switch";
import {
  CreateAddressFormContainer,
  DirTitle,
  InputWrap,
  Error,
  SwitchContainer,
} from "./styled";
import CountryPicker from "ui/CountryPicker";
import CardSelectTypeAddress from "components/Address/card-select-type-address";
import Input from "ui/TextInput";
import PhoneInput from "ui/PhoneInput";
import TextArea from "ui/TextArea";
import { StrokedButton } from "ui/Buttons";
import { addressSchema } from "constants/validators";
import { useNotification } from "libs/context/AlertContext";
import { Address as AddressType } from "types";

interface ModalNewAddressProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  isEditing?: boolean;
  initialValues?: AddressType;
  isFirstAddress?: boolean;
  tabIndex?: number;
}

const ModalAddress = (props: ModalNewAddressProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    initialValues,
    isEditing,
    isFirstAddress,
    tabIndex,
  } = props;
  const { t } = useTranslation();
  const { showWarning } = useNotification();

  const formik = useFormik({
    initialValues: {
      id: initialValues?.id || "",
      country: initialValues?.country || "",
      isBilling: tabIndex === 1 ? true : false,
      isShipping: tabIndex === 0 ? true : false,
      isPrimaryBilling: isFirstAddress
        ? true
        : initialValues?.isPrimaryBilling || false,
      isPrimaryShipping: isFirstAddress
        ? true
        : initialValues?.isPrimaryShipping || false,
      state: initialValues?.state || "",
      city: initialValues?.city || "",
      street: initialValues?.street || "",
      fullName: initialValues?.fullName || "",
      phone: initialValues?.phone || "",
      postalCode: initialValues?.postalCode || "",
      additionalComments: initialValues?.additionalComments || "",
    },
    validationSchema: addressSchema,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
      formik.resetForm();
    },
  });

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CreateAddressFormContainer>
        <DirTitle>
          {isEditing
            ? `${t("label_editing_address")}`
            : `${t("create_a_new_address")}`}
        </DirTitle>

        <InputWrap style={{ marginBottom: "-14px" }}>
          <CountryPicker
            value={{ country: values.country, region: values.state }}
            onChange={(data) => {
              setFieldValue("country", data.country);
              setFieldValue("state", data.region);
            }}
          />
          {errors.country && <Error>{errors.country}</Error>}
        </InputWrap>

        <InputWrap>
          <Input
            label={t("city")}
            name="city"
            onChange={handleChange}
            value={values.city}
            type="text"
          />
          {errors.city && <Error>{errors.city}</Error>}
        </InputWrap>

        <InputWrap>
          <Input
            label={t("address")}
            name="street"
            onChange={handleChange}
            value={values.street}
            type="text"
          />
          {errors.street && <Error>{errors.street}</Error>}
        </InputWrap>

        <InputWrap>
          <Input
            label={`${t("full_name")} (${t("name_and_last_name")})`}
            name="fullName"
            onChange={handleChange}
            value={values.fullName}
            type="text"
          />
          {errors.fullName && <Error>{errors.fullName}</Error>}
        </InputWrap>

        <InputWrap style={{ zIndex: 10 }}>
          <PhoneInput
            placeholder={t("phone") + " *"}
            value={values.phone}
            onChange={(e) => {
              setFieldValue("phone", e);
            }}
            maxLength={20}
          />
          {errors.phone && <Error>{errors.phone}</Error>}
        </InputWrap>

        <InputWrap>
          <Input
            label={t("zip_code")}
            name="postalCode"
            maxLength={6}
            onChange={(e) => {
              handleChange("postalCode")(e);
            }}
            value={values.postalCode}
            type="text"
          />
          {errors.postalCode && <Error>{errors.postalCode}</Error>}
        </InputWrap>

        {!isFirstAddress && (
          <InputWrap>
            <CardSelectTypeAddress
              isShipping={tabIndex === 0}
              isBilling={tabIndex === 1}
              hideBilling={tabIndex === 0}
              onChange={(isBilling, isShipping) => {
                setFieldValue("isBilling", isBilling);
                setFieldValue("isShipping", isShipping);
              }}
              errorMessage={errors.isBilling || errors.isShipping}
            />
            {errors.isBilling && <Error>{errors.isBilling}</Error>}
            {errors.isShipping && <Error>{errors.isShipping}</Error>}
          </InputWrap>
        )}

        <InputWrap>
          <TextArea
            label={t("additional_comments")}
            name="additionalComments"
            onChange={handleChange}
            value={values.additionalComments}
          />
          {errors.additionalComments && (
            <Error>{errors.additionalComments}</Error>
          )}
        </InputWrap>
        <InputWrap>
          <SwitchContainer>
            <div style={{ width: "fit-content" }}>
              <Switch
                label={
                  values.isPrimaryShipping && values.isPrimaryBilling
                    ? t("main_shipping_and_billing_address")
                    : values.isPrimaryShipping
                    ? t("main_shipping_address")
                    : values.isPrimaryBilling
                    ? t("main_billing_address")
                    : t("convert_to_main_address")
                }
                onChange={(e) => {
                  if (values.isShipping && values.isBilling) {
                    setFieldValue("isPrimaryShipping", e);
                    setFieldValue("isPrimaryBilling", e);
                  } else if (values.isShipping) {
                    // Si es solo de envío
                    setFieldValue("isPrimaryShipping", e);
                  } else if (values.isBilling) {
                    // Si es solo de facturación
                    setFieldValue("isPrimaryBilling", e);
                  }
                }}
                // Aquí gestionamos si está activado o no
                value={values.isPrimaryShipping || values.isPrimaryBilling}
                style={{ width: "100%" }}
              />
            </div>
          </SwitchContainer>
        </InputWrap>

        <StrokedButton
          onDisabledClick={() => {
            if (!values.isBilling && !values.isShipping) {
              showWarning(
                `${t("label_you_must_select_at_least_one_address_type")}`
              );
            }
          }}
          onClick={() => {
            if (!values.isBilling && !values.isShipping) {
              showWarning(
                `${t("label_you_must_select_at_least_one_address_type")}`
              );
            } else {
              handleSubmit();
            }
          }}
          margin="20px 0px"
          type="button"
          disabled={
            isSubmitting ||
            Object.keys(errors).length > 0 || // Deshabilitar si hay errores en el formulario
            (!values.isBilling && !values.isShipping) // Deshabilitar si no hay tipo de dirección seleccionado
          }
        >
          {t("save_address")}
        </StrokedButton>
      </CreateAddressFormContainer>
    </Modal>
  );
};

export default ModalAddress;
