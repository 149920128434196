import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid, CardItem } from "./styled";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { THEME } from "constants/theme/theme";
import { P } from "ui/Text";

interface CardSelectTypeAddressProps {
  isBilling: boolean;
  isShipping: boolean;
  onChange: (isBilling: boolean, isShipping: boolean) => void;
  label?: string;
  errorMessage?: string | string[];
  disabled?: boolean;
  hideBilling?: boolean;
}

const CardSelectTypeAddress: FC<CardSelectTypeAddressProps> = (
  props: CardSelectTypeAddressProps
) => {
  const {
    isBilling,
    isShipping,
    onChange,
    label,
    errorMessage,
    disabled,
    hideBilling,
  } = props;
  const { t } = useTranslation();

  function handleCardClick(type: "billing" | "shipment") {
    if (type === "billing") {
      onChange(!isBilling, isShipping);
    } else if (type === "shipment") {
      onChange(isBilling, !isShipping);
    }
  }

  return (
    <Container>
      <P
        style={{
          marginBottom: ".5rem",
          fontSize: "14px",
          color: THEME.colors.black80,
        }}
      >
        {label ? label : t("address_type")}
      </P>
      <Grid>
        {!hideBilling ? (
          <CardItem
            selected={isBilling}
            disabled={disabled}
            onClick={() => {
              if (!disabled) {
                handleCardClick("billing");
              }
            }}
          >
            <AttachMoneyIcon
              style={{
                color:
                  disabled || !isBilling
                    ? THEME.colors.black40
                    : THEME.colors.primary,
              }}
            />
            {t("billing")}
          </CardItem>
        ) : null}

        {hideBilling ? (
          <CardItem
            selected={isShipping}
            disabled={disabled}
            onClick={() => {
              if (!disabled) {
                handleCardClick("shipment");
              }
            }}
          >
            <LocalShippingIcon
              style={{
                color:
                  disabled || !isShipping
                    ? THEME.colors.black40
                    : THEME.colors.primary,
              }}
            />
            {t("shipment")}
          </CardItem>
        ) : null}
      </Grid>
      {errorMessage && (
        <p style={{ color: THEME.colors.negative100 }}>
          {Array.isArray(errorMessage) ? errorMessage.join(", ") : errorMessage}
        </p>
      )}
    </Container>
  );
};

export default CardSelectTypeAddress;
