import {initializeApp} from "firebase/app";
import {getFirestore, Firestore} from 'firebase/firestore/lite';
import {getFirestore as getFirestoreFull, Firestore as FirestoreFull} from 'firebase/firestore';
import {getFunctions} from "firebase/functions";
import {getAnalytics} from "firebase/analytics";
import {getStorage} from "firebase/storage";
import {getAuth } from "firebase/auth";

const BUCKET_NAME = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

const firebaseConfig = { 
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// using emulators
// if (window.location.hostname === 'localhost') {
//   connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
// }

export const firestore: Firestore  = getFirestore(app);
export const firestoreFull: FirestoreFull = getFirestoreFull(app);
export const storage = getStorage(app, `gs://${BUCKET_NAME}`);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);


//PARA USAR SECRET MANAGER
// import { initializeApp } from "firebase/app";
// import { getFirestore, Firestore} from 'firebase/firestore/lite';
// import { getFirestore as getFirestoreFull, Firestore as FirestoreFull } from 'firebase/firestore';
// import { getFunctions } from "firebase/functions";
// import { getAnalytics } from "firebase/analytics";
// import { getStorage } from "firebase/storage";
// import { getAuth } from "firebase/auth";
// import { secretManagerClient } from "libs/secret-manager";

// let app;
// let firestore;
// let firestoreFull;
// let storage;
// let analytics;
// let functions;
// let auth;

// async function initializeFirebase() {
//   const firebaseConfig = {
//     // projectId: await secretManagerClient('REACT_APP_FIREBASE_PROJECT_ID'),
//     // apiKey: await secretManagerClient('REACT_APP_FIREBASE_API_KEY'),
//     // authDomain: await secretManagerClient('REACT_APP_FIREBASE_AUTH_DOMAIN'),
//     // storageBucket: await secretManagerClient('REACT_APP_FIREBASE_STORAGE_BUCKET'),
//     // messagingSenderId: await secretManagerClient('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
//     // appId: await secretManagerClient('REACT_APP_FIREBASE_APP_ID'),
//     // measurementId: await secretManagerClient('REACT_APP_FIREBASE_MEASUREMENT_ID'),
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   };

//   app = initializeApp(firebaseConfig);
//   firestore = getFirestore(app);
//   firestoreFull = getFirestoreFull(app);
//   storage = getStorage(app, `gs://${firebaseConfig.storageBucket}`);
//   analytics = getAnalytics(app);
//   functions = getFunctions(app);
//   auth = getAuth(app);
  
// }

// (async () => {
//   await initializeFirebase();
// })();

// export { firestore, firestoreFull, storage, analytics, functions, auth };





