import Extras1 from 'assets/extras (1).png';
import Extras2 from 'assets/extras (2).png';
import Extras3 from 'assets/extras (3).png';
import Extras4 from 'assets/extras (4).png';

export const BANNERS_PATH = 'PANAMA/Services/Categories/Yatchs/Banners'
export const SERVICES_PATH = 'PANAMA/Services/Categories/Yatchs/Posts'
export const DESTINATIONS_PATH = 'PANAMA/Services/Categories/Yatchs/Destinations'
export const TECHNICAL_PATH = 'PANAMA/Services/Categories/TechnicalServices/Posts'
export const MARKETING_PATH = 'PANAMA/Services/Categories/Marketing/Posts'
export const YACHT_EXTRA_PATH = 'PANAMA/Services/Categories/Yatchs/ExtraServices'

export const YACHT_VIDEO_URL = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/countries%2FPANAMA%2Fvideos%2FVIDEO-YATE-YOUTUBE.mp4?alt=media&token=f7d77221-8e35-4496-a513-f1dcc39e8c27'

export const EXTRAS_MOCK = [
    {
        image: Extras1 as string,
        name: {
            es: "SERVICIO DE DJ",
            en: "DJ SERVICE"
        },
        shortDescription: {
            es: "Desde $225: Los mejores Djs y equipos, con la música que tu decidas.",
            en: "From $225: The best DJs and equipment, with the music you decide."
        }
    },
    {
        image: Extras2 as string,
        name: {
            es: "BEBIDAS",
            en: "DRINKS"
        },
        shortDescription: {
            es: "Disponibles paquetes de bebidas básicos, premium o hechos a la medida.",
            en: "Basic, premium or custom-made drink packages available."
        }
    },
    {
        image: Extras3 as string,
        name: {
            es: "COMIDA",
            en: "FOOD"
        },
        shortDescription: {
            es: "Menú disponible o menú hecho a la medida",
            en: "Available menu or custom made menu."
        }
    }, {
        image: Extras4 as string,
        name: {
            es: "TODO INCLUIDO",
            en: "ALL INCLUDE"
        },
        shortDescription: {
            es: "Destino: Taboga, Las perlas, Bahía de panamá.",
            en: "Destination: Taboga, The Pearls, Panama Bay."
        }
    },
];
