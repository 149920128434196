import React from 'react';
import styled from 'styled-components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useRegisterEvent } from 'hooks/useAnalytics/useAnalytics';
import { useTranslation } from 'react-i18next';


type WhatsAppButtonProps = {
    phoneNumber: string;
    message?: string;
    link: string;
}

const Button = styled.button`
    background-color: #25D366;
    color: white;
    padding: 10px 20px;
    margin: 0px 0px 12px 0px;
    border-radius: 16px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #1E9E5E;
    }
`;

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber, message = '', link }) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(`${message}, url: ${link}`)}`;
    const registerEvent  = useRegisterEvent();

    const { t } = useTranslation();

    // page_path: pagePath,
    // event_category: eventCategory,
    // event_action: eventAction,
    // event_label: eventLabel,

    const handleRegisterEvent = () => {
        registerEvent({
            pagePath: link,
            eventCategory: 'WhatsApp button',
            eventAction: 'click',
            eventLabel: 'Click on WhatsApp button',

        });
    }


    return (
        <a onClick={handleRegisterEvent} href={whatsappUrl} target="_blank" rel="nofollow noopener noreferrer"  style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="button">
                <WhatsAppIcon />
                {t("contact_by_whatsapp")}
            </Button>
        </a>
    );
};

export default WhatsAppButton;
