import { SagaIterator } from "@redux-saga/core";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import login from "services/login/email";
import loginWithGoogle from "services/login/google";
import {
  setError,
  setLoading,
  setUser,
  userLoginSagas,
  loginGoogleSagas,
  setEmailVerification,
} from "redux/user/reducer";
import { getLanguage } from "redux/common";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "config/firebase";

function* loginSaga(action: any): SagaIterator {
  yield put(setLoading(true));
  const language = yield select(getLanguage);
  const errorMessage =
    language === "es"
      ? "El correo o la contraseña son incorrectos"
      : "The email or password is incorrect";

  try {
    const { email, password } = action.payload;

    const { data: user, status } = yield call(login, email, password);

    if (status === "OK") {
      const idToken = user.session.idToken;
      const customToken = user.session.customToken;

      yield call(signInWithCustomToken, auth, customToken);

      const currentUser = auth.currentUser;
      if (currentUser && !currentUser.emailVerified) {
        yield put(setEmailVerification(true));
      }

      yield put(
        setUser({
          email: currentUser?.email,
          token: idToken,
          customToken: customToken,
          ...user,
        })
      );

      /* window.location.href = "/";  */ // Redirigir al usuario a la página principal

      yield put(setError(false));
      yield put(setLoading(false));
    } else {
      // Si hay algún error, limpiar el estado del usuario
      yield put(setUser(null));
      yield put(setLoading(false));
    }
  } catch (err: any) {
    yield put(setError(`Error servicio iniciando sesión, ${errorMessage}`));
    yield put(setLoading(false));
  } finally {
    yield put(setLoading(false));
  }
}

function* loginGoogleSaga(action: any) {
  yield put(setLoading(true));
  try {
    const { data: user } = yield call(
      loginWithGoogle,
      action?.payload?.credential
    );
    if (user.uid) {
      yield put(
        setUser({
          ...user,
        })
      );
      yield put(setLoading(false));
    } else {
      console.error("loginGoogleSaga error", user);
      yield put(setError("Login google error"));
      yield put(setLoading(false));
    }
  } catch (err: any) {
  } finally {
    yield put(setLoading(false));
  }
}

export function* userGoogleSagas(): SagaIterator {
  yield all([takeLatest(loginGoogleSagas.trigger, loginGoogleSaga)]);
  yield all([takeLatest(userLoginSagas.trigger, loginSaga)]);
}
