import { useState, useCallback } from 'react';
import getUserByIdService from 'services/user/get-user-by-id-service/get-user-by-id-service';
import { ServiceResponse } from 'types';

const useGetUserById = () => {
  const [userData, setUserData] = useState<any | null>(null); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState<string | null>(null); 

  const getUserById = useCallback(async (id: string): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      const response: ServiceResponse = await getUserByIdService({ id });

      if (response.success) {
        setUserData(response.data);
      } else {
        setError(response?.message || 'error getting user info');
      }
    } catch (err: any) {
      setError(err.message || 'Error al obtener los datos del usuario');
    } finally {
      setLoading(false);
    }
  }, []);

  return { getUserById, userData, loading, error };
};

export default useGetUserById;
