import {createSelector} from '@reduxjs/toolkit'

// TODO: import Store type and add reducer type in Store interface
const getCart = (state: any) => state.cart

export const totalCart = createSelector(getCart, (cart) => cart.total)

export const itemsCart = createSelector(getCart, (cart) => cart.items)

export const totalItemsCart = createSelector(getCart, (cart) => cart.items.length)
