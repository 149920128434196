import { query, where, collection, getDocs } from "firebase/firestore/lite";
import { firestore } from "config/firebase";

const verifyNickname = async (nickname: string) => {
  try {
    const usersRef = collection(firestore, 'Users');
    const queryEqualsNickname = query(usersRef, where('nickname', '==', nickname));
    const querySnapshot = await getDocs(queryEqualsNickname);

    const isNicknameInUse = !querySnapshot.empty;
    return isNicknameInUse;

  } catch (error) {
    console.error('Error at verify nickname', error);
  }
}

export default verifyNickname;
