import styled from 'styled-components'


export const Title = styled.h2`
  text-align: left;
  margin-top: 10px;
  text-align: center;
  
  ${props => props.theme.typography.subheading.sm.bold};
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.md.bold};

  }


  @media only screen and (max-width: 480px) {
    margin-top: 0px;
  }

`

export const Container = styled.div`
  background: #E7EDF5;
  justify-content: center;
  padding: 1rem 0;
  @media only screen and (max-width: 992px) {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`


export const ServiceDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  @media only screen and (min-width: 768px) {
    padding: .5rem;
  }
`
export const Services = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  @media only screen and (max-width: 600px) {
    
  }
`
export const Service = styled.div`
  display: flex;
  box-shadow: 0 1px 10px 1px rgba(2, 64, 154, 0.1);
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  //margin-top: 3rem;
  margin: 3rem auto 2rem auto;
  width: 98%;
  height: auto;
`
export const ServiceImg = styled.img`
  transform: translateY(-3rem);
  height: 120px;
  width: 120px;
  width: 100%;
  object-fit: contain;
  margin-bottom: -3rem;
  align-self: center;

  @media only screen and (max-width: 600px) {
    font-size: .75rem;
    transform: translateY(-3.5rem);
  }

  // max 440
  @media only screen and (max-width: 440px) {
    margin-bottom: -3.5rem;
  }
`

export const BlueContainer = styled.div`
  background: #0089FF;
  ${props => props.theme.typography.body.md.bold};
  color: #ffffff;
  width: 100%;
  
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transform: translateY(1rem);
  margin-top: -1rem;
  height: 150px;

  @media only screen and (max-width: 480px) {
    height: 130px;
  }
  
`

export const ServiceDesc = styled.div`
  background: #ffffff;
  height: 100%;
  color:#0C121D;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 0rem 1rem;
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  height: 180px;
  @media only screen and (max-width: 768px) {
    
  }
`
export const LittleText = styled.p`
  ${props => props.theme.typography.body.sm.regular};
  @media only screen and (max-width: 600px) {
    font-size: .625rem;
  }
`

export const LinkServices = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
`
export const TextBold = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.sm.bold};
  }


  @media only screen and (max-width: 480px) {
    font-size: 0.65rem;
  }
`

export const ViewLink = styled.a`
  display: block;
  cursor: pointer;
  text-align: center;
  color:#3A36DB;
  background: #E3F1FE;
  border-radius: 1rem;
  padding: 2px 6px;
  width: 102%;

  //hover
  &:hover {
    opacity: 0.8;
  }


  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.md.bold};
    margin: 6px 0px;
  }

  // max 480
  @media only screen and (max-width: 480px) {
    font-size: 0.80rem;
  }

  // max 340
  @media only screen and (max-width: 340px) {
    font-size: 0.65rem;
    margin-top: 12px;
  }
`

export const ServicesTitle = styled.h2`
  ${props => props.theme.typography.subheading.lg.bold};
  @media only screen and (max-width: 992px) {
    text-align: ${props => props.alignM || "left"};
  }
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.subheading.sm.bold};
  }
`
