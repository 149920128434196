import { useState, useCallback } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import { firestore } from 'config/firebase';

interface FirestoreDoc {
  uid: string;
  title: string;
  description: string;
  // Otros campos del documento
}

const BATCH_SIZE = 10;

// Función para dividir los IDs en lotes
const chunkArray = (array: string[], size: number): string[][] => {
  const result: string[][] = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const useGetClassifiedListChats = () => {
  const [loadingGetClassifiedListChats, setLoading] = useState<boolean>(false);
  const [errorGetClassifiedListChats, setError] = useState<Error | null>(null);
  const [collectionData, setCollectionData] = useState<FirestoreDoc[] | null>(null);

  const fetchClassifiedChats = useCallback(async (country: string, ids: string[]) => {
    setLoading(true);
    setError(null);

    try {
      const db = firestore;
      const path = `${country.toUpperCase()}/Classifieds/Posts`;

      // Dividimos los IDs en lotes de 10
      const batches = chunkArray(ids, BATCH_SIZE);

      // Realizamos múltiples consultas en paralelo
      const queries = batches.map((batch) => {
        const batchQuery = query(
          collection(db, path),
          where("id", "in", batch)
        );
        return getDocs(batchQuery);
      });

      // Esperamos a que todas las consultas terminen
      const results = await Promise.all(queries);

      // Combinamos los resultados en un solo array de documentos
      const combinedData: FirestoreDoc[] = results.flatMap((querySnapshot) =>
        querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id } as FirestoreDoc))
      );

      setCollectionData(combinedData);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  }, []);

  function clearCollectionData() {
    setCollectionData(null);
  }

  return {
    loadingGetClassifiedListChats,
    errorGetClassifiedListChats,
    collectionData,
    fetchClassifiedChats,
    clearCollectionData,
  };
};
