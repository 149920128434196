import ForYou1 from 'assets/foryou (1).png';
import ForYou2 from 'assets/foryou (2).png';
import ForYou3 from 'assets/foryou (3).png';

export const RESPONSIVE_BREAKPOINTS = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

export const SUB_SERVICES = [
    {
        image: ForYou1,
        title: {
            es: "Contenedor completo  (FCL)",
            en: "ingles"
        },
        desc: {
            es: "Esta es la mejor opción para pedidos de gran volumen te ayudamos en todo el preceso de transporte y documentacion para que recibas tu mercancia de forma segura.",
            en: "ingles"
        }
    },
    {
        image: ForYou2,
        title: {
            es: "Carga consolidada (LCL)",
            en: "ingles"
        },
        desc: {
            es: "Una opción para compradores que desean ahorrar dinero con el transporte marítimo, pero que no tienen suficiente volumen para llenar un contenedor de envío completo, Desde 1 caja.",
            en: "ingles"
        }
    },
    {
        image: ForYou3,
        title: {
            es: "CARGA AEREA",
            en: "ingles"
        },
        desc: {
            es: "Diseñado para envíos de carga más livianos, muestras o compras de urgencia, ya que sus tiempos de entrega son más cortos.\n",
            en: "ingles"
        }
    }
];
