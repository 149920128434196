

import { PropertyClassified} from 'types/classifieds'
import { ContentCardByTypeContainer, ImageProperty,ColumnProperty, ValueProperty} from './styled';
import { EnumClassifiedPropertyIconUrls } from 'constants/classifieds';
import {  parseValueClassifiedProperty } from './main';

const EXCLUDED_PROPERTIES = ['Year', 'Brand', 'Fuel type', 'Model', 'New', 'Vehicle type', 'Furnished', 'Gym', 'Social area', 'Mileage',  'Seller type', 'Type of property', 'Type of operation'

 ]
interface IContentCardClassified {
    properties: PropertyClassified[];
}

const ContentCardClassified = (props:  IContentCardClassified) => {
    const { properties } = props;

    function getIcon(key: string) {
            switch (key) {
                case 'Rooms':
                    return EnumClassifiedPropertyIconUrls.Rooms;
                case 'Bathrooms':
                    return EnumClassifiedPropertyIconUrls.Bathrooms;
                case 'Parkings':
                    return EnumClassifiedPropertyIconUrls.Parkings;
                case 'Square meters':
                    return EnumClassifiedPropertyIconUrls.SquareMeters;
                case 'Transmission':
                    return EnumClassifiedPropertyIconUrls.Transmission; 
                case 'Seats':
                    return EnumClassifiedPropertyIconUrls.Seats;  
                case 'Doors':
                    return EnumClassifiedPropertyIconUrls.Doors;
                default:
                    return 'default';
            }


    }

    

    // const language = useSelector(getLanguage);
    const propertiesFiltered = properties.filter((item: PropertyClassified) => !EXCLUDED_PROPERTIES.includes(item.key.en));

    return (
        <ContentCardByTypeContainer>
            {
                propertiesFiltered.map((item: PropertyClassified, index: number) => {
                    return (
                        <ColumnProperty key={item.id}>
                            <ImageProperty src={getIcon(item.key.en)} alt={item.key.en} title={item.key.en} />
                            <ValueProperty isNumber={!isNaN(parseInt(item.value as any))}>{parseValueClassifiedProperty(item.value as any)}</ValueProperty>
                        </ColumnProperty>
                    )
                })
            }
        </ContentCardByTypeContainer>
    )
}
export default ContentCardClassified;