import styled from 'styled-components';
import {THEME} from 'constants/theme/theme';


export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
`

export const Subcontainer = styled.div`
    display: flex;

    @media (max-width: 968px) {
        flex-direction: column;
    }
`

export const KeyItemGrid = styled.div`
    display: flex;
    gap: .3rem;
    padding: .3rem .5rem;
    /* border: 1px solid #000;
    border-radius: .3rem; */
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
    color: ${THEME.colors.classifieds};

    & > p {
        margin: 0;
    }
`

export const LabelKeyItemGrid = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
`

export const ValueKeyItemGrid = styled.p`
    font-family: 'Montserrat', sans-serif;
    margin: 0;
`

export const ContainerChatButton = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 4rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        margin-top: 0rem;
    }

`
