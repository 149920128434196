import { httpsCallable,  } from "firebase/functions";
import {ServiceResponse} from 'types'
import {functions} from 'config/firebase'

const login = async (email: string, password: string): Promise<ServiceResponse> => {
  try{
    const loginWithEmail = httpsCallable(functions, 'emailAuth')
    const result: any = await loginWithEmail({ email, password });
    return { status: 'OK', data: result.data.data }
  }
  catch(err: any){
    console.error('login error', err)
    throw new Error(err)
  }

}

export default login
