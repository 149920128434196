import Destinos1 from 'assets/destinos (1).png';
import Destinos2 from 'assets/destinos (2).png';
import Destinos3 from 'assets/destinos (3).png';
import Destinos4 from 'assets/destinos (4).png';
import { useTranslation } from 'react-i18next';

export const useOptionsFormRealState= () =>{
    const { t } = useTranslation();

    return [
        t('rent_property'),
        t('buy_property'),
        t('sell_property'),
        t('appraisal'),
        t('advice'),
        t('administration'),
        t('investment'),
        t('loans'),
        t('insurance'),
        t('others')
      ];

}
  

export const DATA = [
    {
        image: Destinos1,
        name: {
            es: "Pasa día isla Taboga",
            en: "ISLA TABOGA"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "ingles"
        }
    },
    {
        image: Destinos2,
        name: {
            es: "Pasa día isla Taboga",
            en: "ARCHIPIELAGO DE LAS PERLAS"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "ingles"
        }
    },
    {
        image: Destinos3,
        name: {
            es: "Pasa día isla Taboga",
            en: "BAHIA DE PANAMA"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "ingles"
        }
    }, {
        image: Destinos4,
        name: {
            es: "Pasa día isla Taboga",
            en: "PUENTE DE LAS AMERICAS"
        },
        shortDescription: {
            es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
            en: "ingles"
        }
    },
];

export const DATA_MOCK = [
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de cocle",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle",
            en: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de cocle",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle",
            en: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de cocle",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle",
            en: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de cocle",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle",
            en: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de cocle",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle",
            en: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle"
        },
    },
    {
        image: "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
        price: 34900,
        dimensions: "7,015.82 mts2",
        location: "Provincia de cocle",
        title: {
            es: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle",
            en: "Lote Oportunidad ubicado en el retiro, anton, provincia de cocle"
        },
    }
];
