import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 5px;
    align-items: center;
    @media (min-width: 768px) {
        display: none;
    }
`;

export const Text = styled.h1`
    margin-left: 3%;
    font-size: 16px;
    font-weight: 600;
`;

export const Image = styled.img`
    width: 55%;
    height: 60%;
    margin-right: 0.8rem;
    border-radius:10px ;
`;

export const Hr = styled.hr`
    width: 100%;
    border: 1px solid black;
    @media (min-width: 992px) {
        display: none;
    }
`;
