import { httpsCallable } from "firebase/functions";
import { ServiceResponse } from "types";
import { functions } from "config/firebase";
import { IClassified } from "types";

const createClassifiedService = async (props: IClassified): Promise<ServiceResponse> => {
    const newClassifiedService = httpsCallable(functions, "newClassified");

    try {
        const response: any = await newClassifiedService(props) as ServiceResponse;

        if (response.data.status !== 'OK' || !response.data.success) {
            throw new Error(response.message);
        }

        return response.data;
    } catch (error: any) {
        if (error.code) {
            switch (error.code) {
                case 'invalid-argument':
                    throw new Error('Invalid argument: ' + error.message);
                case 'unauthenticated':
                    throw new Error('Unauthorized access: ' + error.message);
                case 'already-exists':
                    throw new Error('A classified with this title already exists: ' + error.message);
                default:
                    throw new Error('An unexpected error occurred: ' + error.message);
            }
        } else {
            throw new Error('An unexpected error occurred. Please try again later.');
        }
    }
};

export default createClassifiedService;