import dayjs from "dayjs";
import 'dayjs/locale/es';  // Importa la localización en español
import _ from "lodash";
dayjs.locale('es');

export const parseTimestampToDate = (timestamp) => {
        return dayjs(timestamp.seconds * 1000).format(
                "DD/MM/YYYY"
        );
};

export const parseDateIso8601ToDate = (date) => {
        return dayjs(date).format(
                "DD/MM/YYYY"
        );
}

/**
 * @description: parse currency to symbol
 */

export const parseCurrencyToSymbol = (currency: string) => {
        switch (currency) {
                case "USD":
                        return "$";
                case "EUR":
                        return "€";
                case "GBP":
                        return "£";
                case "COP":
                        return "$";
                case "MXN":
                        return "$";
                default:
                        return "";
        }
}

export function isMobile() {
        return window.innerWidth <= 992;
}

export function priceFormat(value: number | string): string {
        let stringValue = value.toString();

        let parts = stringValue.split('.');

        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        if (parts.length < 2) {
                parts.push('00');
        } else {
                parts[1] = parts[1].length === 1 ? parts[1] + '0' : parts[1];
        }

        return parts.join(',');
}

export function onlyNumbers(input: string | number | Event): string {
        let text: string;

        // Verificar si el input es un Event
        if (input instanceof Event && input.target && (input.target as HTMLInputElement).value) {
                text = (input.target as HTMLInputElement).value;
        } else if (typeof input === 'string' || typeof input === 'number') {
                text = input.toString();
        } else {
                throw new Error("La entrada debe ser un string, un número o un Event");
        }

        // Extraer solo los números del texto
        const numbers = text.match(/\d+/g) || [];
        return numbers.join('');
}

export function capitalize(text: string) {
        return _.capitalize(text);
}

export function isValidEmail(email) {
        var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
}
