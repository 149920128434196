import { useEffect } from 'react';
import MainLayout from 'views/layout/MainLayout'
import { Container, Content, ItemChoice, ImageChoice } from "./styled";
import { H5, H2 } from 'ui/Text';
import { Link } from "react-router-dom";
import useAnalytics from 'hooks/useAnalytics/useAnalytics';
import { useTranslation } from 'react-i18next';
import { MAIN_ROUTES } from 'constants/routes';
import SimpleImageBanner from 'ui/simple-image-banner';

const Products = () => {
    const registerLog = useAnalytics();
    const { t } = useTranslation();

    useEffect(() => {
        registerLog({ screenName: 'Products main screen', pageTitle: 'Products of whatdoyouneed7.com', pagePath: window.location.pathname, pageLocation: window.location.href });
    }, [registerLog]);

    return (
        <MainLayout type='products'>
            <Container>
                <Content>
                    <SimpleImageBanner hrefLink='https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+ofertas+en+Gaming+y+Softwares+Digitales.&type=phone_number&app_absent=0' hideDesktop imageUrl='https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FProductos%20desde%20china%20a%20panama%20SIn%20intermediarios%20a%20precios%20de%20fabrica.webp?alt=media&token=a1beed9c-04a2-4a7f-93cf-2c54423bc3e4' />
                    <H2 style={{ color: '#FFF' }} margin='0px 0px 32px 0px'>{t('our_options_for_you')}</H2>
                    <Link style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to={MAIN_ROUTES.ProductsStore}>
                        <ItemChoice background='#E3F1FE'>
                            <ImageChoice src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fbolsa-de-la-compra.png?alt=media&token=ac27e7ff-6f21-4eba-9e78-8e4180bc4597" loading="lazy" alt="Bolsa compra" title="Bolsa compra" />
                            <H5>{t('retail_store')}</H5>
                        </ItemChoice>
                    </Link>
                    <Link style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to={MAIN_ROUTES.ProductsMachinery}>
                        <ItemChoice background='#F6E7FE'>
                            <ImageChoice src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fdemoledor.png?alt=media&token=6f57e98b-99b5-454c-8fd9-39e17a5332a5' loading='lazy' alt='Demoledor' title='Demoledor' />
                            <H5>{t('machinery_equipment')}</H5>
                        </ItemChoice>
                    </Link>
                    <Link to={MAIN_ROUTES.ProductsWholesale} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <ItemChoice background='#FDC2BC'>
                            <ImageChoice src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fmayorista.png?alt=media&token=0bf06999-663e-4d88-ac46-ccc399745214' loading='lazy' alt='Mayorista' title='Mayorista' />
                            <H5>{t('merchandise_supplies')}</H5>
                        </ItemChoice>
                    </Link>
                </Content>
            </Container>
        </MainLayout>
    )
}

export default Products;
