import { useNavigate } from "react-router-dom";
import {
  ButtonContainer,
  Container,
  DescriptionText,
  Image,
  ImageWrapper,
  CardInfoWrapper,
  TitleText,
  Link,
  PriceText
} from "./styled";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PriceFromText } from "ui/Text/price-type-text";
import { setServiceDetail } from "modules/services/store";
import { getCountry } from "redux/common";
import parseHtmlToText from "utils/parseHtmlToText";
interface ServicesCardProps {
  id?: string;
  linkButton?: boolean;
  showPrice?: boolean;
  image: string;
  title?: any;
  description?: any;
  price?: any;
  shortDescription?: string;
  service: any;
} 

const ServicesCard: FC<ServicesCardProps> = ({
  id,
  image,
  title,
  description,
  price,
  shortDescription,
  service
}) => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const country = useSelector(getCountry);
  const { t } = useTranslation();

  const handleButtonClick = (service) => {
    dispatch(setServiceDetail(service));
    navigate(`detail?id=${id}`, {
      state: { route: `${country}/Services/Categories/` },
    });
  }

  return (
    <Container>
      <ImageWrapper>
        <Image src={image} onClick={() => handleButtonClick(service)} loading='lazy' alt={title} title={title} />
      </ImageWrapper>
      <CardInfoWrapper>
        <TitleText>{title}</TitleText>
        {
          price && (
            <div style={{ width: '100%', display: 'flex', gap: '.5rem' }}>
              <PriceFromText style={{ marginRight: '0rem' }}>
                {t("From")}
              </PriceFromText>
              <PriceText>
                {price && (
                  <span>
                    ${price}
                  </span>
                )}
              </PriceText>
            </div>
          )
        }
        {description && (
          <DescriptionText>
            {parseHtmlToText(shortDescription || '') || "Error description"}
          </DescriptionText>
        )}
      </CardInfoWrapper>
      <ButtonContainer>
        <Link onClick={() => handleButtonClick(service)} href={""}>{t('view_service')}</Link>
      </ButtonContainer>
    </Container>
  );
};

export default ServicesCard;
