import { BREAKPOINTS } from "constants/index";
import {
  Container,
  Content,
  Description,
  Image,
  ImageContainer,
  TextContainer,
  Title,
} from "./styled";
import { useTranslation } from "react-i18next";


export const Banner = () => {
  const { t } = useTranslation();

  return (
    <Container fondo="#EBEAFC" pav="1rem" pah="8rem">
      {window.innerWidth < BREAKPOINTS.lg && (
        <Title family={"Outfit, monospace"} weight={700} color={"#0C121D"}>
          {t('discover_panama')}
        </Title>
      )}
      <Content>
        <TextContainer>
          {window.innerWidth > BREAKPOINTS.lg && (
            <Title family={"Outfit, monospace"} weight={700} color={"#0C121D"}>
              {t('discover_panama')}
            </Title>
          )}
          <Description>
            {t('country_of_abundance_where_the_north_and_the_south_connect')}.
          </Description>
        </TextContainer>
        {window.innerWidth > BREAKPOINTS.lg && (
          <ImageContainer>
            <Image
              src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Fimages%2Fmujer-sonriente-algo-presentacion-camara-removebg-preview%202.png?alt=media&token=974c48a5-1dda-4d24-8b75-ed1cf130cbc4"
              loading="lazy" alt="Mujer sonriendo" title="Mujer sonriendo"
            />
          </ImageContainer>
        )}
      </Content>
    </Container>
  );
};
