import { LOW_PRICE_FILTER, HIGH_RATING_FILTER } from 'constants/index';
import { DBCountryReference,  ProductsCategoryType, FiltersProducts } from 'types';
import { DB_ROUTES } from "constants/routes";

export function selectImageSize (bannersData) {
    const imagesByResolution = () => {
        if (window.innerWidth < 768) {
            return bannersData.imagesMobile
        } else {
            return bannersData.imagesDesktop
        }
    }
    return imagesByResolution()
}

interface QueryFilterFirestore {
    field: string;
    operator: string;
    value: string | number;
  }
  
  interface OrderBy {
    field?: string;
    direction?: 'asc' | 'desc';
  }
  
  export const buildQueryFilterProducts = (filters: FiltersProducts) => {
    const { priceRange, categoryId, order } = filters;
  
    const query: QueryFilterFirestore[] = [];
    const orderBy: OrderBy = {};
  
    if (priceRange) {
      query.push({
        field: 'price',
        operator: '>=',
        value: priceRange[0],
      });
  
      query.push({
        field: 'price',
        operator: '<=',
        value: priceRange[1],
      });
    }
  
    if (categoryId) {
      query.push({
        field: 'idCategory',
        operator: '==',
        value: categoryId,
      });
    }
  
    if (order) {
      switch (order) {
        case LOW_PRICE_FILTER:
          orderBy.field = 'price';
          orderBy.direction = 'asc';
          break;
        case HIGH_RATING_FILTER:
          orderBy.field = 'rating';
          orderBy.direction = 'desc';
          break;
        default:
          break;
      }
    }
  
    return {
      query,
      orderBy,
    };
  };


export const getDBroutebyMainCategoryProduct = (mainCategory: ProductsCategoryType) => {
    switch (mainCategory) {
      case 'store':
        return `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_STORE}`;
      case 'wholesale': 
        return `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_WHOLESALE}`;
      case 'machinery':
        return `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_MACHINERY}`;
      default:
        return `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_STORE}`;
    }

};