import {
  Browser,
  BrowserDesc,
  BrowserImg,
  FlexColumn,
  ImageWrapper,
  SmallSection,
  Text,
  Title
} from "./styled";
import { Button } from "ui/Buttons";
import { useTranslation } from "react-i18next";

import yachtImg from "assets/yacht.png";

const FooterBanner = () => {
  const { t } = useTranslation();

  return (
    <SmallSection>
      <FlexColumn pah="1rem">
        <Browser>
          <BrowserDesc>
            <Title>
              {t('do_you_own_a_yacht_and_want_to_rent_it_we_promote_it_for_you')}
            </Title>
            <Text>
              {t('register_with_us_and_start_earning_money')}
            </Text>
            <Button
              style={{ alignSelf: "left", marginTop: "10px", fontSize: "1.1rem", fontWeight: "bold", width: "60%" }}
              onClick={() => {
                window.open("https://api.whatsapp.com/send?phone=50764050893&text=Hola%20deseo%20anunciar%20mi%20yate%20en%20su%20plataforma", "_blank");
              }}
            >
              {t('register_here_with_exclamation_mark')}
            </Button>
            <ImageWrapper>
              <BrowserImg src={yachtImg} loading="lazy" />
            </ImageWrapper>
          </BrowserDesc>
        </Browser>
      </FlexColumn>
    </SmallSection>
  );
};

export default FooterBanner;
