import styled from "styled-components";

export const Container = styled.div`
    width: 144px;
    height: 80px;
    background-color: #E3F1FE;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        transform: scale(1.02);
    }
    @media (max-width: 1300px) {
        width: 160px;
    }

    @media (max-width: 1100px) {
        width: 144px;
    }

    @media (max-width: 992px) {
        width: 20vw;
    }

    @media (max-width: 768px) {
        width: 25vw;
    }

`;

export const ImageIcon = styled.img`
    margin-top: 16px;
    width: 30px;
    height: 30px;
`;

export const Text = styled.p`
    font-family: 'Montserrat', sans-serif;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #0C121D;
    text-align: center;

    @media (max-width: 992px) {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;
