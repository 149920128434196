import { useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import { ContainerImage, Img } from "./styled";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import VideoPlayer from "components/VideoPlayer";
import './styles.css'

export const Image = (props) => {
  const { image } = props;
  return (
      <ContainerImage>
        {
          <Img loading="lazy" alt="Imagen Principal del Producto" title="Imagen Principal del Producto" src={image?.original || image} />}
      </ContainerImage>
  );
};

interface PropsMediaGallery {
  data: any[];
  currentSelected: number;
}

// funcion que toma un url y detecta si es una imagen o un video y retorna el componente correspondiente
const ImageOrVideo = (url: string) => {

  if (typeof url === 'string' &&  url.includes('.mp4')) {
    return (
          <VideoPlayer
          url={url}
          playing={false}
          volume={0}
          showTitle={false}
        />
    );
  }
  return  <Image image={url} loading="lazy" alt="Imagen Principal del Producto" title="Imagen Principal del Producto" />;
}


// Main component
const MediaGalleryComponent = (props: PropsMediaGallery) => {
  const { data, currentSelected } = props;

  const imageGalleryRef = useRef<any | null>(null);
  const [countClick, setCountClick] = useState<number>(0);

  useEffect(() => {
    if ( countClick > 1 ) {
      if (imageGalleryRef.current) {
        imageGalleryRef.current.toggleFullScreen();
      }
      setCountClick(0);
    }
  }, [countClick]);

  return (
    <ImageGallery
      showNav
      showFullscreenButton={!false}
      ref={imageGalleryRef}
      onClick={
        () => {
          setCountClick(countClick + 1);
        }
      }
      showPlayButton={false}
      items={data}
      startIndex={currentSelected}
      renderItem={(item: string) => {
        return ImageOrVideo(item); 
      }}
      renderLeftNav={(onClick, disabled) => {
        return (
          <button
            type="button"
            className="image-gallery-icon image-gallery-left-nav"
            disabled={disabled}
            onClick={onClick}
            aria-label="Previous Slide"
          >
            <KeyboardArrowLeftIcon />
          </button>
        );
      }
      }
      renderRightNav={(onClick, disabled) => {
        return (
          <button
            type="button"
            className="image-gallery-icon image-gallery-right-nav"
            disabled={disabled}
            onClick={onClick}
            aria-label="Next Slide"
          >
            <KeyboardArrowRightIcon />
          </button>
        );
      }
      }
    />
  );
};

export default MediaGalleryComponent;
