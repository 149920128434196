import { doc, collection, getDocs, deleteDoc } from "firebase/firestore/lite";
import { onSnapshot, collection as collectionFull } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firestore, firestoreFull, functions } from "config/firebase";
import { Address, UserBasicDataFormProps } from "types/common";

/**
 * @description Function to update the basic information of the user
 * @returns {Promise<any>}
 * @param {string} userUid
 * @param {UserBasicDataFormProps} userInfo
 * @example updateUserBasicDataFormProps('userUid', {firstName: 'John', firstSurname: 'Doe', phone: '123456789', email: 'user@email.com'})
 */
export const updateUserBasicInfo = async (
  userUid: string,
  userInfo: UserBasicDataFormProps
) => {
  const {
    firstName,
    firstSurname,
    phoneNumber,
    email,
    picture,
    country,
    city,
    state,
  } = userInfo;

  try {
    const updateBasicInfo = httpsCallable(functions, "updateUserInfo");
    const requestData = {
      uid: userUid,
      firstName,
      firstSurname,
      phoneNumber,
      email,
      picture,
      country,
      city,
      state,
    };
    await updateBasicInfo(requestData);
    return { success: true, message: "User info updated successfully" };
  } catch (error) {
    console.error("Error updating the user basic info", error);
    return {
      success: false,
      message: `Error updating the user basic info: ${error}`,
    };
  }
};

/**
 * @description Function to create a new address for the user
 * @returns {Promise<any>}
 */
/**
 * @description Function to create a new address for the user
 * @returns {Promise<any>}
 */

interface CreateAddressResponse {
  id?: string;
  success: boolean;
  message: string;
}

export const createNewAddress = async (
  userUid: string,
  address: Address
): Promise<CreateAddressResponse> => {
  const createAddress = httpsCallable(functions, "createUserAddress");
  try {
    const requestData = {
      userUid,
      ...address,
    };

    await createAddress(requestData);

    return {
      success: true,
      message: "Address created successfully",
      id: requestData.id,
    };
  } catch (error: any) {
    console.error("error create", error);
    throw new Error(error.message || "Error creating the address");
  }
};

/**
 * @description Function to update an existing address for the user
 * @returns {Promise<any>}
 * @param {string} userUid
 * @param {Address} address
 */

export const updateAddress = async (userUid: string, address: Address) => {
  if (!address.isBilling && !address.isShipping) {
    throw new Error("Address must be either billing or shipping");
  }
  const transactionUpdate: any = httpsCallable(functions, "updateUserAddress");

  try {
    const requestData = {
      userUid,
      ...address,
    };

    const response = await transactionUpdate(requestData);
    return {
      success: true,
      addressId: response.data.id,
      message: "Address updated successfully",
    };
  } catch (error: any) {
    console.error("error update", error);
    return {
      success: false,
      message: error.message || "Error updating address",
      addressId: null,
    };
  }
};

/**
 * @description Function to retrieve all addresses for a uFser using a Firestore listener
 * @returns {Promise<any>}
 */
export const getUserAddresses = async (
  userUid: string,
  onUpdate: (addresses: Address[]) => void
) => {
  const userAddressesRef = collectionFull(
    firestoreFull,
    `Users/${userUid}/Addresses`
  );

  try {
    const unsubscribe = onSnapshot(userAddressesRef, (snapshot) => {
      const addresses: Address[] = snapshot.docs.map((doc) => ({
        ...(doc.data() as Address),
        id: doc.id,
      }));
      onUpdate(addresses);
    });

    return unsubscribe; // Return the unsubscribe function
  } catch (error) {
    console.error("Error retrieving user addresses", error);
    return () => {}; // Return an empty function as a fallback
  }
};
/**
 * @description Function delete an address for a user
 * @returns {Promise<any>}
 */

export const deleteAddress = async (userUid: string, addressId: string) => {
  const userAddressesRef = doc(
    firestore,
    `Users/${userUid}/Addresses`,
    addressId
  );

  try {
    await deleteDoc(userAddressesRef);
    return { success: true, message: "Address deleted successfully" };
  } catch (error) {
    console.error("Error deleting the address", error);
    return { success: false, message: `Error deleting the address: ${error}` };
  }
};

/**
 * @description Function to validte if an address with the same alias already exists ojo esto puede ser eliminado si no se utliza en el futuro
 * @returns {Promise<any>}
 * @param {string} userUid
 * @param {string} alias
 * @example validateAddressAlias('userUid', 'Main address')
 * @example validateAddressAlias('userUid', 'Billing address')
 * */
export const validateAddressName = async (userUid: string, alias: string) => {
  const userAddressesRef = collection(firestore, `Users/${userUid}/Addresses`);
  try {
    const addressesSnapshot = await getDocs(userAddressesRef);
    const addresses = addressesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const aliasExists = addresses.find(
      (address: any) => address.alias === alias
    );
    if (aliasExists) {
      return {
        success: false,
        message: "An address with the same alias already exists",
      };
    } else {
      return { success: true, message: "Alias is available" };
    }
  } catch (error) {
    console.error("Error validating the alias", error);
    return { success: false, message: `Error validating the alias: ${error}` };
  }
};
