import styled from "styled-components";
import {THEME} from "constants/theme/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FDFDFE;
  
  @media only screen and (min-width: 1200px) {
    width: 500px;

  }
`

export const Title = styled.h2`
    font-family: Outfit, sans-serif;
    font-weight: 700;
    color: ${THEME.colors.black100};
    margin: 1rem 0;
    text-align: center;
`;

export const Description = styled.p`
    color: #406385;
    margin: 1rem 0;
    text-align: center;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
`;

