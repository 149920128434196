import { FC, useState } from "react";
import { auth } from "config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import {
  Container,
  FormTitle,
  InputForm,
  InputWrap,
  Link,
  LoginContainer,
  FormContainer,
  LoginImg,
  ImageContainer,
  LoginWrap,
  NoAccount,
  ButtonContainer,
} from "./styled";
import Input from "ui/TextInput";
import MainLayout from "views/layout/MainLayout";
import loginSide from "assets/loginSide.png";
import { MdPerson } from "react-icons/md";
import { Button } from "ui/Buttons";
import { useNavigate } from "react-router-dom";
import { theme } from "libs/theme";
import { ThemeProvider } from "styled-components";
import { useNotification } from "libs/context/AlertContext";
import { useTranslation } from "react-i18next";

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showSuccess, showError } = useNotification();

  const [email, setEmail] = useState<string>("");

  const handleSendEmailForgotPassword = (emailUser: string) => {
    sendPasswordResetEmail(auth, emailUser)
      .then(() => {
        showSuccess(
          `Se envió un correo a ${emailUser} para cambiar la contraseña`
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch((error) => {
        showError("Ha ocurrido un error al intentar cambiar la contraseña");
      });
  };

  return (
    <MainLayout hideSearch topbarPaddingBottom="10px">
      <Container>
        <ThemeProvider theme={theme}>
          <LoginContainer>
            <LoginWrap>
              <ImageContainer>
                <LoginImg src={loginSide} loading="lazy" />
              </ImageContainer>
              <FormContainer>
                <FormTitle>{t('forgot_your_password')}</FormTitle>
                <InputForm>
                  <InputWrap>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      label={t('email')}
                    />
                  </InputWrap>
                </InputForm>
                <ButtonContainer>
                  <Button
                    type="button"
                    onClick={() => handleSendEmailForgotPassword(email)}
                  >
                    {t('recover')}
                  </Button>
                </ButtonContainer>
                <NoAccount>
                  <Link onClick={() => navigate("/login")}>
                    <MdPerson /> {t('login')}
                  </Link>
                  <Link onClick={() => navigate("/register")}>
                    <MdPerson /> {t('register')}
                  </Link>
                </NoAccount>
              </FormContainer>
            </LoginWrap>
          </LoginContainer>
        </ThemeProvider>
      </Container>
    </MainLayout>
  );
};

export default ForgotPassword;
