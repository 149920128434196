import { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form, FormTitle, InputWrap, OptionTitle, Categories, Category, BottomButtonContainer, ErrorContainer} from '../styled'
import Input from 'ui/TextInput'
import ImageUploader from "react-images-upload";
import Select from 'ui/Select';
import { Button, StrokedButton } from 'ui/Buttons';
import { TextError } from 'ui/Text';
import TextArea from 'ui/TextArea';
import ModalLoader from 'ui/ModalLoader';
import { productsValidationSchema } from '../constants';
import { uploadImagesToFirestore, deleteMultipleImagesFromFirestorageInSinglePath} from 'hooks/useUploadImagesToFirestorage/useUploadImagesToFirestorage';
import { LEVELS_URGENCY_NEEDS } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'libs/context/AlertContext';
import { getCountry, getLanguage } from 'redux/common';

interface Props {
    setStep?: (step: number) => void;
    category: number;
    userData: any;
    setCategory: (category: number) => void;
    subcategories: any;
    onSubmit: (values: any) => void;
    needUid: string;
}

const NeedsProductsForm = (props: Props) => {
    const { setStep, subcategories, category,setCategory, onSubmit, userData, needUid } = props;
    const {showWarning} = useNotification();
    const { t } = useTranslation();
    const country = useSelector(getCountry);
    const language = useSelector(getLanguage);

    const [showErrors, setShowErrors] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: '',
            image: '',
            link: '',
            needReference: '',
            urgencyLevel: '',
            extraComments: '',
        },
        validationSchema: productsValidationSchema,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values) => {
            onSubmit(values);
            setTimeout(() => {
                if (setStep) {
                    setStep(2);
                }
            }
            , 200);
        },
});

    const handleUploadImage = async (e: File[]) => {
        if (!uploadingImage) {
            setUploadingImage(true);
            if (formik.values.image) {
                    await deleteMultipleImagesFromFirestorageInSinglePath(`${country}/Needs/Services/${userData.uid}/NeedRequests/${needUid}`);
                if (e) {
                    await uploadImagesToFirestore(e, `${country}/Needs/Services/${userData.uid}/NeedRequests/${needUid}`);
                }
            } else {
                const { downloadURLs } = await uploadImagesToFirestore(e, `${country}/Needs/Services/${userData.uid}/NeedRequests/${needUid}`);
                formik.setFieldValue('image', downloadURLs[0]);
            }
            setUploadingImage(false);
        }
    };

    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <FormTitle>{t('what_do_you_need')}</FormTitle>
                <OptionTitle>{t('what_product_are_you_looking_for?')}*</OptionTitle>
                <InputWrap size={'100%'}>
                    <Input
                        type='text'
                        name='title'
                        label={t('what_product_are_you_looking_for?')}
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        onBlur={formik.handleBlur}
                    />
                    {showErrors && formik.errors.title ? (
                        <ErrorContainer>
                            <TextError>{formik.errors.title}</TextError>
                        </ErrorContainer>
                    ) : null}
                </InputWrap>
                <OptionTitle>{t('link_product')}</OptionTitle>
                <InputWrap size='100%'>
                    <Input
                        type='text'
                        name='link'
                        label={t('link_product') as string}
                        onChange={formik.handleChange}
                        value={formik.values.link}
                        onBlur={formik.handleBlur}
                    />
                    {showErrors && formik.errors.link ? (
                        <ErrorContainer>
                            <TextError>{formik.errors.link}</TextError>
                        </ErrorContainer>
                    ) : null}
                </InputWrap>
                <OptionTitle>{t('upload_an_image_referring_to_the_need')}.</OptionTitle>
                <ImageUploader
                    singleImage={true}
                    withLabel={true}
                    label={t('there_is_no_image_selected') as string}
                    withPreview={true}
                    labelStyles={{ color: "#406385", fontWeight: 800 }}
                    buttonStyles={{
                        backgroundColor: "#fff",
                        color: "#233854",
                        fontWeight: 700
                    }}
                    withIcon={true}
                    buttonText={t('upload_an_image') as string}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                    onChange={handleUploadImage}
                />
                <OptionTitle>
                    {t('choose_the_option_that_best_suits_your_needs')}*
                </OptionTitle>
                <Categories>
                    {subcategories.map((item, index) => (
                        <Category
                            onClick={() => {
                                setCategory(index);
                                formik.setFieldValue("needReference", item?.value[language]);
                            }}
                            key={index}
                            style={{
                            background: index === category ? "#038a26" : "",
                            color: index === category ? "#ffffff" : "",
                            }}
                        >
                            {item?.value[language]}
                        </Category>
                    ))}
                </Categories>
                {
                    showErrors && formik.errors.needReference ? (
                        <ErrorContainer>
                            <TextError>{formik.errors.needReference}</TextError>
                        </ErrorContainer>
                    ) : null
                }
                <OptionTitle>{t('urgency_level')}*</OptionTitle>
                <InputWrap size={"100%"}>
                    <Select
                        options={LEVELS_URGENCY_NEEDS}
                        value={formik.values.urgencyLevel}
                        onChange={(e) => formik.setFieldValue("urgencyLevel", e)}
                        label={t('urgency_level')}
                        hideNeverChoice
                    />
                    {
                        showErrors && formik.errors.urgencyLevel ? (
                            <ErrorContainer>
                                <TextError>{formik.errors.urgencyLevel}</TextError>
                            </ErrorContainer>
                        ) : null
                    }
                </InputWrap>
                <OptionTitle>{t('write_in_detail_what_you_need')}*</OptionTitle>
                <InputWrap size={"100%"}>
                    <TextArea
                        name='extraComments'
                        label={t('describe_your_need_here')}
                        onChange={formik.handleChange}
                        value={formik.values.extraComments}
                    />
                    {
                        showErrors && formik.errors.extraComments ? (
                            <ErrorContainer>
                                <TextError>{formik.errors.extraComments}</TextError>
                            </ErrorContainer>
                        ) : null
                    }
                </InputWrap>
                <BottomButtonContainer>
                    <StrokedButton onClick={() => {
                        if (setStep) {
                            setStep(0);
                        }
                    }}>{t('back')}</StrokedButton>
                    <Button 
                        type='button'
                        onClick={() => {
                            formik.handleSubmit();
                            setShowErrors(true);
                        }}
                        onDisabledClick={() => {
                            showWarning(t('complete_all_fields'))
                            setShowErrors(true)
                            console.log('errors', formik.errors)
                        }}
                        disabled={Object.keys(formik.errors).length > 0}
                    >
                        {t('send_request')}
                    </Button>
                </BottomButtonContainer>
                <ModalLoader isLoading={uploadingImage} />
            </Form>
        </div>
    );

};

export default NeedsProductsForm;