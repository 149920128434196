import { httpsCallable } from "firebase/functions";
import { functions } from 'config/firebase';

interface IQuery {
    terms: {
        [key: string]: string
    }
    indices: string[],
    filters?: {
        [key: string]: string
    }[],
    sortField: string,
    sortOrder: string,
    from: number,
    size: number
}


const generalSearch = async (query: IQuery) => {
    const { terms, indices, filters, sortField, sortOrder, from, size } = query;

    const dataSearch = {
        "terms": terms,
        "indices": indices,
        "filters": filters || [],
        "sortField": sortField || "",
        "sortOrder": sortOrder || "asc",
        "from": from || 0,
        "size": size || 10
    }

    const generalSearch = httpsCallable(functions, 'generalSearch')(dataSearch)

    const hits = generalSearch.then((result) => {
        return result
    })
        return hits
    };

export default generalSearch;
