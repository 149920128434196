import { useState } from 'react';
import { ServiceResponse } from 'types';
import paymentCheckout from 'services/checkout/paymentCheckout/payment-checkout-service';
import { PaymentsProps} from 'types/common';
import { useNotification } from 'libs/context/AlertContext';

interface PaymentState {
  isLoading: boolean;
  error?: string | null;
  success: boolean | null;
  paymentResponse: any;
  idConflict?: boolean;
}

const usePaymentProcessing = () => {
  const initialPaymentState: PaymentState = {
    isLoading: false,
    error: null ,
    success: null,
    paymentResponse: null,
  }; 

  const { showError } = useNotification();

  const [paymentState, setPaymentState] = useState<PaymentState>(initialPaymentState);

  const processPayment = async (paymentData: PaymentsProps) => {
   
    setPaymentState({ ...initialPaymentState, isLoading: true });
      const response: ServiceResponse = await paymentCheckout(paymentData);
      if (response.status === 'OK') {
        setPaymentState({ ...initialPaymentState, success: true, paymentResponse: response.data, isLoading: false});
      } else if (response.code === 409) {
        setPaymentState({ error: response.message, success: false, paymentResponse: null, isLoading: false, idConflict: true});
        showError('Error al realizar el pago')
      } else {
        setPaymentState({ error: response.message, success: false, paymentResponse: null, isLoading: false});
      }
  };

  return { ...paymentState, processPayment };
};

export default usePaymentProcessing;
