import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const ContainerBar = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 2rem 1rem 0 1rem;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`
export const Title = styled.h1`
  ${props => props.theme.typography.heading.lg};
  text-align: center;
  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.heading.sm.bold};
  }
`
export const Subtitle = styled.h2`
  ${props => props.theme.typography.subheading.xs.semibold};
  color: #a0a0a0;

  text-align: center;
  @media only screen and (max-width: 768px) {
  }
`

export const Paragraph = styled.p`
  ${props => props.theme.typography.body.lg.input};
  font-weight: ${props => props.weight || 400};
  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.body.md.regular};

    font-weight: ${props => props.weight || 400};
  }
`

export const Span = styled.span`
  ${props => props.theme.typography.body.lg.input};
  font-weight: 700;
  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.body.md.regular};
    font-weight: 700;
  }
`
export const Upper = styled.span`
  text-transform: uppercase;
`
export const ListU = styled.ul`
  @media only screen and (max-width: 768px) {
  }
`

export const ListO = styled.ol`
  @media only screen and (max-width: 768px) {
  }
`

export const ListItem = styled.li`
  ${props => props.theme.typography.body.lg.input};
  font-weight: ${props => props.weight || 400};
  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.body.md.regular};
    font-weight: ${props => props.weight || 400};
  }
`

