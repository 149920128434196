import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 80%;
  background-color: #ffffff;
  align-self: center;
  border-radius: 16px;
  color: #fbfbfe;
  padding: 1rem;
  @media (max-width: 992px) {
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  padding: 3rem 0;
  background: #08A691;
  flex-direction: column;
  width: 100%;
  align-self: center;
  text-align: center;
  margin-bottom: -5rem;
  padding-bottom: 6rem;
`

export const HeaderTitle = styled.h1`
  color: #fdfdfe;
  ${props => props.theme.typography.subheading.xs.bold};
  font-size: 2.2rem;
  
`

export const HeaderSubtitle = styled.p`
  color: #fdfdfe;
  ${props => props.theme.typography.body.sm.regular};
  font-size: 1.2rem;
`