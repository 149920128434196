import { firestoreFull as firestore } from "config/firebase";
import { useSelector } from "react-redux";
import {  addDoc, collection } from "firebase/firestore";
import { useNotification } from "libs/context/AlertContext";
import { getCountry } from "redux/common";


interface IServiceSubmitEarnMoney {
    name: string;
    phone: string;
    email: string;
    businessModel: string;
}

export const useSubmitFormEarnMoney = () => {
    const { showSuccess, showError } = useNotification();
    const country = useSelector(getCountry);


    const submitForm = async (data: IServiceSubmitEarnMoney) => {
        try {
            const response = await addDoc(collection(firestore, `${country}/Earnmoney/Requests/`), data);
            showSuccess("The form was submitted successfully");
            return response;
        } catch (error: any) {
            showError(error.message);
        }
    };

    return { submitForm };
 
};
