import { useState } from 'react';
import createInquiry from 'services/services/yachts/create-yacht-inquiry/create-yacht-inquiry';
import { YachtInquiryType } from 'types/services';

const useYachtInquiry = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const sendInquiry = async (data: YachtInquiryType): Promise<YachtInquiryType | null> => {
        setLoading(true);
        try {
            const response = await createInquiry(data);
            setLoading(false);
            return response;
        } catch (err: any) {
            setError(err);
            setLoading(false);
            return null;
        }
    };

    return { sendInquiry, loading, error };
};

export default useYachtInquiry;