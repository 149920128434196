import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

interface ISection {
    justify?: string;
}

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${(props: ISection) => props.justify || 'flex-start'};
    gap: .5rem;
`;

export const Image = styled.img`
    width: 300px;
    height: 300px;
`;

export const ProductName = styled.h1`
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
`;

    