import React from "react";

import {
  SponsorDesc,
  SponsorImg,
  SponsorName,
  SponsorPrice,
  SponsorProduct,
} from "./styled";
import { useNavigate } from "react-router-dom";

const Offers = (props: any) => {
  const { data } = props;
  const { id, title, images } = data;
  const { name, image, price, description } = data;
  const navigate = useNavigate();
  return (
    <SponsorProduct
      onClick={() =>
        navigate(`/offer/detail?id=${id}`, {
          state: { route: "PANAMA/Offers/Posts" },
        })
      }
      contenido={"Oferta"}
    >
      <SponsorImg src={images ? images[0] : image} />
      <SponsorPrice>${price.toLocaleString()}</SponsorPrice>
      <SponsorName>{title ? title.es : name.es}</SponsorName>
      <SponsorDesc>{description.es}</SponsorDesc>
    </SponsorProduct>
  );
};

export default Offers;
