import styled from "styled-components";
import { THEME } from "constants/theme/theme";

export const Container = styled.div`
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 0px 0px 8px 0px;
  margin-right: .5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 768px) {
    max-width: 320px;
    height: auto;
    margin-right: 4px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  overflow: hidden;

  @media (max-width: 767px) {
    max-height: 180px;
  }

  @media (max-width: 600px) {
    max-height: 140px;
  }
`;

export const Image = styled.img`
    width: 100%;
    min-height: 192px;
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: 150px;
  max-height: 250px;
  text-align: left;
  padding: 8px;

  @media (max-width: 600px) {
  height: 100%;
  min-height: 150px;
  max-height: 250px;
  }
`;

export const TitleText = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${THEME.colors.black100};
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

export const PriceText = styled.div`
  padding: 0.25rem 0 0.5rem 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${THEME.colors.black80};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
`;

export const DescriptionText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #0C121D;
  & p {
    margin: 0px;
  }
`;

export const Link = styled.a`
  font-family: Montserrat, sans-serif;
  padding: 4px 1rem;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${THEME.colors.primary};
  background-color: ${THEME.colors.blueLight};
  border-radius: 16px;
`;
