import {logEvent} from "firebase/analytics";
import {analytics} from 'config/firebase'


interface IAnalyticsParams {
    [key: string]: string | number | boolean
}

export function registerLog(name: string, params?: IAnalyticsParams) {
    logEvent(analytics, name, params)
}

export function registerEvent(name: string, params?: IAnalyticsParams) {
    logEvent(analytics, name, params)
}