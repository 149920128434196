import { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { createTheme, ThemeProvider } from "@mui/material";
import { Container, ListProductsContainer, SpaceBetween, GridContainer, Filter, TextFilter } from './styled';
import { H3 } from 'ui/Text'
import { MdFilter } from "react-icons/md";
import ProductsSidebarFilter from "views/containers/SidebarFilters/Products";
import { STORE_PRODUCTS_CATEGORIES } from 'constants/index';
import Product from "ui/Products";
import { useTranslation } from "react-i18next";
import { Product as ProductType, ProductCategory as ProductCategoryType, FiltersProducts } from 'types';
import './styles.css';

const ItemSkeletons = new Array(60).fill(0);

interface Props {
    data: ProductType[]
    categories: ProductCategoryType[];
    setFilters: (filters: FiltersProducts) => void;
    filters: FiltersProducts;
}

function getCategoryName(id: number) {
    const category = STORE_PRODUCTS_CATEGORIES.find((category) => category.id === id);
    return category?.name.es || '';
}

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

const ContentProuctsByCategory = (props: Props) => {
    const { data, categories, setFilters, filters } = props
    const { t } = useTranslation();

    const [isOpenFiltersSidebar, setIsOpenFiltersSidebar] = useState(false);

    const handleSetFilters = (filters: FiltersProducts) => {
        setFilters(filters);
    }

    return (
        <Container>
            <ThemeProvider theme={lightTheme} >
                <ListProductsContainer pad={"1rem"}>
                    <SpaceBetween>
                        <H3>{getCategoryName(data?.[0]?.idCategory || 0)}</H3>
                        <Filter>
                            <TextFilter onClick={() => setIsOpenFiltersSidebar(true)}><MdFilter />{t('filter')}</TextFilter>
                        </Filter>
                    </SpaceBetween>
                    <GridContainer>
                        {
                            data?.length > 0 ? (
                                data?.map((item, indexItem) => (
                                    <Product data={item} key={indexItem} />
                                ))
                            ) : (
                                ItemSkeletons.map((item, index) => (
                                    <Skeleton key={index} variant="rectangular" width={300} height={300} />
                                ))
                            )
                        }
                    </GridContainer>
                </ListProductsContainer>
            </ThemeProvider>
            <ProductsSidebarFilter
                categories={categories}
                open={isOpenFiltersSidebar}
                setOpen={() => setIsOpenFiltersSidebar(!isOpenFiltersSidebar)}
                onSetFilters={handleSetFilters}
                filters={filters}
            />
        </Container>
    )
}

export default ContentProuctsByCategory;


