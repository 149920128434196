import React, { useEffect, useRef } from "react";
import {
  ChangeDialog,
  ChangePassword,
  Column,
  DataContainer,
  DialogBody,
  Info,
  Input,
  InputLabel,
  InputWrap,
  // Paragraph,
  Summary,
  SummaryTitle,
  // Terms,
  UpdateButtonContainer,
} from "../../styled";

import { MdArrowBack } from "react-icons/md";
import { useTranslation } from "react-i18next";
import CountryPicker from "ui/CountryPicker";
import PhoneInput from "ui/PhoneInput";
import { Button } from "ui/Buttons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalLoader from "ui/ModalLoader";
import { Formik } from "formik";
import { useTheme } from "@mui/material/styles";
import useUpdateUserBasicInfo from "hooks/user/useUpdateUserInfo/use-update-user-info";
import { useNotification } from "libs/context/AlertContext";

interface UserInformationProps {
  user: any;
}

const UserInformation = ({ user }: UserInformationProps) => {
  const { showSuccess, showError } = useNotification();
  const { t } = useTranslation();

  const USER_COUNTRY_BORN = user?.[0]?.countryBorn || "";

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    updateUserInfo,
    loading: loadingUpdateUser,
    error: errorUpdateUser,
    success: successUpdateUser,
  } = useUpdateUserBasicInfo();

  const hasUpdated = useRef(false); // Usar una referencia para evitar bucles infinitos en useEffect
  const formikRef = useRef(null); // Usar una referencia para acceder al objeto formik

  useEffect(() => {
    if (successUpdateUser && !hasUpdated.current) {
      showSuccess("información actualizada");
      hasUpdated.current = true; // Marcar que la actualización ha ocurrido
    }

    if (errorUpdateUser) {
      showError(errorUpdateUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateUser, errorUpdateUser, user]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangePassword = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!user.length) {
    return <div>Cargando</div>;
  }

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          firstName: user.length > 0 ? user[0].firstName : "",
          firstSurname: user.length > 0 ? user[0].firstSurname : "",
          phone: user.length > 0 ? user[0].phone : "",
          email: user.length > 0 ? user[0].email : "",
          country:
            user.length > 0 && user[0].address?.country
              ? user[0].address.country
              : USER_COUNTRY_BORN,
        }}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const dataToSubmit = {
            ...values,
            country: values.country || USER_COUNTRY_BORN,
          };

          await updateUserInfo(user[0].id, dataToSubmit);
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <DataContainer onSubmit={handleSubmit}>
            <Column>
              <InputWrap size={"90%"}>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id="firstName"
                  className="form__input"
                  autoComplete="off"
                  defaultValue={values.firstName}
                />
                <InputLabel htmlFor="firstname" className="form__label">
                  {t("name")}
                </InputLabel>
                <Info>
                  {t(
                    "label_enter_exactly_as_it_appears_on_your_identification"
                  )}
                </Info>
              </InputWrap>

              <InputWrap size={"90%"}>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id="firstSurname"
                  className="form__input"
                  autoComplete="off"
                  defaultValue={values.firstSurname}
                />
                <InputLabel htmlFor="firstSurname" className="form__label">
                  {t("last_name")}
                </InputLabel>
                <Info>
                  {t(
                    "label_enter_exactly_as_it_appears_on_your_identification"
                  )}
                </Info>
              </InputWrap>

              <InputWrap size={"90%"}>
                {/* <Input
                                    onChange={(e ) => {
                                        handleChange('phone')(e)
                                    }
                                    }
                                    onBlur={handleBlur}
                                    type="text"
                                    id="phone"
                                    className="form__input"
                                    autoComplete="off"
                                    defaultValue={values.phone}
                                /> */}
                <InputLabel
                  htmlFor="phone"
                  className="form__label"
                  style={{ top: "-10px", zIndex: 999 }}
                >
                  {t("phone")}
                </InputLabel>
                <PhoneInput
                  onChange={(e) => {
                    handleChange("phone")(e);
                  }}
                  value={values.phone}
                  placeholder="Número de teléfono"
                />
                <Info>{t("make_sure_you_have_access_to_this_phone")}</Info>
              </InputWrap>
              <InputWrap size={"90%"}>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id="email"
                  className="form__input"
                  autoComplete="off"
                  placeholder=" "
                  defaultValue={values.email}
                  readOnly
                />
                <InputLabel htmlFor="email" className="form__label">
                  {t("email")}
                </InputLabel>
                <Info>{t("make_sure_you_have_access_to_this_email")}</Info>
              </InputWrap>
            </Column>
            <Column>
              <InputWrap size={"90%"}>
                <InputLabel
                  htmlFor="country"
                  className="form__label"
                  style={{ top: "-10px" }}
                >
                  {t("country")}
                </InputLabel>
                <CountryPicker
                  disabled
                  value={{ country: USER_COUNTRY_BORN }}
                  onChange={(data) => {
                    handleChange("country")(data?.country);
                  }}
                  onlyCountry
                />
              </InputWrap>
              <UpdateButtonContainer>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  type="submit"
                  disabled={isSubmitting}
                  style={{ maxWidth: "400px" }}
                >
                  {t("update_profile")}
                </Button>
              </UpdateButtonContainer>
              <UpdateButtonContainer>
                <ChangePassword onClick={handleClickOpen}>
                  {t("change_password")}
                </ChangePassword>
              </UpdateButtonContainer>
            </Column>
          </DataContainer>
        )}
      </Formik>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle onClick={handleClose} id="responsive-dialog-title">
          {<MdArrowBack />}
        </DialogTitle>
        <DialogContent>
          <ChangeDialog>
            <DialogBody>
              <Summary>
                <SummaryTitle>{t("change_password")}</SummaryTitle>
              </Summary>
              <InputWrap size={"100%"}>
                <Input
                  type="password"
                  id="pass"
                  className="form__input"
                  autoComplete="off"
                  placeholder=" "
                />
                <InputLabel htmlFor="pass" className="form__label">
                  {t("current_password")}
                </InputLabel>
              </InputWrap>
              <InputWrap size={"100%"}>
                <Input
                  type="password"
                  id="pass"
                  className="form__input"
                  autoComplete="off"
                  placeholder=" "
                />
                <InputLabel htmlFor="pass" className="form__label">
                  {t("new_password")}
                </InputLabel>
                <Info>{t("label_use_into_8_20_characters")}</Info>
              </InputWrap>
              <InputWrap size={"100%"}>
                <Input
                  type="password"
                  id="pass"
                  className="form__input"
                  autoComplete="off"
                  placeholder=" "
                />
                <InputLabel htmlFor="pass" className="form__label">
                  {t("repeat_password")}
                </InputLabel>
              </InputWrap>
            </DialogBody>
            {/* <Terms>
                            <Checkbox
                                style={{ color: "#615EE2", padding: "0 .4rem" }}
                                defaultChecked={true}
                            />
                            <Paragraph>
                                Cerrar sesión en todos los dispositivos vinculados a esta
                                cuenta.
                            </Paragraph>
                        </Terms> */}
          </ChangeDialog>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <Button onClick={handleChangePassword}>{t("save_changes")}</Button>
        </DialogActions>
      </Dialog>
      <ModalLoader isLoading={loadingUpdateUser} />
    </>
  );
};

export default UserInformation;
