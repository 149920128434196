import { PaymentMethods } from 'types';

export const getTypePaymentMethod = (type: PaymentMethods) => {

    switch (type) {
        case 'card':
            return 'Tarjeta de crédito';
        case 'yappy':
            return 'Yappy';
        case 'ach':
            return 'ACH';
        case 'cash':
            return 'Efectivo';
        case 'paypal':
            return 'Paypal';
        default:
            return 'Error metodo de pago';
    }
}

export const getPaymentMethodByCountry = (country: string) => {

    switch (country) {
        case 'PANAMA':
            return ['yappy', 'cash', 'ach', 'paypal'];
        case 'SPAIN':
            return ['paypal'];
        default:
            return [ 'paypal'];
    }
}