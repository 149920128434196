import styled from 'styled-components';
import {THEME} from 'constants/theme/theme';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 450px) {
        
    }
`;

export const Label = styled.h5`
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: 10px;
    margin-right: 10px;
`;

export const Options = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: flex-start;
    border-radius: 5px;
    max-width: 100px;
`;

interface IOption {
    active: boolean;
}

export const Option = styled.div<IOption>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    background-color: ${props => props.active ? THEME.colors.primary : 'transparent'};
    color: ${props => props.active ? THEME.colors.white : THEME.colors.black100};
    border: 1px solid ${props => props.active ? THEME.colors.primary : THEME.colors.black100};
    width: 40px;
    cursor: pointer;
    border-radius: 6px;
`;

export const OptionText = styled.p`
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
`;