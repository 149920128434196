import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { DBCountryReference, DBLanguageReference } from 'types/common'
import { CurrencyName } from 'constants/common'
// import {createSagasTrigger} from 'lib/redux/sagas/trigger'

export type InitialState = typeof initialState

interface IState { 
  language: DBLanguageReference
  country: DBCountryReference
  currency: CurrencyName
}

export const initialState: IState = {
  language: DBLanguageReference.es,
  country: DBCountryReference.PAN,
  currency: CurrencyName.USD
} 

const commonSlice = createSlice({
  name: 'common', 
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<DBLanguageReference >) => {
      state.language = action.payload
    },
    setCountry: (state, action: PayloadAction<{ country: DBCountryReference }>) => {
      state.country = action.payload.country
    },
    setCurrency: (state, action: PayloadAction<{ currency: CurrencyName }>) => {
      state.currency = action.payload.currency
    }
  }
})



export const { setLanguage, setCountry, setCurrency } = commonSlice.actions

// export const commonSagasTrigger = createSagasTrigger<{isAuth: boolean}>('auth_sagas')

export const commonReducer = commonSlice.reducer





