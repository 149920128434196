import styled from 'styled-components'
import { THEME } from 'constants/theme/theme'

export const Container = styled.div`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 2rem;

  @media only screen and (max-width: 992px) {
    border-radius: 1rem;
  
  }

`;

export const Card = styled.div`
  width: 100%;
  height: ${props => props.height || "430px"};
  display: flex;
  flex-direction: column;
  align-items: space-between;
  border-radius: 2rem;
  overflow: hidden;
  text-align: left;
  margin: 6px auto;
  padding:  0 0 0.5rem 0;
  background-color: ${props => props.background || "#ffffff"};
  box-shadow: rgba(2, 64, 154, 0.4) 0px 2px 2px 0.5px;

  @media only screen and (max-width: 768px){
    width: 100%;
    padding: 0;
    height: ${props => props.height || "400px"};
    margin: 0;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 60%;
  max-height: 238px;

  @media only screen and (max-width: 768px){
    width: 100%;
    height: 58%;
  }
`

export const Price = styled.span`
  font-size: 1.1rem;
  padding: 0 0.5rem;
  font-weight: 700;
  color: ${THEME.colors.black80};

  @media only screen and (max-width: 992px){
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }

`;

export const ShortDescription = styled.p`
  font-family: Montserrat, sans-serif;
  display: -webkit-box;
  vertical-align: left;
  font-size: .9rem;
  font-weight: 300;
  text-align: left;
  line-height: 1.2;
  -webkit-line-clamp: 3;
  -webkit-box-orient: ${props => props.orientation || "vertical"};
  overflow: hidden;
  max-height: ${props => (props.isExpanded ? '100%' : '3.8rem')};
  transition: max-height 0.3s ease-in-out;
  margin: 0.25rem 0;
  padding: 0 0.5rem;
  

  @media only screen and (max-width: 768px){
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: .9rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 14px;
  height: 100%;
  object-fit: cover;
  
  @media only screen and (max-width: 992px){
    border-radius: 28px;
  }
  @media only screen and (max-width: 600px) {
    max-height: 218px;
  }
`


export const DescriptionContainer = styled.div`
  ${props => props.theme.typography.body.sm.bold};
  min-height: 7rem;
  height: 50%;
  margin: 0;
  padding: .5rem 0;
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.label.xs.bold};
    font-size: .725rem;
  }
`

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  padding: .5rem 0;
  justify-content: center;
  flex-direction: column;
`

export const Title = styled.p`
  font-family: Montserrat, sans-serif;
  color: ${props => props.theme.colors.black100};
  font-weight: 700;
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.clamp || "1"};
  -webkit-box-orient: vertical;
  margin-bottom: .4rem;
  margin: 0px .5rem;
  @media only screen and (max-width: 992px){
    padding: 0 0.5rem;
    font-weight: 700;
    font-size: 1.1rem;
    -webkit-line-clamp: 2;
    margin: 0px;
  }
`

export const LinkButton = styled.a`
  width: ${(props) => props.width || 'auto'};
  color: ${(props) => props.theme.colors.primary80};
  align-items: center;
  align-self: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  border-radius: 12px;
  background-color: ${THEME.colors.blueLight};
  padding:  ${(props) => props.padding || '0.2rem .5rem'};
  min-width: 50%;

  @media (max-width: 1080px) {
    font-size: 0.9rem;
  }
  @media (max-width: 620px) {
    font-weight: 700;
    font-size: .9rem;
  }
`

export const Button = styled.div`
  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  background-color: #3A36DB;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 40px;
  padding: 8px 16px;
  min-width: 120px;
  max-width: 500px;
  margin-bottom: 1rem;
  &:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 1080px) {
    font-size: 1rem;
  }

`
