import styled from 'styled-components'

export const Container = styled.div`
  clear: both;
  background: ${props => props.background || "#ffffff"};
  justify-content: space-between;
  padding: ${props => props.pav};

  @media only screen and (max-width: 600px) {
    padding: 0rem 0rem 1rem 0rem;
  }
`

export const Link = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 600px) {

  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: 1rem 0rem;
  justify-content: space-between;
  align-items: ${props => props.align || "stretch"};
  width: 100%;
  background: ${props => props.background || "#ffffff"};
  text-align: center;

  @media only screen and (max-width: 992px) {
    border-radius: 0;
    padding: 1.5rem .5rem 0 .5rem;
  }
  @media only screen and (max-width: 600px) {
    padding: 0 1rem;
  }
`


export const Titles = styled.h2`
  text-align: ${props => props.align || "left"};
  font-family: Outfit, monospace;
  font-weight: ${props => props.weight || "700"};
  font-size: ${props => props.size || "2.5rem"};
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.subheading.sm.bold};
    text-align: ${props => props.alignM || "left"};
  }
`
export const SponsorProduct = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #FDFDFE;
  line-height: 1.3rem;
  text-align: left;
  margin-right: .5rem;
  padding: 1rem;
  height: 25rem;
  border-radius: 1rem;


  &:before {
    ${({contenido}) => contenido && `
    background: #F72C1E;
    `}
    content: '${props => props.contenido}';
    padding: .5rem;
    color: #ffffff;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    top: .5rem;
    left: 0;
  }
`

export const SponsorBuy = styled.div`
  ${props => props.theme.typography.body.md.bold};
  display: flex;
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
`
