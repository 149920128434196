import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollRestoration = () => {
    const location = useLocation();
    const scrollPositions = new Map();

    useEffect(() => {
        // Almacenar la posición del scroll actual antes de cambiar de ruta
        return () => {
            scrollPositions.set(location.pathname, window.scrollY);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        // Restaurar la posición del scroll al cargar una ruta
        const savedPosition = scrollPositions.get(location.pathname) || 0;
        window.scrollTo(0, savedPosition);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
};
