import { useQuery } from 'react-query';
import { getProductsByMultipleCategories } from 'services/products/get-products-services';
import { Product } from 'types';

interface IFetchProductsByMultipleCategories {
  collectionPath: string;
  ids: number[];
}

export const useGetProductsByMultiplesCategories = ({ ids, collectionPath }: IFetchProductsByMultipleCategories) => {
  const fetchProducts = () => getProductsByMultipleCategories(ids, collectionPath);

  const queryKey = ['productsByMultipleCategories', ids.join(','), collectionPath];

  const { data, error, isLoading, refetch } = useQuery<Product[][], Error>(
    queryKey,
    fetchProducts,
    {
      // Configuraciones adicionales recomendadas:
      staleTime: 5 * 60 * 1000,  // Los datos frescos se consideran durante 5 minutos
      cacheTime: 30 * 60 * 1000, // Los datos se mantienen en el caché durante 30 minutos
      refetchOnWindowFocus: false, 
      refetchOnReconnect: true,   // Hace un refetch de los datos automáticamente cuando el navegador se reconecta a internet
      retry: 1,                   // Intenta refetching una vez si la query falla inicialmente
      onError: (error) => {
        console.error('Error fetching products:', error);
      }
    }
  );

  return {
    productsByMultipleCategories: data ?? [], // Se asegura que siempre se devuelva un array, incluso si data es undefined
    loadingProductsByMultipleCategories: isLoading,
    errorProductsByMultipleCategories: error,
    refetchProductsByMultipleCategories: refetch
  };
};
