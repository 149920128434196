import { Container, Cuad, FlexColumn, SponsorBuy, Titles } from "./styled";
import InfoCard from "components/cards/services/info";
import Skeleton from "@mui/material/Skeleton";
import { Solution } from "types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setServiceDetail } from "modules/services/store";
import { useTranslation } from "react-i18next";

const ItemSkeletons = [1, 2, 3];

interface Props {
  solutions: Solution[] | null;
}

export const Solutions = ({ solutions }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleServiceCardClick = (itemService: any) => {
    dispatch(setServiceDetail(itemService));
    navigate(`detail?id=${itemService.uid}&category=business`);
  }

  return (
    <Container background="#E7F7f6">
      <FlexColumn background="#E7F7f6" pav={"1rem"}>
        <div
          className={
            "d-flex justify-content-between align-items-center w-100"
          }
        >
          <Titles size={"1.5rem"}>{t('solutions_for_you')}</Titles>
        </div>
        <Cuad>
          {solutions !== null
            ? solutions.map((item: Solution, index: number) => {
              return (
                <InfoCard
                  textButton={`${t('view_more')}`}
                  data={item}
                  key={index}
                  onClick={() => handleServiceCardClick(item)}
                  linkButton={false}
                />
              );
            })
            : ItemSkeletons.map((item) => {
              return (
                <div key={item}>
                  <div>
                    <Skeleton
                      variant={"rounded"}
                      width={"95%"}
                      height={200}
                    />
                    <Skeleton variant={"text"} width={"20%"} />
                    <Skeleton variant={"text"} width={"60%"} />
                    <Skeleton variant={"text"} width={"100%"} />
                    <SponsorBuy>
                      <Skeleton
                        variant={"rounded"}
                        width={"25%"}
                        height={25}
                      />
                      <Skeleton
                        variant={"rounded"}
                        width={"25%"}
                        height={25}
                      />
                    </SponsorBuy>
                  </div>
                </div>
              );
            })}
        </Cuad>
      </FlexColumn>
    </Container>
  );
};
