import { functions } from 'config/firebase';
import { httpsCallable,  } from "firebase/functions";
import { LogisticInquiryType } from 'types/services';

const createLogisticInquiry = async (data:  LogisticInquiryType ) => {
    const createLogisticInquiry = httpsCallable(functions, 'createLogisticInquiry');
    const response = await createLogisticInquiry(data);
    return response.data as LogisticInquiryType 
}

export default createLogisticInquiry;

