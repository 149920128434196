
import { FC } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { persistor, store } from "libs/redux";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { theme } from "libs/theme";
import StyledApp from "libs/theme/StyledApp";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./routes";
import { NotificationProvider } from "libs/context/AlertContext";
import { QueryClient, QueryClientProvider } from 'react-query';
import "i18n/locales";

const queryClient = new QueryClient();

const App: FC = () => {

  return (
      <NotificationProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <StyledApp />
                <Router />
              </QueryClientProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </NotificationProvider>
  
  )
  
}

export default App;
