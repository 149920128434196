
import Destinos1 from "assets/destinos (1).png";
import Destinos2 from "assets/destinos (2).png";
import Destinos3 from "assets/destinos (3).png";
import Destinos4 from "assets/destinos (4).png";

export const DATA = [
  {
    image: Destinos1,
    name: {
      es: "ADMINISTRAMOS TU PROPIEDAD",
      en: "ISLA TABOGA",
    },
    desc: {
      es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
      en: "Whether commercial or residential, we can help you with everything related to your property.",
    },
  },
  {
    image: Destinos2,
    name: {
      es: "ADMINISTRAMOS TU PROPIEDAD",
      en: "ARCHIPIELAGO DE LAS PERLAS",
    },
    desc: {
      es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
      en: "Whether commercial or residential, we can help you with everything related to your property.",
    },
  },
  {
    image: Destinos3,
    name: {
      es: "ADMINISTRAMOS TU PROPIEDAD",
      en: "BAHIA DE PANAMA",
    },
    desc: {
      es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
      en: "Whether commercial or residential, we can help you with everything related to your property.",
    },
  },
  {
    image: Destinos4,
    name: {
      es: "ADMINISTRAMOS TU PROPIEDAD",
      en: "PUENTE DE LAS AMERICAS",
    },
    desc: {
      es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
      en: "Whether commercial or residential, we can help you with everything related to your property.",
    },
  },
];

export const DATA_MOCK = [
  {
    image:
      "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
    price: 34900,
    dimensions: "7,015.82 mts2",
    location: "Provincia de Coclé",
    title: {
      es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
      en: "Opportunity Lot located in Retiro, Anton, province of Cocle",
    },
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
    price: 34900,
    dimensions: "7,015.82 mts2",
    location: "Provincia de Coclé",
    title: {
      es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
      en: "Opportunity Lot located in Retiro, Anton, province of Cocle",
    },
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
    price: 34900,
    dimensions: "7,015.82 mts2",
    location: "Provincia de Coclé",
    title: {
      es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
      en: "Opportunity Lot located in Retiro, Anton, province of Cocle",
    },
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
    price: 34900,
    dimensions: "7,015.82 mts2",
    location: "Provincia de Coclé",
    title: {
      es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
      en: "Opportunity Lot located in Retiro, Anton, province of Cocle",
    },
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
    price: 34900,
    dimensions: "7,015.82 mts2",
    location: "Provincia de Coclé",
    title: {
      es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
      en: "Opportunity Lot located in Retiro, Anton, province of Cocle",
    },
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
    price: 34900,
    dimensions: "7,015.82 mts2",
    location: "Provincia de Coclé",
    title: {
      es: "Lote Oportunidad ubicado en el retiro, Antón, provincia de Coclé",
      en: "Opportunity Lot located in Retiro, Anton, province of Cocle",
    },
  },
];

export const OPTIONS_FORM_TECHNICAL_SERVICE = [];

export const MESSAGE_FOOTER_BANNER = {
  name: {
    es: "¿Cómo puedo publicar mis servicios?",
    en: "How can I publish my services?",
  },
  shortDescription: {
    es: "Te ofrecemos la oportunidad de publicar tus servicios de manera gratuita.",
    en: "We offer you the opportunity to publish your services for free.",
  },
};

export const MESSAGE_FOOTER_BANNER_TOURISM = {
  name: {
    es: "¿Cómo puedo publicar mis tours?",
    en: "How can I publish my tours?",
  },
  shortDescription: {
    es: "Te ofrecemos la oportunidad de publicar tus tours.",
    en: "We offer you the opportunity to publish your tours.",
  },
};
