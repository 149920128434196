import { useState, useCallback } from 'react';
import { collection, getDocs, query, where, DocumentData, orderBy } from "firebase/firestore/lite";
import { firestore } from 'config/firebase';
import firebase from 'firebase/compat/app';

interface FirestoreDoc extends DocumentData {}

interface Filter {
  field: string;
  operator: firebase.firestore.WhereFilterOp;
  value: string | number | boolean | null | firebase.firestore.FieldPath | number[];
}

interface OrderBy {
  field?: string;
  direction?: 'asc' | 'desc';
}

interface FetchOptions {
  path: string;
  filters?: Filter[];
  orderFilter?: OrderBy;
  filterMode?: 'AND' | 'OR'; // Nueva opción para determinar si el filtro es 'AND' o 'OR'
}

export const useGetFirestoreCollection = () => {
  const [loadingGetFirestoreCollection, setLoading] = useState<boolean>(true);
  const [errorGetFirestoreCollection, setError] = useState<Error | null>(null);
  const [collectionData, setCollectionData] = useState<FirestoreDoc[] | null>(null);

  const fetchCollection = useCallback(async (options: FetchOptions) => {
    setLoading(true);
    setError(null);

    try {
      const { path, filters = [], orderFilter, filterMode = 'AND' } = options; // El filtro por defecto es 'AND'
      const db = firestore;
      let firestoreQuery;

      if (filterMode === 'AND') {
        // Combinar filtros usando 'AND' (todas las condiciones deben coincidir)
        firestoreQuery = collection(db, path);
        filters.forEach((filter: Filter) => {
          const { field, operator, value } = filter;
          firestoreQuery = query(firestoreQuery, where(field, operator, value)) as any;
        });
      } else if (filterMode === 'OR') {
        // Combinar filtros usando 'OR' (una o más condiciones deben coincidir)
        const queries = filters.map((filter: Filter) => {
          const { field, operator, value } = filter;
          return query(collection(db, path), where(field, operator, value)) as any;
        });

        // Ejecutar las consultas en paralelo
        const results = await Promise.all(queries.map(q => getDocs(q)));

        // Combinar los resultados eliminando duplicados
        const data = results.flatMap(querySnapshot =>
          querySnapshot.docs.map(doc => ({ ...doc.data() as any, uid: doc.id } as FirestoreDoc))
        );

        // Filtrar duplicados si hay documentos con el mismo `uid`
        const uniqueData = Array.from(new Map(data.map(item => [item.uid, item])).values());

        setCollectionData(uniqueData);
        setLoading(false);
        return;
      }

      // Si hay un filtro de orden
      if (orderFilter?.field && orderFilter.direction) {
        const { field, direction } = orderFilter;
        firestoreQuery = query(firestoreQuery, orderBy(field, direction)) as any;
      }

      // Ejecutar la consulta
      const querySnapshot = await getDocs(firestoreQuery);

      const data = querySnapshot.docs.map(doc => ({ ...doc.data() as any, uid: doc.id } as FirestoreDoc));
      setCollectionData(data);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  }, []);

  function clearCollectionData() {
    setCollectionData(null);
  }

  return { loadingGetFirestoreCollection, errorGetFirestoreCollection, collectionData, fetchCollection, clearCollectionData };
};
