

export const getStatusText = (status: string) => {

    switch (status) {
        case 'pending':
            return 'Pendiente'
        case 'accepted':
            return 'Aceptado'
        case 'rejected':
            return 'Rechazado'
        case 'completed':
            return 'Completado'
        case 'canceled':
            return 'Cancelado'
        default:
            return 'Pendiente'
    }
}

export const getUrgencyLevelText = (urgencyLevel: string) => {
    
        switch (urgencyLevel) {
            case 'low':
                return 'Baja'
            case 'medium':
                return 'Media'
            case 'high':
                return 'Alta'
            default:
                return 'Sin urgencia'
        }
}

// get service category name 

export const  getServiceCategoryName = (category: string) => {
    switch (category) {
        case 'business':
            return 'International Business'
        default:
            return category
    }
}
