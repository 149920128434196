
import Carousel from "react-multi-carousel";
import Skeleton from "@mui/material/Skeleton";
import { lightTheme } from "constants/theme/theme";
import {ThemeProvider} from "@mui/material";

const ItemSkeletons = new Array(60).fill(0);

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
};


const Skeletons = () => {

    return(
            <Carousel
                swipeable
                draggable
                showDots={false}
                responsive={responsive}
                ssr={true} 
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                arrows={true}
            >
                {ItemSkeletons.map((item, index) => (
                    <div key={index}>
                        <ThemeProvider theme={lightTheme}>
                            <Skeleton variant={"rounded"} height={200} width={"90%"} style={{margin: "0 auto"}}/>
                            <div style={{width: "90%", margin: "0 auto"}}>
                                <Skeleton variant={"text"} width={"60%"}/>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <Skeleton variant={"rounded"} width={"40%"} height={30}/>
                                    <Skeleton variant={"rounded"} width={"30%"} height={30}/>
                                </div>
                            </div>
                        </ThemeProvider>
                    </div>
                ))}
            </Carousel>
        )
}

export default Skeletons;