import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css"
import { Container } from "./styled";

function CarouselGeneral({
  items,
  renderItem: RenderItem,
  itemsToShow = 4,
  itemsTablet = 3,
  itemsMobile = 3,
  props = {},
}) {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: itemsToShow,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: itemsTablet || itemsToShow,
    },
    mobile: {
      breakpoint: { max: 580, min: 0 },
      items: itemsMobile || itemsToShow,
    }
  };

  return (
    <Container>
      <Carousel
        swipeable
        draggable
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        arrows={true}
      >
        {items.map((item, index) => (
          <RenderItem key={index} data={item} props={props} />
        ))}
      </Carousel>
    </Container>
  );
}

export default CarouselGeneral;
