import { FC, useEffect, useState } from "react";
import { userSelector } from "redux/user";
import { useSelector } from "react-redux";
import {
  Container,
  Content,
  SideSections,
  PillSection,
  ProfileCameraIcon,
  ProfileContainer,
  ProfileName,
  ProfilePicture,
  SectionTitle,
  ProfileImage,
} from "./styled";
import UserOrders from "views/containers/Profile/MyOrders";
import { MdCameraAlt } from "react-icons/md";
import MainLayout from "views/layout/MainLayout";
import { IoIosArrowBack } from "react-icons/io";
import ModalLoader from "ui/ModalLoader";
import { useNotification } from "libs/context/AlertContext";
import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firestoreFull as firestore, storage } from "config/firebase";
import { v4 } from "uuid";
import _ from "lodash";

import useLogout from "hooks/useLogout/useLogout";
import { getLanguage } from "redux/common";
import { getUserAddresses } from "services/user/updateInfo/service-update-user-info";
import useUserOrdersListener from "hooks/user/useGetUserOrders/useGetUserOrders";
import UserAddressesComponent from "./sections/user-addresses/user-addresses";
import UserInformationComponent from "./sections/user-info/user-info";
import RequestsHistorialComponent from "./sections/user-requests/user-requests";
import UserFavoritesComponent from "./sections/user-favorites/user-favorites";
import MainSectionComponent from "./sections/main-section";
import { BREAKPOINTS } from "constants/index";
import { Address } from "types";
import useUpdateUserBasicInfo from "hooks/user/useUpdateUserInfo/use-update-user-info";
import { userInfo as userInfoRedux } from "redux/user";

const StepNamesSpanish = [
  "Información Personal",
  "Mis Pedidos",
  "Mis Solicitudes",
  "Mis Direcciones",
  "Mis Favoritos",
  "Mis clasificados",
];

const StepNamesEnglish = [
  "Personal Information",
  "My Orders",
  "My Requests",
  "My Addresses",
  "My Favorites",
  "My Classifieds",
];

const Profile: FC = () => {
  const logout = useLogout();
  const { showSuccess, showError } = useNotification();
  const language = useSelector(getLanguage);
  const userInfo: any = useSelector(userInfoRedux as any);
  const { userData } = useSelector(userSelector);
  const [user, setUser] = useState<any>([]);
  const [listAddress, setListAddress] = useState<Address[]>([]);
  const {
    updateUserInfo,
    loading: loadingUpdateUser,
    error: errorUpdateUser,
    success: successUpdateUser,
  } = useUpdateUserBasicInfo();
  const [request, setRequest] = useState([]);

  getUserAddresses(userData.uid, (addressesItems: Address[]) => {
    setListAddress(addressesItems);
  });

  const orders = useUserOrdersListener(userData.uid);

  async function uploadImageToFirebaseStorage(image): Promise<void> {
    const imageRef = ref(
      storage,
      `Users/${userData.uid}/profilePicture/${v4()}`
    );

    uploadBytes(imageRef, image).then(async (snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        await updateUserInfo(userData.uid, { picture: url });
      });
    });
  }

  useEffect(() => {
    if (successUpdateUser) {
      showSuccess("Foto actualizada");
    }

    if (errorUpdateUser) {
      showError("Error al actualizar la foto");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateUser, errorUpdateUser]);

  // Getting user data

  useEffect(() => {
    setUser([userData]);
  }, [userData]);

  async function handleUpdateUserPicture(picture) {
    try {
      await uploadImageToFirebaseStorage(picture);
      showSuccess("Foto actualizada");
    } catch (error) {
      showError("Error al actualizar la foto");
    }
  }

  useEffect(() => {
    if (user.length > 0) {
      // const path2 = "Users/" + user[0]["uid"] + "/Payments";
      // const querySnapshot2 = getDocs(collection(firestore, path2));
      // querySnapshot2.then((querySnapshot2) => {
      //   querySnapshot2.forEach((doc) => {
      //     // @ts-ignore
      //     setInvoices((items) => [...items, { ...doc.data(), id: doc.id }]);
      //   });
      // });

      const path3 = "Users/" + user[0]["id"] + "/NeedsRequests";
      const querySnapshot3 = getDocs(collection(firestore, path3));
      querySnapshot3.then((querySnapshot2) => {
        querySnapshot2.forEach((doc) => {
          // @ts-ignore

          setRequest((items) =>
            _.uniqBy([...items, { ...doc.data(), id: doc.id }], "id")
          );
        });
      });

      const path4 = "Users/" + user[0]["id"] + "/PurchaseHistory";
      const querySnapshot4 = getDocs(collection(firestore, path4));
      querySnapshot4.then((querySnapshot2) => {
        querySnapshot2.forEach((doc) => {
          // @ts-ignore
          setPurchases((items) => [...items, { ...doc.data(), id: doc.id }]);
        });
      });
    }
  }, [user]);

  const handleLogout = (): void => {
    logout();
  };

  const [step, setStep] = useState(0);

  return (
    <MainLayout type="main">
      <Container>
        {userData ? (
          <Content
            style={{
              width:
                step === 0
                  ? window.innerWidth < BREAKPOINTS.lg
                    ? "95%"
                    : "70%"
                  : "95%",
            }}
          >
            <ProfileContainer>
              <ProfilePicture>
                <ProfileImage
                  className={"img-fluid"}
                  src={
                    userInfo.picture ||
                    "https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Fimages%2Fno-picture.png?alt=media&token=d9e9ce5e-c40b-425a-a527-d054060a654a&_gl=1*17foigb*_ga*MTcxODE5NDAzOS4xNjkxNjI2MjU1*_ga_CW55HF8NVT*MTY5NzczNTQ4Ni4yMjQuMS4xNjk3NzM1NTI1LjIxLjAuMA.."
                  }
                  loading="lazy"
                  alt="Foto de perfil del usuario"
                  title="Foto de perfil del usuario"
                />
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  name="image"
                  onChange={async (event) => {
                    if (event.target.files) {
                      const maxSize = 5 * 1024 * 1024;
                      if (event && event.target.size > maxSize) {
                        showError(
                          "El archivo es demasiado grande. Máximo 5MB."
                        );
                      } else {
                        let image = event.target.files[0];
                        await handleUpdateUserPicture(image);
                      }
                    } else {
                      alert("debe elegir un archivo");
                    }
                  }}
                  type="file"
                />
                <label
                  htmlFor="raised-button-file"
                  style={{ cursor: "pointer" }}
                >
                  <ProfileCameraIcon>
                    <MdCameraAlt
                      color={"#3A36DB"}
                      size={window.innerWidth < 2000 ? 15 : 25}
                    />
                  </ProfileCameraIcon>
                </label>
              </ProfilePicture>
              <ProfileName>
                {`${userInfo.firstName} ${userInfo.firstSurname}`}
              </ProfileName>
              <p>{user.picture}</p>
            </ProfileContainer>
            <PillSection>
              <MainSectionComponent
                onChange={setStep}
                step={step}
                handleLogout={handleLogout}
              />
              <SideSections style={{ display: step === 0 ? "none" : "block" }}>
                <SectionTitle onClick={() => setStep(0)}>
                  <IoIosArrowBack />{" "}
                  {language === "es"
                    ? StepNamesSpanish[step - 1]
                    : StepNamesEnglish[step - 1]}
                </SectionTitle>
                {step === 1 ? (
                  <UserInformationComponent user={[userInfo]} />
                ) : step === 2 ? (
                  <UserOrders orders={orders} />
                ) : step === 3 ? (
                  <RequestsHistorialComponent request={request} />
                ) : step === 4 ? (
                  <UserAddressesComponent
                    listAddress={listAddress}
                    userId={user.length > 0 ? user[0].id : ""}
                  />
                ) : step === 5 ? (
                  <UserFavoritesComponent favorites={[]} />
                ) : null}
              </SideSections>
            </PillSection>
          </Content>
        ) : (
          <Content style={{ width: "95%" }}>
            <h4>Para ver la información del perfil debe iniciar sesión.</h4>
          </Content>
        )}
      </Container>
      <ModalLoader isLoading={loadingUpdateUser} />
    </MainLayout>
  );
};

export default Profile;
