import styled from "styled-components";

export const Container = styled.div`
    padding: 0;
    margin: 0;

    video {
      /* border-radius: 16px; */
      object-fit: contain;
      width: 100%;
    }
  @media only screen and (max-width: 992px) {
    padding: 0;
    margin: 0 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    video {
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 420px) {
    margin: 0;
  }
`
export const PlayerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 40vw;
  max-height: 40vh;
  background-color: #FDFDFE;
  @media only screen and (max-width: 992px) {
    max-width: 100%;
    max-height: 100%;
  }
`

interface IPlayerSubContainer {
  useForm?: boolean
}

export const PlayerSubContainer = styled.div<IPlayerSubContainer>`
  display: flex;
  width: ${props => props.useForm ? "65%" : "100%"};
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }

  // selecciona las etiquetas de video
  video {
    object-fit: contain;
  }
`

export const FormSubContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`


export const Titles = styled.h2`
  text-align: ${props => props.align || "left"};
  font-family: Outfit, monospace;
  font-weight: ${props => props.weight || "700"};
  font-size: ${props => props.size || "2.5rem"};
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.subheading.sm.bold};
    text-align: ${props => props.alignM || "left"};
    padding: 8px 0 8px 8px;
  }
`
