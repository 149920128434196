import styled from "@emotion/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FDFDFE;;
`

export const ContainerBread = styled.div`
  width: 100%;
  padding: 20px 20px 100px 20px;
  margin-bottom: 4rem;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
  background-color: #FDFDFE;
  align-self: center;
  border-radius: 16px;

  @media (max-width: 992px) {
    width: 100%;
  }
`
export const YellowBar = styled.div`
  height: 400px;
  width: 100%;
  background-color: #FFB800;
  
`
