
/* eslint-disable @typescript-eslint/ban-types */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {createSagasTrigger} from 'libs/redux/sagas/trigger'


export type InitialState = typeof initialState


interface IState { 
  isAuth: boolean
  loading: boolean
  error: boolean
}

export const initialState: IState = {
  isAuth: false,
  loading: false,
  error: false,
} 


type SetAuth = PayloadAction<{ isAuth: boolean }>

const authSlice = createSlice({
  name: 'auth', 
  initialState,
  reducers: {
    setAuth(state, action: SetAuth) {
      state.isAuth = action.payload.isAuth
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload 
    },
    logout(state) {
      state.isAuth = false
      state = initialState
    },
  },
})



export const { setAuth, setLoading, setError, logout,} = authSlice.actions

export const authSagasTrigger = createSagasTrigger<{isAuth: boolean}>('auth_sagas')

export const authReducer = authSlice.reducer





