
import Modal from 'ui/Modal';
import { ImageModal, ShortDescriptionText, TitleModal, Row, PriceFromText } from './styled-modal';
import { useSelector } from 'react-redux';
import { getLanguage } from 'redux/common/selector';
import Price from 'components/Price';
import { useTranslation } from 'react-i18next';
import WhatsAppButton from "components/ButtonWhatsapp/button-whatsapp";

interface ModalInfoProps {
    isOpen: boolean;
    onClose: () => void;
    data: any
}

const ModalInfo = (props: ModalInfoProps) => {
    const { isOpen, onClose, data } = props;
    const language = useSelector(getLanguage);
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose}
        >
            <div style={{ textAlign: 'start' }}>
                <TitleModal>{data?.name[language]}</TitleModal>
                <div>
                    <ImageModal src={data?.image || data?.images[0]} />
                    {
                        data?.price ? (
                            <Row>
                                {
                                    data?.priceType ? (
                                        <PriceFromText>{t('from')}</PriceFromText>
                                    ) : null
                                }
                                <Price value={data?.price || 0} currencySymbol='USD' size='1.5rem' />
                            </Row>
                        )
                            :
                            null
                    }
                    <ShortDescriptionText>{data?.shortDescription[language]}</ShortDescriptionText>
                </div>
            </div>
            < WhatsAppButton
                phoneNumber="+50764055638"
                link={window.location.href}
                message={`Hola, estoy interesado en mas información sobre ${data?.name[language]}`}
            />

        </Modal>
    );
};

export default ModalInfo;
