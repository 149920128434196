import {createGlobalStyle} from 'styled-components'

const StyledApp = createGlobalStyle`
  body {
    //overflow: hidden;
    margin: 0;
    font-family:Montserrat ,monospace;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  ul {
    padding-left: 1rem;
  }

`
export default StyledApp
