import styled, { keyframes } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Image = styled.img`
    width: 80px;
    height: 80px;
`;

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

export const LoadingText = styled.div`
  font-size: 24px;
  font-weight: bold;
  animation: ${blink} 1s linear infinite;
  color: #fff;
`;