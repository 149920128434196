import { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import { BREAKPOINTS } from "constants/index";

function MultiCarousel({
  handleCat,
  handleType,
  items,
  type,
  renderItem: RenderItem,
  itemsToShow = 6,
  itemsTablet = 2,
  itemsMobile = 2,
  style,
}: any) {

  const carouselRef = useRef(null);

  const responsive = {
    desktop: {
      breakpoint: { max: BREAKPOINTS.max, min: BREAKPOINTS.lg },
      items: itemsToShow,
    },
    tablet: {
      breakpoint: { max: BREAKPOINTS.lg, min: BREAKPOINTS.sm },
      items: itemsTablet,
    },
    mobile: {
      breakpoint: { max: BREAKPOINTS.sm, min: 0 },
      items: itemsMobile,
    },
  };

  return (
    <div style={{ width: "100%", ...style }}>
      <Carousel
        swipeable
        showDots={false}
        arrows={true}
        responsive={responsive}
        ssr={true}
        ref={carouselRef}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3100}
        keyBoardControl={true}
        transitionDuration={0}
        containerClass="carousel-container"
        itemClass="carousel-items"
      >
        {items && items?.length && items?.map((item, index) => (
          <RenderItem
            data={item}
            handleType={handleType}
            type={type}
            handleCat={handleCat}
            key={index}
            linkButton={true}
            showPrice={false}
            id={item?.id || ''}
            title={item?.name?.es || ''}
            description={item?.description?.es || ''}
            image={item?.images ? item?.images[0] : ''}
            price={item?.price || ''}
            shortDescription={item?.shortDescription?.es || ''}
            service={item}
          />
        ))}
      </Carousel>
    </div>
  );
}

export default MultiCarousel;
