import { useState, useCallback } from 'react';
import sendMessageToClassifiedChatService from 'services/classifieds/send-message-to-classified-chat-service/send-message-to-classified-chat-service';
import { IMessageToClassifiedChat, ServiceResponse } from 'types';

const useSendMessageToClassifiedChat = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const sendMessage = useCallback(async (messageData: IMessageToClassifiedChat): Promise<ServiceResponse | null> => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await sendMessageToClassifiedChatService(messageData);

      if (response.success) {
        setSuccess(true);
        return response;
      } else {
        setError(response?.message || 'Error sending message to chat');
        return null;
      }
    } catch (err: any) {
      setError(err.message || 'Error sending message to chat');
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  return { sendMessage, loading, error, success };
};

export default useSendMessageToClassifiedChat;
