import { useState, useEffect } from 'react';
import {firestore }from 'config/firebase';
import {doc, getDoc} from 'firebase/firestore/lite';

const PATH_VEHICLE_BRANDS_DOCUMENT = 'ASSETS/Jsons/Vehicles/vehicleBrands';

const useVehicleBrands = () => {
    const [listOfBrands, setListOfBrands] = useState([]);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const docRef = doc(firestore, PATH_VEHICLE_BRANDS_DOCUMENT);

        getDoc(docRef)
        .then((doc) => {
            if (doc.exists()) {
                // @ts-ignore
                setListOfBrands(doc.data().vehicleBrands);
            } else {
                setError(new Error('No se encontro el documento'));
            }
        })
        .catch((error) => {
            setError(error);
            console.error("error hook get banners", error);
        })
        .finally(() => {});
    }
    , []);

    return [listOfBrands, error];
};

export default useVehicleBrands;
