import styled from 'styled-components';
import {THEME} from 'constants/theme/theme';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ChatViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
`;

export const ChatListItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding: 1rem 0;
`;

export const SendChatContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
    height: 100px;
    padding: 1rem 0;
    border-top: 1px solid #e0e0e0;
`;


// CHAT ITEM

interface IChatItemContainerProps {
    alignSelf: string
    isOwner: boolean
}

export const ChatItemContainer = styled.div<IChatItemContainerProps>`
    display: flex;
    flex-direction: column;
    padding: .5rem;
    background-color: ${props => props.isOwner ? '#fff;' : '#f0f0f0'};
    border: ${props => props.isOwner ? '1px solid #e0e0e0;' : 'none'};
    border-radius: .5rem;
    width: fit-content;
    max-width: 55%;
    min-width: 20%;
    align-self: ${props => props.alignSelf || 'flex-start'};
`;

export const InputChat = styled.input`
    width: 100%;
    height: 100%;
    padding: 1rem;
    border: none;
    border-radius: .5rem;
    outline: none;
`;

export const DateMessage = styled.p`
    font-size: .8rem;
    color: ${THEME.colors.gray80};
    margin-top: .5rem;
    font-weight: 600;
    line-height: .8rem;
    margin: 4px 0 0 0;
`;