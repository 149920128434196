import styled from "styled-components";
import { THEME } from "constants/theme/theme";


export const CardContainer = styled.div`
  width: 98%;
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #FDFDFE;
  text-align: left;
  padding: .5rem .5rem 0 .5rem;
  border: 1px solid #E5E5E5;
  border-radius: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);


  &:before {
    ${({contenido}) => contenido && `
    background:#08A791;
    `}
    content: '${props => props.contenido}';
    padding: .5rem;
    color: #ffffff;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    top: .5rem;
    left: 0;
    @media only screen and (max-width: 500px){
      ${props => props.theme.typography.body.sm.regular};
    }
  }
`

export const SpanMiddle = styled.span`
  vertical-align: middle;
`

export const CardImageClassified = styled.img`
  width: ${props => props.size || "100%"};
  border-radius: .5rem;
  object-fit: cover;
  height: 220px;
  margin-bottom: .5rem;

  @media only screen and (max-width: 968px){
    height: 140px;
  }

  @media only screen and (max-width: 400px){
    height: 120px;
  }
`

export const ContentCard = styled.div`
  display: flex;
  flex: 1;
  height: 45%;
  flex-direction: column;
  margin-bottom: 4px;
`

export const CrudActions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .5rem;
`;

export const ItemAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: .5rem;
  gap: .5rem;
  padding: .5rem;
  border-radius: .5rem;
  background: ${props => props.type === 'edit' ? THEME.colors.gray20 : props.type === 'delete' ? THEME.colors.alert20 : '#ffffff'};
  color: ${props => props.type === 'edit' ? THEME.colors.gray100 : props.type === 'delete' ? THEME.colors.alert100 : THEME.colors.gray100};
  border: 1px solid #E5E5E5;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover {
    background: ${props => props.type === 'edit' ? THEME.colors.gray40 : props.type === 'delete' ? THEME.colors.alert40 : '#ffffff'};
    color: ${props => props.type === 'edit' ? THEME.colors.gray100 : props.type === 'delete' ? THEME.colors.alert100 : THEME.colors.gray100};
    border: 1px solid #E5E5E5;
  }
`


export const Price = styled.span`
  color: ${THEME.colors.classifieds200};
  font-weight: 700;
`

export const TitleCard = styled.span`
  font-size: 1rem;
  color:#0C121D;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const DescriptionCard = styled.span`
  font-size: .8rem;
  color:#0C121D;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media only screen and (max-width: 768px){
    // delete line-clamp
    -webkit-line-clamp: 5;

  }


  @media only screen and (max-width: 400px){
    font-size: 12px;
  }
`
export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
`
export const Location = styled.p`
  color:#262494;
  ${props => props.theme.typography.label.xs.medium};
  font-family: Montserrat, monospace !important;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 400px){
    font-size: 8px;
  }
`

// CONTENT CARDS

export const ContentCardByTypeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  width: 100%;
  margin: 0;
  grid-gap: .3rem;

  @media only screen and (max-width: 768px){
    grid-template-columns: repeat(2, 1fr);

  }
`

export const ColumnProperty = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: .3rem;
  margin-top: .3rem;
  border: 1px solid #E5E5E5;
  border-radius: .5rem;
  padding: .5rem .5rem .0rem .5rem;

  @media only screen and (max-width: 768px){
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: .5rem;
    width: 100%;
  }
`

export const ImageProperty = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;

  @media only screen and (max-width: 768px){
    width: 15px;
    height: 15px;
  }
`

interface IValueProperty {
  isNumber?: boolean;
}

export const ValueProperty = styled.p<IValueProperty>`
  color: ${THEME.colors.black100};
  font-weight: 700;// ${props => props.isNumber ? 700 : 400};
  font-size: ${props => props.isNumber ? ".9rem" : ".6rem"};
  margin: 0;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
`

interface IStatus {
  color: string;
}

export const TextStatus = styled.span<IStatus>`
  color: ${props => props.color};
  font-weight: 700;
  font-size: .8rem;
`
