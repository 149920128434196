import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 8px 16px 8px 16px;

    @media (max-width: 768px) {
     position: relative;
     margin-left: 2px;
     z-index: 1000;
    }

`;
export const SearchBarContainer = styled.div`
    display: flex;
    flex: 1;
    background-color: #fff;
    height: 44px;
    border-radius: 6px;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

    // shadow
    box-shadow: 0 2px 1px 0 #fff;
`;

export const Input = styled.input`
    width: 95%;
    height: 44px;
    border: 44px;
    outline: none;
    padding: 0 10px;
    font-size: 16px;
    color: #000;
`;

export const ResultsContainer = styled.div`
    width: 100%;
    max-width: 845px;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #fff;
    margin-top: 60px;
    border-radius:6px;
    color: #000;
    overflow-y: scroll;
`;    

export const Result = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    max-height: 80vh;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #f5f5f5;
    }
    
    overflow-y: scroll;
`;

export const ResultItem = styled.div`
    display: flex;
    
`;    

export const TextItem = styled.p`
    font-size: 14px;
    margin: 0;
    margin-left: 10px;
    font-weight: 500;
    color: #000;
`;

export const ClearButton = styled.div`
    cursor: pointer;
    // hover
    &:hover {
        opacity: 0.5;
    }
`
