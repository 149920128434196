/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react';
import MainLayout from 'views/layout/MainLayout';
import useRemoveBgPixian from 'hooks/tools/useRemoveBg/use-remove-bg';

const RemoveBg = () => {
    const { handleRemoveBg, data} = useRemoveBgPixian();

    const [imageWithOutBg, setImageWithOutBg] = useState<string>("");

    function handleUploadFile(event: any) {
        const file = event.target.files[0];
        handleRemoveBg(file);
    }

    useEffect(() => {
        if (data) {
            setImageWithOutBg(data);
        }
    }, [data]);
    

    return (
        <MainLayout>
            <div>
                <h1>Remover fondo</h1>
            </div>
            <div>
                <input
                    type="file"
                    onChange={handleUploadFile}
                />
                <img src={imageWithOutBg} alt="image with out bg" />
            </div>

        </MainLayout>
    );
}

export default RemoveBg;