import { FC } from "react";
import {
  Container,
  ItemLanguage,
  GridLanguage,
  FlagImage,
  Row,
} from "./styled";
import { USA_FLAG_URL, SPAIN_FLAG_URL } from "constants/images";
import { DBLanguageReference } from "types/common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { THEME } from "constants/theme/theme";
import { useTranslation } from "react-i18next";

interface IProps {
  language: DBLanguageReference;
  setLanguage: (language: DBLanguageReference) => void;
}

const LanguageContentConfigModal: FC<IProps> = (props: IProps) => {
  const { language, setLanguage } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <h6>{t("select_the_language_of_your_preference")}</h6>
      <GridLanguage>
        <ItemLanguage
          isSelected={language === DBLanguageReference.es}
          onClick={() => setLanguage(DBLanguageReference.es)}
        >
          <FlagImage src={SPAIN_FLAG_URL} alt="Spain flag" title="Spain flag" />
          <Row>
            <span>Español</span>
            {language === "es" && (
              <CheckCircleIcon
                style={{ color: THEME.colors.positive80, fontSize: "16px" }}
              />
            )}
          </Row>
        </ItemLanguage>
        <ItemLanguage
          isSelected={language === DBLanguageReference.en}
          onClick={() => setLanguage(DBLanguageReference.en)}
        >
          <FlagImage src={USA_FLAG_URL} alt="USA flag" title="USA flag" />
          <Row>
            <span>English</span>
            {language === "en" && (
              <CheckCircleIcon
                style={{ color: THEME.colors.positive80, fontSize: "16px" }}
              />
            )}
          </Row>
        </ItemLanguage>
      </GridLanguage>
    </Container>
  );
};

export default LanguageContentConfigModal;
