import { PriceType } from 'types'
import { ProductsCategoryType } from 'types/common'


export function getButtonText ( productCategory: ProductsCategoryType) {

    switch (productCategory) {
        case 'machinery':
            return 'Cotizar'
        case 'store':
            return 'Comprar'
        case 'wholesale':
            return 'Ver producto'
        default:
            return 'Ver más'
    }

}

export function getPriceTypeByCategoryType (category:ProductsCategoryType ): PriceType | undefined {
    switch (category) {
        case 'store':
            return 'fixed'
        case 'machinery':
            return 'invisible'
        case 'wholesale':
            return 'range'
        default:
            return undefined
    }
}