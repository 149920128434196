import {all, call} from 'redux-saga/effects'
import {routinePromiseWatcherSaga} from 'redux-saga-routines'
import { userGoogleSagas } from 'redux/user/sagas'
import {cartSagas}  from 'redux/cart/sagas/sagas-cart'
import { authSagas } from 'redux/auth/sagas'
// import { checkoutSagas } from 'ducks/checkout/sagas/sagas-checkout'
import {SagaIterator} from 'redux-saga'


export default function* rootSaga(): SagaIterator {
  try {
    yield all([
      call(routinePromiseWatcherSaga),
      call(userGoogleSagas),
      call(cartSagas),
      call(authSagas),
      // call(checkoutSagas)
    ])
  } catch {
    console.log('error root saga')
  }
}