import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { THEME } from 'constants/theme/theme';

import { SelectStyled } from './styled';

type IItemOption = {
  value: string | number;
  label: string;
};

interface IsSelectProps {
  label: string;
  name?: string;
  error?: string | undefined;
  touched?: any;
  value: Array<string | number> | string | number | null;
  defaultValues?: Array<string | number>;  // Prop para valores por defecto
  onChange?: (value: Array<string | number> | string | number) => void;
  width?: string;
  options: IItemOption[] | [];
  style?: React.CSSProperties;
  onBlur?: () => void;
  showLabel?: boolean;
  changeById?: boolean;
  disabled?: boolean;
  hideNeverChoice?: boolean;
  multiple?: boolean;
}

export default function Select(props: IsSelectProps) {
  const {
    label,
    name,
    error,
    value,
    options,
    onChange,
    defaultValues,  // Destructura la prop defaultValues
    onBlur,
    width,
    disabled,
    hideNeverChoice,
    multiple,
  } = props;

  // Nuevo estado para manejar los labels de los valores seleccionados
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  // Nuevo useEffect para manejar los valores por defecto y buscar los labels correspondientes
  useEffect(() => {
    if (defaultValues && defaultValues.length > 0) {
      const defaultLabels = defaultValues.map(defaultValue =>
        options.find(option => option.value === defaultValue)?.label || ''
      );
      setSelectedLabels(defaultLabels);
    }
  }, [defaultValues, options]);

  const handleOnChange = (
    e: React.ChangeEvent<{ value: number | string | string[] }>,
  ) => {
    const selectedValue = !multiple ? e.target.value : [e.target.value];
    if (onChange) {
      onChange(selectedValue as any);
    }
    // Actualiza selectedLabels cuando cambia el value
    const newLabels = (e.target.value instanceof Array ? e.target.value : [e.target.value])
      .map(val => options.find(option => option.value === val)?.label || '');
    setSelectedLabels(newLabels);
  };

  const renderValueSelected = (selected: any) => {
    if (multiple) {
      return selectedLabels.join(', ') || 'Nada seleccionado';
    }
    return selected || selectedLabels[0] || 'Nada seleccionado';
  };

  return (
    <>
      <FormControl style={{ width: width || '100%' }}>
        <InputLabel id="select-label">{label || 'Inserte label'}</InputLabel>
        <SelectStyled
          disabled={disabled === true ? true : false}
          labelId="main-select-label"
          id="main-select"
          multiple={multiple}
          value={value || defaultValues || []}  // Usa defaultValues si value es null o undefined
          name={name}
          label={label || 'Inserte label'}
          sx={{
            borderRadius: '10px',
            width: '100%',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${props.disabled ? '#F5F5F5' : THEME.colors.primary}`,
            },
          }}
          renderValue={renderValueSelected}  // Usa renderValue para mostrar valores seleccionados
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={handleOnChange}
          onBlur={onBlur}
        >
          {!hideNeverChoice && (
            <MenuItem key={0} value={0}>
              <em>Ninguno</em>
            </MenuItem>
          )}
          {options.map((option: any, index: number) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </SelectStyled>
        {error && (
          <p style={{ color: 'red', fontSize: '12px', margin: '0px 10px' }}>{error}</p>
        )}
      </FormControl>
    </>
  );
}
