import styled from "@emotion/styled";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -16px;
    
    @media (max-width: 992px) {
        padding: 0;
        margin-top: 20px;
    }
`;

