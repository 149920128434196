import React from 'react';
import styled from 'styled-components';
import { THEME } from "constants/theme/theme";

interface PriceProps {
    value: number | string;
    currencySymbol?: string;
    size?: string; // Tamaño de la fuente, opcional
}

const PriceContainer = styled.span<{ size?: string }>`
  font-size: ${(props) => props.size || '1em'};
`;

const PriceComponent = styled.span<{ size?: string }>`
  color: ${THEME.colors.classifieds200};
  font-weight: 700;
  font-size: ${(props) => props.size || '1em'}; // Utiliza size, si se proporciona
`;

const DecimalPart = styled.span<{ size?: string }>`
  font-size: ${(props) => (props.size ? `calc(${props.size} * 0.8)` : '0.8em')}; // Tamaño más pequeño para la parte decimal
`;

const formatNumber = (value: number | string): { integerPart: string, decimalPart: string } => {
    let numericValue = typeof value === 'string' ? parseFloat(value) : value;
    let stringValue = numericValue?.toFixed(2); // Asegura dos decimales
    let parts = stringValue.split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Añade puntos como separadores de miles

    return { integerPart: parts[0], decimalPart: parts[1] };
}

const Price: React.FC<PriceProps> = ({ value, currencySymbol = '', size }) => {
    const { integerPart, decimalPart } = formatNumber(value);

    return (
        <PriceContainer size={size}>
            <PriceComponent size={size}>
                {currencySymbol} {integerPart},
                <DecimalPart size={size}>{decimalPart}</DecimalPart>
            </PriceComponent>
        </PriceContainer>
    );
}

export default Price;