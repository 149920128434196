import { useState } from 'react';
import  removeBgPixian  from 'services/tools/remove-bg-pixian/remove-bg-pixian-service';

export default function useRemoveBgPixian() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleRemoveBg = async (file) => {
        setLoading(true);
        setError(null);
        
        try {
            const result = await removeBgPixian(file);
            setData(result);
        } catch (err: any) {
            console.error('Error en useRemoveBgPixian:', err);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { handleRemoveBg, data, loading, error };
}
