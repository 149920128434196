import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'config/firebase'; // Asegúrate de que este camino es correcto para tu configuración de Firebase
interface GenerateIdParams {
    path: string;
}

interface GenerateIdResponse {
    uid: string;
}

const useGenerateFirestoreId = () => {
    const [loading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [uid, setId] = useState<string | null>(null);

    const generateId = async (params: GenerateIdParams) => {
        setIsLoading(true);
        setError(null);
        try {
            const generateIdFunction = httpsCallable<GenerateIdParams, GenerateIdResponse>(functions, 'generateCustomFirestoreId');
            const response = await generateIdFunction(params);
            setId(response.data.uid);
        } catch (error: any) {
            setError(new Error(error.message));
            setId(null);
        } finally {
            setIsLoading(false);
        }
    };

    return { generateId, loading, uid, error };
};

export default useGenerateFirestoreId;
