import { firestoreFull as firestore } from "config/firebase";
import { where, getDocs, collection, query } from 'firebase/firestore'

const PATH_USERS = 'Users'

async function verifyEmailAvailability(email: string) {
    const usersRef = collection(firestore, PATH_USERS);
    const q = query(usersRef, where("email", "==", email));

    return await getDocs(q).then((querySnapshot) => {
        if (querySnapshot.size > 0) {
            return false;
        } else {
            return true;
        }
    });
}



export default verifyEmailAvailability;
