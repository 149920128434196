import {Container, Image} from "./styled"

interface Props {
    hrefLink: string;
    imageUrl: string;
    hideDesktop?: boolean;
    hideMobile?: boolean
}


const SimpleImageBanner = (props: Props) => {
    const {hrefLink, imageUrl, hideDesktop, hideMobile} = props;


    return (
        <Container hideMobile={hideMobile} hideDesktop={hideDesktop} >
            <a href={hrefLink} target='_blank' rel='nofollow noopener noreferrer'>
                <Image src={imageUrl} loading="lazy" alt="Promo" title="Promo" />
            </a>
            <hr style={{ border: "1px solid black", width:"100%" }} />
    </Container>
    )
};

export default SimpleImageBanner;