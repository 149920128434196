import styled from 'styled-components';

export const ImagesAddedGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 1rem 0rem;
    
`;


export const ImageAdded = styled.img`
    width: 100%;
    max-height: 400px;

`;
