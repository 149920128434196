import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

// Extender Day.js con el plugin 'calendar'
dayjs.extend(calendar);

// Configuración para personalizar el formato de calendario
const customCalendarFormat = {
  sameDay: 'h:mm a',   // Para fechas de hoy, mostrar solo la hora
  nextDay: '[Mañana]', // Opción adicional si se desea manejar futuras fechas
  nextWeek: 'dddd',    // Para fechas en la próxima semana
  lastDay: '[Ayer]',   // Para la fecha de ayer
  lastWeek: '[El] dddd', // Para la semana pasada
  sameElse: 'DD/MM/YYYY' // Para todas las demás fechas
};

// Función para formatear la fecha al estilo WhatsApp
export const formatDate = (dateString) => {
  return dayjs(dateString).calendar(null, customCalendarFormat);
};
