import styled from "@emotion/styled";
import { THEME } from "constants/theme/theme";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 992px) {
        align-items: center;
    }
`;

export const SaleProductContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: center; */
    margin-top: 10px;
    gap: 1rem;
`;


export const Price = styled.div`
    margin-top: 1rem;
    color: ${THEME.colors.positive200};
`

export const TextRegularPrice = styled.div`
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-decoration: line-through;
    margin: .5rem .5rem .3rem 1rem;
    color: gray;
`

export const RegularPrice = styled.div`
    color: gray;
    font-size: 1.3rem;
    margin-bottom: 4px;
`

export const SalePrice = styled.div`
    color: ${THEME.colors.positive200};
`

interface IRow {
    display?: string;
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    margin?: string;
    padding?: string;
    width?: string;
    height?: string;
}

export const Row = styled.div`
    display: ${(props: IRow) => props.display || 'flex'};
    flex-direction: ${(props: IRow) => props.flexDirection || 'row'};
    justify-content: ${(props: IRow) => props.justifyContent || 'flex-start'};
    align-items: ${(props: IRow) => props.alignItems || 'flex-start'};
    margin: ${(props: IRow) => props.margin || '0'};
    padding: ${(props: IRow) => props.padding || '0'};
    width: ${(props: IRow) => props.width || 'auto'};
    height: ${(props: IRow) => props.height || 'auto'};
`;

