import { useTranslation } from "react-i18next";
import {
    Container,
    Section,
    Column,
    ContentTopWrapper,
    ContentWrapper,
    ImageWrapper,
    IconWrapper,
    TitleWrapper,
    TextCardWrapper,
    PaySafeWrapper,
    PayTextWrapper,
    PayImageWrapper,
    ButtonCardContainer,
    Button,
    Overlay,
    TextOverlay,
    H4,
} from "./styled";
//import { Button } from "ui/Buttons";

interface ICardEarnMoney {
    image: string;
    title?: string;
    shortDescription?: string;
    text?: string;
    onView?: () => void;
    gradient?: string;  // Nueva propiedad para el gradiente
}

export const TopCardEarnMoney = (props: ICardEarnMoney) => {
    const { image, title, shortDescription } = props;

    return (
        <Column>
            <ContentTopWrapper>
                <ImageWrapper>
                    <img src={image} loading="lazy" alt={title} title={title} />
                    <Overlay>
                        <TextOverlay>
                            <H4>{title}</H4>
                            {shortDescription && <h6>{shortDescription}</h6>}
                        </TextOverlay>
                    </Overlay>
                </ImageWrapper>
            </ContentTopWrapper>
        </Column>
    )
}


   
export const CardEarnMoney: React.FC<ICardEarnMoney> = ({ image, title, shortDescription, onView, gradient}) => {
    const { t } = useTranslation();

    return (
        <Column>
            <ContentWrapper gradient={gradient}>

                {title &&
                    <TitleWrapper>
                        <H4>{title}</H4>
                    </TitleWrapper>
                }

                <IconWrapper>
                    <img src={image} loading="lazy" alt={title} title={title} width="75%" height="auto" />
                </IconWrapper>

                {shortDescription &&
                    <TextCardWrapper>

                        <p>{shortDescription}</p>
                        <ButtonCardContainer>
                            <Button
                                onClick={onView}
                            >{t('view_more')}</Button>
                        </ButtonCardContainer>

                    </TextCardWrapper>
                }
            </ContentWrapper>
        </Column>
    )
}


export const PaySafeEarnMoney = (props: ICardEarnMoney) => {

    const { image, title, shortDescription, text } = props;

    return (
        <Container>
            <Section>
                <PaySafeWrapper>
                    <PayTextWrapper>
                        <H4><strong>{title}</strong></H4>
                        <br />
                        <H4>{shortDescription}</H4>
                    </PayTextWrapper>
                    <PayImageWrapper>
                        <img src={image}
                            loading="lazy"
                            alt={title}
                            title={title} />
                        <H4>{text}</H4>
                    </PayImageWrapper>
                </PaySafeWrapper>
            </Section>
        </Container>
    )
}
