import axios from "axios";

interface PropsUseReverseGeocoding {
    apiKey: string;
}

interface PropsGetReverseGeocoding {
    lat: number;
    lng: number;
}

const useReverseGeocoding = ({ apiKey }: PropsUseReverseGeocoding) => {

    const getReverseGeocoding = async ({ lat, lng }: PropsGetReverseGeocoding) => {
        const PATH_REVERSE_GEOCODING = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
        try {
            const response = await axios.get(PATH_REVERSE_GEOCODING);
            return response.data;
        } catch (error) {
            console.error('Error al realizar reverse geocoding', error);
            return null;
        }
    };

    return { getReverseGeocoding };
};

export default useReverseGeocoding;
