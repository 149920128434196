import axios from "axios"
interface PropsUseGeocoding {
    apiKey: string
}
interface PropsGetGeocoding {
    place: string
}

const useGeocoding = (props: PropsUseGeocoding) => {
    const { apiKey,} = props
    const getGeocoding = async ({place}: PropsGetGeocoding) => {
        const PATH_GEOCODING = `https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${apiKey}`
        const response = await axios.get(PATH_GEOCODING)
        return response.data
    }
    return { getGeocoding }
}

export default useGeocoding
