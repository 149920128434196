import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import MainLayout from "views/layout/MainLayout"; 
import { Container, ContainerBread, Content } from "./styled";
import DetailsProduct from "views/containers/Products/DetailsProduct";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation } from "react-router-dom";
import useAnalytics from "hooks/useAnalytics/useAnalytics";
import Skeleton from "@mui/material/Skeleton";
import {
  ContentProductsContainer, 
  GalleryContainer, 
  General,
  Header,
  ItemContainer,
  Review,
  ReviewHeader,
  ReviewRating,
  Reviews,
  SpaceBetween,
} from "views/containers/Products/DetailsProduct/styled";
import { MdStarRate } from "react-icons/md";
import { getDBroutebyMainCategoryProduct } from "../main";
import { getProductById } from "services/products/get-products-services";
import { ProductsCategoryType } from "types";
import { BREAKPOINTS } from "constants/index";
import { getLanguage } from "redux/common/selector/dist";


function getProductId (uid: string | number) {
  return isNaN(Number(uid)) ? uid : parseInt(uid as string);
}

const ProductDetail = () => {
  const location = useLocation();
  const registerLog = useAnalytics();
  const language = useSelector(getLanguage);

  const [productData, setProductData] = useState<any>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uid = searchParams.get("uid");
    const mainCategory: ProductsCategoryType = searchParams.get( "mainCategory" ) as ProductsCategoryType;
    const collectionPath = getDBroutebyMainCategoryProduct(mainCategory);

    if (uid) {
      getProductById(getProductId(uid), collectionPath).then((res) => {
        setProductData(res);
      }); 
    }
  }, [location]);

  useEffect(() => {
    if (productData !== null) {
      registerLog({
        screenName: "ProductDetail",
        pageLocation: window.location.href,
        pagePath: window.location.pathname,
        pageTitle: `ProductDetail: ${productData.name[language]}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);


  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FFF" href="/" onClick={handleClick}>
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="#FFF"
      href="/products"
      onClick={handleClick}
    >
      Productos
    </Link>,
    <Link underline="hover" key="2" color="#FFF" onClick={handleClick}>
      {productData !== null ? productData?.name[language].substring(0, 60) : ""}
    </Link>,
  ];

  return (
    <MainLayout type="products">
      <Container>
        <ContainerBread
          style={{
            background: "linear-gradient(180deg, #B218FF 0%, #8900CE 100%)",
          }}
        >
          {window.screen.width > BREAKPOINTS.lg && (
            <>
              <Breadcrumbs
                sx={{ margin: "0px 20px" }}
                separator="›"
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </>
          )}
        </ContainerBread>  
        <Content>
          {productData !== null || productData?.length > 0 ? (
            <DetailsProduct data={productData} />
          ) : (
            <Container>
              <Skeleton variant={"text"} />
              <ContentProductsContainer>
                <GalleryContainer>
                  <Skeleton
                    variant={"rectangular"}
                    width={"100%"}
                    height={"100%"}
                  />
                </GalleryContainer>
                <ItemContainer>
                  <Header>
                    <Skeleton variant={"text"} />
                  </Header>
                  <Skeleton variant={"text"} />
                  <SpaceBetween>
                    <Skeleton variant={"text"} width={100} />
                    <Skeleton variant={"text"} width={100} />
                  </SpaceBetween>
                  <hr />
                  <Skeleton variant={"text"} />
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <Skeleton
                      variant={"rectangular"}
                      width={100}
                      height={100}
                    />
                    <Skeleton
                      variant={"rectangular"}
                      width={100}
                      height={100}
                    />
                    <Skeleton
                      variant={"rectangular"}
                      width={100}
                      height={100}
                    />
                    <Skeleton
                      variant={"rectangular"}
                      width={100}
                      height={100}
                    />
                  </div>
                  <hr />
                  <General>
                    <Skeleton variant={"text"} />
                    <Skeleton variant={"text"} />
                  </General>
                </ItemContainer>
              </ContentProductsContainer>
              <hr />
              <Reviews>
                <Skeleton variant={"text"} />
                <Review>
                  <ReviewHeader>
                    <Skeleton variant={"text"} />
                    <Skeleton variant={"text"} />
                  </ReviewHeader>
                  <ReviewRating>
                    <MdStarRate />
                    <MdStarRate />
                    <MdStarRate />
                    <MdStarRate />
                    <MdStarRate />
                    <Skeleton variant={"text"} />
                  </ReviewRating>
                  <Skeleton variant={"text"} />
                  <Skeleton variant={"text"} />
                  <Skeleton variant={"text"} />
                </Review>
              </Reviews>
            </Container>
          )}
        </Content>
      </Container>
    </MainLayout>
  );
};

export default ProductDetail;
