import {Container, Content, Description, Image, ImageContainer, TextContainer} from "./styled";
import { useTranslation } from 'react-i18next';

export const Banner = ()=>{
    const { t } = useTranslation();
    return (<Container background="#EBEAFC" pav="1rem" pah="8rem">
        <Content>
            <TextContainer>
               <Description> {t('business_banner')}</Description>
            </TextContainer>
            <ImageContainer>
                <Image src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Fimages%2Fmujer-sonriente-algo-presentacion-auriculares-removebg-preview.webp?alt=media&token=063b478b-9369-4b9c-a6a0-fc01a44a2145' loading="lazy" alt="Mujer sonriendo" Title="Mujer sonriendo"/>
            </ImageContainer>
        </Content>
    </Container>
    )
}

