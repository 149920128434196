import { useState } from "react";


import Favorite from "ui/Favorites";
import {
    Favorites,
} from "../../styled";
import { Tab, Tabs } from "@mui/material";


interface FavoriteProps {
    favorites: any[];
}

const UserFavorites = (props: FavoriteProps) => {
    const { favorites } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    return (
        <>
            <p>Productos y servicios favoritos</p>
            <Tabs
                style={{ marginBottom: "1rem" }}
                variant="fullWidth"
                value={tabIndex}
                onChange={handleTabChange}
            >
                <Tab label="Productos" />
                <Tab label="Servicios" />
            </Tabs>
            {tabIndex === 0 && (
                <Favorites>
                    {favorites.map((item, index) =>
                        item["type"] === "product" ? (
                            <Favorite key={index} size={"30%"} sizeSm={"46%"} data={item} />
                        ) : (
                            ""
                        )
                    )}
                </Favorites>
            )}
            {tabIndex === 1 && (
                <Favorites>
                    {favorites.map((item, index) =>
                        item["type"] === "service" ? (
                            <Favorite key={index} size={"30%"} sizeSm={"46%"} data={item} />
                        ) : (
                            ""
                        )
                    )}
                </Favorites>
            )}
        </>
    );
};

export default UserFavorites;