import { useCallback } from "react";
import MainLayout from "views/layout/MainLayout";
import {Container, ButtonsContainer} from './styled'
import {H1, P} from 'ui/Text'
import {Button, StrokedButton} from 'ui/Buttons'
import resendEmailService from "services/login/resend-email/resend-email-service";
import { useNotification } from "libs/context/AlertContext";

interface VerifyEmailProps {
  userEmail: string
}

function reloadPage() {
  window.location.reload();
}

const VerifyEmail = ({userEmail}: VerifyEmailProps) => {
  const { showError, showSuccess } = useNotification();


  const resendEmail = useCallback(() => {
    const resultResendEmail: any = resendEmailService(userEmail);
    if (resultResendEmail.success) {
      showSuccess('Correo reenviado')
    } else {
      showError('Error al reenviar el correo')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail])
  

  return (
    <MainLayout>
        <Container>
          <H1>Verifica tu correo</H1>
          <P style={{textAlign: 'center'}} >Hemos enviado un correo a <strong>{userEmail}</strong> para verificar tu cuenta. por favor revisa tu bandeja de entrada y sigue las instrucciones.</P>
          <ButtonsContainer>
            <Button onClick={reloadPage}>Ya he confirmado mi correo</Button>
            <StrokedButton onClick={resendEmail}
            >Reenviar correo</StrokedButton>
          </ButtonsContainer>
      </Container>
    </MainLayout>
  )
}

export default VerifyEmail;