import { FC } from 'react';
import Modal from 'ui/Modal';
import UserBasicDataForm from 'components/Forms/user-basic-data/user-basic-data-form';
import { UserBasicDataFormProps } from 'types';

interface ModalUserBasicDataFormProps {
    onSubmit: (values: UserBasicDataFormProps) => void;
    isOpen: boolean;
    onClose: () => void;
}

const ModalUserBasicDataForm: FC<ModalUserBasicDataFormProps> = (props: ModalUserBasicDataFormProps) => {
    const { onSubmit, isOpen, onClose } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <UserBasicDataForm  onSubmit={onSubmit} />
        </Modal>
    )
}

export default ModalUserBasicDataForm

