import { Button } from 'ui/Buttons';
import { LOGISTIC_PANAMA_PHONE_NUMBER } from "constants/constants";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
import { getLanguage } from "redux/common";
import {BannerText} from './styled';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Figure,
  Image,
  ContentDesktop,
  ContentMobile,
} from "./styled";
import { Multilingual } from 'types'


interface BannerBlogProps {
    imageMobile: Multilingual;
    imageDesktop: Multilingual;
    bannerText: string;
    message: string;
    size?: 'big';
}

const BannerBlog = (props: BannerBlogProps) => {
  const { imageMobile, imageDesktop, bannerText, size, message } = props;

  const language = useSelector(getLanguage);
  const { t } = useTranslation();

  
  return (
    <Container>
      <ContentDesktop>
        <Link to="https://www.quenecesitas7.com/products/wholesale">
          <Image 
            src={imageDesktop[language]}
            loading="lazy"
            alt={bannerText}
            title={bannerText}
          />
        </Link>
        <BannerText>{bannerText}</BannerText>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "0 auto 20px" }}>
          <Button onClick={() => window.open(`https://wa.me/${LOGISTIC_PANAMA_PHONE_NUMBER}?text=${encodeURIComponent(`${message}`)}`)}>
            {t("contact_here")}
          </Button>
        </div>
      </ContentDesktop>
      <ContentMobile>
        <Link to="https://www.quenecesitas7.com/products/wholesale">
          <Figure>
            <Image
              style={{ width: "100%" }}
              src={imageMobile[language]}
              loading="lazy"
              size={size}
              alt={bannerText}
              title={bannerText}
            />
          </Figure>
        </Link>
        <BannerText>{bannerText}</BannerText>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "0 auto 20px" }}>
          <Button onClick={() => window.open(`https://wa.me/${LOGISTIC_PANAMA_PHONE_NUMBER}?text=${encodeURIComponent(`${message}`)}`)}>
            {t("contact_here")}
          </Button>
        </div>
        <hr style={{ position: "relative", left: "-5%", border: "1px solid black", width:"150%" }} />
      </ContentMobile>
    </Container>
  );
};

export default BannerBlog;
