import * as Yup from 'yup';

export const productsValidationSchema = Yup.object({
    title: Yup.string().required('Debes agregar un título a tu necesidad'),
    link: Yup.string().url('Debe ser una URL válida'),
    urgencyLevel: Yup.string().required('Debes elegir qué tan urgente es tu necesidad'),
    extraComments: Yup.string().min(20, 'La descripción de la necesida debe tener almenos 20 letras').required('Debe agregar una breve descripción de su necesidad'),
    needReference: Yup.string().required('Debe elegir una opción')
    // Add any other validations you need
  });
