import { useEffect, useState } from "react";
import MainLayout from "views/layout/MainLayout";
import { Container, ContainerBread, Content } from "./styled";
import DetailsClassified from "views/containers/Classifieds/detail-classified";
import { useGetFirestoreDoc } from "hooks/useGetDocumentFirestore/useGetDocumentFirestore";
import Skeleton from "@mui/material/Skeleton";
import {
  ContentProductsContainer,
  DescriptionGrid,
  DescriptionItem,
  GalleryContainer,
  General,
  Header,
  ItemContainer,
  Options,
  SpaceBetween,
} from "views/containers/Classifieds/detail-classified/styled";

const PATH = "PANAMA/Classifieds/Posts";

const ClassifiedDetail = (props) => {
  const { fetchDoc, docData } = useGetFirestoreDoc();

  const [idClassified, setIdClassified] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    setIdClassified(id);
  }, []);

  useEffect(() => {
    if (idClassified) {
      fetchDoc({
        path: PATH,
        uid: idClassified,
      });
    }

    // eslint-disable-next-line
  }, [idClassified, PATH]);

  useEffect(() => {
    if (docData) {
      window.scrollTo(0, 0);
    }
  }, [docData]);

  return (
    <MainLayout type="classifieds">
      <Container>
        <ContainerBread
          style={{
            background: "linear-gradient(180deg, #08A791 0%, #016D52 100%)",
          }}
        >
        </ContainerBread>
        <Content>
          {docData ? (
            <DetailsClassified item={docData as any} />
          ) : (
            <div>
              <Skeleton variant={"text"} />
              <ContentProductsContainer>
                <GalleryContainer>
                  <Skeleton
                    variant={"rectangular"}
                    width={"100%"}
                    height={"100%"}
                  />
                </GalleryContainer>
                <ItemContainer>
                  <Header>
                    <SpaceBetween>
                      <Skeleton variant={"text"} width={"70%"} />
                      <Skeleton
                        variant={"rectangular"}
                        width={100}
                        height={25}
                      />
                    </SpaceBetween>
                  </Header>
                  {/*<FullName>{item.description}</FullName>*/}
                  <hr />
                  <Options>
                    <Skeleton variant={"text"} />
                    <DescriptionGrid>
                      <DescriptionItem>
                        <Skeleton variant={"text"} />
                        <Skeleton variant={"text"} />
                      </DescriptionItem>
                      <DescriptionItem>
                        <Skeleton variant={"text"} />
                        <Skeleton variant={"text"} />
                      </DescriptionItem>
                      <DescriptionItem>
                        <Skeleton variant={"text"} />
                        <Skeleton variant={"text"} />
                      </DescriptionItem>

                      <DescriptionItem>
                        <Skeleton variant={"text"} />
                        <Skeleton variant={"text"} />
                      </DescriptionItem>

                      <DescriptionItem>
                        <Skeleton variant={"text"} />
                        <Skeleton variant={"text"} />
                      </DescriptionItem>
                      <DescriptionItem>
                        <Skeleton variant={"text"} />
                        <Skeleton variant={"text"} />
                      </DescriptionItem>
                    </DescriptionGrid>
                  </Options>
                  <hr />
                  <General>
                    <Skeleton variant={"text"} />
                    <Skeleton variant={"text"} />
                  </General>
                </ItemContainer>
              </ContentProductsContainer>
            </div>
          )}
        </Content>
      </Container>
    </MainLayout>
  );
};

export default ClassifiedDetail;
