import styled from "styled-components";

export const CardUser = styled.div`
  text-align: left;
  margin-bottom: 1rem;
  color: #0C121D;
`

export const LoginButton = styled.div`
  width: 100%;
  height: 52px;
  text-align: center;
  padding: 1rem 0;
  background: #3A36DB;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 1rem;

  &:hover {
    background: #3F6CD6;
  }



  `

export const FirstInfo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`

export const InfoImg = styled.img`
  border-radius: 50%;
  width: 70px;
  height: 70px; 
`
export const ProfileInfo = styled.div`
  margin-left: .5rem;
`

export const ImageProfile = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0px;
`
export const InfoDesc = styled.div`
  font-size: 1rem;
  font-weight: 500;
  &:first-child {
    font-weight: 700;
  }
`
export const FullImg = styled.img`
  width: 100%;
`
export const SimpleList = styled.div`
  text-align: left;
`
export const ListU = styled.ul`
  list-style: none;
  padding: 0;
  font-weight: 500;
  font-size: 1rem;
`
export const ListItem = styled.li`
  padding: .4rem 0;
`
export const ListLink = styled.a`
  color: #0C121D;
  text-decoration: none;
`

export const Icon = styled.span`
  margin-left: .5rem;
`

export const Socials = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  color: #3F6CD6;
  margin-bottom: 25px;
`
export const Logout = styled.div`
  display: flex!important;
  flex-direction: row;
  width: 100%;
  text-align: left;
  color: #0C121D;
  padding-bottom: 1rem;
  justify-content: space-between;
`

export const SpanMiddle = styled.a`
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 700;
  color:#102746;
`
export const MenuItem = styled.a`
  display: inline-block;
  color: #102746;
  margin-bottom: .2rem;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  padding: 1rem 1rem;
  font-weight: 700;
  font-size: 1rem;

  &:hover {
    color: #3A36DB;
    background: #F5F5F6;
  }

  &:hover > img {
    filter: invert(22%) sepia(83%) saturate(7214%) hue-rotate(245deg) brightness(88%) contrast(94%);
  }
`



export const ImageIcon = styled.img`
    width: 20px;
    height: 20px;
`

export const OffersIcon = styled.img`
    width: 20px;
    height: 20px;
`

export const ServicesIcon = styled.img`
    width: 20px;
    height: 20px;
`

export const ProductsIcon = styled.img`
    width: 20px;
    height: 20px;
`
