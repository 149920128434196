import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 90%;
  margin: 0 auto;
  flex-basis: calc(33.333% - 1rem);
  margin-bottom: 0.2rem;
  @media only screen and (max-width: 992px) {
    flex-basis: calc(49%);
  }

  @media only screen and (max-width: 440px) {
    flex-basis: calc(100%);
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
 // justify-content: start;
  background: #FFFFFF;
  text-align: left;
  height: 100%;
  margin-right: .2rem;
  padding: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 45%; /* Relación de aspecto 4:3 (3 / 4 * 100) */

  @media only screen and (max-width: 1240px) {
    padding-top: 66.67%; /* Relación de aspecto 4:3 (3 / 4 * 100) */
  }

  @media only screen and (max-width: 680px) {
    padding-top: 75%; /* Relación de aspecto 4:3 (3 / 4 * 100) */
  }
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;

  @media only screen and (max-width: 680px) {
    min-height: 120px;
  }
`

export const DescriptionContainer = styled.div`
  padding: 0.5rem;
  margin-bottom: 1rem;
  ${props => props.theme.typography.body.sm.regular};
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Alinear título y descripción */

  @media only screen and (max-width: 680px) {
    height: 70%;
    margin-bottom: 0;
  }
  
  @media only screen and (max-width: 600px) {
    font-size: 0.725rem;
    ${props => props.theme.typography.label.xs.regular};
  }
`
export const Title = styled.p`
  color: #0C121D;
  font-weight: 600;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 24px;

  @media only screen and (max-width: 768px) {
    min-height: 16px;
    margin-bottom: 0.2rem;
  }
`

export const Description = styled.p`
  color: #0C121D;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

export const TransButton = styled.button`
  display: block;
  width: 100%;
  margin: .5rem auto;
  background: #3A36DB;
  color: #F5F5F6;
  padding: .4rem 1rem;
  border-radius: 2rem;
  border:0;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 500px){
    ${props => props.theme.typography.body.sm.bold};
  }
`

export const SpanMiddle = styled.span`
  font-size: 0.9rem;
  vertical-align: middle;
`
