import { useTranslation } from "react-i18next";
import { Container, Cuad, Titles } from "./styled";
import YachtCard from "components/cards/Commons/YatchCard";

interface IListYachts {
  yachts: any[],
  hideTitle?: boolean
}

const ListYachts = (props: IListYachts) => {
  const { yachts, hideTitle } = props;
  const { t } = useTranslation();

  return (
    <Container fondo="#ffffff" pav="1rem" pah="2rem">
      <div
        className={
          "d-flex justify-content-between align-items-center w-100 mb-2"
        }
      >
      </div>
      {
        !hideTitle && (
          <Titles size={"1.5rem"}>
            {t('get_to_know_our_fleet_of_yachts_available_for_you')}
          </Titles>
        )
      }
      <Cuad>
        {yachts.map((item, index) => {
          return <YachtCard key={index} data={item} isExtraCard={false} />;
        })}
      </Cuad>
    </Container>
  );
};

export default ListYachts
