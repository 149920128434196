import Modal from '../Modal'
import Loader from '../Loader';

interface IProps {
    isLoading: boolean;
    text?: string;
}

const ModalLoader = (props: IProps) => {
    const { isLoading, text } = props;
    return (
        <Modal hideCloseIcon isOpen={isLoading} onClose={() => { }} styleContent={{background: 'transparent', boxShadow: 'none'}}>
            <Loader text={text} />
        </Modal>
    )
}

export default ModalLoader;
