import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { collection, getDocs } from "firebase/firestore/lite";
import { Link as LinkWrapper } from "react-router-dom";
import {
  TextBold,
  Service,
  ServiceDesc,
  ServiceImg,
  BlueContainer,
  ViewLink,
  Container,
  Title,
  LittleText,
} from "./styled";
import { firestore } from "config/firebase";
import { FlexColumn, Titles } from "../Offers/promoted-offers/styled";
import { getCountry, getLanguage } from "redux/common";
import SimpleImageBanner from "ui/simple-image-banner";

import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Skeleton from "@mui/material/Skeleton";
import { SERVICES_PATHS } from "./constants";
import CustomCarouselButton from "components/custom-carousel-button";

const ItemsSkeleton = [1, 2, 3, 4];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 2,
  },
};

interface Props {
  style?: React.CSSProperties;
}

const TopServices = (props: Props) => {
  const { style } = props;
  const { t } = useTranslation();
  const currentCountry = useSelector(getCountry);
  const currentLanguage = useSelector(getLanguage);

  const [services, setServices] = useState([]);

  useEffect(() => {
    if (services.length === 0) {
      const querySnapshot = getDocs(
        collection(firestore, `${currentCountry}/Services/PostsFeatureds`)
      );
      querySnapshot.then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id) {
            // @ts-ignore
            setServices((items) => [...items, { ...doc.data(), id: doc.id }]);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={style}>
      <FlexColumn background="transparent">
        <div className={"mb-2 mt-2"}>
          <Titles size={"1.5rem"}>{t("featured_services")}</Titles>
        </div>
        <Carousel
          responsive={responsive}
          customLeftArrow={<CustomCarouselButton direction="left" />}
          customRightArrow={<CustomCarouselButton direction="right" />}
          arrows={true}
        >
          {services.length > 0
            ? services.map((service: any, index) => {
                if (index < 20) {
                  return (
                    <Service key={index}>
                      <BlueContainer>
                        <ServiceImg
                          src={service.featuredImage}
                          loading="lazy"
                          alt={service.name[currentLanguage]}
                          title={service.name[currentLanguage]}
                        />
                        <Title>{service.name[currentLanguage]}</Title>
                      </BlueContainer>
                      <ServiceDesc>
                        <div style={{ height: "30%" }}>
                          <LittleText>
                            {service.subTitle[currentLanguage]}
                          </LittleText>
                        </div>
                        <div style={{ height: "40%" }}>
                          <TextBold>
                            {service.subTitleDescription[currentLanguage]}
                          </TextBold>
                        </div>
                        <div style={{ height: "10%" }}>
                          <LinkWrapper
                            to={`/services/${SERVICES_PATHS[service.category]}`}
                            state={{
                              path: `${currentCountry}/Services/PostsFeatureds`,
                            }}
                          >
                            <ViewLink>{t("view_more")}</ViewLink>
                          </LinkWrapper>
                        </div>
                      </ServiceDesc>
                    </Service>
                  );
                }

                return null;
              })
            : ItemsSkeleton.map((item) => {
                return (
                  <div key={item}>
                    <Service>
                      <BlueContainer>
                        <Skeleton
                          variant={"rounded"}
                          height={100}
                          width={"50%"}
                          style={{
                            margin: "0 auto",
                            transform: "translateY(-2rem)",
                          }}
                        />
                        <Skeleton variant={"text"} />
                      </BlueContainer>
                      <ServiceDesc>
                        <Skeleton variant={"text"} />
                        <Skeleton variant={"text"} />
                        <Skeleton
                          variant={"rounded"}
                          height={50}
                          width={"80%"}
                          style={{ margin: "0 auto" }}
                        />
                      </ServiceDesc>
                    </Service>
                  </div>
                );
              })}
        </Carousel>
      </FlexColumn>
      <div style={{ position: "relative", left: "-5%", width: "105%" }}>
        <SimpleImageBanner
          hrefLink="/services/tourism"
          hideDesktop
          imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FVisita%20las%20mejores%20playas%20y%20sitios%20turisticos%20en%20panama%2C%20Volcan%20varu%2C%20archipielago%20de%20las%20perlas%2C%20Coiba%20island%202.webp?alt=media&token=affd019d-731e-48f3-abbf-b370f4fa3aea"
        />
      </div>
      <div style={{ position: "relative", left: "-5%", width: "110%" }}>
        <SimpleImageBanner
          hrefLink="/services/tourism"
          hideMobile
          imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FVisita%20las%20mejores%20playas%20y%20sitios%20turisticos%20en%20panama%2C%20Volcan%20varu%2C%20archipielago%20de%20las%20perlas%2C%20Coiba%20island%202.webp?alt=media&token=affd019d-731e-48f3-abbf-b370f4fa3aea"
        />
      </div>
    </Container>
  );
};

export default TopServices;
