import styled from 'styled-components';
import { THEME } from 'constants/theme/theme';


interface IContainer {
    isSelected: boolean;
}

export const Container = styled.div<IContainer>`
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ isSelected }) => isSelected ? THEME.colors.primary : THEME.colors.gray80};
    border-radius: 6px;
    cursor: pointer;
    padding: 10px;
    gap: .5rem;

    &:hover {
        border: 2px solid ${THEME.colors.primary};
    }


    p {
        margin: 0;

        @media (max-width: 576px) {
            font-size: 12px;
        }
    }
`;

export const ImageIcon = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 6px 6px 0 0;

    @media (max-width: 576px) {
        width: 35px;
        height: 35px;
    }
`;