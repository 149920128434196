export function completeChars(desc, end) {
    let len = desc.length;
    if (len < end) {
        let rest = end - len;
        let newStr = "";
        for (let i = 0; i < rest-5; i++) {
            newStr += '\u00A0 ';
        }
        return desc + newStr;
    } else {
        return desc + "...";
    }
}