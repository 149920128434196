import { useState, useEffect } from "react";
import { Address } from "types/common";
import { getUserAddresses } from "services/user/updateInfo/service-update-user-info";

const useGetSingleUserAddress = (userUid: string, addressId: string): Address | null => {
    const [address, setAddress] = useState<Address | null>(null);
    
    useEffect(() => {
        let unsubscribe: (() => void) | null = null;
    
        try {
        const startListening = async () => {
            unsubscribe = await getUserAddresses(userUid, (updatedAddresses) => {
            const address = updatedAddresses.find((address) => address.id === addressId);
            setAddress(address || null);
            });
        };
        startListening();
        } catch (error) {
        console.error("Error getting the addresses", error);
        }
    
        return () => {
        if (unsubscribe) {
            unsubscribe();
        }
        };
    }, [userUid, addressId]);
    
    return address;
    }

export default useGetSingleUserAddress;