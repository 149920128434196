import { httpsCallable } from "firebase/functions";
import {ServiceResponse, CreateOrderProps} from 'types'
import {functions} from 'config/firebase'

const createOrder = async (props: CreateOrderProps): Promise<ServiceResponse> => {
    const createOrder = httpsCallable(functions, 'createOrder') 
    try {
        const response = await createOrder(props)
        return response.data as ServiceResponse
    }
    catch (error: any) {
        return {
            status: error?.status || 'ERROR',
            message: error?.message || 'Error al crear el usuario',
            code: error?.code || 500
        }
    }
}

export default createOrder