import styled from 'styled-components';

export const BugPopupContainer = styled.div`
    border-radius: 50%;
    padding: .25rem .4rem;
    background: #fff; 
    z-index: 100000000000000;

`;

export const ContentPopup = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
`;