import {createSelector} from '@reduxjs/toolkit'

// TODO: import Store type and add reducer type in Store interface
const getCommonState = (state: any) => state.common


export const getLanguage = createSelector(
  getCommonState,
  (state) => state.language
)

export const getCountry = createSelector(
  getCommonState,
  (state) => state.country
)


export const getCurrency = createSelector(
  getCommonState,
  (state) => state.currency
)
