import { Container } from "./styled";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {THEME} from 'constants/theme/theme'

interface Props {
    onClick?: () => void;
}

export default function FloatingActionButtons({onClick}: Props) {
    

    return (
        <Container>
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Fab 
                style={{
                    backgroundColor: THEME.colors.classifieds,
                    color: THEME.colors.white,
                }} 
                aria-label="add"
                onClick={onClick ? onClick : () => {}}
                >
                    <AddIcon />
                </Fab>
            </Box>
        </Container>
    );
}