import { httpsCallable } from "firebase/functions";
import {ServiceResponse} from 'types'
import {functions} from 'config/firebase'
import { CreateNeedRealStateLandingType } from "types";

const CreateLandingNeedService = async (props: CreateNeedRealStateLandingType): Promise<ServiceResponse> => {
    const createNewLandingNeedRequestService = httpsCallable(functions, 'createServiceLandingNeed')

    try {
        const response = await createNewLandingNeedRequestService(props)
        return {
            status: 'OK',
            data: response.data
        }
    }
    catch (error: any) {
        console.error('error creating new landing need',error)
        alert('error creando la necesidad')
        return {
            status: error?.status || 'ERROR',
            message: error?.message || 'Error creating new need',
            code: error?.code || 500
        }
    }
}

export default CreateLandingNeedService
