
import MainLayout from "views/layout/MainLayout/main-layout";
import ChatApp from "views/containers/Chatbot/chatbot";


const Chatbot = () => {
    return (
        <MainLayout>
            <ChatApp />
        </MainLayout>
    );
};

export default Chatbot;