import * as Yup from 'yup';


export const validationSchema = Yup.object({
    peopleCount: Yup.number().required('Debes especificar la cantidad de personas.'),
    destination: Yup.string().required('Debes elegir un destino.'),
    date: Yup.string().required('Debes elegir una fecha para tu aventura en yate.'),
    fullName: Yup.string().required('El nombre es importatante para poder contactarte.'),
    phone: Yup.string().min(8, 'Debe ser un numero valido de almenos 8 caracteres').required('El teléfono es importante para poder contactarte.'),
    additionalComments: Yup.string(),
    
});
