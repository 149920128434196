import styled from "styled-components";
import { THEME } from "constants/theme/theme";


export const Container = styled.div`
    width: 100%;
`;

export const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
`;

interface ICardItem {
    selected: boolean;
    disabled?: boolean;
}

export const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 4px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: ${(props: ICardItem) =>  props.disabled? `2px solid ${THEME.colors.black40}` :  props.selected ? `2px solid ${THEME.colors.primary}` : '2px solid #E5E5E5'};

    @media only screen and (max-width: 768px) {
        padding: 10px 6px;
        font-size: 14px;
        text-align: center;
    }

    @media only screen and (max-width: 400px) {
        padding: 10px 4px;
        font-size: 12px;
    }
`;