export const ROLES = {
    CUSTOMER: 4,
    ADMIN: 2,
    SUPER_ADMIN: 1,
    MODERATOR: 3,
    GUEST: 5,
    EDITOR: 6,
    MANAGER: 7,
    SUPPORT: 8,
    AUDITOR: 9,
    DEVELOPER: 10,
};