import { FC } from "react";
import {useNavigate} from 'react-router-dom';
import {Card, Container, PropertyItem, Row, Image, ImageContainer, SpanMiddle, Title, TransButton, PropertiesContainer, Price, OperationTypeContainer, ShortDescription, RowProperties} from './styled'
import {MdLocationPin} from "react-icons/md";
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { useTranslation } from "react-i18next";


interface RealStateCardProps {
    title: string;
    images: string[];
    link: string;
    shortDescription: string;
    description?: string;
    price?: string | number;
    location?: string;
    operationType: string;
    numberOfRooms?: number;
    numberOfBathrooms?: number;
    numberOfParkings?: number;
    squareMeters?: number;
}


const PropertiesCard: FC<RealStateCardProps> = (props: RealStateCardProps) => {
    const {title, images, link, shortDescription, price, operationType, location, numberOfRooms, numberOfBathrooms, numberOfParkings, squareMeters} = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Container>
            <Card
                onClick={() => {
                    if (link) navigate(link);
                }}
            >
                    <ImageContainer>
                        <Image src={images?.[0] || ''} loading="lazy" alt={title || ''} title={title || ''} />
                    </ImageContainer>
                    <Row style={{marginTop: '6px'}}>
                        <OperationTypeContainer>
                            <p style={{margin: 0}}>{operationType}</p>
                        </OperationTypeContainer>
                        <PropertyItem>
                            <MdLocationPin />
                            <SpanMiddle>{location}</SpanMiddle>
                        </PropertyItem>  
                    </Row>
                    <Title>{title || ''}</Title>
                    <RowProperties>
                        <Price>{price ? `$${price}` : ''}</Price><Row>
                        <PropertiesContainer>
                            {
                                squareMeters && (
                                    <PropertyItem>
                                        <SquareFootIcon/>
                                        <SpanMiddle>{squareMeters}</SpanMiddle>
                                    </PropertyItem>
                                )
                            }
                            {
                                numberOfRooms && (
                                    <PropertyItem>
                                        <BedIcon />
                                        <SpanMiddle>{numberOfRooms}</SpanMiddle>
                                    </PropertyItem>
                                )
                            }
                            {
                                numberOfBathrooms && (
                                    <PropertyItem>
                                        <BathtubIcon />
                                        <SpanMiddle>{numberOfBathrooms}</SpanMiddle>
                                    </PropertyItem>
                                )
                            }
                            {
                                numberOfParkings && (
                                    <PropertyItem>
                                        <LocalParkingIcon />
                                        <SpanMiddle>{numberOfParkings}</SpanMiddle>
                                    </PropertyItem>
                                )
                            }
                        </PropertiesContainer>
                    </Row>    
                </RowProperties>
                <ShortDescription>{shortDescription}</ShortDescription>
                <div className={"mt-4 w-100"}><TransButton style={{width: "90%", margin: "0 auto"}} background={"#3A36DB"}><SpanMiddle>{t('view_property')}</SpanMiddle></TransButton></div>
            </Card>
        </Container>
    )
}

export default PropertiesCard