import styled from 'styled-components'

export const Container = styled.div`
  clear: both;
  background: ${props => props.fondo || "#ffffff"};
  justify-content: space-between;
  padding: ${props => props.pav} ${props => props.pah};
  overflow: hidden;
  margin-top: .5rem;
  @media only screen and (max-width: 992px) {
    padding: 1rem;
  }
  @media only screen and (max-width: 768px){
    padding: 0 0 0.5rem 0;
  }
`

export const Cuad = styled.div`
  display: grid;
  margin: 0 2rem;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  @media only screen and (max-width: 992px){
    margin: 0 .5rem;
  }
  @media only screen and (max-width: 768px){
    grid-template-columns: repeat(1, 1fr);
    margin: .5rem auto;
    gap: .5rem;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 992px){
    padding: 0 1rem;
  }
`


export const Title = styled.p`
  font-family: ${props => props.family};
  color: ${props => props.color};
  font-weight: ${props => props.weight};
  font-size: 2.2rem;
  line-height: 2.8rem;
  margin-top: 16px;
  @media only screen and (max-width: 1080px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 2.0rem;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
`


export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
  height: 100%;
  justify-content: flex-start;
  @media only screen and (max-width: 620px) {
    width: 100%;
    justify-content: center;
  }
`

export const Description = styled.p`
  font-size: 1.2rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
    font-family: Montserrat, sans-serif;
  }
  @media only screen and (max-width: 600px) {
    font-size: .9rem;
    line-height: 1.2rem;
  }
`
export const ImageContainer = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;


  @media only screen and (max-width: 992px) {
    width: 40%;
    font-size: .6rem;
    float: right;
    margin-bottom: -36px;
  }

  @media only screen and (max-width: 768px) {
    width: 60%;
  }

  @media only screen and (max-width: 560px) {
    width: 60%;
  }
`

export const Image = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  float: right;

  @media only screen and (max-width: 992px) {
    max-height: 200px;
  }
`


export const Link = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 600px) {

  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: ${props => props.pav || "1rem"} ${props => props.pah || "1rem"};
  justify-content: space-between;
  align-items: ${props => props.align || "stretch"};
  width: 100%;
  background: ${props => props.fondo || "#ffffff"};
  text-align: center;
  @media only screen and (max-width: 992px) {
    border-radius: 0;
    padding: 1.5rem 1rem 0 1rem;
  }
  @media only screen and (max-width: 600px) {
    /* padding: 2rem 1rem; */

  }
`


export const Titles = styled.h2`
  text-align: ${props => props.align || "left"};
  font-family: Outfit, monospace;
  font-weight: ${props => props.weight || "700"};
  font-size: ${props => props.size || "2.5rem"};
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.subheading.sm.bold};
    text-align: ${props => props.alignM || "left"};
  }
`

export const SponsorBuy = styled.div`
  ${props => props.theme.typography.body.md.bold};
  display: flex;
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
`
export const Video = styled.video`
  width: 100%;
  height: 15rem;
  object-fit: cover;
`


export const SmallSection = styled.div`
  clear: both;
  background: #ffffff;
  margin: 0 4rem;
  justify-content: space-between;
  padding: ${props => props.pav} ${props => props.pah};
  @media only screen and (max-width: 992px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

export const BSpan = styled.span`
  color: #08A791;
`

export const Browser = styled.div`
  background: #F4F5F7;
  border-radius: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 1rem auto;
  justify-content: space-between;
  @media only screen and (max-width: 992px) {
    width: 95%;
  }
`

export const BrowserBody = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    width: 35%;
    padding: 1rem;
  }
`
export const BrowserImg = styled.img`
  width: 15%;
  height: 100%;
  object-fit: contain;
  float: right;
  border-radius: 2rem;
  @media only screen and (max-width: 992px) {
    width: 40%;
  }
`
export const BText = styled.h2`
  ${props => props.theme.typography.heading.md};
  @media only screen and (max-width: 1200px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }

  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.heading.md};
  }
  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }

  @media only screen and (max-width: 600px) {

  }
`

export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.fondo || "#ffffff"};
  color: ${props => props.fondo === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 3rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.sm.bold};
  }
`

export const FlexRow = styled.div`
  display: flex;
  gap: 1rem;
  background: #ffffff;
  margin-bottom: ${props => props.mbottom || 0};
  border-radius: 1rem 0 0 1rem;
  width: ${props => props.size || "80%"};;
  height: ${props => props.sizeV || "100%"};;
  justify-content: center;
  padding: 1rem;
  float: right;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap:0;
    float: none;
    width: 100%;
    padding: .5rem 0;
  }
`


export const Options = styled.div`
  display: flex;
  color: #ffffff;
  
  background: #ffffff;
  border-radius: 1rem;
  height: ${props => props.sizeV || "100%"};
  width: ${props => props.size || "80%"};
  margin-bottom: 0;
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
  }
`
export const Option = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  ${props => props.theme.typography.body.lg.medium};
  padding: 2rem 1rem;
  justify-content: ${props => props.justify || "space-between"};
  align-items: ${props => props.align || "stretch"};
  transform: translateX(-1rem);
  margin-right: -1rem;
  width: ${props => props.size || "152%"};
  background: #08A791;
  text-align: left;
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.md.regular};
  }
`
export const ProductImg = styled.img`
  width: ${props => props.size || "100%"};
  padding: ${props => props.pad || "0"};
  border-radius: .5rem;
  object-fit: cover;
`
