import { ServiceDetail } from "./entities";

export interface ServiceDetailData {
  serviceDetailData: ServiceDetail;
}

export const initialState: ServiceDetailData = {
  serviceDetailData: {
    category: "",
    categoryName: {
      en: "",
      es: "",
    },
    description: {
      en: "",
      es: "",
    },
    featuredImage: "",
    id: "",
    images: [],
    name: {
      en: "",
      es: "",
    },
    benefits: {
      en: "",
      es: "",
    },
    price: 0,
    extraInfo: [],
    shortDescription: {
      en: "",
      es: "",
    },
    type: "",
    uid: "",
  },
};
