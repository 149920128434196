import styled from 'styled-components'

export const Container = styled.div`
  clear: both;
  background: #F9FFF6;
  justify-content: space-between;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 768px){
    padding: 0;
  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  justify-content: space-between;
  align-items: ${props => props.align || "stretch"};
  width: 100%;
  background: #E7EDF5;
  text-align: center;
  @media only screen and (max-width: 992px) {
    border-radius: 0;
    padding: 1.5rem .5rem 0.5rem;
  }
`

export const Link = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 600px) {

  }
`

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    text-align: center;
    padding: 0 2rem 1rem 2rem;
  }
`

export const Title = styled.h2`
  text-align: center;
  font-family: Outfit, monospace;
  font-weight: ${props => props.weight || "700"};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.subheading.sm.bold};
    text-align: ${props => props.alignM};
    font-size: 1.25rem;
  }
`
