import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import ModalLoader from "ui/ModalLoader";
import { BackButton } from "ui/Buttons";
import { Container } from "./styled";
// Forms
import RealStateClassifiedForm from "views/containers/Forms/Classifieds/new-classified/real-state-form-classified/real-state-classified-form";
import VehicleClassifiedForm from "views/containers/Forms/Classifieds/new-classified/vehicle-form-classified";
import EmploymentClassifiedForm from "views/containers/Forms/Classifieds/new-classified/employment-form-classified";
import TechFormClassified from "views/containers/Forms/Classifieds/new-classified/tech-form-classified";
import CommonFormClassified from "views/containers/Forms/Classifieds/new-classified/common-form-classified";
import useUpdateClassified from "hooks/classifieds/useUpdateClassified/use-update-classified";
import { useNotification } from "libs/context/AlertContext";
import "./index.css";
import { IRealStateClassified, IVehicleClassified, ITechClassified, IEmploymentClassified, ICommonClassified } from "types";
import { ClassifiedCategories } from "constants/classifieds";
import { userSelector } from "redux/user";
import { getLanguage, getCountry } from "redux/common";
import { formatValuesRealState, formatValuesVehicles } from "../NewClassified/main";
import { uploadImagesToFirestore, } from 'hooks/useUploadImagesToFirestorage/useUploadImagesToFirestorage'; //deleteMultipleImagesFromFirestorageInSinglePath
// import { useTranslation } from "react-i18next";

interface ITypedForm {
  category: string;
  onSubmit?: (values: IRealStateClassified | IVehicleClassified | ITechClassified | IEmploymentClassified | ICommonClassified ) => void;
  userUid: string;
  createClassifiedSuccess: boolean;
  categoryName: string;
  formProperties?: any;
  language: string;
  initialValues?: any; // Add initial values for editing
  isEditMode?: boolean; // Add isEditMode to determine if we are editing
}

const TypeFormSelector = (props: ITypedForm) => {
  const { category, onSubmit, userUid, createClassifiedSuccess, categoryName, formProperties, language, initialValues, isEditMode } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category]);

  const commonProps = {
    categoryType: category,
    language,
    createClassifiedSuccess,
    userUid,
    category: categoryName,
    onSubmit: (values: any) => { onSubmit && onSubmit(values); },
    initialValues, // Pass initial values
    isEditMode, // Pass isEditMode
    images: initialValues?.images // Pass images if they exist
  };

  switch (category) {
    case ClassifiedCategories.RealState:
      return <RealStateClassifiedForm {...commonProps} />;
    case ClassifiedCategories.Vehicles:
      return <VehicleClassifiedForm {...commonProps} formProperties={formProperties} />;
    case ClassifiedCategories.Employments:
      return <EmploymentClassifiedForm {...commonProps} formProperties={formProperties} />;
    case ClassifiedCategories.Tech:
      return <TechFormClassified {...commonProps} formProperties={formProperties} />;
    case ClassifiedCategories.Home:
    case ClassifiedCategories.Construction:
    case ClassifiedCategories.Music:
    case ClassifiedCategories.Pets:
    case ClassifiedCategories.Sports:
      return <CommonFormClassified {...commonProps} formProperties={formProperties} />;
    default:
      return <></>;
  }
}

const EditClassifieds = (props: any) => {
  const { initialValues, category, formProperties, classifiedData } = props; 
  //console.log('FClassified data in cont', classifiedData);
  const navigate = useNavigate(); // Usar el hook useNavigate
  const { showError, showSuccess } = useNotification();
  const user = useSelector(userSelector)?.userData;

  const language = useSelector(getLanguage);
  const country = useSelector(getCountry);
  const { editClassified:updateClassified, isUpdating: isLoadingUpdateError, dataClassifiedEdited: updateResponse, updateError } = useUpdateClassified();
  const [successUpdateClassified, setSuccessUpdateClassified] = useState(false);

  useEffect(() => {
    let img = document.getElementsByClassName("uploadIcon")[0];
    if (img !== undefined) {
      img["src"] =
        "https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/assets%2Ficons%2Fadd-image.png?alt=media&token=9a56282b-da2e-4e9b-ac27-89f8a5523c05";
    }
  }, []);

  useEffect(() => {
    if (updateResponse?.success) {

      showSuccess("Clasificado actualizado exitosamente");
      setSuccessUpdateClassified(true);
    }

    if (updateError) {
      console.log("error de actualizar clasificado", updateError);
      showError("Error al actualizar clasificado");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResponse, updateError]);



  // function uploadMultipleImages(e: File[]) {
  //   if (e) {
  //     return e.map((image) => {
  //       return URL.createObjectURL(image);
  //     });
  //   }
  //   return [];
  // }

   async function handleSubmitClassified(eSubmit: any) {

    const imagesBlob = eSubmit.images.filter((image: any) => image instanceof Blob);
    const imagesUrl = eSubmit.images.filter((image: any) => typeof image === 'string');

    if (imagesBlob.length > 0) {
      const { downloadURLs } = await uploadImagesToFirestore(imagesBlob, eSubmit.imagesReference);
      eSubmit.images = [...imagesUrl, ...downloadURLs];
    }
    

    const classifiedData = {
      ...eSubmit,
      userId: user.uid,
      country: country,
      contactUserData: {
        email: user.email,
        phone: user.phone,
        firstName: user.firstName,
        firstSurname: user.firstSurname,
      }
    };

    if (initialValues?.uid) {
      classifiedData.uid = initialValues.uid;
    }

    if (eSubmit.categoryType === ClassifiedCategories.RealState) {
      const formattedValues = formatValuesRealState(classifiedData);
      updateClassified(formattedValues);
    }

    
    if (eSubmit.categoryType === ClassifiedCategories.Vehicles) {
      const formattedValues = formatValuesVehicles(classifiedData);
      updateClassified(formattedValues);
    }


    //  console.log('classifiedData para editar', classifiedData);

    updateClassified(classifiedData);
  }

  return (
    <>
      <Container>
    
        <BackButton onClick={() => navigate(-1)} />
          <h2 style={{ color: '#000' }}>{category || ''}</h2>
        
          <TypeFormSelector
              createClassifiedSuccess={successUpdateClassified}
              formProperties={formProperties}
              userUid={user.uid} 
              category={category || ''} 
              categoryName={initialValues?.categoryName || ''}
              onSubmit={(eSubmit) => {
                handleSubmitClassified(eSubmit as any as IRealStateClassified | IVehicleClassified | ITechClassified | IEmploymentClassified | ICommonClassified);
              }}
              language={language}
              initialValues={classifiedData || initialValues} 
              isEditMode={true} 
        />
        <ModalLoader isLoading={isLoadingUpdateError} />

      </Container>
    </>

  );

};

export default EditClassifieds;
