
export function formatValuesRealState(values: any) {

    const properties: any[] = [];
    let counterProperties = 0;

    if (values.numberOfRooms) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Rooms',
                es: 'Habitaciones'
            },
            value: parseInt(values.numberOfRooms)
        })
    }

    if (values.numberOfBathrooms) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Bathrooms',
                es: 'Baños'
            },
            value: parseInt(values.numberOfBathrooms)
        })
    }

    if (values.numberOfParking) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Parkings',
                es: 'Garages'
            },
            value: parseInt(values.numberOfParking)
        })
    }

    if (values.squareMeters) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Square meters',
                es: 'Metros cuadrados'
            },
            value: parseInt(values.squareMeters)
        })
    }

    if (values.includeSocialArea) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Social area',
                es: 'Area social'
            },
            value: values.includeSocialArea
        })
    }

    if (values.includeGym) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Gym',
                es: 'Gimnasio'
            },
            value: values.includeGym
        })
    }

    if (values.isFurnished) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Furnished',
                es: 'Amoblado'
            },
            value: values.isFurnished
        })
    }

    if (values.typeOfOperation) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Type of operation',
                es: 'Tipo de operación'
            },
            value: values.typeOfOperation
        })
    }

    if (values.typeOfProperty) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Type of property',
                es: 'Tipo de propiedad'
            },
            value: values.typeOfProperty
        })
    }

    if (values.sellerType) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Seller type',
                es: 'Tipo de vendedor'
            },
            value: values.sellerType
        })
    }

    const valueFormatted = {
        ...values,
        properties
    }


    return valueFormatted
}



export function formatValuesVehicles(values: any) {

    const properties: any[] = [];
    let counterProperties = 0;

    if (values.numberOfDoors) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Doors',
                es: 'Puertas'
            },
            value: values.numberOfDoors
        })
    }

    if (values.numberOfSeats) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Seats',
                es: 'Asientos'
            },
            value: values.numberOfSeats
        })
    }

    if (values.brand) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Brand',
                es: 'Marca'
            },
            value: values.brand
        })
    }

    if (values.model) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Model',
                es: 'Modelo'
            },
            value: values.model
        })
    }

    if (values.year) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Year',
                es: 'Año'
            },
            value: values.year
        })
    }

    if (values.includeGym) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Gym',
                es: 'Gimnasio'
            },
            value: values.includeGym
        })
    }

    if (values.mileage) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Mileage',
                es: 'Kilometraje'
            },
            value: values.mileage
        })
    }

    if (values.isNew) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'New',
                es: 'Nuevo'
            },
            value: values.isNew
        })
    }

    if (values.fuelType) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Fuel type',
                es: 'Tipo de combustible'
            },
            value: values.fuelType
        })
    }

    if (values.transmissionType) {
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Transmission',
                es: 'Transmisión'
            },
            value: values.transmissionType
        })
    }

    if (values.vehicleType){
        properties.push({
            id: counterProperties++,
            key: {
                en: 'Vehicle type',
                es: 'Tipo de vehículo'
            },
            value: values.vehicleType
        })
    }

    if (values.hasCrashed) {
        properties.push({
            id: counterProperties++,
            key: {
                es: 'Chocado',
                en: "Crashed"
            },
            value: values.hasCrashed
        })
    }


    const valueFormatted = {
        ...values,
        properties
    }


    return valueFormatted
}