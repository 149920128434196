import styled from "@emotion/styled";
import { THEME } from "constants/theme/theme";

export const Container = styled.div`
  width: 100%;
  // align all content to the center
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

export const BlueContainer = styled.div`
  width: 100%;
  height: 400px;
  background: linear-gradient(180deg, #0089FF 0%, #0071D3 100%);
`;

export const Content = styled.div`
  width: 100%;
  padding: 3.5% 5%;
  border-radius: 16px;
  margin-top: -390px;
  background: #FFFFFF;
  min-height: 80vh;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const HeadContent = styled.div`
  display: flex;
  width: 100%;
`;

export const SpanReview = styled.span`
  padding-left: 0.2rem;
  font-size: 12px;
  font-weight: 400;
`

export const Title = styled.h1`
  width: 100%;
  color: #0C121D;
  font-family: Outfit, sans-serif;
  text-align: center;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
    text-align: left;
  }

`

export const RatingsWrapper = styled.div`
  color: #3A36DB;
  display: flex;
  align-items: center;
  padding-top: 1rem;
`

export const Subtitle = styled.h2`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
`

export const TextPrice = styled.h2`
  padding: 0.5rem 0;
  font-family: Outfit;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #3A36DB;
  margin: 0;
  padding: 0;
`

export const PriceContainer = styled.div`
width: 80%;
display: flex;
gap: 1rem;
align-items: flex-end;
align-self: center;

@media only screen and (max-width: 992px) {
  width: 100%;
}
`

export const WrapperText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: .5rem;
  padding: 0 37%;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

`

export const InitPriceText = styled(TextPrice)`
  color: ${THEME.colors.primary};
  font-size: 18px;
  text-decoration: underline;
  margin-right: -.5rem;
  margin-bottom: .2rem;
`;

export const LocationWrapper = styled.div`
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`

export const LocationText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #3A36DB;
`

export const TimeText = styled(LocationText)`
`

export const BodyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: .5rem;
`;

export const GalleryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
  padding: 0 10%;

  @media only screen and (max-width: 992px) {
    padding: 0;
  }
`;


export const ImageCarouselContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  max-height: 600px;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  width: 80%;
  display: flex;
  align-self: center;
  flex-direction: column;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const HeadInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BodyInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

`;

export const RatingButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #5C7C9A;
  margin: 5px 0;
`;

export const SectionTitle = styled.p`
  color: #406385;
  margin: 0.5rem 0 1rem 0;
  font-family: Outfit, monospace;
  font-weight: 600;
  font-size: 1.375rem;
`;

export const DescriptionText = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
`
export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 20px;
`;
