import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ whiteBackground }) => (whiteBackground ? '#FFFFFF' : 'rgba(0, 0, 0, 0.75)')};
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;



export const ModalContent = styled.div<React.CSSProperties>`
  max-height: 90vh;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  // scrol bar blue
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #3A36DB;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  


  @media only screen and (max-width: 768px){
    width: 95%;
  }

  ${({ style }) => style};
`;
