import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BugPopupContainer, ContentPopup } from './styled'
import Tooltip from '@mui/material/Tooltip';
import ModalLoader from 'ui/ModalLoader';
import Modal from 'ui/Modal';
import { H2 } from 'ui/Text';
import TextArea from 'ui/TextArea';
import { Button } from 'ui/Buttons';
import { useTranslation } from 'react-i18next';
import useReportBug from 'hooks/system/useReportBug/useReportBug';
import { useNotification } from 'libs/context/AlertContext';

const validationSchema = Yup.object({
    bugDescription: Yup.string().required('Required'),
});

const PopupBugReporter = () => {
    const { t } = useTranslation();
    const { reportBug, isLoading, data, error } = useReportBug();
    const { showSuccess, showError} = useNotification();

    const [open, setOpen] = useState(false);


    function handleReportBug(values: any) {
        reportBug(values);
    }



    const formik = useFormik({
        initialValues: {
            bugDescription: ''
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleReportBug(values);
        },
    });

    useEffect(() => {
        setOpen(false);

        formik.setSubmitting(false);
        if (error) {
            showError(t('error_sending_your_report'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);


    useEffect(() => {
        if (data) {
            showSuccess(t('your_report_has_been_sent_successfully'));
            formik.resetForm();
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);



    return (
        <BugPopupContainer>
            <Tooltip title={t('report_bug')} placement="top-start" onClick={() => setOpen(true)}>
                <img src="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/assets%2Ficons%2Fwarning_report_icon.png?alt=media&token=f63203a1-6e69-45f8-a7aa-8662ce3c4289" alt="Bug Perry Icon" style={{ width: '20px' }} />
            </Tooltip>
            <Modal
                isOpen={open}
                onClose={() => setOpen(false)}
                styleContent={{ minWidth: '50%' }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <ContentPopup>
                        <H2>{t('report_bug')}</H2>
                        <div style={{ margin: '1rem 0rem', width: '100%' }}>
                            <TextArea
                                label={t('description_of_the_problem')}
                                name="bugDescription"
                                value={formik.values.bugDescription}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <Button
                            disabled={formik.isSubmitting || Object.keys(formik.errors).length > 0 || formik.values.bugDescription === ''}
                            type='submit'
                            onClick={() => formik.handleSubmit()}
                        >
                            {t('send')}
                        </Button>
                    </ContentPopup>
                </form>
            </Modal>
            <ModalLoader isLoading={isLoading} />
        </BugPopupContainer>
    )
}

export default PopupBugReporter;
