import React, { useState, useEffect } from "react";
import {
  AditionalComment,
  ContactMessage,
  Pedido,
  PedidoBody,
  PedidoDesc,
  PedidoHeader,
  PedidoHeaderItem,
  PedidoName,
  PedidoPrice,
  PedidosContainer,
  PedidoTotal,
  RequestBody,
  RequestDesc,
  RequestHBItemLarge,
  RequestHeader,
  RequestHeaderDesc,
  RequestHeaderPrice,
  RequestImg,
  RequestName,
  Request,
  Resume,
  SubtitleSpan,
  SumFinalText,
  Summary,
  SummarySubTitle,
  SummaryTitle,
  TransButton,
  VerticalLine,
  SummaryId,
  NotFoundMessage,
} from "../../styled";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import { Tab, Tabs } from "@mui/material";
import { IoMdEye } from "react-icons/io";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { THEME } from "constants/theme/theme";
import { getStatusText, getUrgencyLevelText } from "utils/text-helpers";
import Skeleton from "@mui/material/Skeleton";
import { formatDate } from "utils/format-date";

interface RequestsHistorialProps {
  request: any;
}

const RequestsHistorial = ({ request }: RequestsHistorialProps) => {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [requestSelected, setRequestSelected] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [request]);

  const handleClickOpen = (item: any) => {
    setOpen(true);
    setRequestSelected(item);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <>
      <p>{t("historical_recent_requests")}</p>
      <Tabs
        style={{ marginBottom: "1rem" }}
        variant="fullWidth"
        value={tabIndex}
        onChange={handleTabChange}
      >
        <Tab label={t("needs")} />
      </Tabs>

      {tabIndex === 0 && (
        <PedidosContainer>
          {loading ? (
            <Pedido>
              <Skeleton variant={"rounded"} width={"100%"} height={300} />
            </Pedido>
          ) : request.length > 0 ? (
            request.map((item, index) =>
              item["type"] !== "need" ? (
                <Pedido key={index}>
                  <PedidoHeader>
                    <PedidoHeaderItem>
                      <PedidoName>{t("request")}: </PedidoName>
                      <PedidoDesc>{item?.id}</PedidoDesc>
                    </PedidoHeaderItem>
                    <VerticalLine />
                    <PedidoHeaderItem>
                      <PedidoName>{t("date")}: </PedidoName>
                      <PedidoDesc>{formatDate(item?.date)}</PedidoDesc>
                    </PedidoHeaderItem>
                    <VerticalLine />
                    <PedidoHeaderItem>
                      <PedidoName>{t("status")}: </PedidoName>
                      <PedidoDesc color={"#000"}>
                        {getStatusText(item?.status) || "Procesando"}
                      </PedidoDesc>
                    </PedidoHeaderItem>
                  </PedidoHeader>
                  <PedidoHeaderItem>
                    <PedidoName>{t("need")}: </PedidoName>
                    <PedidoDesc color={THEME.colors.black80}>
                      {item?.needTitle}
                    </PedidoDesc>
                  </PedidoHeaderItem>
                  <PedidoBody>
                    <PedidoTotal>
                      {t("category")}:{" "}
                      <PedidoPrice>{item?.needCategory}</PedidoPrice>
                    </PedidoTotal>
                    <TransButton
                      onClick={() => handleClickOpen(item)}
                      margin={"0"}
                    >
                      <IoMdEye />
                      {t("view")}
                    </TransButton>
                  </PedidoBody>
                </Pedido>
              ) : null
            )
          ) : (
            <NotFoundMessage>
              <p>{t("we_have_not_found_any_requests")}</p>
            </NotFoundMessage>
          )}
        </PedidosContainer>
      )}

      {tabIndex === 1 && (
        <PedidosContainer>
          {loading ? (
            <Pedido>
              <Skeleton variant={"rounded"} width={"100%"} height={300} />
            </Pedido>
          ) : request.length > 0 ? (
            request.map((item, index) =>
              item["type"] === "need" ? (
                <Pedido key={index}>
                  <PedidoHeader>
                    <PedidoHeaderItem>
                      <PedidoName>{t("request")}: </PedidoName>
                      <PedidoDesc>{item?.id}</PedidoDesc>
                    </PedidoHeaderItem>
                    <VerticalLine />
                    <PedidoHeaderItem>
                      <PedidoName>{t("date")}: </PedidoName>
                      <PedidoDesc>{formatDate(item?.date)}</PedidoDesc>
                    </PedidoHeaderItem>
                    <VerticalLine />
                    <PedidoHeaderItem>
                      <PedidoName>{t("status")}: </PedidoName>
                      <PedidoDesc color={"#038A25"}>
                        {getStatusText(item?.status) || "Procesando"}
                      </PedidoDesc>
                    </PedidoHeaderItem>
                  </PedidoHeader>
                  <PedidoBody>
                    <PedidoTotal>
                      {t("category")}:{" "}
                      <PedidoPrice>{item?.needCategory}</PedidoPrice>
                    </PedidoTotal>
                    <TransButton
                      onClick={() => handleClickOpen(item)}
                      margin={"0"}
                    >
                      <IoMdEye /> {t("view")}
                    </TransButton>
                  </PedidoBody>
                </Pedido>
              ) : null
            )
          ) : (
            <NotFoundMessage>
              <p>{t("we_have_not_found_any_requests")}</p>
            </NotFoundMessage>
          )}
        </PedidosContainer>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title2"
      >
        <DialogTitle onClick={handleClose} id="responsive-dialog-title2">
          <MdArrowBack />
        </DialogTitle>
        <DialogContent>
          <Summary>
            <SummaryTitle>
              {t("need_detail")}
              <SummaryId>#{requestSelected?.id}</SummaryId>
            </SummaryTitle>
            <SummarySubTitle>
              {t("date")}: {formatDate(requestSelected?.date)}
              &nbsp;&nbsp;&nbsp;&nbsp; {t("status")}:{" "}
              <SubtitleSpan>
                {getStatusText(requestSelected?.status) || "Procesando"}
              </SubtitleSpan>
            </SummarySubTitle>
            <Request>
              <RequestHeader>
                {requestSelected.needImage && (
                  <RequestImg src={requestSelected.needImage} />
                )}
                <RequestHeaderDesc>
                  {requestSelected.needTitle}
                </RequestHeaderDesc>
                {tabIndex === 0 && (
                  <RequestHeaderPrice>
                    {requestSelected.needCategory}
                  </RequestHeaderPrice>
                )}
              </RequestHeader>
              <RequestBody>
                {tabIndex === 0 && (
                  <>
                    <RequestHBItemLarge>
                      <RequestName>{t("need_description")}: </RequestName>
                      <RequestDesc>{requestSelected?.needTitle}</RequestDesc>
                    </RequestHBItemLarge>
                    <RequestHBItemLarge>
                      <RequestName>{t("urgency_level")}: </RequestName>
                      <RequestDesc>
                        {getUrgencyLevelText(requestSelected?.needUrgencyLevel)}
                      </RequestDesc>
                    </RequestHBItemLarge>
                    <VerticalLine />
                  </>
                )}
              </RequestBody>
            </Request>

            {tabIndex === 1 && (
              <Resume>
                <SumFinalText>{t("additional_comments")}</SumFinalText>
                <hr />
                <AditionalComment>
                  {t(
                    "this_is_an_additional_comment_from_the_customer_regarding_this_service_request"
                  )}
                </AditionalComment>
              </Resume>
            )}
            <ContactMessage>
              {t(
                "this_is_an_additional_comment_from_the_customer_regarding_this_service_request"
              )}
            </ContactMessage>
          </Summary>
        </DialogContent>
        <DialogActions>
          <TransButton onClick={handleClose}>{t("exit")}</TransButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestsHistorial;
