import React, {FC} from 'react'
import {Container, ContainerBar, Paragraph, Subtitle, Title} from './styled'
import { useTranslation } from 'react-i18next';
// CONTAINERS
import MainLayout from 'views/layout/MainLayout'
import {ThemeProvider} from "@mui/material";
import {theme} from "libs/theme";

const Politics: FC = () => {
    const { t } = useTranslation();

    return (

        <MainLayout>
            <Container id="home-container">
                <ContainerBar id="home-wrap">
                    <ThemeProvider theme={theme}>
                        <Title>{t('privacy_policy')}</Title>
                        <Subtitle>{t('last_update')}: 10/04/2023</Subtitle>
                        <Paragraph weight={700}>{t('about_us')}</Paragraph>
                        <Paragraph>{t('our_website_is')}: https://whatdoyouneed7.com</Paragraph>
                        <Paragraph weight={700}>{t('what_personal_data_we_collect_and_why_we_collect_it')}</Paragraph>
                        <Paragraph weight={700}>{t('comments')}</Paragraph>
                        <Paragraph>{t('when_visitors_leave_comments_on_the_site_we_collect_the_data')}.</Paragraph>
                        <Paragraph weight={700}>Media</Paragraph>
                        <Paragraph>{t('if_you_upload_images_to_the_website')}</Paragraph>
                        <Paragraph weight={700}>{t('contact_forms')}</Paragraph>
                        <Paragraph weight={700}>Cookies</Paragraph>
                        <Paragraph>{t('if_you_leave_a_comment_on_our_site')}.</Paragraph>
                        <Paragraph>{t('if_you_have_an_account_and_you_log_in_to_this_site')}.</Paragraph>
                        <Paragraph>{t('when_you_log_in_we_will_also_set_up_several_cookies')}.</Paragraph>
                        <Paragraph>{t('if_you_edit_or_publish_an_article')}.</Paragraph>
                        <Paragraph weight={700}>{t('embedded_content_from_other_websites')}</Paragraph>
                        <Paragraph>{t('articles_on_this_site_may_include_embedded_content')}.</Paragraph>
                        <Paragraph>{t('these_websites_may_collect_data_about_you')}.</Paragraph>
                        <Paragraph weight={700}>{t('analytics')}</Paragraph>
                        <Paragraph weight={700}>{t('who_we_share_your_data_with')}</Paragraph>
                        <Paragraph weight={700}>{t('how_long_we_retain_your_data')}</Paragraph>
                        <Paragraph>{t('if_you_leave_a_comment_the_comment_and_its_metadata_are_retained_indefinitely')}.</Paragraph>
                        <Paragraph>{t('for_users_that_register_on_our_website_if_any_we_also_store_the_personal_information')}.</Paragraph>
                        <Paragraph weight={700}>{t('what_rights_do_you_have_over_your_data')}</Paragraph>
                        <Paragraph>{t('if_you_have_an_account_on_this_site_or_have_left_comments')}</Paragraph>
                        <Paragraph weight={700}>{t('where_we_send_your_data')}</Paragraph>
                        <Paragraph>{t('visitor_comments_may_be_checked_through_an_automated_spam_detection_service')}.</Paragraph>
                        <Paragraph weight={700}>{t('your_contact_information')}</Paragraph>
                        <Paragraph>{t('it_is_only_used_for_the_purpose_of_contacting_you_in_case_of_any_irregularity')}.</Paragraph>
                    </ThemeProvider>
                </ContainerBar>
            </Container>
        </MainLayout>
    )
}

export default Politics
