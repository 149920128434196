import styled from "@emotion/styled";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FDFDFE; 
    padding: 1.2rem 0 0 0;

    @media only screen and (max-width: 768px) {
      padding: .5rem 0 0 0;
    }
` 
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
  background-color: #FDFDFE;
  align-self: center;
`

export const VideoPlayerContainer = styled.div`
    margin-top: 1rem;
    /* margin-bottom: 1rem; */
    max-width: 100%;

    @media only screen and (max-width: 768px) {
        margin-bottom: -1rem;
        height: 250px;
    }
`