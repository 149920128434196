import {Container, SubContainer, ImageContainer, InfoContainer, TopInfoContainer, Row} from './styled';
import { P, H5 } from 'ui/Text';
import Badge from 'ui/badge';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import { formatDate } from 'utils/format-date';

type Messages = {
    createdAt: string;
    senderId: string;
    text: string;
};
interface CardChatClassifiedProps {
   image: string;
   title: string;
   category: string;
   messages: Messages[];
}

const CardChatClassified = (props: CardChatClassifiedProps) => {
    const { image, title, category, messages } = props;

    return (
        <Container>
            <SubContainer>
                <ImageContainer>
                    <img src={image || "https://via.placeholder.com/150"} alt={title} />
                </ImageContainer>
                <InfoContainer>
                    <TopInfoContainer>
                        <H5>{title}</H5>
                        <Row>
                            <P>{formatDate(messages[messages.length - 1].createdAt)}</P>
                            {/* <Badge icon={<MarkChatUnreadIcon />} count={3} /> */}
                        </Row>
                    </TopInfoContainer>
                    <P>{category}</P>
                    <P>{messages[messages.length - 1].text}</P>
                </InfoContainer>
            </SubContainer>
        </Container>
    );
};

export default CardChatClassified;