import styled from "styled-components";

export const CountContainer = styled.div`
    display: flex;
    flex: 1;
    gap: .5rem;
    justify-content: flex-end;
    align-items: center;
`
export const CartNameSmall = styled.span`
    font-weight: 600;
    font-size: 16px;
`
export const ButtonUpdateQty = styled.div`
    cursor: pointer;
    // hover
    &:hover {
    transform: scale(1.05);
    }
`

export const AmountInput = styled.input<{sizeW: string, sizeH: string}>`
  text-align: center;
  width: ${props=>props.sizeW};
  height: ${props=>props.sizeH};
  border-radius: 5px;
  border: 2px solid #CDD0D4;
  padding:16px 8px;
  font-size: 18px;
`


// ${props => props.theme.typography.body.sm.regular};