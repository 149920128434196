import Skeleton from "@mui/material/Skeleton";
import styled from "styled-components";


 const SponsorBuy = styled.div`
  ${props => props.theme.typography.body.md.bold};
  display: flex;
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
`
export const Video = styled.video`
  width: 100%;
  height: 15rem;
  object-fit: cover;
`

const SkeletonComponent = () => {

    return (
        <div>
            <Skeleton variant="rounded" width="95%" height={200} />
            <Skeleton variant="text" width="20%" />
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="100%" />
            <SponsorBuy>
                <Skeleton variant="rounded" width="25%" height={25} />
                <Skeleton variant="rounded" width="25%" height={25} />
            </SponsorBuy>
        </div>
    );
}

export default SkeletonComponent;