import styled from "@emotion/styled";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FDFDFE;
`


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: #FDFDFE;
  align-self: center;
  border-radius: 16px;
  padding: 2% 1%;

  @media (max-width: 992px) {
    width: 100%;
    padding: 0;
  }
`
