import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
import { getLanguage } from "redux/common";

import {
  CategoriesContainer,
  ItemCategory,
  ImageCategory,
} from "./styled";
import SliderCarousel from "components/SliderCarousel";
import { H3 } from "ui/Text";
import { ImageObject } from "types";

interface SliderCategoriesMobileProps {
  data: any[];
  type: string;
  category: string;
  handleType: any;
  onSelectCategory: (category: string) => void;
  images: string[] | ImageObject[];
  numberSkeletons: number[];
  hideSlideCategories?: boolean;
}

const SliderCategoriesMobile: FC<SliderCategoriesMobileProps> = ({
  data,
  type,
  handleType,
  onSelectCategory,
  images,
  numberSkeletons,
  hideSlideCategories
}) => {
  const navigate = useNavigate();
  const language = useSelector(getLanguage);


  return (
    <>
      <SliderCarousel images={images} />
      {
        !hideSlideCategories ? (
          <>
            <H3
              color="#FFF"
              style={{ textAlign: "center", margin: "0.5rem 0rem" }}
              family="Outfit"
            >
              Categorías de {type || ''}
            </H3>
            <CategoriesContainer>
              {data.length > 0
                ? data.map((category: any, index: number) => {
                  return (
                    <ItemCategory
                      key={index}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        if (type === "Servicios") {
                          navigate(
                            category.name.en
                              .toLowerCase()
                              .replaceAll(" ", "-")
                          );
                        } else {
                          onSelectCategory(category.uid);
                          handleType(category.id);
                        }
                      }}
                      backgroundColor={category.bgColor}
                    >
                      <ImageCategory
                        src={category?.image || ""}
                        alt={category?.name[language] || ""}
                        title={category?.name[language] || ""}
                      />
                      <span>{category.name[language]}</span>
                    </ItemCategory>
                  );
                })
                : numberSkeletons.map((item) => {
                  return (
                    <ItemCategory key={item}>
                      <Skeleton
                        variant={"rounded"}
                        width={"100%"}
                        height={"4rem"}
                      />
                    </ItemCategory>
                  );
                })}
            </CategoriesContainer>
          </>
        ) : (
          <div style={{ height: '1.2rem' }} />
        )
      }
    </>
  );
};

export default SliderCategoriesMobile;
