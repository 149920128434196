import styled, {css} from "styled-components";


export const CreateAddressFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4rem;

  &:nth-child(1) {
    ${(props) =>
        !props.isListAdressEmpty &&
        css`
        border-right: 1px solid #9ba1aa;
      `}
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    &:nth-child(1) {
      border-right: 0;
      border-bottom: 1px solid #9ba1aa;
    }
  }
`;

export const InputWrap = styled.div`
  position: relative;
  width: ${props => props.size || "80%"};
  z-index: 1;
  margin-bottom: -10px;

  @media only screen and (max-width: 768px) {
   width: 100%;
  }
`

export const DirTitle = styled.span`
  ${props => props.theme.typography.body.sm.regular};
`

export const Error = styled.p`
  color: #F1851F;
  ${props => props.theme.typography.body.sm.regular};
  margin: 8px 0px 10px 0px;
`;

export const SwitchContainer = styled.div`
  width: 80%;
  padding-top: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
