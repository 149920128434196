import styled from 'styled-components'

export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 99%;
  overflow: scroll;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: #fafafa;
  margin-top: 10px;
  // moidify the scroll and add #5100D6 color
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #FBFBFB;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #5100D6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #5100D6;
  }

  @media only screen and (max-width: 992px) {
    border-radius: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`
export const PaneTitle = styled.p`
  text-align: center;
  margin-top: 1rem;
  color: #000000;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.2rem;
`

export const SpaceBetween = styled.div`
  display: flex;
  margin: 1rem 0rem;
  justify-content: space-between;
`
export const PaneSection = styled.div`
  padding: .5rem 1rem;
  margin-bottom: .3rem;
  width: 100%;

  @media only screen and (max-width: 992px) {
    padding: .5rem;
  }
`

export const PaneFooter = styled.div`
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
`

export const PaneFooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap:1rem;
`
