import styled from "@emotion/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FDFDFE;
`

export const ContainerBread = styled.div`
  width: 100%;
  padding: 20px 20px 100px 20px;
  margin-bottom: 4rem;

  @media (max-width: 992px) {
    padding: 20px 20px 76px 20px;
  }


`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: -150px;
  background-color: #FDFDFE;
  align-self: center;
  border-radius: 16px;
  padding: 3% 5%;
  min-height: 80vh;
  
  @media (max-width: 992px) {
    width: 100%;
  }
`
