import { useState, useEffect } from 'react';

const defaultOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

const useCurrentLocation = (options = defaultOptions) => {
    const [location, setLocation] = useState({ lat: null, lng: null });
    const [error, setError] = useState<any>(null);

    const handleSuccess = (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({
            lat: latitude,
            lng: longitude,
        });
    };

    const handleError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        const geo = navigator.geolocation;
        if (!geo) {
            setError('Geolocation is not supported by your browser');
            return;
        }

        const watcher = geo.watchPosition(handleSuccess, handleError, options);
        return () => geo.clearWatch(watcher);
    }, [options]);

    return { location, error };
};

export default useCurrentLocation;
