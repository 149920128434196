

import { useSelector, useDispatch } from "react-redux";
import { userSelector} from 'redux/user'
import {cleanCart, removeFromCartSagasTrigger} from 'redux/cart'

interface IDeleteCartItem {
    id?: string;
}

const useDeleteCartItem = () => {
    const dispatch = useDispatch();
    const rootUserSelector: any = useSelector(userSelector as any)
    const user = rootUserSelector?.user?.userData || null;

    const deleteCartItem = async ({id}: IDeleteCartItem) => {

        if (user) {
            if (id) {
                dispatch(removeFromCartSagasTrigger.run({id}))
            } else {
                console.error('No se ha podido eliminar el producto del carrito, no tiene id')
            }
        } else {
            dispatch(cleanCart());
        }
    
    }

    return deleteCartItem;
}


export default useDeleteCartItem;