import styled from 'styled-components'
import { THEME } from 'constants/theme/theme';

export const Container = styled.div`
  width: 100%;
  @media only screen and (max-width: 768px){
    width: 100%;
    margin: 0;
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  
  border:1px solid #CDD0D4;
  text-align: left;
  margin-right: .2rem;
  border-radius: 1rem;
  padding: 1rem;
`

export const ImageContainer = styled.div`
  width: 100%;
`

export const Image = styled.img`
  height: 16rem;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: .5rem 0 .5rem 0;
  padding: 0 1rem;
  align-items: center;
  @media only screen and (max-width: 992px){
    margin: 0;
    padding: .5rem;
  }
`

export const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: .5rem;
  @media only screen and (max-width: 768px){
      width: 100%;
      margin-bottom: .5rem;
  }
`


export const Price = styled.p`
    font-size: 18px;
    margin: .3rem 0;
    font-weight: 700;
    color: ${THEME.colors.primary};
  @media only screen and (max-width: 992px){
    font-size: 14px;
  }

  @media only screen and (max-width: 768px){
    width: 100%;
  }


`;

export const OperationTypeContainer = styled.div`
  height: fit-content;
  width: fit-content;
  background-color: ${THEME.colors.primary};
  color: #FFFFFF;
  border-radius: .5rem;
  padding: .1rem .7rem;
  font-size: 14px;
`


export const TransButton = styled.button`
  display: block;
  text-transform: uppercase;
  width: ${props => props.size || "auto"};
  background: ${props=>props.background || "#F5F5F6"};
  color: ${props=>(props.background && "#FFFFFF") || "#406385"};
  padding: .4rem 1rem;
  border: 1px solid #406385;
  border-radius:2rem;
  ${props => props.theme.typography.body.sm.bold};
  @media only screen and (max-width: 992px){
    padding: ${props => props.pas || ".4rem 1rem"};
  }
  
  @media only screen and (max-width: 500px){
    ${props => props.theme.typography.body.sm.bold};
    padding: .2rem .4rem;
  }
`

export const SpanMiddle = styled.span`
  vertical-align: middle;
  padding-left: .2rem;
`


export const Row = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  ${props => props.theme.typography.body.lg.medium};
`

export const RowProperties = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  ${props => props.theme.typography.body.lg.medium};

  @media only screen and (max-width: 992px){
    flex-direction: column;
    gap: 0;
  }
`

export const Title = styled.p`
  color: #0C121D;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 4px 0;
  min-height: 3rem;
`

export const PropertyItem = styled.p`
  margin: 0;
  ${props => props.theme.typography.body.md.regular};
  color: #3A36DB;
`

export const ShortDescription = styled.p`
  margin: 0;
  ${props => props.theme.typography.body.md.regular};
  color: #0C121D;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  text-align: left;
`