import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    /* margin-top: 1rem; */
  }

  @media (max-width: 620px) {
    width: 100%;
    padding: 0rem 1rem 0rem 1rem;
  }
`;

export const Figure = styled.figure`
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: 992px) {
    max-height: 90%;
    position: relative;
    bottom: -6%;
  }
`;

interface IImage {
  size?: 'big'
}

export const Image = styled.img<IImage>`
  width: 100%;
  /* border-radius: 1rem; */
  height: ${props => props?.size === 'big' ? '400px' : '100%'};
  object-position: 55% 120%;
  /* box-shadow: 0 4px 8px 2px rgba(2, 64, 154, 0.16); */
  @media (max-width: 620px) {
    object-fit: cover;
  }
`;

export const Text = styled.p`
  margin: 1rem 0;
  font-family: Outfit, monospace;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #0C121D;
`;

export const ContentDesktop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 1rem;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const ContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const BannerText = styled.p`
  text-align: center;
  font-family: Outfit, monospace;;
  font-size: 1.2rem;
  font-weight: 450;
  margin-top: 18px;
  line-height: 1.2;
  margin: 12px auto 15px 20px;
  @media only screen and (max-width: 600px) {
    width: 90%;
    text-align: left;
    margin: 10px 29px 18px auto;
  }
`;