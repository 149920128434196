import { Container, Cuad, Titles } from "./styled";
import ExpandCard from "components/cards/expand-card";
import { FC } from "react";
import { useTranslation } from "react-i18next";


interface ContentMarketingProps {
  services: any[];
}

const ContentMarketing: FC<ContentMarketingProps> = ({ services }) => {
  const { t } = useTranslation();

  return (
    <Container background="#ffffff" pav="0.5rem" pah="2rem">
      <Titles size={"1.5rem"}>{t('our_services_for_you')}</Titles>
      <Cuad>
        {services.map((item, index) => {
          return (
            <ExpandCard
              key={index}
              data={item}
              textButton={`${t('view_more')}`}
              hasRedirectToDetail={true}
            />
          );
        })}
      </Cuad>
    </Container>
  );
};

export default ContentMarketing;
