import { useDispatch } from 'react-redux';
import { logout, setUser } from 'redux/user';
import { setAuth } from 'redux/auth';
import { signOut } from 'firebase/auth';
import { auth } from 'config/firebase';
import { useNotification } from 'libs/context/AlertContext';

const useLogout = () => {
    const dispatch = useDispatch();
    const {showError} = useNotification();

    async function logoutUser() {
        try {
            await signOut(auth);
            dispatch(logout());
            dispatch(setUser(null));
            dispatch(setAuth({ isAuth: false }));
        } catch (error) {
            console.error('Error cerrando sesión:', error);
            showError('Error cerrando sesión');
        }
    }

    return logoutUser;
}

export default useLogout;
