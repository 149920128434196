import styled, { css } from "styled-components";

interface ContainerProps {
  isSelected: boolean;
}

export const CarItemContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  position: relative;

  label {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    cursor: pointer;
  }

  label span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: white;
    transition: background-color 0.3s, border-color 0.3s;
  }

  label input[type="checkbox"]:checked + span {
    background-color: #3a36db;
    border-color: #3a36db;
  }

  label input[type="checkbox"]:focus + span {
    outline: none;
    box-shadow: none;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.6rem;
  background: #f5f5f6;
  box-shadow: 0 4px 8px 2px rgba(2, 64, 154, 0.16);
  border-radius: 10px;
  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid #3a36db;
      box-shadow: 0 0 10px rgba(92, 124, 154, 0.2);
    `}
  @media only screen and (max-width: 500px) {
    height: 90px;
    padding: 1rem;
  }
`;

export const CartItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;
export const CartRemove = styled.div``;
export const ItemImage = styled.img`
  width: 15%;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    width: 15%;
  }

  @media only screen and (max-width: 500px) {
    width: 25%;
  }
`;
export const CartName = styled.span`
  ${(props) => props.theme.typography.body.sm.regular};
  @media only screen and (max-width: 500px) {
    display: inline-block;
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CountContainer = styled.div`
  display: flex;
  /* flex: 1; */
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;

export const ButtonUpdateQty = styled.div`
  cursor: pointer;
  // hover
  &:hover {
    transform: scale(1.05);
  }
`;

export const CartNameSmall = styled.span`
  ${(props) => props.theme.typography.body.sm.regular};
  font-weight: 600;
  margin-bottom: 2px;
`;
export const CartAmount = styled.span`
  ${(props) => props.theme.typography.body.sm.regular};
  margin-right: 0.5rem;
`;
export const AmountInput = styled.input`
  text-align: center;
  width: ${(props) => props.sizeW};
  height: ${(props) => props.sizeH};
  border-radius: 5px;
  border: 2px solid #cdd0d4;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
`;
export const CartPrice = styled.span`
  white-space: nowrap;
  ${(props) => props.theme.typography.subheading.md.semibold};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media only screen and (max-width: 600px) {
    justify-content: flex-end;
    gap: 0.5rem;
  }
`;
export const CartSection2 = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const NameContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const CartSection3 = styled.div`
  text-align: right;
  /*  height: 110%;
  min-height: 100px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 540px) {
    width: 100%;
    height: 40px;
    min-height: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
