import { useState } from 'react';
import createOrder from 'services/checkout/create-order/create-order-service';
import { ServiceResponse, CreateOrderProps } from 'types';

const useCreateOrder = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [response, setResponse] = useState<ServiceResponse | null>(null);

    const createOrderHandler = async (props: CreateOrderProps) => {
        setLoading(true);
        setError(null);
        setResponse(null);
        try {
            const result = await createOrder(props);
            if (result.status === 'ERROR') {
                throw new Error(result.message);
            }
            setResponse(result);
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    return {
        createOrder: createOrderHandler,
        loading,
        error,
        response,
    };
}

export default useCreateOrder;
