import styled from "styled-components";

interface ButtonProps {
  direction: "left" | "right";
}

export const Button = styled.button<ButtonProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 90;
  left: ${({ direction }) => (direction === "left" ? "-5px" : "unset")};
  right: ${({ direction }) => (direction === "right" ? "-5px" : "unset")};
  &:focus {
    outline: none;
  }
`;
