import styled from 'styled-components'
import { THEME } from 'constants/theme/theme'

export const Container = styled.div`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 2rem .5rem 2rem;

  @media only screen and (max-width: 992px) {
    border-radius: 1rem;
    
  }

`;

export const Card = styled.div`
  width: 98%;
  height: 380px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  border-radius: 2rem;
  text-align: left;
  box-shadow: 0 2px 2px .5px #02409A66;
  margin: 6px auto;
  background-color: #FFF;

  @media only screen and (max-width: 1024px){
    border-radius:1rem;
    height: 350px;
  }
  @media only screen and (max-width: 768px){
    width: 98%;
    height: 320px;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 60%;
  overflow: hidden;

`

export const Price = styled.span`
  font-weight: 600;
`;

export const SpanMiddle = styled.span`
  display: -webkit-box;
  vertical-align: middle;
  font-size: .75rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.2;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media only screen and (max-width: 768px){
    font-size: .7rem;
  }
`
export const Image = styled.img`
  width: 100%;
  border-radius: 14px;
  height: 100%;
  object-fit: cover;
  
  @media only screen and (max-width: 992px){
    border-radius: 14px;
  }
  @media only screen and (max-width: 600px) {
    height: 90%;
  }
`


export const DescriptionContainer = styled.div`
  flex: 1;
  min-height: 7rem;
  overflow: hidden;
  margin: 0;
  padding: 2px 1rem 0rem 1rem;
  text-align: center;
  ${props => props.theme.typography.body.sm.bold};
  @media only screen and (max-width: 600px) {
    padding: 0 1rem 0rem 1rem;
    font-size: .725rem;
    ${props => props.theme.typography.label.xs.bold};
  }
`

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: .5rem 0;
`

export const Title = styled.p`
  color:#3A36DB;
  font-weight: 700;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: .4rem;
  @media only screen and (max-width: 992px){
    ${props => props.theme.typography.body.md.bold};
  }
`


export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  padding: .4rem 1rem;
  border:0;
  margin: 0 auto;
  border-radius: 0%;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 992px){
    width: 80%;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 500px){
    ${props => props.theme.typography.body.sm.bold};
    padding: 0;
  }
`

export const LinkButton = styled.a`
  color: ${(props) => props.theme.colors.primary80};
  font-weight: bold;

  cursor: pointer;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || 'auto'};
  min-width: 50%;
  background-color: ${THEME.colors.blueLight};
  padding:  ${(props) => props.padding || '0.2rem .5rem'};

  @media (max-width: 1080px) {
    font-size: 0.7rem;
  }

`
