import { useState } from "react";
import Carousel from "react-multi-carousel";
import Skeleton from "@mui/material/Skeleton";
import ExtraCard from "components/cards/services/extraServices";
import ModalInfo from "./modal";
import { Container, FlexColumn, SponsorBuy, TitleWrapper, Titles } from "./styled";
import { useTranslation } from "react-i18next";


export const Extras = (props) => {
  const { data } = props;

  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleClick = (subService: any) => {
    setIsOpenModal(true);
    setSelectedService(subService);
  };
  const ItemSkeletons = [1, 2, 3, 4];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };
  return (
    <Container background="#E7EDF5">
      <FlexColumn background="#E7EDF5">
        <TitleWrapper>
          <Titles alignM={"center"} size={"1.5rem"}>
            {t('personalized_services_for_you')}
          </Titles>
        </TitleWrapper>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          arrows={true}
          // className={"pb-3"}
        >
          {data !== null
            ? data.map((offer: any, index: number) => {
              return (
                <div style={{ margin: "0", height: "100%" }} key={index}>
                  <ExtraCard data={offer} textButton={`${t('view_more')}`} onClick={() => { handleClick(offer) }} />
                </div>
              );
            })
            : ItemSkeletons.map((item) => {
              return (
                <div key={item}>
                  <div>
                    <Skeleton
                      variant={"rounded"}
                      width={"95%"} 
                      height={200}
                    />
                    <Skeleton variant={"text"} width={"20%"} />
                    <Skeleton variant={"text"} width={"60%"} />
                    <Skeleton variant={"text"} width={"100%"} />
                    <SponsorBuy>
                      <Skeleton
                        variant={"rounded"}
                        width={"25%"}
                        height={25}
                      />
                      <Skeleton
                        variant={"rounded"}
                        width={"25%"}
                        height={25}
                      />
                    </SponsorBuy>
                  </div>
                </div>
              );
            })}
        </Carousel>
        <ModalInfo
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          data={selectedService}
        />
      </FlexColumn>
    </Container>
  );
};
