// Status of the classifieds

export enum ClassifiedStatus {
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected',
}


export enum ClassifiedCategories {
    Home = 'Home',
    Construction = 'Construction',
    Employments = 'Employments',
    Music = 'Music',
    Pets = 'Pets',
    RealState = 'RealState',
    Tech = 'Tech',
    Vehicles = 'Vehicles',
    Sports = 'Sports'
}

export enum EnumClassifiedPropertyIconUrls {    
    Doors = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fpuerta-de-coche.png?alt=media&token=99e564bb-1de3-43ba-90cc-87cef2289fca',
    Seats = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fseat-auto.png?alt=media&token=47cc5a2f-6feb-41dd-89ec-6331f2bc5d12',
    Brand = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fbrand-64x64.png?alt=media&token=3cc331ee-b798-40e1-baba-b5f3ad02c8e1',
    Transmission = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Ftransmision-64x64.png?alt=media&token=2eef1907-aabd-49c9-b061-780ae36d4321',
    Mileage = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fmileage-64x64.png?alt=media&token=a898f099-6414-4c38-8bf6-2ed07f6dca84',
    Rooms = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fbed-64x64.png?alt=media&token=09b0df8c-e95c-49ff-863e-0d7ed9312232',
    Bathrooms = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fbathroom_64x64.png?alt=media&token=aec0d3d1-f55d-41a4-9507-ef2500adccb8',
    SquareMeters = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fsquare_meters-64x64.png?alt=media&token=5d152ae6-ff5c-4e11-b49d-c09364af4cbb',
    Parkings = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fparking-64x64.png?alt=media&token=dd6dc5b9-dc32-4ec3-8130-4950285adc0a',
    Model = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fvechicle-yellow-64x64.png?alt=media&token=e9e527d2-0649-4ca7-92e3-2e3c600f2e72',
    Salary = 'https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fbillete-de-banco%20.webp?alt=media&token=f9557e54-38b7-4412-b7a7-86fa980a09fc'
    
}

export const ClassifiedsRoutes = {
    Posts: 'Classifieds/Posts',
    Categories: 'Classifieds/Categories',
    Home: '/Classifieds',
    DetailClassified: 'Classified/detail',
}

