import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Skeleton from "@mui/material/Skeleton";
import { Solution } from "types";
import { Container, TitleWrapper } from "./styled";
import { FlexColumn, SponsorBuy, Titles } from "./styled";
import { setServiceDetail } from "modules/services/store";
import ExtraCard from "components/cards/services/extraServices";
import { useTranslation } from "react-i18next";
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ItemSkeletons = [1, 2, 3];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 500 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 2,
  },
};

const TopDestinations = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { destinations } = props;


  const handleClick = (subService: any) => {
    dispatch(setServiceDetail(subService));
    navigate(`detail?id=${subService.uid}`);
  };

  return (
    <Container>
      <FlexColumn>
        <TitleWrapper>
          <Titles alignM={"center"} size={"1.5rem"}>
            {t('our_top_10_destinations_in_panama')}
          </Titles>
        </TitleWrapper>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          arrows={true}
        >
          {destinations !== null
            ? destinations.map((item: Solution, index: number) => {
              return (
                <ExtraCard
                  data={item}
                  onClick={() => { handleClick(item) }}
                  textButton={`${t('view_more')}`}
                  key={index}
                />
              );
            })
            : ItemSkeletons.map((item) => {
              return (
                <div key={item}>
                  <div>
                    <Skeleton
                      variant={"rounded"}
                      width={"95%"}
                      height={200}
                    />
                    <Skeleton variant={"text"} width={"20%"} />
                    <Skeleton variant={"text"} width={"60%"} />
                    <Skeleton variant={"text"} width={"100%"} />
                    <SponsorBuy>
                      <Skeleton
                        variant={"rounded"}
                        width={"25%"}
                        height={25}
                      />
                      <Skeleton
                        variant={"rounded"}
                        width={"25%"}
                        height={25}
                      />
                    </SponsorBuy>
                  </div>
                </div>
              );
            })}
        </Carousel>
      </FlexColumn>
    </Container>
  );
};

export default TopDestinations;
