import { useEffect, useState } from 'react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore/lite';
import { firestore } from 'config/firebase';
import { ProductCategory } from 'types';

interface UseCategoriesState {
  categories: ProductCategory[];
  loading: boolean;
  error: Error | null;
}

interface Filter {
  field: string;
  value: string | number | boolean;
}


interface OrderFilter {
  field: string;
  direction: 'desc' | 'asc';
}

const useCategories = (collectionPath: string, filters?: Filter[], orderFilter?: OrderFilter): UseCategoriesState => {
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [maxRequest, setMaxRequest] = useState(0);

  useEffect(() => {
    setMaxRequest(maxRequest + 1);
    if (categories.length === 0 && maxRequest < 10) {
      setLoading(true);
      setError(null);

      let baseQuery: any = collection(firestore, collectionPath);

      filters?.forEach((filter) => {
        baseQuery = query(baseQuery, where(filter.field, '==', filter.value));
      });

      if (orderFilter) {
        baseQuery = query(baseQuery, orderBy(orderFilter.field, orderFilter.direction));
      }


      const querySnapshot = getDocs(baseQuery);

      querySnapshot
        .then((snapshot) => {
          const listOfCategoriesFromFirestore: ProductCategory[] = [];
          snapshot.forEach((doc: any) => {
            const dataWithUid = { ...doc.data(), uid: doc.id };
            listOfCategoriesFromFirestore.push(dataWithUid);
          });
          setCategories(listOfCategoriesFromFirestore);
        })
        .catch((error) => {
          setError(error);
          console.error("error hook get categories", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories.length, collectionPath,  orderFilter]); // filters,
  return { categories, loading, error };
};

export default useCategories;