export function getLocalISO8601() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Mes en formato MM
    const day = String(now.getDate()).padStart(2, '0'); // Día en formato DD
    const hours = String(now.getHours()).padStart(2, '0'); // Hora en formato HH
    const minutes = String(now.getMinutes()).padStart(2, '0'); // Minutos en formato mm
    const seconds = String(now.getSeconds()).padStart(2, '0'); // Segundos en formato ss

    // Obtener el offset de la zona horaria
    const timezoneOffset = -now.getTimezoneOffset();
    const diffHours = Math.floor(timezoneOffset / 60);
    const diffMinutes = timezoneOffset % 60;

    // Formatear la zona horaria
    const timezoneFormatted =
        (diffHours >= 0 ? '+' : '-') +
        String(Math.abs(diffHours)).padStart(2, '0') +
        ':' +
        String(Math.abs(diffMinutes)).padStart(2, '0');

    // Combinar los componentes para formar la fecha ISO local
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneFormatted}`;
}
