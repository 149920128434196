import React, { useState, useRef, useEffect} from 'react';
import { TextField } from '@mui/material';
import styled from 'styled-components';
// import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledTextArea = styled(TextField)`
  width: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  & textarea {
    font-family: 'Montserrat', sans-serif;
  }

  & textarea::-webkit-outer-spin-button,
  & textarea::-webkit-inner-spin-button {
    display: none;
  }
  & .MuiInputBase-root {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  & .MuiFormHelperText-root {
    font-size: 11px;
    margin-left: 25px;
    color: #ff0000;
  }
`;

const labelProps = {
  style: {
    color: '#797A7C',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
  },
};

interface ITextAreaProps {
  value?: string | undefined;
  placeholder?: string | undefined;
  name?: string;
  label: string;
  error?: boolean | undefined;
  helperText?: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean | undefined;
  maxLength?: number | undefined;
}

const TextArea = (props: ITextAreaProps) => {
  const {
    name,
    label,
    error,
    helperText,
    onChange,
    onBlur,
    value,
    className,
    placeholder,
    style,
    disabled,
    maxLength,
  } = props;

  const textAreaRef: any = useRef(null);

  const [rows, setRows] = useState(4);

  useEffect(() => {
    if (textAreaRef.current) {
        // calcula el tamaño del texto y si tiene y pon una nueva fila por cada 100 caracteres
      const totalTextSize = value?.split('').length || 0;
      const rows = Math.floor(totalTextSize / 100) + 4;
      setRows(rows);
    }
  }, [value]); 
  
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (maxLength && inputValue.length > maxLength) {

      event.target.value = inputValue.slice(0, maxLength);
    }

    if (onChange) {
      onChange(event);
    }

    if (textAreaRef.current) {
      const totalTextSize = event.target.value.length;
      const newRows = Math.floor(totalTextSize / 100) + 4;
      setRows(newRows);
    }
  };

  return (
    <StyledTextArea
      style={{
        width: style?.width || '100%',
        maxWidth: style?.maxWidth || '100%',
        height: style?.height || 'auto',
        backgroundColor: disabled ? '#F3F3F3' : 'transparent',
        border: disabled
          ? 'border: 1px solid rgba(145, 158, 171, 0.2)'
          : '0.1px solid rgba(251, 251, 251, 0.2)',
        ...style,
      }}
      onBlur={onBlur}
      className={className}
      value={value}
      type='textarea'
      name={name}
      label={label}
      error={error}
      maxLength={maxLength}
      helperText={helperText}
      ref={textAreaRef}
      onChange={handleTextChange}
      placeholder={placeholder && placeholder}
      InputProps={{
        // disableUnderline: true,
      }}
      InputLabelProps={{ ...labelProps }}
      disabled={disabled}
      multiline={true}
      rows={rows}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
          '& fieldset': {
            borderRadius: '10px',
            border: '2px solid #3A36DB'
          },
        },
      }}
    />
  );
};

export default TextArea;
