import styled from 'styled-components'
import { THEME } from 'constants/theme/theme'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${props => props.carousel !== true ? "auto" : "420px"};
    margin: ${props => props.carousel !== true ? "10px 6px 0px 0px" : "10px 6px 20px 0px;"};
    background-color: #fff;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #CDD0D4;

    @media (max-width: 768px) {
        height: ${props => props.carousel !== true ? "auto" : "420px"};
    }

    &:hover {
        transform: scale(1.02);
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 10px 5px 10px;
    
    @media (max-width: 768px) {
        height: ${props => props.carousel !== true ? "100%" : "380px"};
        justify-content: flex-start;
        padding: 0; 
    }
`;

export const ContainerImage = styled.div`
    width: 100%;
    border-radius: 14px;
    height: ${props => props.isExtraCard !== true ? "290px" : "190px"};
    @media (max-width: 1140px) {
        height: ${props => props.isExtraCard !== true ? "200xp" : "200px"};
    }

    @media (max-width: 640px) {
        height: ${props => props.isExtraCard !== true ? "280px" : "180px"};
    }

`;

export const Image = styled.img`
    width: 100%;
    border-radius: 10px;
    height: ${props => props.isExtraCard !== true ? "290px" : "180px"};
    object-fit: cover;
    object-position: 30% 70%;
    @media (max-width: 1140px) {
        height: ${props => props.isExtraCard !== true ? "290px" : "200px"};
    }

    @media (max-width: 768px) {
        height: ${props => props.isExtraCard !== true ? "280px" : "180px"};
    }
    
`;

export const YachtInfoContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: .5rem;
    justify-content: space-between;
    @media (max-width: 992px) {
        height: 40%;
    }

    @media (max-width: 768px) {
        justify-content: flex-start;
        flex: 1;
        padding: 0 10px .5rem 10px;
    }
`;

export const Title = styled.h3`
    font-size: 16px;
    font-weight: 600;
    color: #0C121D;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (max-width: 768px) {
        margin: 0;
        padding: 0;
        font-size: 14px;
        /* height: 28%; */
    }

    @media (max-width: 420px) {
        font-size: 12px;
    }
`;

export const ContainerItemFeature = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
`;

export const ItemFeature = styled.div`
    display: flex;
    height: 30px;
    flex-direction: row;
    width: ${props => props.isExtraCard || "32%"};
    border: 1px solid #406385;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 0px 6px;
`;

export const ContainerItemFeatureText = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const QuantityItemFeature = styled.p`
    font-size: 14px;    
    line-height: 14px;
    font-weight: 700;
    color: #406385;
    margin: 0;
    text-align: center;
`;

export const TextItemFeature = styled.p`
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    color: #406385;
    margin: 0;

    @media (max-width: 520px) {
        font-size: 9px;
        line-height: 9px;
    }
`;

export const InfoContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 8px;

    @media (max-width: 768px) {
        justify-content: flex-end;
        padding: 0; 
    }
`;

export const PriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 8px 0;
    align-items: flex-end;
`;

export const TextFrom = styled.p`
    font-size: 14px;
    font-weight: 700;
    color: ${THEME.colors.black80};
    margin: 0 10px 3px 0;
    text-transform: uppercase;
    text-decoration: underline;
`;

export const Price = styled.p`
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    color: #038A25;

    @media (max-width: 768px) {
        font-size: 1.3rem;
    }

`;

export const Description = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #0C121D;       
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 6px;
    margin-bottom: 6px;
    @media (max-width: 768px) {
        & p {
            margin: 0px;
        }

    }
`;

export const LinkButton = styled.a`
  font-family:Montserrat, monospace;
  display: inline-block;
  color: ${(props) => props.theme.colors.primary80};
  font-weight: 700;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  align-self: center;
  background-color: ${THEME.colors.blueLight};
  padding:  ${(props) => props.padding || '0.2rem .5rem'};
  margin: .5rem 0;

  @media (max-width: 1080px) {
    font-size: 0.7rem;
  }
  @media (max-width: 620px) {
    margin-top: -1rem;
    font-weight: 700;
    font-size: 0.9rem;
  }
`
