

import Modal from 'ui/Modal';
import { StrokedButton, Button } from 'ui/Buttons/';
import { useTranslation } from 'react-i18next';

const ModalDeleteConfirm = ({ isOpen, onClose, onDelete }) => {
    const { t } = useTranslation();

    const handleDelete = () => {
        onDelete();
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={t('delete_product') as string}
        >
            <p>{t('confirm_delete_product_from_cart')}</p>
            <div style={{ width: '100%', display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
                <StrokedButton onClick={onClose}>{t('cancel')}</StrokedButton>
                <Button
                    onClick={handleDelete}
                    backgroundColor='#FF0000'
                >{t('delete')}</Button>
            </div>
        </Modal>
    );
};

export default ModalDeleteConfirm;