import styled from "@emotion/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FDFDFE;
`

export const ContainerBread = styled.div`
  width: 100%;
  padding: 20px 20px 600px 20px;
  margin-bottom: 4rem;

  @media (max-width: 992px) {
    padding: 20px 0px 40px 0px;
    margin-bottom: 0rem;
  }

  @media (max-width: 768px) {
    padding: 20px 0px 20px 0px;
    margin-bottom: 0rem;
  }
`

export const ContainerExtras = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #E7EDF5;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: -650px;
  background-color: #FDFDFE;
  align-self: center;
  border-radius: 16px;
  padding: 3% 5%;
  min-height: 80vh;
  
  @media (max-width: 992px) {
    margin-top: -28px;
    border-radius: 25px;
    width: 100%;
    padding: 5%;
  }

  @media (max-width: 600px) {
    margin-top: -30px;
    border-radius: 25px;
    width: 100%;
  }
`

export const ContentExtras = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 3% 5%;
  
  @media (max-width: 992px) {
    width: 100%;
    padding: 5%;
  }
`

export const TopContainer = styled.div`
  display: block;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  gap: 1rem;

  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

export const ImagesContainer = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media only screen and (max-width: 992px) {
    img {
        border-radius: 25px;
    }
  }
`

export const ItemContainer = styled.div`
  width: 100%;

  ul {
    list-style: none; /* Elimina los puntos de la lista */
  }

  li::before {
    content: "-"; /* Agrega un guion antes de cada ítem */
    margin-right: 5px; /* Espacio entre el guion y el texto */  
  }
`

export const CustomList = styled.ul`
  padding: 0; /* Elimina el padding predeterminado */

  li {
    margin-bottom: 10px;
  }
`;


export const FormWrapper = styled.div`
  width: 100%;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SelectableComponentWrapper = styled.div`
  max-width: 767px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 1200px) {
    max-width: 500px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SelectableButton = styled.div<{ selected: boolean }>`
  width: 100%;
  display: inline-block;
  background-color: ${({ selected }) => (selected ? '#88d5ff' : '#bdefff')};
  color: black;
  font-weight: 600;
  text-align: center;
  padding: 15px 5%;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade un box-shadow para dar efecto de profundidad */
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#88d5ff' : '#bdefff')};
  }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const SelectableDivContainer = styled.div`
  max-width: 410px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

export  const SelectableDiv = styled.div<{ selected: boolean }>`
  width: 100%;
  max-width: 126px;
  height: auto;
  padding: 12%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade un box-shadow para dar efecto de profundidad */
  text-align: center;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#f48479' : '#FDC2BC')};
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#f48479' : '#FDC2BC')};
  }

  img {
    display: block;
    width: 100%;
    max-width: 40px;
    margin: 0 auto 10px auto;
  }
`;

export const Select = styled.select`
  width: 100%;
  max-width: 410px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 14px;
`;

export const Span = styled.span`
  display: block;
  color: red;
`;

export const H2 = styled.h2`
  color: #406385;
  margin-bottom: 20px;
`;

export const H3 = styled.h3`
  color: #406385;
  margin-bottom: 20px;
`;

export const ContactButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export const FormButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;

  @media (max-width: 550px) {
    justify-content: center;
  }
`;