import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const ContainerBar = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -1.4rem;
`

export const LoginContainer= styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const LoginWrap = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
`


export const LoginImgWrap = styled.div`
  width: 50%;
  max-height: 100vh;


  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: -280px;
  }
  @media (max-width: 768px) {
    margin-top: -64px;
    height: auto;
    margin-bottom: -80px;
    max-height: 60vh;
  }
`

export const LoginImg = styled.img`
  width: 100%;
  height: 100%;
  min-height: 300px;
  object-fit: cover;
`
export const LoginForm = styled.form`
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 1rem;
  box-shadow: 0 8px 20px 5px rgba(2, 64, 154, 0.4);
  width: 40%;
  background: white;
  border-radius: 2rem;

  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 2rem 2rem 0 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0;
    border-radius: 2rem 2rem 0 0;
  }
  @media (max-width: 640px) {
    min-height: 740px;
  }

  @media (max-width: 400px) {
    min-height: 800px;
  }
`

interface IInputWrap {
  zIndex?: number
}

export const InputWrap = styled.div<IInputWrap>`
  position: relative;
  width: 100%;
  z-index: ${props => props.zIndex || 1};
  margin-bottom: 10px;
`
export const FormTitle = styled.span`
  ${props => props.theme.typography.heading.sm.bold};
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    ${props => props.theme.typography.subheading.lg.bold};
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
`
export const SocialsHolder = styled.div`
  display: flex;
  justify-content: center;
`
export const SocialImage = styled.img`
    padding: 0 1rem;
`
export const FormSubtitle = styled.span`
  ${props => props.theme.typography.subheading.sm.semibold};
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    ${props => props.theme.typography.subheading.xs.semibold};
  }
`


export const TransButton = styled.button`
  display: block;
  margin: 0 auto 1rem auto;
  background: ${props => props.color === "#ffffff" ? "#3A36DB" : "#ffffff"};
  color: ${props => props.color === "#ffffff" ? "#ffffff" : "#406385"};
  border-radius: 50px;
  padding: .6rem 1rem;
  border: 1px solid #406385;
  font-weight: 700;
  ${props => props.theme.typography.body.lg.bold};
`


export const Input = styled.input`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  ${props => props.theme.typography.body.lg.input};
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;
  &:focus {
    border-color: #3A36DB;
  }
  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const Terms = styled.div`
  display: flex;
  margin: 30px 1.5rem 0px 1.5rem;
  align-items: flex-start;
`

export const Links = styled.div `
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: .8rem;
  justify-content: space-evenly;
  margin-top: 1rem;

  @media (max-width: 480px) {
    font-size: 0.75rem;
    margin-top: 1.5rem;
  }
`

export const Link = styled.a `
  color: #3A36DB;
  font-size: 1rem;
  font-weight: 700;
  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`
export const InputForm = styled.div `
  font-size: 15px;
  line-height: 1.5;
  color: #666666;

  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 25px;
  padding: 0 20px;


  // max 480px
  @media (max-width: 480px) {
    padding: 0 10px;
  }

  outline: none;
  border: none;

  &:focus {
    border-color: transparent !important;
  }
`

export const LoginWrapButton = styled.div `
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
`

export const LoginButton = styled.button`
  outline: none !important;
  border: none;
  
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #57b846;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;

  &:hover {
    background: #333333;
    cursor: pointer;
  }
`

export const SpanMiddle = styled.span`
vertical-align: middle;
`

export const Paragraph = styled.p`
  ${props => props.theme.typography.body.md.regular};
  text-align: center;

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`

export const ErrorContainer = styled.div`
  margin: 10px 0px;
  color: red;
  font-size: 12px;
`;