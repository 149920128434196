import { httpsCallable } from "firebase/functions";
import { ServiceResponse } from "types";
import { functions } from "config/firebase";
import { IEditClassified } from "types";

const editClassifiedService = async (props: IEditClassified): Promise<ServiceResponse> => {
    const updateClassifiedService = httpsCallable(functions, "editClassified");
    try {

        const response: ServiceResponse = await updateClassifiedService(props) as ServiceResponse;
        if (response.data.status === "ERROR" || !response.data.success) {
            throw new Error(response.message);
        }

        return response.data;
    } catch (error: any) {
        return {
            status: error?.status || "ERROR",
            message: error?.message || "Error al editar el clasificado",
            code: error?.code || 500,
        };
    }
};

export default editClassifiedService;
