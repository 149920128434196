import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface TooltipBaseProps {
  children: React.ReactNode;
  title: string;
  backgroundTitle?: string;
}

const StyledTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ backgroundTitle }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundTitle || '#000',
  },
}));

export default function TooltipBase(props: TooltipBaseProps) {
  const { children, title, backgroundTitle } = props;

  return (
    <StyledTooltip title={title || ''} placement="top" backgroundTitle={backgroundTitle}>
      <IconButton
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {children}
      </IconButton>
    </StyledTooltip>
  );
}
