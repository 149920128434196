import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 40px 0px 100px 0px;
  margin-bottom: -4rem;

  @media only screen and (max-width: 768px){
    padding: 10px 20px 100px 20px;
    margin-bottom: -100px;
  }
`;

export const TextCategoriesContainer = styled.div`
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 68px;

  @media (max-width: 992px) {
    margin: 0 8px 5rem 0;
    justify-content: center;
    height: auto;
  }
`;

export const CategoriesContainer = styled.div`
  display: grid;
  gap: 1rem;
  padding: .5rem;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  @media only screen and (max-width: 600px){
    gap: .5rem;
    padding: .25rem 0 1rem 0;
  }
`

export const ItemCategory = styled.div`
  padding: 1rem;
  ${props => props.theme.typography.subheading.sm.semibold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  text-align: center;
  min-width: 0;
  width: 100%;
  height: 100%;
  // background color per props
  background-color: ${props => props.backgroundColor || "#E3F1FE"};

  border-radius: 1rem;
  box-shadow: 0 4px 8px 2px rgba(2, 64, 154, 0.16);
  transition: .3s;
  cursor: pointer;
  color: #0C121D;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.label.xs.medium};
  }
  @media only screen and (max-width: 400px){
    padding: .6rem .3rem;
  }
`

export const ImageCategory = styled.img`
  width: 35px;
  height: 35px;
  object-fit: contain;
`;


export const DesktopCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  padding: 0 2rem;
`;
