export type Image = {
    imageDesktop: string;
    imageMobile: string;
}  

export  const MOCK_ADDS_IMAGES = [
    "https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fbanner%20iptv.png?alt=media&token=5a5707f9-2656-46e2-aa71-f64e6a5be935",
    // "https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/countries%2FPANAMA%2Fbanners%2Fhome%2Fpublicidad%2Fbanners%20ads-20.png?alt=media&token=ca4addc4-7189-4e6c-9b0c-b64b614eab36",
    // "https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/countries%2FPANAMA%2Fbanners%2Fhome%2Fpublicidad%2Fbanners%20ads-21.png?alt=media&token=4934a4ba-76c9-41f3-8104-409e7d3d5509",
    // "https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/countries%2FPANAMA%2Fbanners%2Fhome%2Fpublicidad%2Fbanners%20ads-22.png?alt=media&token=cc866ef6-ca87-486b-bcdb-5c4e5e34b81e",
];

export const imageBannerBlogMobileHandyman = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fparte-trabajador-construccion-masculino.webp?alt=media&token=e0d75dbc-fc04-407a-8b6b-e6f2c220d948',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fparte-trabajador-construccion-masculino.webp?alt=media&token=e0d75dbc-fc04-407a-8b6b-e6f2c220d948',
  };


export  const imageBannerBlogDesktopHandyman = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FPonemos%20profesionales%20a%20tu%20disposicio%CC%81n%20para%20cualquier%20reparacio%CC%81n%20o%20mantenimiento%20en%20tu%20hogar%20o%20empresas_png.webp?alt=media&token=7e92b052-6277-4eae-bf6b-7c35b968cb26',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FPonemos%20profesionales%20a%20tu%20disposicio%CC%81n%20para%20cualquier%20reparacio%CC%81n%20o%20mantenimiento%20en%20tu%20hogar%20o%20empresas_png.webp?alt=media&token=7e92b052-6277-4eae-bf6b-7c35b968cb26',
  };

  export const imageBannerBlogMobileRealstate = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FAlquiler%20venta%20y%20compra%20de%20propiedades%20en%20panama.webp?alt=media&token=2ade245b-c6a0-4187-a797-25daab60bcaf',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FAlquiler%20venta%20y%20compra%20de%20propiedades%20en%20panama.webp?alt=media&token=2ade245b-c6a0-4187-a797-25daab60bcaf',
  };


export  const imageBannerBlogDesktopRealstate = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FSi%20esta%CC%81s%20buscando%20comprar%2C%20vender%20o%20alquilar%20casas%2C%20apartamentos%20o%20terrenos%2C%20Encuentralo%20de%20forma%20personalizada_png.webp?alt=media&token=2fa4ae3f-ea36-4f48-ab71-b88213a1aefa',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FSi%20esta%CC%81s%20buscando%20comprar%2C%20vender%20o%20alquilar%20casas%2C%20apartamentos%20o%20terrenos%2C%20Encuentralo%20de%20forma%20personalizada_png.webp?alt=media&token=2fa4ae3f-ea36-4f48-ab71-b88213a1aefa',
  };

  export const imageBannerBlogMobileYatch = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fyates-lujo-puerto-noche.webp?alt=media&token=7a7c4d64-4654-4daf-bfeb-3ae68a86f2c7',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fyates-lujo-puerto-noche.webp?alt=media&token=7a7c4d64-4654-4daf-bfeb-3ae68a86f2c7',
  };


export  const imageBannerBlogDesktopYatch = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Falquila%20tu%20yate%20privado%20con%20nosotros%20para%20todo%20tipo%20de%20eventos%20.webp?alt=media&token=6ba760d5-1671-4f71-a33c-b078cee980ff',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Falquila%20tu%20yate%20privado%20con%20nosotros%20para%20todo%20tipo%20de%20eventos%20.webp?alt=media&token=6ba760d5-1671-4f71-a33c-b078cee980ff',
  }

  export const imageBannerBlogMobilePrefabHouse = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fimagen%20casa%20autosuficiente.webp?alt=media&token=ab7b1b81-cb5d-4877-b987-407ddd9c5950',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fimagen%20casa%20autosuficiente.webp?alt=media&token=ab7b1b81-cb5d-4877-b987-407ddd9c5950',
  };


export  const imageBannerBlogDesktopPrefabHouse = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FSi%20esta%CC%81s%20buscando%20comprar%2C%20vender%20o%20alquilar%20casas%2C%20apartamentos%20o%20terrenos%2C%20Encuentralo%20de%20forma%20personalizada.%20%20.webp?alt=media&token=15e8431e-99b1-421a-bb1d-060e5c2ddcfb',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FSi%20esta%CC%81s%20buscando%20comprar%2C%20vender%20o%20alquilar%20casas%2C%20apartamentos%20o%20terrenos%2C%20Encuentralo%20de%20forma%20personalizada.%20%20.webp?alt=media&token=15e8431e-99b1-421a-bb1d-060e5c2ddcfb',
  }

  export const imageBannerBlogMobileGanaDinero = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fgana%20dinero%20y%20los%20mejores%20beneficios%20con%20quenecesitas7.webp?alt=media&token=4bcf5c49-37de-444f-a49b-f612e788a68c',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fgana%20dinero%20y%20los%20mejores%20beneficios%20con%20quenecesitas7.webp?alt=media&token=4bcf5c49-37de-444f-a49b-f612e788a68c',
  };


export  const imageBannerBlogDesktopGanaDinero = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fprueba2.png?alt=media&token=d0580531-8dfc-4770-a62b-cd29beb7abfb',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fprueba2.png?alt=media&token=d0580531-8dfc-4770-a62b-cd29beb7abfb',
  }

  export const imageBannerBlogMobileIptv = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FTelevision%20por%20internet%20sin%20interrupciones.webp?alt=media&token=37e335f0-d1b8-4af2-a75f-17d20bdfb152',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FTelevision%20por%20internet%20sin%20interrupciones.webp?alt=media&token=37e335f0-d1b8-4af2-a75f-17d20bdfb152',
  };


export  const imageBannerBlogDesktopIptv = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fdisfruta%20del%20mejor%20servicio%20de%20television%20por%20internet%20iptv%20SMARTER.webp?alt=media&token=3d33c0e5-3efd-4ce7-bb5d-e5b012a74a2c',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fdisfruta%20del%20mejor%20servicio%20de%20television%20por%20internet%20iptv%20SMARTER.webp?alt=media&token=3d33c0e5-3efd-4ce7-bb5d-e5b012a74a2c',
  };

  export const imageBannerBlogMobileStarlink = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fstarlink.webp?alt=media&token=e0d296cc-2644-42a7-99fb-db1c5168a9cf',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fstarlink.webp?alt=media&token=e0d296cc-2644-42a7-99fb-db1c5168a9cf',
  };


export  const imageBannerBlogDesktopStarlink = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fstarlink%20internet%20satelital%20en%20panama%CC%81.webp?alt=media&token=e26a3894-bb85-40df-82f1-a3fffa464b59',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fstarlink%20internet%20satelital%20en%20panama%CC%81.webp?alt=media&token=e26a3894-bb85-40df-82f1-a3fffa464b59',
  };

  export const imageBannerBlogMobileBuyFromChina = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FFABRICAS%20CHINAS.webp?alt=media&token=eca7905c-e7fb-4e40-b309-47be29810dfc',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FFABRICAS%20CHINAS.webp?alt=media&token=eca7905c-e7fb-4e40-b309-47be29810dfc',
  };


export  const imageBannerBlogDesktopBuyFromChina = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fprueba3.png?alt=media&token=95286714-92ef-4fea-9fb7-9b2dcbf4da2a',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fprueba3.png?alt=media&token=95286714-92ef-4fea-9fb7-9b2dcbf4da2a',
  };

  export const imageBannerBlogMobileBannerSolarPanel = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FENERGIA%20RENOVABLE%20PANELES%20SOLARES.webp?alt=media&token=51c35795-69ec-4afd-88b5-3b8e75962da1',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FENERGIA%20RENOVABLE%20PANELES%20SOLARES.webp?alt=media&token=51c35795-69ec-4afd-88b5-3b8e75962da1',
  };


export  const imageBannerBlogDesktopBannerSolarPanel = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FAhorra%20en%20consumo%20electrico%20y%20utiliza%20energia%20renovable%20KITS%20paneles%20solares.webp?alt=media&token=a2514da7-e9bb-43c2-8efa-f1c319b155b8',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FAhorra%20en%20consumo%20electrico%20y%20utiliza%20energia%20renovable%20KITS%20paneles%20solares.webp?alt=media&token=a2514da7-e9bb-43c2-8efa-f1c319b155b8',
  };

  export const imageBannerBlogMobileTrapeador = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FTrapeador%20super%20absorvente%20disponibles%20en%20panama.webp?alt=media&token=a28de9b7-de5b-4540-9559-c38f18dbf0a2',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FTrapeador%20super%20absorvente%20disponibles%20en%20panama.webp?alt=media&token=a28de9b7-de5b-4540-9559-c38f18dbf0a2',
  };


export  const imageBannerBlogDesktopTrapeador = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FTrapeador%20absorvente%20.webp?alt=media&token=dd65c515-c8a8-472c-a0fd-ada153ec4715',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FTrapeador%20absorvente%20.webp?alt=media&token=dd65c515-c8a8-472c-a0fd-ada153ec4715',
  };

  export const imageBannerBlogMobileAuditoriaFinanciera = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FAuditorias%20financiera%20para%20empresas%20en%20panama.webp?alt=media&token=2adcc1f5-b54f-4e9d-b35a-5e66efcc79ea',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FAuditorias%20financiera%20para%20empresas%20en%20panama.webp?alt=media&token=2adcc1f5-b54f-4e9d-b35a-5e66efcc79ea',
  };


export  const imageBannerBlogDesktopAuditoriaFinanciera = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fauditoria%20financiera%20para%20empresas%20en%20panama.webp?alt=media&token=26cc7721-ae50-47d4-bec6-43f8e576def8',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fauditoria%20financiera%20para%20empresas%20en%20panama.webp?alt=media&token=26cc7721-ae50-47d4-bec6-43f8e576def8',
  };

  export const imageBannerBlogMobileIslaContadora = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FTour%20isla%20contadora%20archipielago%20de%20las%20perlas%20panama.webp?alt=media&token=9695fd15-f885-44a6-b0cc-3bafe301cc73',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FTour%20isla%20contadora%20archipielago%20de%20las%20perlas%20panama.webp?alt=media&token=9695fd15-f885-44a6-b0cc-3bafe301cc73',
  };


export  const imageBannerBlogDesktopIslaContadora = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FTours%20disponible%20en%20panama%20isla%20contadora.webp?alt=media&token=dd07942a-4529-45aa-a2a9-0b71bc73e6ae',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FTours%20disponible%20en%20panama%20isla%20contadora.webp?alt=media&token=dd07942a-4529-45aa-a2a9-0b71bc73e6ae',
  };

  export const imageBannerBlogMobileMarketing = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FConsultoria%20de%20marketing%20para%20empresas%20en%20panama%CC%81.webp?alt=media&token=f622bcbb-8db6-4176-9f70-4b4e018d77e6',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FConsultoria%20de%20marketing%20para%20empresas%20en%20panama%CC%81.webp?alt=media&token=f622bcbb-8db6-4176-9f70-4b4e018d77e6',
  };


export  const imageBannerBlogDesktopMarketing = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FAsesoria%20de%20marketing%20digital%2C%20estrategias%20de%20marketing%20en%20panama%CC%81.webp?alt=media&token=1e4f1943-1e72-41e0-8f2f-66fa7490e61e',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FAsesoria%20de%20marketing%20digital%2C%20estrategias%20de%20marketing%20en%20panama%CC%81.webp?alt=media&token=1e4f1943-1e72-41e0-8f2f-66fa7490e61e',
  };

  export const imageBannerBlogMobileProductosPromocionales = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FProductos%20e%20insumos%20promocionales%20para%20empresas%20y%20negocios.webp?alt=media&token=1f810cf7-6e32-43bd-8283-a3ffec66863a',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2FProductos%20Promocionales%20para%20empresas%20grandes%2C%20todo%20tipo%20de%20productos%20pra%20grandes%20marcas.webp?alt=media&token=946e729c-d7ab-4c03-9aa5-bb26d6023e03',
  };


export  const imageBannerBlogDesktopProductosPromocionales = {
    en: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FPromotional%20Products%20for%20companies%20.jpg?alt=media&token=b30d459f-c80e-4530-b4c9-dee31ca29409',
    es: 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FHome%2FAds%2Fproductos%20promocionales%20para%20empresas%20y%20negocios-07.png?alt=media&token=bb3b0ced-29e9-4c37-a36c-0a050102ff8e',
  };


  