/* eslint-disable @typescript-eslint/no-unused-vars */
import {SagaIterator} from '@redux-saga/core'
import {all, put, takeLatest} from 'redux-saga/effects'
import {authSagasTrigger, setAuth, setError, setLoading} from 'redux/auth/reducer'

function* authSaga(action: any) { 
  try {
      yield put(setLoading(true))
      yield put(setAuth({isAuth: action?.payload?.isAuth }))
      yield put(setLoading(false))
     
  } catch (err: any) { 
    console.error('authSaga error', err)
    yield put(setError(err.message))
  } finally {
    yield put(setLoading(false))
  }
}

export function* authSagas(): SagaIterator {
  yield all([takeLatest(authSagasTrigger.trigger, authSaga)])
}
