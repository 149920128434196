import styled from 'styled-components';
import {THEME} from 'constants/theme/theme';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 1rem;
    min-height: 100vh;
`;

export const SubContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 2rem;
    height: 100%;
    margin-top: 2rem;

    @media (max-width: 992px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 500px;
    background-color: white;
    border: 1px solid ${THEME.colors.classifieds};
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 1px 1px 1px ${THEME.colors.classifieds};

    @media (max-width: 992px) {
        width: 100%;
        height: 100px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
`;

export const ChatListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
`;