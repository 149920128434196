import  {useEffect } from 'react';

const apiKey = process.env.REACT_APP_WDYN_GOOGLE_MAPS_API_KEY

const MapPlaces = () => {
  useEffect(() => {
    // Crear un nuevo elemento de script
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;

    // Añadir el script al DOM
    document.body.appendChild(script);

    // Opcional: Limpieza al desmontar el componente
    return () => {
      document.body.removeChild(script);
    };
  }, []); 
  return null; // Este componente no renderiza nada
};

export default MapPlaces;
