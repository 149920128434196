import styled, { keyframes } from 'styled-components';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {THEME} from 'constants/theme/theme'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const SubContainer = styled.div`
  clear: both;
  width: 100%;
  background: ${props => props.background || "#ffffff"};
  justify-content: space-between;
  padding: 2rem 2rem 0 2rem;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    padding: 1rem 1rem 0 1rem;
    margin-bottom: -1rem;
    
  }
  @media only screen and (max-width: 768px){
    padding: 1rem;
  }
`

export const WrapperGrid = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Grid = styled.div`
  width: 100%;
  display: grid;
  /* margin: 0 2rem; */
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  justify-items: space-between;
  @media only screen and (max-width: 1250px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 992px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 350px){
    grid-template-columns: repeat(1, 1fr);
    gap: .5rem;
  }
`

export const GridItem = styled.div`
  background: ${({ isSelected }) => (isSelected ? '#028a27' : '#ebebfe')};
  cursor: pointer;
  width: 100%;
  height: 90px;
  padding: 5%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem !important;
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#52545b')} !important;
  @media only screen and (max-width: 1500px) {
    font-size: 0.9rem !important;
  }
  @media only screen and (max-width: 992px) {
    font-size: 1rem !important;
  }

  &:hover {
    background: ${THEME.colors.positive60};
    color: #ffffff !important;
    transform: scale(1.03);
  }

  ${props => props.theme.typography.subheading.md.bold};
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 1rem 0;
  }
  @media only screen and (max-width: 500px) {
    font-size: .6rem!important;
  }
  @media only screen and (max-width: 390px) {
    font-size: .5rem!important;
  }

  @media only screen and (max-width: 340px) {
    font-size: .4rem!important;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 768px){
    padding: 1rem;
  }
`


export const Title = styled.p`
  font-family: ${props => props.family};
  color: ${props => props.color};
  font-weight: ${props => props.weight};
  font-size: 2.2rem;
  line-height: 2.8rem;
  @media only screen and (max-width: 1080px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 2.0rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  @media only screen and (max-width: 400px) {
    width: 120%;
  }
`


export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
  height: 100%;
  justify-content: flex-start;
  @media only screen and (max-width: 600px) {
    width: 60%;
    float: left;
  }

  @media only screen and (max-width: 500px) {
    width: 70%;
  }
`

export const Description = styled.p`
  font-size: 1.2rem;
  text-align: left;
  margin-top: 2rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    margin-top: 0rem;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: .8rem;
    line-height: 1rem;
  }
`

export const LabelArrow = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 2rem;

  @media only screen and (max-width: 992px) {
    margin-bottom: 0rem;
  }
`

const slideAnimation = keyframes`
  0%, 100% { transform: translateX(0); }
  50% { transform: translateX(20px); } // ajusta esto según la distancia deseada
`;

// Crear un componente de Styled para el icono
export const AnimatedIcon = styled(ArrowRightAltIcon)`
  animation: ${slideAnimation} 2s infinite ease-in-out;
  font-size: 6rem;
  color: ${THEME.colors.primary};
`;

export const ContainerArrowRight = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: 992px) {
    justify-content: center;
    display: none;
  }
`



export const ImageContainer = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;


  @media only screen and (max-width: 992px) {
    width: 40%;
    font-size: .6rem;
    float: right;
    margin-bottom: -36px;
  }

  @media only screen and (max-width: 768px) {
    width: 60%;
  }

  @media only screen and (max-width: 560px) {
    width: 60%;
  }
`

export const Image = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  float: right;

  @media only screen and (max-width: 992px) {
    max-height: 200px;
  }
`


export const Link = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 600px) {

  }
`

export const InputLabel = styled.label`
  position: absolute;
  width: 80%;
  height: 1.5rem;
  text-align: left;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: #000000;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #FFFFFF;
  font-weight: 600;
  @media only screen and (max-width: 600px){
    font-size: .8rem;
    top: 1.2rem;
  }
  
`

export const InputWrap = styled.div`
  position: relative;
  width: ${props => props.size || "80%"};
  z-index: 1;
  margin: 1rem auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`


export const TextArea = styled.textarea`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  background-color: #FFFFFF;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const OptionTitle = styled.p`
  margin-top: 1rem;
  text-align: center;
  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 600px){
    ${props => props.theme.typography.subheading.md.bold};
    margin: 0;
    padding: 0.5rem 0;
    font-size: 1.2rem;
  }
`


export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  appearance: none;
  background: url('https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Farrowdown.webp?alt=media&token=7dd41691-6faf-42d6-a14b-59247ee56365') no-repeat 98%;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: 1rem;
  height: 3.5rem;
  @media only screen and (max-width: 600px) {
    font-size: .8rem;
  }

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown) &:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`


export const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  /* padding: 2rem 2rem 0 2rem; */
  justify-content: space-between;
  /* align-items: ${props => props.align || "stretch"}; */
  margin-top: 2rem;

  background: ${props => props.background || "#ffffff"};
  text-align: center;
  @media only screen and (max-width: 992px) {
    border-radius: 0;
  }

`


export const Titles = styled.h2`
  text-align: ${props => props.align || "left"};
  font-family: Outfit, monospace;
  font-weight: ${props => props.weight || "700"};
  font-size: ${props => props.size || "2.5rem"};
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.subheading.sm.bold};
    text-align: center;
    width: 100%;
  }
`

export const SponsorBuy = styled.div`
  ${props => props.theme.typography.body.md.bold};
  display: flex;
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
`
export const Video = styled.video`
  width: 100%;
  height: 15rem;
  object-fit: cover;
`


export const SmallSection = styled.div`
  clear: both;
  background: #ffffff;
  margin: 0 4rem;
  justify-content: space-between;
  padding: ${props => props.pav} ${props => props.pah};
  @media only screen and (max-width: 992px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

export const BSpan = styled.span`
  color: #08A791;
`

export const Browser = styled.div`
  background: #F4F5F7;
  border-radius: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 1rem auto;
  justify-content: space-between;
  @media only screen and (max-width: 992px) {
    width: 95%;
  }
`

export const BrowserBody = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    width: 35%;
    padding: 1rem;
  }
`
export const BrowserImg = styled.img`
  width: 15%;
  height: 100%;
  object-fit: contain;
  float: right;
  border-radius: 2rem;
  @media only screen and (max-width: 992px) {
    width: 40%;
  }
`
export const BText = styled.h2`
  ${props => props.theme.typography.heading.md};
  @media only screen and (max-width: 1200px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }

  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.heading.md};
  }
  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }

  @media only screen and (max-width: 600px) {

  }
`

// export const TransButton = styled.button`
//   display: block;
//   width: 40%;
//   margin: 0 auto;
//   background: #3A36DB;
//   color: #F5F5F6;
//   border-radius: 50px;
//   padding: 1rem 0;
//   border: 1px solid #406385;
//   ${props => props.theme.typography.body.lg.bold};

//   @media only screen and (max-width: 1200px){
//     width: 50%;
//   }
//   @media only screen and (max-width: 768px){
//     ${props => props.theme.typography.body.md.bold};
//     width: 100%;
//     padding: .5rem 0;
//   }
// `



export const FlexRow = styled.div`
  background: white;
  margin-left: -4.5rem;
  width: 115%;
  
  
  @media only screen and (max-width: 992px) {
    gap:0;
    width: 108%;
    margin-left: -1rem;
  }
`


export const ImgNextButton = styled.div`
  width: 60%;
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  @media only screen and (max-width: 992px) {
    width: 95%;
    gap: 1rem;
  }
`


export const Options = styled.div`
  display: flex;
  color: #ffffff;
  
  background: #ffffff;
  border-radius: 1rem;
  height: ${props => props.sizeV || "100%"};
  width: ${props => props.size || "80%"};
  margin-bottom: 0;
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
  }
`
export const Option = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  ${props => props.theme.typography.body.lg.medium};
  padding: 2rem 1rem;
  justify-content: ${props => props.justify || "space-between"};
  align-items: ${props => props.align || "stretch"};
  transform: translateX(-1rem);
  margin-right: -1rem;
  width: ${props => props.size || "152%"};
  background: #08A791;
  text-align: left;
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.md.regular};
  }
`
export const ProductImg = styled.img`
  width: 25%;
  @media only screen and (max-width: 768px){
    width: 50%;
  }
`
export const Message = styled.p`
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.sm.bold};
  }
`
