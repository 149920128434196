import styled from 'styled-components'

export const Container = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    background-color: #FDFDFE;
    padding: 1rem 2rem;

    @media only screen and (max-width: 992px) {
        padding: 0rem;
    }
`

export const ListProductsContainer = styled.div`
  width: 100%;
  margin: 20px 0px;
  background-color: ${props => props.color || "#F5F5F6"};
  border-radius: 16px;
  padding: .5rem 1rem;

  @media only screen and (max-width: 1200px) {
    padding: 20px;
  }
  @media only screen and (max-width: 992px) {
    border-radius: 0;
    padding: 0 .5rem;
  }
  
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;
  color:#406385;
`

export const TextFilter = styled.span`
  cursor: pointer;
  margin-right: 16px;
  ${props => props.theme.typography.subheading.md.semibold};
  @media only screen and (max-width: 768px){
    ${props => props.theme.typography.subheading.xs.semibold};
  }
`

export const SpaceBetween = styled.div`
  display: flex;
  margin: 1rem 0rem 0rem 0rem;
  justify-content: space-between;
`
export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 6fr);
    grid-gap: .5rem;
    padding: 1rem 0rem;
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 32fr);
    }
    @media only screen and (max-width: 480px) {
        grid-template-columns: repeat(2, 60fr);
    } 
`

