import styled from "@emotion/styled";

export const Container = styled.div``;

export const ContentServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 620px) {
    padding: 0 0.5rem;
  }
`;

export const ListServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: #F5F5F6; */
  border-radius: 16px;
  width: 100%;
  height: auto;
  margin: 0px 0;

  @media (max-width: 992px) {
    padding: 0 2%;
    width: 100%;
    border-radius: 0px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 992px) {
    padding: 0.5rem 0 0.3rem 1.3rem;
  }
  @media (max-width: 620px) {
    display: none;
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 992px) {
  }

  
`;

export const CarouselServicesContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0 0 0;
  overflow: visible;

  @media (max-width: 992px) {
    gap: .5rem;
    width: 100%;
    margin-bottom: -1rem;
  }
`;
