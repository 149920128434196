import styled from 'styled-components';
import { THEME } from 'constants/theme/theme';


export const PriceFromText = styled.div`
    padding: 0.25rem 0 0rem 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${THEME.colors.black80};
    margin-right: -.5rem;
`;