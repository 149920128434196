import { httpsCallable } from "firebase/functions";
import { ServiceResponse } from "types";
import { functions } from "config/firebase";
import { IInitClassifiedChat } from "types";


const initClassifiedChatService = async (props: IInitClassifiedChat): Promise<ServiceResponse> => {
    const initChatFunction = httpsCallable(functions, "initClassifiedChat");

    try {
        const response: any = await initChatFunction(props);
        
        if (response.data.status === "ERROR" || !response.data.success) {
            throw new Error(response.data.message);
        }

        return {
            status: "OK",
            message: response.data.message,
            success: response.data.success,
            data: response.data.data,
            isExist: response.data.isExist || false,
        };
    } catch (error: any) {
        return {
            status: error?.status || "ERROR",
            message: error?.message || "Error al inicializar el chat clasificado",
            code: error?.code || 500,
        };
    }
};

export default initClassifiedChatService;
