import { Container, Image, LoadingText } from './styled';
import { useTranslation } from 'react-i18next';

const loader = require('assets/loader.gif');


interface LoadingProps {
    text?: string;
}

const Loader = (props: LoadingProps) => {
    const { text } = props;
    const { t } = useTranslation();

    return (
        <Container>
            <Image src={loader} loading="lazy" alt="loader QUENECESITAS7" />
            <LoadingText color='#fff' margin='10px 0px'>{text ? text : t('loading')}</LoadingText>
        </Container>
    );
    };

export default Loader;