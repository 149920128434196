export const redirectToDetail = (item, hasRedirectToDetail, navigate, pathname) => {
  if (!hasRedirectToDetail) return;

  switch (pathname) {
    case '/services/business':
      navigate(`/services/business/detail?id=${item.uid}&category=business`);
      break;
    case '/services/real-state':
      navigate(`/services/technical-service/detail?id=${item.uid}&category=TechnicalServices`);
      break;
    default:
      console.error('Categoría desconocida');
  }
};

