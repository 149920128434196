import Carousel from 'react-material-ui-carousel';
import { Container, Image, ItemContainer } from './styled';
import { BREAKPOINTS } from 'constants/index';
import Skeleton from "@mui/material/Skeleton";
import './style.css'

const ItemCarousel = ({ image, link }) => {
  return (
    <ItemContainer>
      <Image src={image} onClick={() => { window.open(link, '_blank') }} alt='Image Carousel' title='Image Carousel' />
    </ItemContainer>
  );
};

type ImageObject = {
  id: string | number;
  image?: string;
  link?: string;
};

interface ICarousel {
  images: string[] | ImageObject[];
  padding?: string;
  paddingMobile?: string;
}

// Main component
const SliderCarousel = (props: ICarousel) => {
  const { images, padding, paddingMobile } = props;

  const stylesCarousel = {
    padding: padding ? padding : "0 1rem 0.5rem 1rem",
    // en maximo de 992px padding es cero
    '@media (max-width: 992px)': {
      padding: paddingMobile ? paddingMobile : "0 0.2rem 0.5rem .2rem",
    },
  }

  return (
    <Container>
      {images.length > 0 ?
        (
          <Carousel
            indicators={false} sx={{ width: '100%', height: `${window.innerWidth < BREAKPOINTS.lg && "100%"}`, ...stylesCarousel }}>
            {
              typeof images[0] === 'object' ?
                images.map((item, index) => <ItemCarousel key={index} image={item.image} link={item.link} />)
                :
                images.map((item, index) => <ItemCarousel key={index} image={item} link={'https://api.whatsapp.com/send?phone=50764050893&text=¡Hola!%20Deseo%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos%20y%20servicios.'} />)
            }
          </Carousel>
        )
        :
        (
          <div style={{width:  window.innerWidth < 992 ? '90%' : '95%'}}>
            <Skeleton  style={{borderRadius: '16px'}} variant="rounded" width='100%' height={window.innerWidth < 992 ?  160 : 340} />
          </div>
        )
      }
    </Container>
  );
};

export default SliderCarousel; 
