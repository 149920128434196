import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./popup.css";
import { CategoriesContainer, ImageCategory, ItemCategory } from "./styled";
import { H3 } from "ui/Text";
import { getLanguage } from "redux/common";

interface PopupDesktopCategoriesProps {
  data: any[];
  position: any;
  text: string;
  type: string;
  handleType: any;
  onSelectCategory: (category: string) => void;
}

const PopupDesktopCategories: FC<PopupDesktopCategoriesProps> = ({
  data,
  position,
  text,
  type,
  handleType,
  onSelectCategory,
}) => {
  const language = useSelector(getLanguage);
  const navigate = useNavigate();
  return (
    <Popup
      trigger={
        <H3 style={{cursor: 'pointer'}} color="#FFF" family="Outfit">
          {text}
        </H3>
      }
      position={position}
    >
      <CategoriesContainer>
        {data.map((category: any, index: number) => {
          return (
            <ItemCategory
              key={index}
              onClick={() => {
                if (type === "Servicios") {
                  navigate(category.name.en.toLowerCase().replaceAll(" ", "-"));
                } else {
                  onSelectCategory(category.uid);
                  handleType(category.id);
                }
              }}
              backgroundColor={category.bgColor}
            >
              <ImageCategory
                src={category?.image || ""}
                loading="lazy"
                alt={category?.name[language] || ""}
                title={category?.name[language] || ""}
              />
              <span>{category?.name[language]}</span>
            </ItemCategory>
          );
        })}
      </CategoriesContainer>
    </Popup>
  )
};

export default PopupDesktopCategories;
