import { httpsCallable } from "firebase/functions";
import {ServiceResponse} from 'types';
import {functions} from 'config/firebase';

const resendEmailService = async (email: string): Promise<ServiceResponse> => {
  try{
    const resendEmail = httpsCallable(functions, 'resendVerificationEmail')
    const result = await resendEmail({ email })
    return { status: 'OK', data: result.data, success: true}
  }
  catch(err: any){
    return { status: 'ERROR', message: err.message, success: false }
  }

}

export default resendEmailService
