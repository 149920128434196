import { BREAKPOINTS } from "constants/index";

export const SCREEN_SIZE_BREAKPOINTS = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

export const SCREEN_SIZE_BREAKPOINT = BREAKPOINTS.lg;
