
export const DB_ROUTES = {
    OLD_CATEGORIES_PRODUCTS_STORE: '/Products/Categories',
    CATEGORIES_PRODUCTS_STORE: '/Products/Categories/Store/Categories',
    CATEGORIES_PRODUCTS_WHOLESALE: '/Products/Categories/Wholesale/Categories',
    CATEGORIES_MACHINERY: '/Products/Categories/Machinery/Categories',
    POSTS_PRODUCTS_STORE: '/Products/Categories/Store/Posts',
    POSTS_PRODUCTS_WHOLESALE: '/Products/Categories/Wholesale/Posts',
    POSTS_PRODUCTS_MACHINERY: '/Products/Categories/Machinery/Posts',
    NEEDS_CATEGORIES_COLLECTION: '/Needs/Categories',
}


export const ROUTES = { 
    HOME: '/',
    PRODUCTS: '/products',
    PRODUCT_DETAIL: '/product/detail',
    PRODUCTS_BY_CATEGORY: '/products/category',
    PRODUCTS_STORE: '/products/store',
    PRODUCTS_WHOLESALE: '/products/wholesale',
    SERVICES: '/services',
}

export const MAIN_ROUTES = {
    Home: '/',
    Products: '/products',
    ProductsStore: '/products/store/*',
    ProductsWholesale: '/products/wholesale',
    ProductsMachinery: '/products/machinery',
    ProductDetail: 'product/detail/*',
    ProductsByCategory: '/products/category/*',
    Services: '/services',
    ServiceDetail: 'service/detail/*',
    Classifieds: '/classifieds',
    ClassifiedDetail: '/classified/detail/*', 
    ClassifiedNew: '/classified/new',
    ClassifiedEdit: '/classified/edit',
    MyClassifieds: '/classifieds/my-classifieds',
    ChatClassifieds: '/classifieds/chats',
    ChatSessionClassified: '/classifieds/chats/session',
    ClassifiedsByCategory: '/classifieds/category/*',
    Offers: '/offers',
    OfferDetail: '/offer/detail/*',
    Business: 'services/business',
    Pays: '/pays',
    Cart: '/cart',
    Checkout: '/checkout',
    CheckoutSingleProduct: '/checkout-single-product',
    Profile: '/profile',
    Login: '/login',
    CreateAccount: '/create-account',
    ForgotPassword: '/forgot-password',
    Needs: '/needs',
    NeedsList: '/needs-list',
    Terms: '/terms-and-conditions',
    Politics: '/politics',
    Contact: '/contact',
    Yatchs: 'services/yatchs',
    YatchsExtra: 'detail',
    Marketing: 'services/marketing',
    SBusiness: 'services/business',
    RealState: 'services/real-state',
    Tourism: 'services/tourism',
    TechnicalService: 'services/technical-service',
    Chatbot: '/chat',
    Tools: '/tools',
    ToolsRemoveBg: '/tools/remove-bg',
    EarnMoney: '/earn-money',
    DetailEarnMoney: '/earn-money/detail',
}

export const MARKETING_ROUTES = {
    Detail: 'detail',
}
