import { CreateNeedRequestProps } from "types";
import CreateNeedRequestService from "services/needs/createNeed/create-need-service";

const useCreateNeedRequest = () => {
    const createNeedRequest = async ({userId, country,needData}: CreateNeedRequestProps ) => {
        const response = await CreateNeedRequestService({userId, country,needData})
        return response
    }
    return {
        createNeedRequest
    }
};



export {
    useCreateNeedRequest
}