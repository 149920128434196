import { useState } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { storage as firestorage } from 'config/firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import createClassifiedService from 'services/classifieds/create-classified-service/create-classified-service';
import { IClassified, ServiceResponse } from 'types';
import { ClassifiedsRoutes } from 'constants/classifieds';
import { v4 } from "uuid";
import {getCountry } from "redux/common";



const useCreateClassified = () => {
    const country = useSelector(getCountry);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState<ServiceResponse | null>(null);

    const uploadImageAndGetURL = async (image, path) => {
        const imageRef = ref(firestorage, `${path}/${image.name}`);
        await uploadBytes(imageRef, image);
        return getDownloadURL(imageRef);
    };

    const createClassified = async (props: IClassified) => {
        setIsLoading(true);
        setError(null);

        try {
            const PATH = `${country}/${ClassifiedsRoutes.Posts}/${props.userId}/${props.category}/${new Date().toISOString()}/${v4()}`
            const images: File[] = props.images as any;
            const uploadPromises = images.map(image => uploadImageAndGetURL(image, PATH));
            const listUrlsImages = await Promise.all(uploadPromises);

            const updatedProps = { ...props, images: listUrlsImages, imagesReference: PATH };;

            const result = await createClassifiedService(updatedProps);
            setResponse(result);
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return { createClassified, isLoading, error, response };
};

export default useCreateClassified;
