import React from 'react';
import { useTranslation } from "react-i18next";
import MainLayout from 'views/layout/MainLayout';


const Pays = () => {
    const { t } = useTranslation();
    
    return (
        <MainLayout type='pays'>
            <h1>{t('payments')}</h1>
        </MainLayout>
    );
};

export default Pays;