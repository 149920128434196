
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { doc, getDoc, updateDoc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore/lite';
import { firestore } from 'config/firebase';
import { getCartSagasTrigger ,addToCartSagasTrigger, updateCartItemSagasTrigger, removeFromCartSagasTrigger, cleanCartSagasTrigger ,addToCart, updateCartItem, removeFromCart, setCart, setLoading, setError, cleanCart} from '../reducer/reducer-cart';

function* getCartSaga() {
  yield put(setLoading(true));
  const user = yield select((state) => state.user.userData);

  if (user) {
    try {
      const userRef = doc(firestore, `Users/${user.uid}`);
      const cartRef = collection(userRef, 'Cart');
      const cart = yield call(getDocs, cartRef);
      const cartItems: any[] = [];
      
      cart.forEach((doc: any) => {
        cartItems.push({ id: doc.id, ...doc.data() });
      });
  
      yield put(setCart({ items: cartItems }));
  
      yield put(setLoading(false));
    } catch (error) {
      // alert(`falla al obtener el carrito,${error}`);
      yield put({ type: 'GET_CART_FAILURE', error });
      yield put(setError(error))
      yield put(setLoading(false));
    }
  }
}

function* addToCartSaga(action) {
  yield put(setLoading(true));
  const user = yield select((state) => state.user.userData);

  if (user) {
    try {
      const { id, count, name, price, image } = action.payload;
      const cartRef = doc(firestore, `Users/${user.uid}/Cart`, id.toString());
      const cart = yield call(getDoc, cartRef);
      if (cart.exists()) {
        const updatedCount =  count === 2 ? cart.data().count + count : count;
        yield call(async () => await updateDoc(cartRef, { count: updatedCount }));
        yield put(addToCart({ id, count: updatedCount, name, price, image }));
        yield put(setLoading(false));
      } else {
        yield call(async () => await setDoc(cartRef, { count, name, price, image }));
        yield put(addToCart({ id, count, name, price, image }));
        yield put(setLoading(false));
      }
    } catch (error) {
      yield put({ type: 'ADD_TO_CART_FAILURE', error });
      yield put(setError(error))
      yield put(setLoading(false));
    }
  } else {
        yield put(addToCart(action.payload));
        yield put(setLoading(false));
  } 
} 

function* updateCartItemSaga(action) {
  yield put(setLoading(true));
  const user = yield select((state) => state.user.userData);

  if (user) {
    try {
      const { id, count } = action.payload;
      const cartRef = doc(firestore, `Users/${user.uid}/Cart`, id.toString());
      yield call(async () => await updateDoc(cartRef, {count}));
  
      yield put(updateCartItem({ id, count }));
      yield put(setLoading(false));
    } catch (error) {
      yield put({ type: 'UPDATE_CART_ITEM_FAILURE', error });
      yield put(setError(error))
      yield put(setLoading(false));
    }
  } else {
    yield put(updateCartItem(action.payload));
    yield put(setLoading(false));
  }

}

function* removeFromCartSaga(action) {
  yield put(setLoading(true));
  try { 
    const user = yield select((state) => state.user.userData);
    const {id} = action.payload;
    const cartRef = doc(firestore, `Users/${user.uid}/Cart/${id}`);
    yield call(deleteDoc, cartRef);
    yield put(removeFromCart(id));
    yield put(setLoading(false));
  } catch (error) {
    yield put({ type: 'REMOVE_FROM_CART_FAILURE', error });
    yield put(setError(error))
    yield put(setLoading(false));
  }
}

function* cleanCartSaga() {
  yield put(setLoading(true));
  try {
    const user = yield select((state) => state.user.userData);
    const cartRef = collection(firestore, `Users/${user.uid}/Cart`);
    const cart = yield call(getDocs, cartRef);
    cart.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
    yield put(cleanCart());
    yield put(setLoading(false));
  } catch (error) {
    yield put({ type: 'CLEAR_CART_FAILURE', error });
    yield put(setError(error))
    yield put(setLoading(false));
  }
}



export function* cartSagas() {
  yield takeLatest(getCartSagasTrigger.trigger, getCartSaga);
  yield takeLatest(addToCartSagasTrigger.trigger, addToCartSaga);
  yield takeLatest(updateCartItemSagasTrigger.trigger, updateCartItemSaga);
  yield takeLatest(removeFromCartSagasTrigger.trigger, removeFromCartSaga);
  yield takeLatest(cleanCartSagasTrigger.trigger, cleanCartSaga);
}
