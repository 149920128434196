const SearchIcon = () => {
    return (    
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1605 17.975C13.1302 21.1856 7.54086 20.3102 4.57877 16.6557C1.72224 13.1323 1.90214 8.04426 4.99978 4.82684C8.20799 1.49453 13.2552 1.0506 16.9006 3.80286C18.9114 5.32135 20.1076 7.34683 20.4268 9.84747C20.7454 12.3375 20.0807 14.5784 18.5029 16.6257C18.587 16.6707 18.6665 16.7238 18.7402 16.7843C19.8946 17.9332 21.0475 19.0835 22.1989 20.2353C22.4725 20.5075 22.6087 20.8247 22.5275 21.2068C22.4916 21.3769 22.412 21.5348 22.2964 21.6648C22.1808 21.7947 22.0333 21.8923 21.8685 21.9478C21.7036 22.0032 21.5271 22.0148 21.3564 21.9812C21.1857 21.9476 21.0268 21.8701 20.8953 21.7562C20.7947 21.6707 20.7047 21.5727 20.611 21.479C19.5079 20.3776 18.4056 19.2758 17.3041 18.1736C17.2513 18.1111 17.2033 18.0447 17.1605 17.975ZM18.511 10.9882C18.526 7.14266 15.4196 4.02201 11.5561 4.00266C10.6386 3.99632 9.72889 4.17121 8.87919 4.51728C8.02949 4.86334 7.25655 5.37377 6.60475 6.01926C5.95295 6.66474 5.43513 7.43256 5.08102 8.27864C4.72691 9.12471 4.54349 10.0324 4.54129 10.9495C4.53652 11.8686 4.71339 12.7796 5.06174 13.6302C5.4101 14.4808 5.92308 15.2543 6.57124 15.9063C7.21941 16.5582 7.99 17.0758 8.83879 17.4293C9.68759 17.7828 10.5979 17.9653 11.5174 17.9663C15.3696 17.9769 18.4954 14.8581 18.511 10.9882Z" fill="#3A36DB"/>
        </svg>
    );
};

export default SearchIcon;


