import { PriceText } from "components/cards/services-card/styled";
import {
  Card,
  DescriptionContainer,
  Image,
  ImageContainer,
  SpanMiddle,
  Title,
  LinkButton,
  WrapperButton,
} from "./styled";
import { Multilingual } from "types";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrency, getLanguage } from "redux/common";


interface ExtraCardProps {
  data: {
    image: string;
    images?: string[];
    name: Multilingual;
    shortDescription: Multilingual;
    price?: number;
  };
  onClick?: () => void; 
  textButton?: string;
  showContentOnModal?: boolean;
}

const ExtraCard: FC<ExtraCardProps> = ({ data, onClick, textButton }) => {
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const language = useSelector(getLanguage);

  return (
    <Card>
      <ImageContainer>
        <Image src={`${data?.images ? data?.images[0] : data?.image}`} loading="lazy" alt={data?.name?.[language] || ""} title={data?.name?.[language] || ""} />
      </ImageContainer>
      <DescriptionContainer>
        <Title>{data?.name?.[language] || ""}</Title>
        {data?.price ? (
          <PriceText>{t('from')} {currency} {data?.price}</PriceText>
        ) : (
          <PriceText>{t('check_the_price')}</PriceText>
        )}
        <SpanMiddle>{data?.shortDescription?.[language] || ''}</SpanMiddle>
      </DescriptionContainer>
      <WrapperButton>
        <LinkButton onClick={onClick} size={"90%"}>
          {textButton || t('click_here')}
        </LinkButton>
      </WrapperButton>
    </Card>
  );
};

export default ExtraCard;
