import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'; // Usamos DatePicker
import { THEME } from 'constants/theme/theme';

interface IYearPickerProps {
    label: string;
    onChange: (value: string) => void;
    defaultValue?: dayjs.Dayjs | string;
    value?: dayjs.Dayjs | string;
    disableFullWidth?: boolean;
    disabled?: boolean;
}

export default function YearPicker(props: IYearPickerProps) {
    const { label, onChange, defaultValue, value, disableFullWidth, disabled } = props;

    const [selectedYear, setSelectedYear] = useState<dayjs.Dayjs | string | null>(
        defaultValue ? dayjs(defaultValue) : null
    );

    useEffect(() => {
        if (value) {
            setSelectedYear(value);
        } else if (value === '') {
            setSelectedYear(null);
        }
    }, [value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <MuiDatePicker
                views={['year']}
                openTo="year"
                label={label || 'Seleccione el año'}
                value={selectedYear ? dayjs(selectedYear) : null}
                onChange={(newValue) => {
                    const year: any = newValue ? newValue.format('YYYY') : '';
                    onChange(year);
                    setSelectedYear(newValue);
                }}
                sx={{
                    '&.MuiFormControl-root': {
                        width: disableFullWidth ? 'auto' : '100%',
                    },
                    
                    '& .MuiSvgIcon-root': {
                        color: disabled ? THEME.colors.black60 : THEME.colors.primary,
                    },

                    '& .MuiInputBase-root': {
                        borderRadius: '8px',
                        borderColor: 'green',
                        '&:hover': {
                            borderColor: THEME.colors.black100,
                        },
                        '&.Mui-focused': {
                            borderColor: THEME.colors.primary,
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderWidth: '2px',
                            borderColor: THEME.colors.primary,
                        },
                        '&:hover fieldset': {
                            borderColor: THEME.colors.black100,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: THEME.colors.primary, 
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}
