import styled from "@emotion/styled";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-height: 80vh;
    text-align: center;
`;

export const Image = styled.img`
    width: 100%;
    max-height: 300px;
    object-fit: contain;
`;