import styled from 'styled-components'

export const Content = styled.div`
  margin: -390px auto 0 auto;
  background-color: #ffffff;
  width: 95%;
  padding: 3.5% 5%;
  border-radius: 1rem;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`


export const ContentProductsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  margin-top: 1rem;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const GalleryContainer = styled.div`
  width: 40%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`
export const DescriptionGrid = styled.div`
  display: grid;
  gap: 1rem;
  padding: .4rem;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and (max-width: 400px){
    gap: .2rem;
  }
`
export const DescriptionItem = styled.div`
  background: #F5F5F6;
  padding: 1rem 1rem 0 1rem;
  @media only screen and (max-width: 400px){
    padding: .5rem .2rem 0 .2rem;
  }
`


export const DescriptionItemTitle = styled.p`
  ${props => props.theme.typography.body.lg.regular};
  text-align: center;
  margin: 0;
`

export const DescriptionItemDesc = styled.p`
  ${props => props.theme.typography.body.md.bold};
  text-align: center;
`
export const Price = styled.span`
  vertical-align: middle;
`

export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
`
export const ItemContainer = styled.div`
  width: 60%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Header = styled.div`
  ${props => props.theme.typography.subheading.lg.bold};
  margin-bottom: 1rem;
`
export const BuyButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 240px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
`

export const SpanReview = styled.span`
  ${props => props.theme.typography.body.md.regular};
  vertical-align: middle;
`
export const Ratings = styled.div`
  color: #3A36DB;
  display: flex;
  align-items: center;
  gap:.5rem;
  ${props => props.theme.typography.subheading.lg.bold}
`


export const Options = styled.div`

`

export const General = styled.div`

`


export const FullName = styled.p`
  ${props => props.theme.typography.body.lg.medium}
`
export const Name = styled.h1`
  color: #0C121D;
  font-family: Outfit, sans-serif;
  font-weight: 700;
  margin-bottom: 16px;
  // max 4 lines
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  // max 768
  @media only screen and (max-width: 768px) {
    font-size: 1.9rem;
    margin-top: 10px;
  }

  // max 480
  @media only screen and (max-width: 480px) {
    font-size: 1.6rem;
  }

`
export const Location = styled.span`
  ${props => props.theme.typography.body.md.bold}
`
export const Availability = styled.span`
  color: ${props=>props.color};
  ${props => props.theme.typography.body.md.bold}
`
export const DescriptionTitle = styled.h3`
  color: #406385;
  margin-bottom: 10px;
  ${props => props.theme.typography.subheading.md.semibold};
`
export const GeneralText = styled.p`
  ${props => props.theme.typography.body.lg.medium};
  color: #0C121D;
`
export const GeneralTitle = styled.h2`
  color: #406385;
  margin-bottom: 10px;
  ${props => props.theme.typography.subheading.md.semibold};
`

export const Reviews = styled.div`
`

export const Review = styled.div`
`

export const ReviewHeader = styled.div`
`

export const ReviewProfile = styled.div`
  border: 1px solid #9BA1AA;
  background: #EBEAFC;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;

  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
`
export const ReviewName = styled.span`
  ${props => props.theme.typography.subheading.xs.semibold};
  color: #406385;
`
export const ReviewRating = styled.div`
  color: #3A36DB;
  ${props => props.theme.typography.subheading.lg.bold};
`
export const ReviewLocation = styled.p`
  line-height: 14.63px;
  color: #0C121D;
  ${props => props.theme.typography.body.sm.regular};
`
export const ReviewOption = styled.p`
  color: #0C121D;
  line-height: 14.63px;
  ${props => props.theme.typography.body.sm.regular};
`
export const ReviewDesc = styled.p`
  color: #0C121D;
  line-height: 17.07px;
  ${props => props.theme.typography.body.md.regular};
`