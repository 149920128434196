import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: cyan;
`

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
  margin-top: -2rem;

  @media (max-width: 768px) {
    margin-top: -4rem;
    margin-bottom: -8rem;
  }
`
export const LoginWrap = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`

export const ImageContainer = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const LoginImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height:400px;
`
export const FormContainer = styled.div`
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(3, 3, 3, .3) 0 0 1rem 0;
  width: 45%;
  background: white;
  border-radius: 2rem;
  margin-right: 2.5%;
  @media (max-width: 992px) {

  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    transform: translateY(-40%);
    justify-content: flex-start;
    padding: 4rem 0;
    height: fit-content;
    min-height: 60vh;
  }
`

export const FormTitle = styled.span`
  ${props => props.theme.typography.heading.sm.bold};
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 30px;

  @media (max-width: 992px) {
    font-size: 1.5rem;
  }
`

export const SocialsHolder = styled.div`
  display: flex;
  justify-content: center;
`
export const SocialImage = styled.img`
  padding: 0 1rem;
`


export const NoAccount = styled.div`
  display: flex;
  ${props => props.theme.typography.body.md.bold};
  justify-content: space-evenly;
  margin: 2rem 20%;
  gap: 1rem;

  @media (max-width: 992px) {
    margin: 2rem 10%;

  }
`

export const Link = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  cursor:pointer;
  @media (max-width: 768px) {
    ${props => props.theme.typography.body.md.bold};
  }
`
export const InputForm = styled.div`
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  border-radius: 25px;
  padding: 0px 30px;
  outline: none;
  border: none;

  &:focus {
    border-color: transparent !important;
  }
`


export const InputWrap = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
`
export const Input = styled.input`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }

`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`