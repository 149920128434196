import styled from "@emotion/styled";
import { THEME } from "constants/theme/theme";


export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    background-color: ${THEME.colors.products};
`


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 520px;
  width: 95%;
  background-color: ${THEME.colors.products};
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 0% 1%;

  @media (max-width: 992px) {
    width: 100%;
    padding: 0;
  }
`

interface IItem {
  background: string;
}

export const ItemChoice = styled.div<IItem>`
  background-color: ${props => props.background};
  display: flex;
  flex-direction: row;
  width: 400px;
  height: 120px;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 16px 24px;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 992px) {
    width: 95%;
  }

  @media (max-width: 520px) {
    font-size: 0.8rem;
  }
`;

export const ImageChoice = styled.img`
  width: 48px;
  height: 44px;
  margin-right: 16px;
`;

