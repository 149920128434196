import { useTranslation } from "react-i18next";
import {
  Copyright,
  Footer,
  FooterS1,
  FooterS3,
  FooterWrap,
  List,
  Logo,
  Titles,
  SocialLinksContainer,
  PaymentLogo,
  ListItemPayment,
  ListPaymentMethods,
  WrapperPaymentMethods,
  TitleMethods,
  AddressLi,
  AddressUl
} from "./styled";
import './styles.css';
import { useSelector } from "react-redux";
import { getLanguage } from "redux/common";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaWhatsapp,
  FaTiktok,
  FaYoutubeSquare,
} from "react-icons/fa";
import { theme } from "libs/theme";
import { ThemeProvider } from "styled-components";
import { THEME } from "constants/theme/theme";
import  {LOGO_WHITE_ENGLISH, LOGO_WHITE_SPANISH} from 'constants/constants'


interface IProps {
  type?:
  | "main"
  | "products"
  | "services"
  | "classifieds"
  | "offers"
  | "business"
  | "pays"
  | "earn-money";
}


function getCurrentYear() {
  return new Date().getFullYear();
}

function getBgColor(type: string) {
  switch (type) {
    case "main":
      return THEME.colors.primary;
    case "products":
      return "#B218FF";
    case "services":
      return "linear-gradient(180deg, #0089FF 0%, #0071D3 100%);";
    case "classifieds":
      return "#08A791";
    case "offers":
      return "#F9B959";
    case "business":
      return "#F72356";
    case "pays":
      return "#FDC2BC";
    case "earn-money":
      return THEME.colors.black200;
    default:
      return THEME.colors.primary;
  }
}

const FooterBar = (props: IProps) => {
  const { type } = props;
  const { t } = useTranslation();

  const language = useSelector(getLanguage);

  const bgColor = getBgColor(type || "main");

  return (
    <ThemeProvider theme={theme}>
      <Footer background={bgColor}>
        <FooterWrap>
          <FooterS1>
            <Logo
              style={{ verticalAlign: "middle", marginLeft: "-3px" }}
              src={language === 'es' ? LOGO_WHITE_SPANISH : LOGO_WHITE_ENGLISH}
              loading="lazy"
              alt="Logo quenecesitas7" 
              title="Logo quenecesitas7"
            />
            <p style={{ marginBottom: "2rem" }}>{t('footer_main_title')}</p>
            <Titles>{t('follow_us')}</Titles>
            <SocialLinksContainer>
              <a
                className="SocialIconsFooter"
                href="https://www.facebook.com/Whatdoyouneed7"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <FaFacebookSquare size={30} />
              </a>
              <a
                className="SocialIconsFooter"
                href="https://www.instagram.com/whatdoyouneed7"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <FaInstagramSquare size={30} />
              </a>
              <a
                className="SocialIconsFooter"
                href="https://www.youtube.com/@whatdoyouneed7"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <FaYoutubeSquare size={30} />
              </a>
              <a
                className="SocialIconsFooter"
                href="https://www.tiktok.com/@whatdoyouneed_7"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <FaTiktok size={25} />
              </a>
            </SocialLinksContainer>
          </FooterS1>
          <FooterS3>
            <Titles style={{ verticalAlign: "middle" }}>{t('locations')}</Titles>
            <List>
              <ul
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontSize: "17px",
                  paddingLeft: "0px",
                }}
              >
                <li style={{ listStyle: "none" }}>Panamá</li>
                <img
                  style={{
                    width: "25px",
                    marginLeft: "10px"
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fflags%2Fpanama-flag.png?alt=media&token=7b6cd52b-c4eb-46d2-9f1e-9d8be2469691"
                  loading="lazy"
                  alt="Bandera de panama" title="Bandera de Panamá"
                />
              </ul>
              <li style={{ listStyle: "none" }}>Whatdoyouneed7 S.A</li>
              <AddressUl>
                <AddressLi>Centro comercial siglo XXI, Vía Ricardo J. Alfaro, Panamá, Local numero 9 </AddressLi>
              </AddressUl>
              <ul style={{display: 'flex', alignItems: 'flex-end', fontSize: '17px', paddingLeft: "0px", marginTop: "0.5rem"}} >
                <li style={{listStyle: 'none'}}>Estados Unidos</li>
                <img style={{width: "25px", marginLeft: "10px"}} src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fflags%2Fusa-flag.png?alt=media&token=a2cb8adf-2a89-4080-9214-e8b063cc4f53" loading="lazy" alt="Bandera de Estados Unidos" title="Bandera de Estados Unidos" />
              </ul>
              <li style={{ listStyle: "none" }}>Whatdoyouneed7 LLC</li>
              <AddressUl>
                <AddressLi>18640 BOBOLINK DR HIALEAH, FL 33015-2424 </AddressLi>
              </AddressUl>
              <ul style={{display: 'flex', alignItems: 'flex-end', fontSize: '17px', paddingLeft: "0px", marginTop: "0.5rem"}} >
                <li style={{listStyle: 'none'}}>China</li>
                <img style={{width: "25px", marginLeft: "10px"}} src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Fflags%2Fchina%20(1).png?alt=media&token=f93f56c8-b91a-486a-996e-75cc92cff5e6" loading="lazy" alt="Bandera de China" title="Bandera de China" />
              </ul>
              <li style={{ listStyle: "none" }}>Whatdoyouneed7 S.A</li>
              <AddressUl>
                <AddressLi> Guangzhou, Guangdong provincia 510080 </AddressLi>
              </AddressUl>
            </List>
          </FooterS3>
          <FooterS3>
            <Titles>{t('contact_us')}</Titles>
            <List>
              <li></li>
              <li>admin@whatdoyouneed7.com</li>
              <li style={{ marginTop: '1rem' }}>
                  <FaWhatsapp size={20} style={{ marginRight: '5px' }} />
                  <a href="https://api.whatsapp.com/send?phone=50764055638&text=¡Hola!%20Deseo%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos%20y%20servicios." target="_blank" rel="nofollow noopener noreferrer" style={{ color: '#fff' }}>+507 6405-5638</a>
              </li>
            </List>
          </FooterS3>
        </FooterWrap>
        <WrapperPaymentMethods>
        <TitleMethods className='text-mp-mobile oculto-desktop'>{t('payment_methods')}</TitleMethods>
          <ListPaymentMethods>
          <TitleMethods className='oculto-mobile'>{t('payment_methods_with_colon')}</TitleMethods>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2Flogo%20paypal.png?alt=media&token=db245c15-0b11-48f5-a2ab-f9ae81da114f&_gl=1*jaqvo0*_ga*NzI1ODA0MTU4LjE2OTMwMDg3MzA.*_ga_CW55HF8NVT*MTY5ODA5NzY2NC4yMi4xLjE2OTgwOTgyOTIuMzkuMC4w"
                loading="lazy"
                alt="logo paypal" 
                title="logo paypal"
              />
            </ListItemPayment>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2Fzelle-logo.webp?alt=media&token=92cb9d3d-04e6-4039-bf90-029a610fea02"
                loading="lazy"
                alt="logo zelle" 
                title="logo zelle"
              />
            </ListItemPayment>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2FBancolombia%20logo.webp?alt=media&token=ec61a8a4-7687-40d0-bbd6-04f75ea54ae2"
                loading="lazy"
                alt="logo Bancolombia" 
                title="logo Bancolombia"
              />
            </ListItemPayment>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2Flogo%20visa-mastercard.png?alt=media&token=b386a81b-4c41-462f-96f4-c7d908b56083&_gl=1*1g5nmjz*_ga*NzI1ODA0MTU4LjE2OTMwMDg3MzA.*_ga_CW55HF8NVT*MTY5ODA5NzY2NC4yMi4xLjE2OTgwOTgzNzYuNjAuMC4w"
                loading="lazy"
                alt="logo visa y mastercard" 
                title="logo visa y mastercard"
              />
            </ListItemPayment>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2FLogo%20stripe.png?alt=media&token=a034252c-1374-4727-b3ff-e4d8ad116dab&_gl=1*d6fhqg*_ga*NzI1ODA0MTU4LjE2OTMwMDg3MzA.*_ga_CW55HF8NVT*MTY5ODA5NzY2NC4yMi4xLjE2OTgwOTgyNzEuNjAuMC4w"
                loading="lazy"
                alt="logo stripe" 
                title="logo stripe"
              ></PaymentLogo>
            </ListItemPayment>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2FBanco%20General%20Logo.webp?alt=media&token=0704781a-b14b-45f2-817e-07d52d1ce251"
                loading="lazy"
                alt="logo Banco General" 
                title="logo Banco General"
              />
            </ListItemPayment>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2FBank%20of%20China.webp?alt=media&token=0e33fb41-be63-434c-94a6-f08fad91d89a"
                loading="lazy"
                alt="logo Bank of china" 
                title="logo Bank of china"
              />
            </ListItemPayment>
            <ListItemPayment>
              <PaymentLogo
                src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Flogos%2Fpayment%2FBitcoin%20logo.webp?alt=media&token=f9ede1a6-34a6-4c3f-9838-5ffc197dbc55"
                loading="lazy"
                alt="logo Bitcoin" 
                title="logo Bitcoin"
              />
            </ListItemPayment>
          </ListPaymentMethods>
        </WrapperPaymentMethods>
        <Copyright>
          {`Copyright © ${getCurrentYear()} quenecesitas7 S.L. ${t('all_rights_reserved')}`}
        </Copyright>
      </Footer>
    </ThemeProvider>
  );
};

export default FooterBar;
