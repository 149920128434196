import styled from "@emotion/styled";

export const ContainerImage = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const Img = styled.img`
    width: 100%;
    height: 90%;
    min-height: 300px;
    object-fit: contain;
    max-height: 75vh;

    /* margin-bottom: .5rem;  */
`;
