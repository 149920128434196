import { useCallback } from 'react';
import { deleteAddress } from 'services/user/updateInfo/service-update-user-info';

const useDeleteAddress = () => {
    const deleteAddressById = useCallback(async (userUid, addressId) => {
        try {
            const response = await deleteAddress(userUid, addressId);
            if (response.success) {
                return { success: true };
            } else {
                console.error(response.message);
                return { success: false, error: response.message };
            }
        } catch (error) {
            console.error('Error:', error);
            return { success: false, error };
        }
    }, []);

    return { deleteAddressById };
};

export default useDeleteAddress;
