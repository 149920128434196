import React from "react";
import { Button } from "./styled";

interface CustomCarouselButtonProps {
  direction: "left" | "right";
  onClick?: () => void;
}

const CustomCarouselButton: React.FC<CustomCarouselButtonProps> = ({
  direction,
  onClick,
}) => {
  const renderArrow = () => {
    if (direction === "left") {
      return <ArrowLeft />;
    } else if (direction === "right") {
      return <ArrowRight />;
    }
    return null;
  };

  return (
    <Button direction={direction} onClick={onClick}>
      {renderArrow()}
    </Button>
  );
};

export default CustomCarouselButton;

const ArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width={24}
    height={24}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 19.5 8.25 12l7.5-7.5"
    />
  </svg>
);

const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width={24}
    height={24}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m8.25 4.5 7.5 7.5-7.5 7.5"
    />
  </svg>
);
