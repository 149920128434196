import styled from 'styled-components'

export const SmallSection = styled.div`
  clear: both;
  background: #ffffff;
  justify-content: space-between;
  padding: ${props => props.pav} ${props => props.pah};
  @media only screen and (max-width: 992px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

export const BSpan = styled.span`
  color: #08A791;
`

export const Browser = styled.div`
  background: #F4F5F7;
  border-radius: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 1rem auto;
  justify-content: space-between;
  @media only screen and (max-width: 992px) {
    width: 95%;
  }
`

export const BrowserBody = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    width: 35%;
    padding: 1rem;
  }
`
export const BrowserImg = styled.img`
  width: 15%;
  height: 100%;
  object-fit: contain;
  float: right;
  border-radius: 2rem;
  @media only screen and (max-width: 992px) {
    width: 40%;
  }
`
export const BText = styled.h2`
  ${props => props.theme.typography.heading.md};
  @media only screen and (max-width: 1200px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }

  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.heading.md};
  }
  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }

  @media only screen and (max-width: 600px) {

  }
`

export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.fondo || "#ffffff"};
  color: ${props => props.fondo === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 3rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.sm.bold};
  }
`