import { THEME } from "constants/theme/theme";

export   function getBgColor(type: string) {
    switch (type) {
      case "main":
        return THEME.colors.primary;
      case "products":
        return THEME.colors.products;
      case "services":
        return THEME.colors.services;
      case "classifieds":
        return THEME.colors.classifieds;
      case "offers":
        return THEME.colors.offers;
      case "business":
        return THEME.colors.business;
      case "pays":
        return THEME.colors.pays;
      case "earn-money":
        return THEME.colors.black200;
      default:
        return THEME.colors.primary;
    }
  }

export interface IProps {
    type?:
    | "main"
    | "products"
    | "services"
    | "classifieds"
    | "offers"
    | "business"
    | "pays"
    | "earn-money";
    searchVisible?: true | false;
    paddingBottom?: string;
  }