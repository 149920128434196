import { THEME } from "constants/theme/theme";
import {ClassifiedStatus} from 'constants/classifieds';

export function parserNameClassifiedProperty(name: string): string {
    
        switch (name) {
            case 'Square meters':
                return 'SquareMeters';
            default:
                return name;
        }

}

export function parseValueClassifiedProperty(value: string): string {
    
        switch (value) {
            case 'Automatica':
                return 'Auto';
            default:
                return value;
        }

}

export function getColorStatus(status: string): string {
    
        switch (status) {
            case ClassifiedStatus.Approved:
                return THEME.colors.positive80;
            case ClassifiedStatus.Rejected:
                return THEME.colors.negative100;
            case ClassifiedStatus.Pending:
                return THEME.colors.alert60; 
            default:
                return THEME.colors.white;
        }

}