import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceDetail } from "./entities";
import { initialState } from "./state";

export type InitialState = typeof initialState;
type SetServiceDetail = PayloadAction<ServiceDetail>;

const serviceDetailSlice = createSlice({
  name: "ServiceDetail",
  initialState,
  reducers: {
    setServiceDetail(state, action: SetServiceDetail) {
      state.serviceDetailData = action.payload;
    },
  },
});

export const { setServiceDetail } = serviceDetailSlice.actions;

export const serviceDetailReducer = serviceDetailSlice.reducer;
