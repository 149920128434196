import { FC } from "react";

import Modal from "ui/Modal/Modal";
import WhatsAppButton from "components/ButtonWhatsapp/button-whatsapp";
import { H2, P } from "ui/Text";
import { LOGISTIC_PANAMA_PHONE_NUMBER, GENERAL_PANAMA_PHONE_NUMBER } from "constants/constants";
import parseHtmlToText from "utils/parseHtmlToText";

interface DetailContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  businessName: string;
  description: string;
  message?: string;
  type?: string;
}

const DetailContactModal: FC<DetailContactModalProps> = ({
  isOpen,
  onClose,
  title,
  businessName,
  description,
  message,
  type,
}) => {


function getNumberByType(type: string): string {
  switch (type) {
    case "Business":
      return LOGISTIC_PANAMA_PHONE_NUMBER;
    default:
      return GENERAL_PANAMA_PHONE_NUMBER;
  }
}

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <H2>{title}</H2>
      <P margin="1rem 0rem">
        {parseHtmlToText(description)}
      </P>
      <WhatsAppButton
        link={window.location.href}
        phoneNumber={getNumberByType(type || "")}
        message={`${message} ${businessName}`}
      />
    </Modal>
  );
};

export default DetailContactModal;
