import { FC } from "react";

import {
  Container,
} from "./styled";
import SliderCategoriesDesktop from "./components/slider-categories-desktop/slider-categories-desktop";
import SliderCategoriesMobile from "./components/slider-categories-mobile/slider-categories-mobile";
import { ImageObject } from "types";
import { SCREEN_SIZE_BREAKPOINT } from "./constants";

interface SliderCategoriesProps {
  data: any[];
  type: string;
  background: string;
  category: string;
  handleType: any;
  onSelectCategory: (category: string) => void;
  images: string[] | ImageObject[];
  hideSlideCategories?: boolean;
}

const SliderCategories: FC<SliderCategoriesProps> = ({
  data,
  type,
  background,
  category,
  handleType,
  onSelectCategory,
  images,
  hideSlideCategories,
}) => {
  const numberSkeletons: number[] = [];
  // si la imagenes son un objeto, le hacemos un map para obtener solo las url
  if (images && images.length && typeof images[0] === "object") {

    images = images.map((image: any) => image);
  }


  for (let index = 0; index < 6; index++) {
    numberSkeletons.push(index);
  }

  return (
    <Container style={{ background: background }}>
      {window.screen.width > SCREEN_SIZE_BREAKPOINT && (
        <SliderCategoriesDesktop
          data={data}
          onSelectCategory={onSelectCategory}
          handleType={handleType}
          images={images}
          type={type}
          category={category}
          numberSkeletons={numberSkeletons}
          hideSlideCategories={hideSlideCategories}
        />
      )}

      {window.screen.width < SCREEN_SIZE_BREAKPOINT && (
        <SliderCategoriesMobile
          data={data}
          onSelectCategory={onSelectCategory}
          handleType={handleType}
          images={images}
          type={type}
          category={category}
          numberSkeletons={numberSkeletons}
          hideSlideCategories={hideSlideCategories}
        />
      )}
    </Container>
  );
};

export default SliderCategories;
