import styled from 'styled-components'

export const ContentProductsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  margin-top: 1rem;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const GalleryContainer = styled.div`
  width: 45%;
  overflow: hidden;
  border-radius: .5rem;
  max-height: 600px;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`
export const DescriptionGrid = styled.div`
  display: grid;
  gap: 1rem;
  padding: .4rem;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and (max-width: 400px){
    gap: .2rem;
  }
`
export const DescriptionItem = styled.div`
  background: #F5F5F6;
  padding: 1rem 1rem 0 1rem;
  @media only screen and (max-width: 400px){
    padding: .5rem .2rem 0 .2rem;
  }
`


export const DescriptionItemTitle = styled.p`
  ${props => props.theme.typography.body.lg.regular};
  text-align: center;
  margin: 0;
`
export const DescriptionItemDesc = styled.p`
  ${props => props.theme.typography.body.md.bold};
  text-align: center;
`
export const Price = styled.span`
  vertical-align: middle;
  color: #3A36DB;
  ${props => props.theme.typography.subheading.lg.bold};
  @media only screen and (max-width:768px){
    ${props => props.theme.typography.body.lg.bold}
  }
`

export const SpanReview = styled.span`
  ${props => props.theme.typography.body.md.regular};
  vertical-align: middle;
`
export const LabelSalary = styled.p`
  ${props => props.theme.typography.body.md.regular};
  margin: 0;
  font-weight: 700;
`
export const ItemContainer = styled.div`
  width: 60%;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`

export const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Header = styled.div`
  ${props => props.theme.typography.subheading.lg.bold};
  margin-bottom: 1rem;
`

export const Options = styled.div`

`

export const General = styled.div`

`


export const FullName = styled.p`
  ${props => props.theme.typography.body.lg.medium}
`
export const Name = styled.p`
  ${props => props.theme.typography.heading.md}
`
export const Location = styled.span`
  ${props => props.theme.typography.body.md.bold}
`
export const Availability = styled.span`
  color: ${props=>props.color};
  ${props => props.theme.typography.body.md.bold}
`
export const DescriptionTitle = styled.h3`
  font-family: 'Outfit';
  font-weight: 600;
  color: #406385;
  margin-top: 10px;
  font-size: 1.25rem;
`
export const GeneralText = styled.p`
  ${props => props.theme.typography.body.lg.medium};
  color: #0C121D;
`
export const GeneralTitle = styled.h2`
  color: #406385;
  font-family: 'Outfit';
  font-weight: 600;
  margin-top: 10px;
  font-size: 1.25rem;
`

export const Reviews = styled.div`
`

export const Review = styled.div`
`

export const ReviewHeader = styled.div`
`

export const ReviewProfile = styled.div`
  border: 1px solid #9BA1AA;
  background: #EBEAFC;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;

  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
`
export const ReviewName = styled.span`
  ${props => props.theme.typography.subheading.xs.semibold};
  color: #406385;
`
export const ReviewRating = styled.div`
  color: #3A36DB;
  ${props => props.theme.typography.subheading.lg.bold};
`
export const ReviewLocation = styled.p`
  line-height: 14.63px;
  color: #0C121D;
  ${props => props.theme.typography.body.sm.regular};
`
export const ReviewOption = styled.p`
  color: #0C121D;
  line-height: 14.63px;
  ${props => props.theme.typography.body.sm.regular};
`
export const ReviewDesc = styled.p`
  color: #0C121D;
  line-height: 17.07px;
  ${props => props.theme.typography.body.md.regular};
`


export const ContainerReasonToReject = styled.div`
  background: #FDFDFE;
  padding: 1rem 1rem .2rem 1rem;
  margin-top: 1rem;
  color: red;
  // SHADOW
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
  border-radius: 10px;
`