import React, { useState } from "react";
import { CarImg, TopSection } from "./styled";
import Carousel from "react-material-ui-carousel";
import Skeleton from "@mui/material/Skeleton";
import { THEME } from "constants/theme/theme";

type image = {
  imageDesktop: string;
  imageMobile: string;
};

interface IProps {
  images: image[] | [];
}

const CarouselTop = (props: IProps) => {
  const { images } = props;
  const [width, setWidth] = useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
  });

  return (
    <TopSection background={THEME.colors.primary}>
      <Carousel
        autoPlay={true}
        animation="slide"
        indicators={false}
        indicatorIconButtonProps={{
          style: {
            color: "white",
            backgroundColor: "white",
            fontSize: "8px",
            margin: "0 16px",
            padding: "0 4px",
            borderRadius: "8px",
            height: "8px",
            width: "8px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "white",
            backgroundColor: "white",
            padding: "10",
            fontSize: "12px",
            width: "16px",
            height: "12px",
          },
        }}
        indicatorContainerProps={{
          style: {
            marginTop: "-1px",
            marginBottom: "14px",
          },
        }}
      >
        {images.length > 0 ? (
          images.map((image, index) => {
            return (
              <CarImg
                key={index}
                src={width < 768 ? image.imageMobile : image.imageDesktop}
                loading="lazy"
                alt="banner image"
                title="banner image"
              />
            );
          })
        ) : (
          <div
            style={{
              width: "100%",
              height: width < 768 ? width * 0.55 : width * 0.18,
              padding: "1rem",
              margin: "1rem auto",
            }}
          >
            {
              <Skeleton
                variant="rectangular"
                height={"95%"}
                animation="wave"
                sx={{ borderRadius: "1rem" }}
              />
            }
          </div>
        )}
      </Carousel>
    </TopSection>
  );
};

export default CarouselTop;
