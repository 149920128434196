import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
        order: 2;
    }
    
`;

interface GridItemProps {
    width?: string;
    alignItems?: 'center' | 'flex-start' | 'flex-end';
}


export const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${(props: GridItemProps) => props.alignItems || 'flex-start'};
`;

export const ResumePriceContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    border: 1px solid #000;
    border-radius: 12px;
    padding: 1rem;

    @media (max-width: 992px) {
        width: 100%;
    }
`;

export const Price = styled.h3`
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 1rem;
`;