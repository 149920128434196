
import { useSelector } from "react-redux";
import { Container, Image, ProductName, Section} from "./styled";
// import Input from "ui/TextInput";
import Counter from "components/Counter";
import Price from "components/Price";
import { getLanguage } from "redux/common";


const SingleProductCheckout = ({ product, quantity, onQuantityChange }) => {

    const language = useSelector(getLanguage);
    const { name, price, images} = product; // description  id,

    const image = images && images.length > 0 ? images[0] : null;

    return (
        <Container>
            <Section>
                <Image src={image} loading="lazy" alt={name[language]} title={name[language]} />
            </Section>
            <Section>
                <ProductName>{name ? name[language] : '...'}</ProductName>
                {
                    price ? <Price size='24px' value={parseFloat(price)?.toFixed(2) || 0} currencySymbol="$"/> : null
                }
                <div>
                    <Counter quantity={quantity} onUpdateQuantity={onQuantityChange} />
                </div>
            </Section>
            {/* <div className="single-product-checkout__remove">
                <button onClick={onRemove}>Volver</button>
            </div> */}
        </Container>
    );
}

export default SingleProductCheckout;