export const theme: any = {
    colors: {
        main: '#440381',
        accent: '#D72638',
        error: '#ff0000',
        white: '#fff',
        black: '#000',
        neutral10: '#FFFFFF',
        neutral35: '#F6F7FA',
        neutral55: '#D4D7E7',
        neutral100: '#938FA5',
        neutral200: '#29224D',
        neutral300: '#020010',
        primary10: '#F3F2FF',
        primary40: '#D6D1FF',
        primary60: '#643AFF',
        primary80: '#4F0EF9',
        primary100: '#5100D6',
        primary200: '#000287',
        secondary15: '#FFF2F2',
        secondary35: '#FFC5CA',
        secondary100: '#FF3B75',
        secondary200: '#FF005C',
        secondary300: '#C30344',
        secondary400: '#9A1930',
        positive15: '#F1FDF5',
        positive35: '#C5F5D7',
        positive55: '#60F6AE',
        positive100: '#18ED94',
        positive200: '#016547',
        positive300: '#0F1210',
        warning15: '#FFF7E2',
        warning35: '#FFD487',
        warning55: '#FE9622',
        warning100: '#E75800',
        warning200: '#AC3400',
        warning300: '#811F00',
        negative15: '#FEE4E0',
        negative35: '#FA8C84',
        negative55: '#F5544E',
        negative100: '#ED1212',
        negative200: '#CA0902',
        negative300: '#631010'
    },
    typography: {
        heading: {
            lg: {
                'font-family': 'Outfit',
                'font-weight': '800',
                'font-size': '3rem'
            },
            md: {
                'font-family': 'Outfit',
                'font-weight': '700',
                'font-size': '2.5rem'
            },
            sm: {
                black: {
                    'font-family': 'Outfit',
                    'font-weight': '900',
                    'font-size': '2rem'
                },
                bold: {
                    'font-family': 'Outfit',
                    'font-weight': '700',
                    'font-size': '2rem'
                }
            },

        },
        subheading: {
            lg: {
                bold: {
                    'font-family': 'Outfit',
                    'font-weight': '700',
                    'font-size': '1.75rem'
                },
                medium: {
                    'font-family': 'Outfit',
                    'font-weight': '600',
                    'font-size': '1.75rem'
                }
            },
            md: {
                bold: {
                    'font-family': 'Outfit',
                    'font-weight': '700',
                    'font-size': '1.375rem'
                },
                semibold: {
                    'font-family': 'Outfit',
                    'font-weight': '600',
                    'font-size': '1.375rem'
                }
            },
            sm: {
                bold: {
                    'font-family': 'Outfit',
                    'font-weight': '700',
                    'font-size': '1.25rem'
                },
                semibold: {
                    'font-family': 'Outfit',
                    'font-weight': '600',
                    'font-size': '1.25rem'
                }
            },
            xs: {
                bold: {
                    'font-family': 'Outfit',
                    'font-weight': '700',
                    'font-size': '1rem'
                },
                semibold: {
                    'font-family': 'Outfit',
                    'font-weight': '600',
                    'font-size': '1rem'
                }
            },

        },
        body: {
            lg: {
                bold: {
                    'font-family': 'Montserrat',
                    'font-weight': '700',
                    'font-size': '1rem'
                },
                medium: {
                    'font-family': 'Montserrat',
                    'font-weight': '500',
                    'font-size': '1rem'
                },
                input: {
                    'font-family': 'Montserrat',
                    'font-weight': '400',
                    'font-size': '1rem'
                }
            },
            md: {
                bold: {
                    'font-family': 'Montserrat',
                    'font-weight': '700',
                    'font-size': '.875rem'
                },
                regular: {
                    'font-family': 'Montserrat',
                    'font-weight': '400',
                    'font-size': '.875rem'
                }
            },
            sm: {
                bold: {
                    'font-family': 'Montserrat',
                    'font-weight': '700',
                    'font-size': '.75rem'
                },
                regular: {
                    'font-family': 'Montserrat',
                    'font-weight': '400',
                    'font-size': '.75rem'
                }
            },
        },
        label: {
            xs: {
                medium: {
                    'font-family': 'Montserrat',
                    'font-weight': '500',
                    'font-size': '.625rem'
                },
                regular: {
                    'font-family': 'Montserrat',
                    'font-weight': '400',
                    'font-size': '.625rem'
                },
                underline: {
                    'font-family': 'Montserrat',
                    'font-weight': '400',
                    'font-size': '.625rem'
                }
            }
        }
    },
    shadows: {
        down: {
            lg: {
                'box-shadow': 'box-shadow: 0px 8px 30px 16px rgba(2, 64, 154, 0.4);'
            },
            md: {
                'box-shadow': 'box-shadow: 4px 4px 16px 8px rgba(2, 64, 154, 0.16);'
            },
            sm: {
                'box-shadow': 'box-shadow: 0px 4px 8px 2px rgba(2, 64, 154, 0.16);'
            }
        },
        up: {
            lg: {
                'box-shadow': 'box-shadow: 4px -8px 30px 16px rgba(2, 64, 154, 0.4);'
            },
            md: {
                'box-shadow': 'box-shadow: 4px -4px 16px 8px rgba(2, 64, 154, 0.16);'
            },
            sm: {
                'box-shadow': 'box-shadow: 0px -4px 8px 2px rgba(2, 64, 154, 0.16);'
            }
        }
    },
    borders: {
        xs: {
            'border-radius': '0',
            'border-width': '0'

        },
        sm: {
            'border-radius': '8px',
            'border-width': '1.5px'
        },
        md: {
            'border-radius': '16px',
            'border-width': '2px'
        },
        lg: {
            'border-radius': '30px',
            'border-width': '2.5px'
        },
        xl: {
            'border-radius': '100%',
            'border-width': '3px'
        },
    },
    shape: {
        borderRadius: 16
    }
}
