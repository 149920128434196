import styled from 'styled-components';


export const HeadContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const CarouselCategoriesContainer = styled.div`
    width: 100%;
    margin-top: 1.5rem;
    padding: 0 3%;
`;

export const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 1rem;
    // gap vertical
    grid-row-gap: .5rem;

    @media (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;