import {
  AmountInput,
  ItemImage,
  Container,
  CartName,
  CartPrice,
  ItemContainer,
  NameContainer,
  CartSection3,
  CountContainer,
  ButtonUpdateQty,
  CarItemContainer,
  CartItemContent,
} from "./styled";
import Xbox from "assets/xbox.png";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

interface ICartItem {
  id: number | string;
  price: number;
  name: string;
  quantity: number;
  image: string;
  onUpdateQuantity: (quantity: number) => void;
  onDeleteItem: (id: number | string) => void;
  isSelected: boolean;
  onSelect: (id: number | string) => void;
}

const CartItem = (props: ICartItem) => {
  const {
    price,
    name,
    quantity,
    onUpdateQuantity,
    image,
    id,
    onSelect,
    isSelected,
  } = props;

  return (
    <CarItemContainer>
      <label htmlFor={`select-item-${id}`}>
        <input
          type="checkbox"
          id={`select-item-${id}`}
          checked={isSelected}
          onChange={() => onSelect(id)}
          aria-label={`Seleccionar ${name}`}
        />
        <span></span>
      </label>
      <Container isSelected={isSelected}>
        <ItemImage src={image || Xbox} />
        <CartItemContent>
          <ItemContainer>
            <NameContainer>
              <CartName>{name || "Error at name"}</CartName>
            </NameContainer>
          </ItemContainer>

          <CartSection3>
            <CartPrice>${price || "error price"}</CartPrice>
            <CountContainer>
              {/* <CartNameSmall>Cantidad</CartNameSmall> */}
              <ButtonUpdateQty
                onClick={() => {
                  if (quantity > 1) {
                    onUpdateQuantity(quantity - 1);
                  }
                }}
              >
                <AiOutlineMinusCircle
                  color={"#5C7C9A"}
                  size={window.innerWidth < 500 ? 20 : 25}
                />
              </ButtonUpdateQty>
              <AmountInput
                sizeW={window.innerWidth < 500 ? "1.8rem" : "2.2rem"}
                sizeH={window.innerWidth < 500 ? "1.5rem" : "2rem"}
                value={quantity}
              />
              <ButtonUpdateQty
                onClick={() => {
                  onUpdateQuantity(quantity + 1);
                }}
              >
                <AiOutlinePlusCircle
                  color={"#5C7C9A"}
                  size={window.innerWidth < 500 ? 20 : 25}
                />
              </ButtonUpdateQty>
            </CountContainer>
          </CartSection3>
        </CartItemContent>
      </Container>
    </CarItemContainer>
  );
};

export default CartItem;
