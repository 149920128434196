import React from "react";
import { Container, Content } from "./styled";
import Header from "../TopBar";
import Footer from "../Footer";
import { Sidebar } from "components/Sidebar/Sidebar";

type layoutType =
  | "main"
  | "products"
  | "services"
  | "classifieds"
  | "offers"
  | "business"
  | "pays"
  | "earn-money";

interface IProps {
  type?: layoutType;
  children: React.ReactNode;
  hideSearch?: boolean;
  topbarPaddingBottom?: string;
}

const MainLayout = ({
  children,
  type,
  hideSearch,
  topbarPaddingBottom,
}: IProps) => {
  return (
    <Container>
      <Sidebar pageWrapId={"home-wrap"} outerContainerId={"home-container"} />
      <Header
        searchVisible={!hideSearch && true}
        type={type}
        paddingBottom={topbarPaddingBottom}
      />
      <Content>{children}</Content>
      <Footer type={type} />
    </Container>
  );
};

export default MainLayout;
