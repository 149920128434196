import { PaymentMethodEnum, PaymentMethodIdEnum } from 'constants/constants';

export function getPaymentName(paymentId: number): PaymentMethodEnum {
    switch (paymentId) {
        case PaymentMethodIdEnum.card:
            return PaymentMethodEnum.card;
        case PaymentMethodIdEnum.paypal:
            return PaymentMethodEnum.paypal;
        case PaymentMethodIdEnum.cash:
            return PaymentMethodEnum.cash;
        case PaymentMethodIdEnum.yappy:
            return PaymentMethodEnum.yappy;
        default:
            return PaymentMethodEnum.card;
    }
}
