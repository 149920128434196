import styled from 'styled-components'

export const ContentProductsContainer = styled.div`
  width: 100%;
`;
export const TextPick = styled.p`
  color: #08A691;
  text-align: center;
  ${props => props.theme.typography.body.sm.bold};
  margin: 2rem 0;
  font-size: 1.3rem;
`

export const CategoryContainer = styled.div`
  border-radius: 16px;
  background: #e7f7f6;
  width: 100%;
  display: flex;
  margin: .5rem 0;
  padding: 2rem 1rem;
  justify-content: space-between;
  // shadow #08A691
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);


  @media only screen and (min-width: 900px){
    width: 50%;
    margin: .5rem auto;
  }

  // hover
  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
`;
export const GoTo = styled.span`
align-self: center;
`

export const Name = styled.span`
  ${props => props.theme.typography.body.lg.bold};
`
export const Description = styled.p`
  ${props => props.theme.typography.body.sm.regular};
`
export const ImageIconPopUp = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-left: .5rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  color: #000000;
`;


export const PublishContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
  @media only screen and (min-width: 900px){
    width: 50%;
    margin: .5rem auto;
  }
`

export const Info = styled.small`
  color: #406385;
  ${props => props.theme.typography.label.xs.regular};
  line-height: 12.19px;
`

export const TextArea = styled.textarea`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Farrowdown.webp?alt=media&token=7dd41691-6faf-42d6-a14b-59247ee56365');
  background-position: 98%;
  background-repeat: no-repeat;
  &:focus {
    border-color: #3A36DB;
  }
  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const PublishForm = styled.form`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: white;
  border-radius: 2rem;
`

export const FormTitle = styled.span`
  /*font-weight: 700;
  font-family: Outfit, monospace;
  font-size: 2rem;*/
  ${props => props.theme.typography.heading.sm.bold}
  color: #333333;
  line-height: 1.2;
  text-align: left;
  width: 100%;
  display: block;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }
`


export const TransButton = styled.button`
  display: block;
  margin: 0 auto 1rem auto;
  background: ${props => props.color === "#ffffff" ? "#3A36DB" : "#ffffff"};
  color: ${props => props.color === "#ffffff" ? "#ffffff" : "#406385"};
  border-radius: 50px;
  padding: .6rem 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.lg.bold};
`
export const SpanMiddle = styled.span`
  vertical-align: middle;
`

export const FooterWrap = styled.div`
  margin: -15rem 0;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
`
// login selected

export const InputLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: #000000;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #ffffff;
`

// export const InputForm = styled.div`
//   margin: 0 20%;
// `

export const InputWrap = styled.div`
  position: relative;
  margin-bottom:1rem;
`

export const Input = styled.input`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  ${props => props.theme.typography.body.lg.input};
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }

`