import styled from "styled-components";


interface IContainer {
  hideDesktop: boolean;
  hideMobile: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%; /* Ancho completo de la ventana */
  padding-bottom:8px;
  @media (min-width: 768px) {
    display: ${props => props.hideDesktop? 'none' : 'block' }
  }

  @media (max-width: 768px) {
    display: ${props => props.hideMobile? 'none' : 'auto' }
  } 
`;

export const Image = styled.img `
width: 100%;
object-fit: cover;

`;

