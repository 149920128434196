import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 1rem;
    min-height: 60vh;
`;

export const SubContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 2rem;
    height: 100%;
    margin-top: 2rem;

    @media (max-width: 992px) {
        flex-direction: column-reverse;
    }
`;


export const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    border: 1px solid #e0e0e0;
    padding: 1rem 1rem 0 1rem;
    border-radius: 10px;
`;

// Resume of the classified
export const ClassifiedResumeView = styled.div`
    display: flex;
    flex-direction: column;
    gap: .3rem;
    width: 400px;
    height: 100%;
    border: 1px solid #e0e0e0;
    padding: 1rem;
    border-radius: 10px;
`;

export const ImageClassified = styled.img`
    width: 100%;
    height: 200px;
    object-fit: contain;
`;

export const Row = styled.div`
    display: flex;
    gap: .5rem;
    align-items: center;
`;