import styled from 'styled-components'


export const BText = styled.h2`
  text-align: center;
  ${props => props.theme.typography.heading.lg};
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.heading.sm.bold};
  }

  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.subheading.lg.bold};
  }

  @media only screen and (max-width: 600px) {
    text-align: left;
    ${props => props.theme.typography.subheading.sm.bold};

  }
`
export const ContainerText = styled.div`
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;
  font-size: 1.5rem;
  text-align: left;
`;

export const Text = styled.p`
  color: #000000;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const HighlightText = styled.span`
  color: #3A36DB;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const SecondaryText = styled.span`
  width: 85%;
  color: #000000;
  font-weight: 700;
  font-size: 1.1rem;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: ${props => props.pav || "1rem"} ${props => props.pah || "0"};
  justify-content: space-between;
  align-items: ${props => props.align || "stretch"};
  width: 100%;
  background: ${props => props.background || "#ffffff"};
  text-align: center;
  
`
export const SmallSection = styled.div`
  clear: both;
  background: ${props => props.background || "#ffffff"};
  justify-content: space-between;
  padding: ${props => props.pav} ${props => props.pah};
  @media only screen and (max-width: 992px) {
    padding: 0;
  }
  @media only screen and (max-width: 500px) {
    padding: 1rem .5rem;
  }
`
export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1.4rem;
  border: 1px solid #406385;
  margin: 1rem 0;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 500px){
    ${props => props.theme.typography.body.sm.bold};
  }
`
export const Browser = styled.div`
  background: #DDDFE8;
  border-radius: 1rem;
  border-top: 2rem solid #102645;
  border-left: .5rem solid #102645;
  border-right: .5rem solid #102645;
  border-bottom: 1rem solid #102645;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    position: absolute;
    content: '■ ■ ■';
    color: #ffffff;
    top: -2rem;
    left: 1rem;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 500px) {
    padding: 0;
  }
`

export const BrowserTitle = styled.div`
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 1rem;
  }
`
export const BTitleSpan = styled.span`
  color: #3A36DB;
`
export const BrowserDesc = styled.div`
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  text-align: left;
  padding: 40px 1rem;
  @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
  }
`
export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`

export const BrowserImg = styled.img`
  display: none;
  @media only screen and (max-width: 600px) {
    position: absolute;
    display: block;
    align-self: right;
    width: 40%;
    bottom: -2.5rem;
    right: -1rem;
  }
`
