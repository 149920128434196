import styled from 'styled-components'

export const ContentClassifiedsContainer = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`;

export const ListClassifiedContainer = styled.div`
  background-color: ${props=>props.color || "#F5F5F6"};
  border-radius: 16px;
  width: 100%;
  padding: ${props => props.pad || "3%"};;
`;

export const CarouselClassifiedsContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`;
export const TitlesLink = styled.p`
  ${props => props.theme.typography.subheading.sm.bold};
  margin: 0;
  @media only screen and (max-width: 600px){
    ${props => props.theme.typography.body.lg.bold};
  }
`

export const LinkMore = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  display: block;
  text-align: center;
  cursor:pointer;
  @media only screen and (max-width: 600px){
    ${props => props.theme.typography.body.md.bold};
  }
`

export const IconImage = styled.img`
  width: 18px;
  height: 18px;
  object-fit: cover;
  margin-right: 8px;
`


export const SpanMiddle = styled.span`
  vertical-align: middle;
`
export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: #ffffff;
  color: #406385;
  border-radius: 50px;
  padding: .2rem 2rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
`

export const Link = styled.a`
  color: #3A36DB;
  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 600px) {

  }
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  gap: .5rem;

  @media only screen and (max-width: 600px) {
    padding: 1rem 1rem 0rem 1rem;
  }
`

export const Filter = styled.div`
  display: flex;
  margin-top:2rem;
  justify-content: space-between;
  color:#406385;
`
export const Amount = styled.span`
  ${props => props.theme.typography.subheading.md.semibold};
  @media only screen and (max-width: 768px){
    ${props => props.theme.typography.subheading.xs.semibold};
  }
`
export const TextFilter = styled.span`
  ${props => props.theme.typography.subheading.md.semibold};
  @media only screen and (max-width: 768px){
    ${props => props.theme.typography.subheading.xs.semibold};
  }
`
export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
`
export const PaneTitle = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: #000000;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.2rem;
  
`

export const PaneSection = styled.div`
  background: #f6f5f8;
  padding: .5rem 3rem;
  width: 100%;
`
export const PaneFooter = styled.div`
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
`


export const PaneFooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap:1rem;
`