import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background: #f9f9f9;
  gap: 20px;
  text-align: center;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 2rem;
    height: 80vh;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`