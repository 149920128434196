import {
  Card,
  Container,
  Description,
  DescriptionContainer,
  Image,
  ImageContainer,
  SpanMiddle,
  Title,
} from "./styled";
import { useSelector } from "react-redux";
import { Multilingual } from "types";
import { LinkButton, StyledButton } from "ui/Buttons";
import { useTranslation } from "react-i18next";
import { getLanguage } from "redux/common";


interface Props {
  data: {
    image: string;
    name: Multilingual;
    shortDescription: Multilingual;
  };
  textButton?: string;
  linkButton?: boolean;

  onClick?: () => void;
}

const InfoCard = (props: Props) => {
  const { t } = useTranslation();
  const { data, onClick, textButton, linkButton } = props;
  const language = useSelector(getLanguage);

  return (
    <Container>
      <Card onClick={onClick}>
        <ImageContainer>
          <Image src={data?.image} loading="lazy" alt={data?.name?.[language]} title={data?.name?.[language]} />
        </ImageContainer>
        <DescriptionContainer>
          {data.name && <Title>{data?.name?.[language]}</Title>}
          <Description>{data?.shortDescription?.[language]}</Description>
        </DescriptionContainer>
        {onClick &&
          (linkButton ? (
            <LinkButton
              style={{
                width: "100%",
                maxWidth: "none",
              }}
              type="button"
              onClick={onClick}
            >
              <SpanMiddle>{textButton || t('click_here')}</SpanMiddle>
            </LinkButton>
          ) : (
            <StyledButton
              style={{
                width: "100%",
                margin: "0 auto",
                height: "auto",
                maxWidth: "none",
              }}
              type="button"
              onClick={onClick}
            >
              <SpanMiddle>{textButton || t('click_here')}</SpanMiddle>
            </StyledButton>
          ))}
      </Card>
    </Container>
  );
};

export default InfoCard;
