import { useState } from 'react';
import { createNewAddress } from 'services/user/updateInfo/service-update-user-info';
import { Address } from 'types';

interface CreateAddressResponse {
    loading: boolean;
    success?: boolean;
    error?: string;
    addressId?: string;
}

const useCreateAddress = () => {
    const [response, setResponse] = useState<CreateAddressResponse>({ loading: false });

    const createAddress = async (userUid: string, address: Address) => {
        setResponse({ loading: true });


        try {
            const result = await createNewAddress(userUid, address);
            if (result.success) {
                setResponse({ loading: false, success: true, addressId: result.id });
            } else {
                setResponse({ loading: false, success: false, error: result.message });
            }
        } catch (error: any) {
            setResponse({ loading: false, success: false, error: error.message });
        }
    };

    return { createAddress, ...response };
};

export default useCreateAddress;
