import { useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom'
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from 'config/firebase';
// SCREENS
import Home from 'modules/Home'
import Profile from 'modules/Profile'
import Services from 'modules/services/home'
import Classifieds from 'modules/Classifieds'
import MyClassifieds from 'modules/Classifieds/my-classifieds'
import Products from 'modules/products'
import ProductsStore from 'modules/products/Store'
import ProductsWholesale from 'modules/products/Wholesale'
import ProductsMachinery from 'modules/products/Machinery'
import ProductsByCategory from 'modules/products/by-category'
import ProductDetail from 'modules/products/Details'
import ClassifiedDetail from 'modules/Classifieds/details'
import ClassifiedsByCategory from 'modules/Classifieds/classifieds-by-category/classifieds-by-category'
import OfferDetail from 'modules/Offers/Details'
import NewClassified from 'modules/Classifieds/new-classified'
import EditClassified from 'modules/Classifieds/edit-classified'
import DetailService from 'modules/services/pages/detail'
import ChatClassifieds from 'modules/Classifieds/chats/chat-list-classifieds'
import ChatSessionClassified from 'modules/Classifieds/chats/chat-session-classified'
import Offers from 'modules/Offers'
import Pays from 'modules/Pays'
import Cart from 'modules/Cart'
import Needs from 'modules/Needs'
import NeedsCategories from 'modules/Needs/Categories/need-categories'
import EarnMoney from 'modules/EarnMoney'
import DetailEarnMoney from 'modules/EarnMoney/pages/detail/detail-earn-money'
import Terms from 'modules/Terms'
import Politics from 'modules/Politics'
import Contact from 'modules/Contact'
import Chatbot from 'modules/Chatbot/chatbot'
// unsecure routes
import Login from 'modules/Login'
import CreateAccount from 'modules/CreateAccount'
import ForgotPassword from 'modules/ForgotPassword'
import Yatch from "modules/services/pages/yacht";
import Business from "modules/services/pages/business";
import RealState from "modules/services/pages/real-state";
import Tourism from "modules/services/pages/tourism";
import Checkout from "modules/Checkout";
import CheckoutSingleProduct from "modules/Checkout/checkout-single-product";
import { MAIN_ROUTES, MARKETING_ROUTES } from '../../constants'
import PageNotFound from 'modules/PageNotFound/PageNotFound'
import TechnicalService from 'modules/services/pages/technical-service'
import Marketing from 'modules/services/pages/marketing/marketing'
import Tools from 'modules/Tools/Tools'
import ToolRemoveBg from 'modules/Tools/pages/remove-bg/remove-bg'
import VerifyEmail from './screen-verify-email/screen-verify-email';

import { TOURS_PATH } from 'modules/services/pages/tourism/constants'
import { MARKETING_PATH, TECHNICAL_PATH, YACHT_EXTRA_PATH  } from 'modules/services/pages/yacht/constants'
import { useScrollRestoration } from "hooks/system/useScrollRestoration/use-scroll-restoration";
import { useLanguage } from 'hooks/common/useLanguage'
import useSyncUserWithRedux from 'hooks/user/useSyncUsersWithRedux/useSyncUsersWithRedux';
import { setAuth } from 'redux/auth';


const PrivateRouter = () => {
  const dispatch = useDispatch();
  useScrollRestoration();
  useSyncUserWithRedux();
  useLanguage();

  const [user, setUser] = useState<User | null>(null);
  const [showModalConfirmEmail, setShowModalConfirmEmail] = useState<boolean>(false);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      if (user) {
        console.log('User is signed in')
        setUser(user)
      } else {
        console.log('User is signed out')
        setUser(null)
      }
    }
    )
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (user) {
      if ( user && !user.emailVerified) {
        setShowModalConfirmEmail(true)
      } else {
        setShowModalConfirmEmail(false)
      }
    } else {
      dispatch(setAuth({
        isAuth: false,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.emailVerified, user])



  if (showModalConfirmEmail) {
    return (
      <VerifyEmail userEmail={user?.email as string} />
    )
  }

  
  return (
    <Routes>
      <Route path='*' element={<PageNotFound />} />
      <Route path={MAIN_ROUTES.Home} element={<Home />} />
      <Route path={MAIN_ROUTES.Profile} element={<Profile />} />
      <Route path={MAIN_ROUTES.Services} >
        <Route index element={<Services />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService />} />
      </Route>
      <Route path={MAIN_ROUTES.Login} element={<Login />} />
      <Route path={MAIN_ROUTES.ServiceDetail} element={<DetailService />} />
      <Route path={MAIN_ROUTES.Products} element={<Products />} />
      <Route path={MAIN_ROUTES.ProductsStore} element={<ProductsStore />} />
      <Route path={MAIN_ROUTES.ProductsMachinery} element={<ProductsMachinery />} />
      <Route path={MAIN_ROUTES.ProductsWholesale} element={<ProductsWholesale />} />
      <Route path={MAIN_ROUTES.ProductsByCategory} element={<ProductsByCategory />} />
      <Route path={MAIN_ROUTES.ProductDetail} element={<ProductDetail />} />
      <Route path={MAIN_ROUTES.Classifieds} element={<Classifieds />} />
      <Route path={MAIN_ROUTES.MyClassifieds} element={<MyClassifieds />} />
      <Route path={MAIN_ROUTES.ClassifiedNew} element={<NewClassified />} />
      <Route path={MAIN_ROUTES.ClassifiedEdit} element={<EditClassified />} />
      <Route path={MAIN_ROUTES.ClassifiedDetail} element={<ClassifiedDetail />} />
      <Route path={MAIN_ROUTES.ClassifiedsByCategory} element={<ClassifiedsByCategory />} />
      <Route path={MAIN_ROUTES.ChatClassifieds} element={<ChatClassifieds />} />
      <Route path={MAIN_ROUTES.ChatSessionClassified} element={<ChatSessionClassified />} />
      <Route path={MAIN_ROUTES.Offers} element={<Offers />} />
      <Route path={MAIN_ROUTES.OfferDetail} element={<OfferDetail />} />
      <Route path={MAIN_ROUTES.Pays} element={<Pays />} />
      <Route path={MAIN_ROUTES.Cart} element={<Cart />} />
      <Route path={MAIN_ROUTES.Checkout} element={<Checkout />} />
      <Route path={MAIN_ROUTES.EarnMoney} >
      <Route index element={<EarnMoney />} />
        <Route path={MAIN_ROUTES.DetailEarnMoney} element={<DetailEarnMoney />} />
      </Route>      
      <Route path={MAIN_ROUTES.CheckoutSingleProduct} element={<CheckoutSingleProduct />} />
      <Route path={MAIN_ROUTES.CreateAccount} element={<CreateAccount />} />
      <Route path={MAIN_ROUTES.ForgotPassword} element={<ForgotPassword />} />
      <Route path={MAIN_ROUTES.Needs} element={<Needs />} />
      <Route path={MAIN_ROUTES.NeedsList} element={<NeedsCategories />} />
      <Route path={MAIN_ROUTES.Terms} element={<Terms />} />
      <Route path={MAIN_ROUTES.Politics} element={<Politics />} />
      <Route path={MAIN_ROUTES.Contact} element={<Contact />} />
      <Route path={MAIN_ROUTES.Yatchs} >
        <Route index element={<Yatch />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService fetchPath={YACHT_EXTRA_PATH} />} />
      </Route>
      <Route path={MAIN_ROUTES.Marketing} >
        <Route index element={<Marketing />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService fetchPath={MARKETING_PATH} />} />
      </Route>
      <Route path={MAIN_ROUTES.RealState} >
        <Route index element={<RealState />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService />} />
      </Route>
      <Route path={MAIN_ROUTES.Business} >
        <Route index element={<Business />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService />} />
      </Route>
      <Route path={MAIN_ROUTES.TechnicalService} >
        <Route index element={<TechnicalService />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService fetchPath={TECHNICAL_PATH} />} />
      </Route>
      <Route path={MAIN_ROUTES.Tourism} >
        <Route index element={<Tourism />} />
        <Route path={MARKETING_ROUTES.Detail} element={<DetailService fetchPath={TOURS_PATH} />} />
      </Route>
      <Route path={MAIN_ROUTES.Chatbot} element={<Chatbot />} />
      <Route path={MAIN_ROUTES.Tools} >
        <Route index element={<Tools />} />
        <Route path={MAIN_ROUTES.ToolsRemoveBg} element={<ToolRemoveBg />} />
      </Route>
      
    </Routes>
  )
}


export default PrivateRouter
