import styled from "styled-components";

export const Container = styled.div`
  width: 180px;
  height: 100px;
  background-color: #E3F1FE;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }

  @media (max-width: 1300px) {
    width: 160px;
  }

  @media (max-width: 1100px) {
    width: 140px;
  }

  @media (max-width: 992px) {
    width: 20vw;
  }

  @media (max-width: 768px) {
    width: 25vw;
  }

`;

export const SpanMiddle = styled.span`
  vertical-align: middle;
`
export const SponsorProduct = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  height: 18rem;
  flex-direction: column;
  justify-content: flex-start;
  background: #FDFDFE;
  line-height: 1.3rem;
  text-align: left;
  //margin-right: .5rem;
  margin: 0 .5rem 0 auto;
  padding: 1rem;
  border-radius: 1rem;

  &:before {
    ${({contenido}) => contenido && `
    background:#08A791;
    `}
    content: '${props => props.contenido}';
    padding: .5rem;
    color: #ffffff;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    top: .5rem;
    left: 0;
    @media only screen and (max-width: 500px){
      ${props => props.theme.typography.body.sm.regular};
    }
  }
`

export const SponsorImg = styled.img`
  width: ${props => props.size || "100%"};
  border-radius: .5rem;
  object-fit: cover;
  height: 40%;
  margin-bottom: .5rem;
`
export const SponsorPrice = styled.span`
  color: #0B8226;
  ${props => props.theme.typography.subheading.xs.bold};
`

export const SponsorName = styled.span`
  font-size: 1rem;
  color:#0C121D;
  ${props => props.theme.typography.body.md.bold};
`
export const SponsorDesc = styled.span`
  padding: .4rem 0;
  ${props => props.theme.typography.body.md.regular};
  line-height: 14.63px;
  color:#0C121D;
`
export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
`
export const SponsorLocation = styled.p`
  color:#262494;
  ${props => props.theme.typography.label.xs.medium};
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
`