import {useSelector} from "react-redux";
import { userInfo} from "redux/user";
import { User } from "types";

export const useIsDataStepOneCompleted = () => {
    const userData: User = useSelector(userInfo as any);
    if (!userData || userData === null) {
        return false;
    } else {
      const { firstName, firstSurname, email, phone } = userData;
      const isDataStepOneCompleted = [firstName, firstSurname, email, phone].every(
        (value) => value && value.trim() !== ''
      );
      return isDataStepOneCompleted;
    }

    
  };


  
  
