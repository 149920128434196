import { FC, useEffect, useState } from "react";
import MainLayout from "views/layout/MainLayout";
import ImageGallery from "components/media-gallery";
import { useTranslation } from "react-i18next";
import {
  BlueContainer,
  BodyContent,
  BodyInfo,
  Container,
  Content,
  DescriptionText,
  Divider,
  HeadContent,
  HeadInfo,
  InfoContainer,
  InitPriceText,
  LocationText,
  LocationWrapper,
  RatingsWrapper,
  SectionTitle,
  SpanReview,
  TextPrice,
  Title,
  WrapperButtons,
  WrapperText,
  PriceContainer,
  GalleryContainer
} from "./styled";
import { Button } from "ui/Buttons";
import Rating from "components/Rating";
import { MdLocationPin } from "react-icons/md";
import ModalSelector from "./components/modal-selector/modal-selector";
import { useSelector } from "react-redux";
import RelatedServices from "./components/related-services";
import { useGetFirestoreDoc } from "hooks/useGetDocumentFirestore";
import { getLanguage, getCountry } from "redux/common";
import ModalLoader from "ui/ModalLoader";
import { getServiceCategoryName } from "utils/text-helpers";
import parseHtmlToText from "utils/parseHtmlToText";
import { getPathByType } from "./main";

interface DetailServiceProps {
  fetchPath?: string;
}

const DetailService: FC<DetailServiceProps> = ({
  fetchPath = "PANAMA/Services/Categories/Yatchs/Posts",
}) => {
  const { t } = useTranslation();

  const [showContactModal, setShowContactModal] = useState(false);
  const [serviceDetail, setServiceDetail] = useState<any>();

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const type = urlParams.get("type");
  const categoryPath = getServiceCategoryName(urlParams.get("category") || "");

  const language = useSelector(getLanguage);
  const country = useSelector(getCountry);

  const path = categoryPath ? `${country}/Services/Categories/${categoryPath}/${getPathByType(type  || 'Posts') }` : fetchPath;

  const TITLE_CATEGORY = {
    Yatchs: "Alquiler de yates",
    TechnicalServices: "Servicios técnicos",
    RealState: "Bienes raíces",
  };

  const { fetchDoc, docData } = useGetFirestoreDoc();

  useEffect(() => {
    if (id && id.length > 0 && id !== "undefined") {
      fetchDoc({
        path: path,
        uid: id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, categoryPath]);

  useEffect(() => {
    if (docData) {
      setServiceDetail(docData);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 300)
    }
  }, [docData]);

  if (!serviceDetail) return <ModalLoader isLoading={true} />;
  
  return (
    <MainLayout type="services">
      <Container>
        <BlueContainer />
        <Content>
          <HeadContent>
            <Title>
              {serviceDetail?.name
                ? serviceDetail.name[language]
                : TITLE_CATEGORY[serviceDetail?.category]}
            </Title>
          </HeadContent>
          <BodyContent>
            <GalleryContainer>
              {serviceDetail?.images ? (
                Array.isArray(serviceDetail.images) &&
                  serviceDetail.images.length > 0 ? (
                  <ImageGallery
                    currentSelected={0}
                    data={serviceDetail.images?.map((image: string) => ({
                      original: image,
                      thumbnail: image,
                    }))}
                  />
                ) : (
                  <ImageGallery
                    currentSelected={0}
                    data={[serviceDetail.images]}
                  />
                )
              ) : (
                <ImageGallery
                  currentSelected={0}
                  data={[serviceDetail?.image]}
                />
              )}
            </GalleryContainer>

            <HeadInfo>
              {serviceDetail?.ranking && (
                <RatingsWrapper>
                  <Rating
                    size="medium"
                    value={parseInt(serviceDetail?.rating || 0)}
                  />
                  <SpanReview>({serviceDetail?.ratingCount || "0"})</SpanReview>
                </RatingsWrapper>
              )}

              {serviceDetail?.priceOptions &&
                serviceDetail?.priceOptions?.length > 0 ? (
                <WrapperText>
                  {(serviceDetail?.category === "Yatchs" || serviceDetail?.priceType === 'from') && (
                    <InitPriceText>{t('from')}</InitPriceText>
                  )}
                  <TextPrice>
                    {`$${serviceDetail?.priceOptions[0]?.value.toLocaleString()}` || "0"}
                  </TextPrice>
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {serviceDetail?.category === "Yatchs" && (
                      <Button
                        style={{
                          padding: "1.5rem 2rem",
                          fontSize: "1.2rem",
                          alignSelf: "center",
                        }}
                        onClick={() => setShowContactModal(true)}
                      >
                        {t("reserve")}
                      </Button>
                    )}
                  </div>
                </WrapperText>
              ) : (
                <PriceContainer>
                  {serviceDetail?.priceType === 'from' && (
                    <InitPriceText>{t('from')}</InitPriceText>
                  )}
                  <TextPrice>
                    {serviceDetail?.price
                      ? `$ ${serviceDetail?.price.toLocaleString()}`
                      :t('inquire_for_best_price')}
                  </TextPrice>
                </PriceContainer>
              )}
              {serviceDetail?.category === "Yatchs" && (
                <LocationWrapper>
                  <LocationText>
                    <MdLocationPin size={25}/>{country}</LocationText>
                </LocationWrapper>
              )}
            </HeadInfo>
            <InfoContainer>
              <BodyInfo>
                <Divider />
                <SectionTitle>{t("general_details")}</SectionTitle>
                <DescriptionText>
                  {parseHtmlToText(
                    serviceDetail?.description?.es
                      ? serviceDetail?.description[language]
                      : serviceDetail?.shortDescription[language] || ""
                  )}
                </DescriptionText>
              </BodyInfo>
              {serviceDetail?.extraInfo && (
                <BodyInfo>
                  <SectionTitle>{t("includes")}</SectionTitle>
                  <DescriptionText>
                    {parseHtmlToText(serviceDetail?.extraInfo[0]?.description[language] || "")}
                  </DescriptionText>
                </BodyInfo>
              )}
              <WrapperButtons>
                {serviceDetail?.category === "Yatchs" ? (
                  <Button
                    style={{
                      padding: "1.5rem 2rem",
                      fontSize: "1.2rem",
                      alignSelf: "center",
                    }}
                    onClick={() => setShowContactModal(true)}
                  >
                    {t("reserve")}
                  </Button>
                ) : (
                  <>
                    <Button
                      style={{
                        backgroundColor: "#29CF2F",
                        padding: "1.5rem 2rem",
                        fontSize: "1.2rem",
                        alignSelf: "center",
                      }}
                      onClick={() => setShowContactModal(true)}
                    >
                      {t("contact")}
                    </Button>
                  </>
                )}
              </WrapperButtons>
            </InfoContainer>
          </BodyContent> 
        </Content>
        { serviceDetail && serviceDetail?.category === "Yatchs" && (
          <RelatedServices category={serviceDetail?.category} />
        )}
        {
          serviceDetail && (
            <ModalSelector
              docData={serviceDetail }
              showContactModal={showContactModal}
              setShowContactModal={setShowContactModal}
              language={language}
            />
          )
        }
      </Container>
    </MainLayout>
  );
};

export default DetailService;
