import { httpsCallable } from "firebase/functions";
import { functions } from "config/firebase"; // Asegúrate de tener tu configuración de Firebase importada
import { ServiceResponse } from "types";

interface IGetUserById {
  id: string;
}

const getUserByIdService = async (
  props: IGetUserById
): Promise<ServiceResponse> => {
  const getUserFunction = httpsCallable(functions, "getUserById");

  try {
    const response: any = await getUserFunction(props);

    if (response.data.status === "ERROR" || !response.data.success) {
      throw new Error(response.data.message);
    }

    return {
      status: "OK",
      message: response.data.message,
      success: true,
      data: response.data.data,
    };
  } catch (error: any) {
    return {
      status: "ERROR",
      message: error?.message || "Error al obtener el usuario",
      code: error?.code || 500,
    };
  }
};

export default getUserByIdService;
