import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 425px) {
        grid-template-columns: 1fr;
    }
`;

interface ItemGridProps {
    bgColor: string;
}

export const ItemGrid = styled.div<ItemGridProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s;
    color: white!important;
    font-weight: 700;
    background-color: ${(props) => props.bgColor};
`;
