import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Browser,
  BrowserBody,
  BrowserImg,
  BText,
  Categories,
  CategoriesWrap,
  Category,
  CategoryModalHeader,
  CategoryModalTitle,
  Container,
  ImageIcon,
  TextCategory,
  TransButton,
  ImageContainer,
  FakeBrowsersButton,
  FakeBrowsersButtonsContainer,
  SubtitleText,
} from "./styled";
import Modal from "ui/Modal";
import { useTranslation } from "react-i18next";
import { getLanguage } from "redux/common";

import imageWoman from "../../../assets/womaneeds.png";
import { collection, getDocs } from "firebase/firestore/lite";
import { firestore } from "../../../config/firebase";
import { Tab, Tabs } from "@mui/material";
import { MdClose } from "react-icons/md";

export const CategoriesSection = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = useSelector(getLanguage);

  const { categories } = props;
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [classifieds, setClassifieds] = useState([]); 

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (products.length === 0) {
      const querySnapshot1 = getDocs(
        collection(firestore, "PANAMA/Categories/Products")
      );
      querySnapshot1.then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // add doc id to data
          doc.data().id = doc.id;

          // @ts-ignore
          setProducts((categories) => [...categories, doc.data()]);
        });
      });
      if (services.length === 0) {
        const querySnapshot2 = getDocs(
          collection(firestore, "PANAMA/Categories/Services")
        );
        querySnapshot2.then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // add doc id to data
            doc.data().id = doc.id;

            // @ts-ignore
            setServices((categories) => [...categories, doc.data()]);
          });
        });
      }

      if (classifieds.length === 0) {
        const querySnapshot3 = getDocs(
          collection(firestore, "PANAMA/Classifieds/Categories")
        );
        querySnapshot3.then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.data().id = doc.id;
            // @ts-ignore
            setClassifieds((categories) => [...categories, doc.data()]);
          });
        });
      }
    }
    // eslint-disable-next-line
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <Container mbottom={"1rem"}>
      <FakeBrowsersButtonsContainer>
        <FakeBrowsersButton />
        <FakeBrowsersButton />
        <FakeBrowsersButton />
      </FakeBrowsersButtonsContainer>
      <Browser>
        <BrowserBody>
          <BText>{t('say_us_what_you_need_today')}</BText>
          <SubtitleText>
            {" "}
            {t('our_agents_will_help_you_find_it')}
          </SubtitleText>
          <div
            style={{
              width: "100%",
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <TransButton onClick={() => navigate("/needs")} fondo={"#3A36DB"}>
              {t('click_here')}
            </TransButton>
          </div>
        </BrowserBody>
        <ImageContainer>
          <BrowserImg src={imageWoman} loading="lazy" alt="Mujer soteniendo un telefono" title="Mujer soteniendo un telefono" />
        </ImageContainer>
      </Browser>
      <CategoriesWrap>
        <Categories>
          {categories.map((category: any, index: number) => {
            return (
              <Category
                wrap={"nowrap"}
                key={index}
                onClick={() => {
                  if (category.linkweb !== undefined) {
                    if (category?.name?.en === "News") {
                      window.open(category.linkweb, "_blank");
                    } else {
                      navigate(category.linkweb);
                    }
                  }
                }}
                backgroundColor={category.bgColor}
              >
                <ImageIcon src={category.image || ""} loading="lazy" alt={category.name[language]} title={category.name[language]} />
                <TextCategory>{category.name[language]}</TextCategory>
              </Category>
            );
          })}
        </Categories>
        {/* <LinkContainer>
                    <Link onClick={() => setShowModal(true)}>Ver todas las categorias</Link>
                </LinkContainer> */}
      </CategoriesWrap>

      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <CategoryModalHeader>
            <MdClose onClick={() => setShowModal(false)} size={30} />
            <CategoryModalTitle>{ t('categories')}</CategoryModalTitle>
            <MdClose color={"#ffffff"} />
          </CategoryModalHeader>
          <Tabs
            style={{ marginBottom: "1rem" }}
            variant="fullWidth"
            value={tabIndex}
            onChange={handleTabChange}
          >
            <Tab label="Productos" />
            <Tab label="Servicios" />
            <Tab label="Clasificados" />
          </Tabs>
          {tabIndex === 0 && (
            <CategoriesWrap style={{ width: "100%" }}>
              <Categories>
                {products.map((category: any, index: number) => {
                  return (
                    <Category
                      key={index}
                      onClick={() =>
                        navigate("/products", {
                          state: {
                            selRoute: category.route.toLowerCase(),
                            selRouteEs: category.name.es,
                          },
                        })
                      }
                      backgroundColor={category.bgColor || category.color}
                    >
                      <ImageIcon
                        src={category.image || ""}
                        loading="lazy"
                        alt={category.name[language]}
                        title={category.name[language]}
                      />
                      <TextCategory>{category.name[language]}</TextCategory>
                    </Category>
                  );
                })}
              </Categories>
            </CategoriesWrap>
          )}
          {tabIndex === 1 && (
            <CategoriesWrap style={{ width: "100%" }}>
              <Categories>
                {services.map((category: any, index: number) => {
                  return (
                    <Category
                      key={index}
                      onClick={() => {
                        category.linkweb !== undefined &&
                          navigate(category.linkweb);
                      }}
                      backgroundColor={category.bgColor || category.color}
                    >
                      <ImageIcon
                        src={category.image || ""}
                        loading="lazy"
                        alt={category.name[language]}
                        title={category.name[language]}
                      />
                      <TextCategory>{category.name[language]}</TextCategory>
                    </Category>
                  );
                })}
              </Categories>
            </CategoriesWrap>
          )}
          {tabIndex === 2 && (
            <CategoriesWrap style={{ width: "100%" }}>
              <Categories>
                {classifieds.map((category: any, index: number) => {
                  return (
                    <Category
                      key={index}
                      onClick={() => {
                        category.linkweb !== undefined &&
                          navigate(category.linkweb);
                      }}
                      backgroundColor={category.bgColor}
                    >
                      <ImageIcon
                        src={category.image || ""}
                        loading="lazy"
                        alt={category.name[language]}
                        title={category.name[language]}
                      />
                      <TextCategory>{category.name[language]}</TextCategory>
                    </Category>
                  );
                })}
              </Categories>
            </CategoriesWrap>
          )}
        </Modal>
      )}
    </Container>
  );
};
