import styled from "@emotion/styled";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
` 
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
  background-color: #E7EDF5;
  align-self: center;
`
