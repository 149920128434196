import BusinessContactModal from "views/containers/Services/Modals/BusinessContactModal/business-contact-modal";
import DetailContactModal from "views/containers/Services/Modals/detail-contact-modal/detail-contact-modal";
import { useTranslation } from "react-i18next";


interface DocData {
  category: string;
  name: { [key: string]: string };
  shortDescription: { [key: string]: string };
  selectedOptions?: string;
  level?: string;
  need?: string;
  // otras propiedades según sea necesario
}

interface IModalSelector {
  docData?: DocData;
  showContactModal: boolean;
  setShowContactModal: (value: boolean) => void;
  extraServiceModalTitle?: string;
  language: string;
}

function removeHtmlTags(text) {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
}

const ModalSelector = ({ docData, showContactModal, setShowContactModal, extraServiceModalTitle = '', language }: IModalSelector) => {
  const parsedShortDescription = docData?.shortDescription?.[language];
  const { t } = useTranslation();

  const selectedOptionsWithoutHTML = docData?.selectedOptions
    ? docData?.selectedOptions.replace(/<\/?[^>]+(>|$)/g, ' ')
    : '';

  switch (docData?.category) {
    case "Yatchs":
      return (
        <DetailContactModal
          title={t('rent_this_yacht_now')}
          isOpen={showContactModal}
          onClose={() => setShowContactModal(false)}
          type="Yatchs"
          businessName={docData?.name[language] || ''}
          description={`${t('rent_now')}: ${docData?.name[language] || ''}. ${parsedShortDescription}
          ${t('this_yacht_is_managed_by_whatdoyouneed7')}`}
          message={`${t('hello_i_am_interested_in_renting_the_yacht')} ${docData?.name[language]} con el siguiente detalle: ${removeHtmlTags(parsedShortDescription)}`}
        />
      );
    case "Business":
      return (
        <BusinessContactModal
          isOpen={showContactModal}
          onClose={() => setShowContactModal(false)}
          businessName={docData?.name[language]}
        />
      );
    case "TechnicalServices":
      return (
        <DetailContactModal
          title="Contrata nuestros servicios"
          type="TechnicalServices"
          isOpen={showContactModal}
          onClose={() => setShowContactModal(false)}
          businessName={docData?.name[language]}
          description="Te ayudamos a encontrar el servicio que necesitas"
          message="Hola, estoy interesado en el servicio"
        />
      );
    case "Form":
      return (
        <DetailContactModal
          title="¡Contrata nuestros servicios!"
          isOpen={showContactModal}
          onClose={() => setShowContactModal(false)}
          businessName={extraServiceModalTitle}
          description={`Deseo recibir información de los siguiente servicios:
          <ul>${docData?.selectedOptions}</ul>
          ${docData?.level}.<br>
          Y mi necesidad especifica es: ${docData?.need}`}
          message={`Hola, deseo recibir información de los siguiente servicios:
          ${selectedOptionsWithoutHTML}.
          Los solicito con un nivel de urgencia: ${docData?.level}.
          Y mi necesidad especifica es: ${docData?.need}`}
        />
      );
    default:
      return (
        <DetailContactModal
          title={`¡Pregunta por ${docData?.name[language]}!`}
          isOpen={showContactModal}
          onClose={() => setShowContactModal(false)}
          businessName={docData?.name[language] || ''}
          description={`Te ayudamos a obtener más información sobre ${docData?.name[language]}. ${docData?.shortDescription[language]}.
          Contáctenos ahora para obtener más información sobre nuestros productos, servicios y precios.`}
          message={`Hola, estoy interesado en ${docData?.name[language]} desde ${extraServiceModalTitle} con el siguiente detalle: ${docData?.shortDescription[language]}`}
        />
      );;
  }
};

export default ModalSelector;
