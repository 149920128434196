import { useTranslation } from "react-i18next";
import { BREAKPOINTS } from "constants/index";
import {
  Container,
  Content,
  Description,
  Image,
  ImageContainer,
  TextContainer,
  Title,
} from "./styled";

export const Banner = () => {
  const { t } = useTranslation();

  return (
    <Container pav="1rem" pah="8rem">
      {window.innerWidth < BREAKPOINTS.lg && (
        <Title family={"Outfit, monospace"} weight={700} color={"#0C121D"}>
          {t('are_you_thinking_about_chartering_a_private_yacht?')}
        </Title>
      )}
      <Content>
        <TextContainer>
          {window.innerWidth > BREAKPOINTS.lg && (
            <Title family={"Outfit, monospace"} weight={700} color={"#0C121D"}>
              {t('are_you_thinking_about_chartering_a_private_yacht?')}
            </Title>
          )}
          <Description>
            {t('banner_charter_your_private_yacht_description_short')}
          </Description>
          <Description>
            {t('banner_charter_your_private_yacht_services')}
          </Description>
        </TextContainer>
        <ImageContainer>
          <Image
            src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Fimages%2Fmujer-sonriente-algo-presentacion-auriculares-removebg-preview.webp?alt=media&token=063b478b-9369-4b9c-a6a0-fc01a44a2145"
            loading="lazy" alt="Mujer sonriendo" title="Mujer sonriendo"
          />
        </ImageContainer>
      </Content>
    </Container>
  );
};
