export function getCardType(cardNumber: string): string {
    // Asigna el tipo de tarjeta como "desconocido" por defecto
    let cardType = 'unknown';
  
    // Elimina espacios en blanco y separadores de grupo de dígitos
    cardNumber = cardNumber.replace(/\s+|-/g, '');
  
    // Si el número de la tarjeta comienza con "34" o "37", es una tarjeta American Express
    if (/^(34|37)/.test(cardNumber)) {
      cardType = 'American Express';
    }
    // Si el número de la tarjeta comienza con "62", es una tarjeta UnionPay
    else if (/^62/.test(cardNumber)) {
      cardType = 'UnionPay';
    }
    // Si el número de la tarjeta comienza con "4", es una tarjeta Visa
    else if (/^4/.test(cardNumber)) {
      cardType = 'Visa';
    }
    // Si el número de la tarjeta comienza con "5", es una tarjeta Mastercard
    else if (/^5/.test(cardNumber)) {
      cardType = 'Mastercard';
    }
    // Si el número de la tarjeta comienza con "6011" o un número que comience con "65", es una tarjeta Discover
    else if (/^6011|^65/.test(cardNumber)) {
      cardType = 'Discover';
    }
    return cardType;
  }