

import { Container, ImageIcon } from './styled'
import {ICategoryClassified} from 'types'
import { useSelector } from 'react-redux'
import { getLanguage } from 'redux/common'
import { useNavigate } from 'react-router-dom'

interface PropsCardCategoryClassified {
    data: ICategoryClassified;
    props: any;
}

export const CardCategoryClassified = (props: PropsCardCategoryClassified) => {
    const { data, props: properties} = props;
    const navigate = useNavigate();
    const language = useSelector(getLanguage);

    const { image, name } = data;

    function handleClick() {
        navigate(`/classifieds/category/${data.category}`);
    }

    return (
        <Container onClick={handleClick} isSelected={properties.categoryType === data.category}>
            <ImageIcon src={image} loading="lazy" alt={name[language]} title={name[language]} />
            <p>{name[language]}</p> 
        </Container>
    )

}

export default CardCategoryClassified;