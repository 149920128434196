import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'config/firebase';
import { useTranslation } from 'react-i18next'; 
interface ReportBugParams {
  bugDescription: string;
}

interface ReportBugResponse {
  data: any;
}

const useReportBug = () => {
  const { t } = useTranslation();


  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null  |  string>(null);
  const [data, setData] = useState<ReportBugResponse | null>(null);

  const reportBug = async (params: ReportBugParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const reportBugFunction = httpsCallable(functions, 'reportBug'); 
      const response: any = await reportBugFunction(params);

      setData(response.data as any);
    } catch (error: any) {
      setError(t('error_sending_your_report'));

    } finally {
      setIsLoading(false);
    }
  };

  return { reportBug, isLoading, data, error };
};

export default useReportBug;
