import { FC } from "react";
import { Container, Grid, CardItem } from "./styled";
import { THEME } from "constants/theme/theme";
import { P } from "ui/Text";

interface CardSelectOperationTypeProps {
    value: string[];
    onChange: (value: string[]) => void;
    label?: string;
    errorMessage?: string | string[];
    disabled?: boolean;
}

const TYPE_OPERATION = ['sale', 'rent', 'short-rental'];

const CardOperationType: FC<CardSelectOperationTypeProps> = (props: CardSelectOperationTypeProps) => {
    const { value, onChange, label, errorMessage, disabled } = props;

    function getLabel(value: string) {
        switch (value) {
            case 'sale':
                return 'Venta';
            case 'rent':
                return 'Alquiler';
            case 'short-rental':
                return 'Alquiler corta duración';    
            default:
                return 'Tipo de operación';
        }
    }

    function handleCardClick(value: string) {
        
        if (props.value.includes(value)) {
            onChange(props.value.filter((item) => item !== value));
        } else {
            onChange([...props.value, value]);
        }
    }

    return (
        <Container>
            <P style={{marginBottom: '.5rem', fontSize: '14px', color: THEME.colors.black80}}>{label ? label : 'Tipo de dirección'}</P>
            <Grid>          
                {
                    TYPE_OPERATION.map((type) => {
                        return (
                            <CardItem
                                key={type}
                                selected={value.includes(type)}
                                disabled={disabled}
                                onClick={() => {
                                    if (!disabled) {
                                        handleCardClick(type);
                                    }
                                }}
                            >
                                {getLabel(type)}
                            </CardItem>
                        )
                    }
                    )
                }
            </Grid>
            <p>
                {
                    errorMessage
                }
            </p>
        </Container>
    )
}

export default CardOperationType;
