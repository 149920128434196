import { Container, Titles, Link, SponsorBuy } from "./styled";
import YatchCard from "components/cards/Commons/YatchCard";
import Carousel from "react-multi-carousel";
import Skeleton from "@mui/material/Skeleton";
import { BREAKPOINTS } from "constants/index";
import { useTranslation } from "react-i18next";


export const ListYatchs = (props) => {
  const { yatchs } = props;
  const { carousel } = props;
  const { t } = useTranslation();

  const ItemSkeletons = [1, 2, 3, 4];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

  return (
    <Container fondo="#E7EDF5" pav="2rem" pah="2rem">
      <div
        className={
          "d-flex justify-content-between align-items-center w-100 mb-2"
        }
      >
        <Titles alignM={"center"} size={"1.5rem"}>
          {t('rent_your_private_yacht')}
        </Titles>
        {window.innerWidth > BREAKPOINTS.lg && <Link href={"yatchs"}>{t('ver_todas')}</Link>}
      </div>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        arrows={false}
      >
        {yatchs !== null
          ? yatchs.map((item, index) => {
            return (
              <div key={index} style={{marginRight:  index === yatchs.length - 1 ? 0 : 5 }} >
                <YatchCard carousel={carousel}  data={item} isExtraCard={true} />
              </div>
            );
          })
          : ItemSkeletons.map((item) => {
            return (
              <div key={item}>
                <div>
                  <Skeleton
                    variant={"rounded"}
                    width={"95%"}
                    height={200}
                  />
                  <Skeleton variant={"text"} width={"20%"} />
                  <Skeleton variant={"text"} width={"60%"} />
                  <Skeleton variant={"text"} width={"100%"} />
                  <SponsorBuy>
                    <Skeleton
                      variant={"rounded"}
                      width={"25%"}
                      height={25}
                    />
                    <Skeleton
                      variant={"rounded"}
                      width={"25%"}
                      height={25}
                    />
                  </SponsorBuy>
                </div>
              </div>
            );
          })}
      </Carousel>

      {/* {window.innerWidth < BREAKPOINTS.lg && (
        <div>
          <Link href={"yatchs"}>Ver todas</Link>
        </div>
      )} */}
    </Container>
  );
};
