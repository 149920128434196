import {
  FlagImage,
  GridLanguage,
  ItemLanguage,
  Row,
  Container,
} from "./styled";
import { PANAMA_FLAG_URL } from "constants/images";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { THEME } from "constants/theme/theme";

const CountryContentConfigModal = () => {
  return (
    <Container>
      <h6>Seleccionar país</h6>
      <GridLanguage>
        <ItemLanguage isSelected={true}>
          <FlagImage
            src={PANAMA_FLAG_URL}
            alt="Panama flag"
            title="Panama flag"
          />
          <Row>
            <span>Panamá</span>
            <CheckCircleIcon
              style={{ color: THEME.colors.positive80, fontSize: "16px" }}
            />
          </Row>
        </ItemLanguage>
      </GridLanguage>
    </Container>
  );
};

export default CountryContentConfigModal;
