import styled from 'styled-components';

interface IRow {
    display?: string;
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    margin?: string;
    padding?: string;
    width?: string;
    height?: string;
}

export const Row = styled.div`
    display: ${(props: IRow) => props.display || 'flex'};
    flex-direction: ${(props: IRow) => props.flexDirection || 'row'};
    justify-content: ${(props: IRow) => props.justifyContent || 'flex-start'};
    align-items: ${(props: IRow) => props.alignItems || 'flex-start'};
    margin: ${(props: IRow) => props.margin || '0'};
    padding: ${(props: IRow) => props.padding || '0'};
    width: ${(props: IRow) => props.width || 'auto'};
    height: ${(props: IRow) => props.height || 'auto'};
`;