import { createTheme, ThemeProvider } from "@mui/material";
import { Container,  TitleSection, SkeletonContainer } from "./styled";
import { ButtonsContainer } from "ui/Products/styled";
import Product from "ui/Products";
import Skeleton from "@mui/material/Skeleton";
import CarouselGeneral from "components/carousel-general";
import { useTranslation } from "react-i18next";


const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export const RelatedProducts = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  const ItemSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <Container background="#E7F7f6" pav="2rem" pah="2rem">
      <TitleSection margin="0px 0px 22px 0px" size="1.5rem">
        {t('business_product_catalog')}
      </TitleSection>
      {data.length > 0 ? (
        <CarouselGeneral
          items={data}
          renderItem={Product}
          itemsToShow={5}
          itemsTablet={3}
          itemsMobile={2}
        />
      ) : (
        ItemSkeletons.map((item) => (
          <div style={{width: '100%'}} key={item}>
            <ThemeProvider theme={lightTheme}>
              <SkeletonContainer>
                {
                  ItemSkeletons.map((item) => (
                    <div style={{ width: '100%' }}>
                      <Skeleton
                        variant={"rounded"}
                        height={200}
                        width={"100%"}
                        style={{ margin: "0 auto" }}
                      />
                      <Skeleton variant={"text"} width={"20%"} />
                      <Skeleton variant={"text"} width={"80%"} />
                      <Skeleton variant={"text"} width={"95%"} />
                      <ButtonsContainer>
                        <Skeleton variant={"rounded"} width={"80%"} />
                      </ButtonsContainer>
                    </div>
                  ))
                }
              </SkeletonContainer>
            </ThemeProvider>
          </div>
        ))
      )}
    </Container>
  );
};
