import styled from "styled-components";
import Badge, { BadgeProps } from '@mui/material/Badge';
interface IHeaderProps {
    background?: string;
    shadow?: string;
}


interface IHeaderProps {
  paddingBottom?: string;
}

export const Header = styled.header<IHeaderProps>`
  width: 100%;
  max-width: 100%;
  min-height: 90px;
  position: fixed;
  z-index: 997;
  padding: 0.5rem .8rem 0.5rem .8rem;
  background: ${(props: IHeaderProps) => props.background || "#3A36DB"};
  color: #ffffff;
  display: flex;
  align-items: center;
  // box-shadow: ${(props: IHeaderProps) => props.shadow === "main" ? "0 4px 8px 2px rgba(2, 64, 154, 0.16)" : "none"};
  @media (max-width: 992px) {
    padding: 1rem .8rem 0.1rem .8rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: ${(props: IHeaderProps) => props.paddingBottom || "70px"};
  }
`

export const Shopping = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
export const LangSmallContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    margin-left: 20px;
  }
`

export const DialogT = styled.h2`
  text-align: center;
  color: #102746;
  ${props => props.theme.typography.subheading.md.bold};
  margin-bottom: 2rem;
  &:nth-child(3){
    margin-top:4rem;
  }
`
export const LangSmall = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    border-radius: .4rem;
    padding: 0.05rem .8rem;
    border: 1px solid #FDFDFE;
  }
`
export const MobileWrap = styled.div`
  display: none;
  width: 100%;
  background: #3A36DB;
  color: #ffffff;
  padding: .8rem;

`

export const Title = styled.h1`
  margin-left: 4rem;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin: 0 auto;
  }
`

export const SpanMiddle = styled.span`
  vertical-align: middle;
  font-weight: 700;
  cursor: pointer;
  margin: 0px 4px;
  //  text in the perfect center

  &:hover {
    opacity: 0.8;
  }
`


export const InputSearch = styled.input`
  border: 1px solid black;
  all: unset;
  font: 16px system-ui;
  color: #000000;
  height: 100%;
  width: 100%;
  padding: 10px 2rem;
  &::placeholder{
    font-size: .8rem;
    color:#3835d7;
  }
`

export const RightMenu = styled.div`
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -16px;
  /* margin-left: 30px; */
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const NameUser = styled.p`
  font-size: .8rem;
  font-weight: 500;
  margin-bottom: 0px;

  &:hover {
    opacity: 0.8;
  }
`


export const MenuItem = styled.div`
  font-size: .8rem;
  margin-left: .5rem;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 63vw;
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    width: 80vw;
  }

  @media (max-width: 360px) {
    width: 70vw;
  }
`
export const ClickableTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  
  @media (min-width: 768px) {
    display: none;
  }

`

export const Logo = styled.img`
  width: 220px;
  margin: 0 0 0 5em;
  object-fit: contain;
  cursor: pointer;

  transform: scale(0.95);
  &:hover {
    opacity: .8;
  }

  @media (min-width: 992px) {
    margin-top: -10px;
  }

  @media (max-width: 992px) {
    width: 180px;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`

export const DBodyLangs = styled.div`
  display: flex;
  justify-content: space-around;
`
export const DBodyLang = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:.5rem;
`
export const DBodyLangImg = styled.img`
    width: 25vw;
`

export const DBodyLangName= styled.p`
  ${props => props.theme.typography.body.md.regular};
`
export const DBodyCountries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const DBodyCountry = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid #bebec9;
`
export const DBodyCountryImg = styled.img`
    width: 25vw;
`

export const DBodyCountryName= styled.p`
  margin:0;
  ${props => props.theme.typography.body.md.regular};
`

export const SearchBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 99;
`

export const MainCategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* padding-left: 2rem; */

  @media (min-width: 992px ) {
    padding: .3rem 0;
  }
  
  &::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
  }
`;

// export const MainCategoriesContainer = styled.div`
//   display: flex;
//   padding: 0px 12px;
//   justify-content: center;
//   gap: 16px;
// `

export const LinkItemMainCategory = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 480px) {
    font-size: 12px;
    gap: 8px;
  }

  @media (max-width: 380px) {
    font-size: 10px;
  }

  @media (max-width: 320px) {
    font-size: 8px;
  }

  &:hover {
    opacity: 0.8;
    color: #FBFBFB;
    transition: 0.3s ease-in-out;
    transform: scale(1.02);
  }
`

export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -6,
    top: -8,
    border: `1px solid #fff`,
    backgroundColor: '#fff',
    color: '#3A36DB',
    padding: '0 1px',
    transform: 'scale(.7)',
  },
}));


// Config Popup

interface IConfigPopupProps {
  responsive?: boolean;
}

export const PopupConfigContainer = styled.div`
  /* width: 60px; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  padding: 4px 8px;
  margin-left: 2rem;
  margin-right: -1rem;
  margin-bottom: 1rem;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 992px) {
    display: ${(props: IConfigPopupProps) => props.responsive ? "flex" : "none"};
  }

  @media (max-width: 360px) {
    display: none;
  }
  
`
export const FlagCountrySelected = styled.img`
  width: 18px;
  margin-right: 4px;
  padding-right: 4px;
  `

export const TextLanguageSelected = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  margin-left: 4px;
  text-transform: uppercase;
`

export const Separator = styled.div`
  width: 1px;
  height: 70%;
  background-color: #FFFFFF;
`