import { Container, Cuad, Titles } from "./styled";
import PropertiesCard from "components/cards/services/properties";
import { getTextOperationType } from "./main";
import { priceFormat } from "utils/common";
import { useTranslation } from "react-i18next";


export const Properties = (props) => {
  const { properties } = props;
  const { t, i18n } = useTranslation();

  return (
    <Container background="#ffffff" pav="2rem" s>
      <div
        className={
          "d-flex justify-content-between align-items-center w-100 mb-2"
        }
      >
        <Titles alignM={"center"} size={"1.5rem"} className={"w-100"}>
          {t('available_properties')}
        </Titles>
      </div>
      <Cuad>
        {properties.map((service, index) => {
          return (
            <PropertiesCard
              key={index}
              title={service?.name?.es || ''}
              images={service?.images || []} 
              link={`detail?id=${service.id}&category=RealState`}
              shortDescription={service?.shortDescription?.[i18n.language]}
              description={service?.description?.es}
              price={priceFormat(service?.price || '')}
              location={service?.location}
              operationType={service?.operationType?.[0] ? getTextOperationType(service?.operationType?.[0]) : ''}
              numberOfRooms={service?.numberOfRooms}
              numberOfBathrooms={service?.numberOfBathrooms}
              numberOfParkings={service?.numberOfParkings}
              squareMeters={service?.squareMeters}
            />
          )


          // if (index === 3)
          //   return (
          //     <>
          //       <ImgAdd
          //         src={
          //           window.innerWidth < 768
          //             ? "https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/countries%2FPANAMA%2Fbanners%2Fservices%2FRealState%2Fbanner%20real%20state%20responsive.png?alt=media&token=34f27c73-1ba9-41f0-8544-4d9e210bbdb8"
          //             : 'https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FServices%2Fcategories%2FrealEstate%2FPromociona%20tus%20propiedades%20con%20nosotros%2C%20Venta%20y%20alquiler%20de%20bienes%20raices%2C%20Venta%20y%20alquiler%20de%20casas%20apartamentos.webp?alt=media&token=c1824bcc-9b64-444c-8800-08515f8ae2a1'
          //         }
          //         alt=""
          //       />
          //       <PropertiesCard
          //         linkButton={true}
          //         showPrice={false}
          //         key={index}
          //         id={service?.id}
          //         title={service?.name?.es}
          //         description={service?.description?.es}
          //         image={service?.image}
          //         price={service?.price?.es}
          //         shortDescription={service?.shortDescription?.es}
          //         service={service}
          //       />
          //     </>
          //   );

          // if (index > 3) return <PropertiesCard
          //   linkButton={true}
          //   showPrice={false}
          //   key={index}
          //   id={service?.id}
          //   title={service?.name?.es}
          //   description={service?.description?.es}
          //   image={service?.image}
          //   price={service?.price?.es}
          //   shortDescription={service?.shortDescription?.es}
          //   service={service}
          // />;
        })}
      </Cuad>
    </Container>
  );
};
