import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DataContainer, InputWrap, Info, ButtonsNavsContainer } from "../styled";
import Input from "ui/TextInput";
import { Button, BackButton } from "ui/Buttons";
import PhoneInput from "ui/PhoneInput/phone-input";
import { useNotification } from "libs/context/AlertContext";
import { User } from "types";
import { updateUserBasicInfo  } from "services/user/updateInfo/service-update-user-info";
import { UserBasicDataFormProps } from "types/common"; 
import { isValidEmail } from "utils/common";
import { STEPS_CHECKOUT } from "../constants";
import { MAIN_ROUTES } from "constants/routes";
import { useSelector, useDispatch } from "react-redux";
import { setOfflineUser, offlineUserInfoSelector, getIpInfoSelector } from "redux/user";
import { setLastStep } from "redux/checkout";
interface StepOneProps {
  onSubmit: (step: number) => void;
  user: User[] | undefined;
  setData: (data: any) => void;
}

const StepOne = (props: StepOneProps) => {
  const { showSuccess, showError, showWarning } = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onSubmit, user: userData, setData } = props;
  const user = userData ? userData : [];
  const offlineUserInfo = useSelector(offlineUserInfoSelector);
  const ipInfo = useSelector(getIpInfoSelector);
  const countryIp = ipInfo.location.country.code.toLowerCase();

  const userUid: string =  user?.length ? user[0].uid : '';

  const handleUpdateUser = async (values: UserBasicDataFormProps) => {

   if (userUid) {
    updateUserBasicInfo(userUid, values)
    .then((res) => {
      setData((prev) => ({
        ...prev,
        userInfo: { ...values },
      }));
      onSubmit(STEPS_CHECKOUT.SHYPMENT_ADDRESS);
      showSuccess(t('data_updated_successfully'));
    })
    .catch((err) => {
      showError(t('error_updating_data'));
    });
    } else {
      dispatch(setOfflineUser({
        firstName: values.firstName?.trim() || '',
        firstSurname: values?.firstSurname?.trim() || '',
        email: values.email || '',
        phone: values.phone || '',
      }));
      onSubmit(STEPS_CHECKOUT.SHYPMENT_ADDRESS);
    }
  };

  const handleBack = () => {
    dispatch(setLastStep(0));
    navigate(-1);
}

  return (
    <Formik
      initialValues={{
        firstName:  (user.length && user[0].firstName) ? user[0].firstName : offlineUserInfo?.firstName || "",
        firstSurname: (user.length && user[0].firstSurname) ? user[0].firstSurname : offlineUserInfo?.firstSurname || "",
        phone: (user.length && user[0].phone )? user[0].phone : offlineUserInfo?.phone || "",
        email: (user.length && user[0].email) ? user[0].email : offlineUserInfo?.email  || "",
      }}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleUpdateUser(values);
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <>
          <DataContainer id="step1Form" onSubmit={handleSubmit}>
            <InputWrap size={"45%"}>
              <Input
                value={values.firstName}
                name="firstName"
                onChange={handleChange}
                type="text"
                label={t('name')}
              />
              <Info>{t('enter_it_as_it_appears_on_your_document')}</Info>
            </InputWrap>
            <InputWrap size={"45%"}>
              <Input
                value={values.firstSurname}
                name="firstSurname"
                onChange={handleChange}
                type="text"
                label={t('last_name')}
              />
              <Info>{t('enter_it_as_it_appears_on_your_document')}</Info>
            </InputWrap>
            <InputWrap style={{ zIndex: 3 }} size={"45%"}>
              <PhoneInput
                onChange={(e) => handleChange("phone")(e)}
                value={values.phone}
                country={countryIp}
              />
              <Info>{t('make_sure_you_have_access_to_this_phone')}</Info>
            </InputWrap>
            <InputWrap style={{ zIndex: 2 }} size={"45%"}>
              <Input
                value={values.email}
                name="email"
                onChange={handleChange}
                type="email"
                label={t('email')}
              /> 
              <Info>{t('make_sure_you_have_access_to_this_email')}</Info>
            </InputWrap>
          </DataContainer>
          <ButtonsNavsContainer>
              <BackButton text={t('return_to_cart') || ''} onClick={() => {
                userData?.length ? navigate(MAIN_ROUTES.Cart) : handleBack()
              }}/>
            <Button
              onClick={handleSubmit}
              disabled={isSubmitting || !values.firstName || !values.firstSurname || !values.phone || !isValidEmail(values.email)}
              onDisabledClick={() => showWarning(t('complete_all_fields') || '')}
              type="submit"
            >
              {t('continue')}
            </Button>
          </ButtonsNavsContainer>
        </>
      )}
    </Formik>
  );
};

export default StepOne;
