import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSagasTrigger } from 'libs/redux/sagas/trigger';

export type InitialState = typeof initialState;

interface IState {
  total: number;
  items: Array<{ id: number; count: number, name: string, price: number }>;
  discount: number;
  error: string | null;
  loading: boolean;
}

export const initialState: IState = {
  total: 0,
  items: [],
  discount: 0,
  error: null,
  loading: false,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
      setLoading(state, action) {
        state.loading = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
      },
      cleanCart(state) {
        Object.assign(state, initialState);
      },
      setCart(state, action: PayloadAction<{ items: Array<{ id: number; count: number, name: string, price: number }> }>) {
        // calculate total with arra of items
        state.total = action.payload.items.reduce((acc, item) => acc + item.count * item.price, 0);
        state.items = action.payload.items;
      },
      addToCart(state, action: PayloadAction<{ id: number; count: number, name: string, price: string, image: string }>) {
        const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
        const price = parseFloat(action.payload.price);
        if (itemIndex >= 0) {
          state.items[itemIndex].count += action.payload.count;
          state.total += action.payload.count * price;
        } else {
          state.items.push({...action.payload, price});
          state.total += action.payload.count * price;
        }
      },
      updateCartItem(state, action: PayloadAction<{ id: number | string, count: number  }>) {
        const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
        if (itemIndex >= 0) {
          const oldCount = state.items[itemIndex].count;
          // @ts-ignore
          const price = parseFloat(state.items[itemIndex].price);
          state.items[itemIndex].count = action.payload.count;
          state.total += (action.payload.count - oldCount) * price;
        }
      },
      removeFromCart(state, action: PayloadAction<number | string>) {
        const itemIndex = state.items.findIndex(item => item.id === action.payload);
        if (itemIndex >= 0) {
          const itemCount = state.items[itemIndex].count;
          // @ts-ignore
          const itemPrice = parseFloat(state.items[itemIndex].price);
          state.total -= itemCount * itemPrice;
          state.items.splice(itemIndex, 1);
        }
      },
    },
  });
  
  export const { setLoading, setError, cleanCart, addToCart, updateCartItem, removeFromCart, setCart } = cartSlice.actions;


  export const getCartSagasTrigger = createSagasTrigger('get_cart_sagas');

  export const addToCartSagasTrigger = createSagasTrigger<{ id: string | number; count: number, name: string, price: string, image: string }>('add_to_cart_sagas');
  
  export const updateCartItemSagasTrigger = createSagasTrigger<{ id: string | number; count: number }>('update_cart_item_sagas');    
  
  export const removeFromCartSagasTrigger = createSagasTrigger<{id: string | number}>('remove_from_cart_sagas');
  
  export const cleanCartSagasTrigger = createSagasTrigger('clean_cart_sagas');
  
  
  export const cartReducer = cartSlice.reducer;