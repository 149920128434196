import { FC } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import ServicesCard from "components/cards/services-card";
import "./directory.css";
import { Container, FlexColumn, Title, WrapperTitle } from "./styled"; // Link
import { RESPONSIVE_BREAKPOINTS } from "modules/services/constants";
import { servicesTitles } from "__mocks__/servicesMock";
import { useTranslation } from "react-i18next";
import { getLanguage } from "redux/common";
// import { BREAKPOINTS } from "constants/index";

interface DirectoryProps {
  title: string;
  services: any[];
}
export const Directory: FC<DirectoryProps> = ({ title, services }) => {
  // const isDesktop = window.innerWidth > BREAKPOINTS.lg;
  const language = useSelector(getLanguage);
  const { t } = useTranslation();

  return (
    <Container>
      <FlexColumn>
        <WrapperTitle>
          <Title>{t(servicesTitles[title])}</Title>
          {/* {isDesktop && <Link href={""}>Ver todas</Link>} */}
        </WrapperTitle>

        <Carousel
          responsive={RESPONSIVE_BREAKPOINTS}
          infinite={true}
          autoPlay={!true}
          autoPlaySpeed={4000}
          arrows={true}
          itemClass="carouselItem"
        >
          {services.length > 0 &&
            services.map((service, index) => {
              return (
                <ServicesCard
                  linkButton={true}
                  showPrice={false}
                  key={index}
                  id={service?.id || service?.uid}
                  title={service?.name?.[language]}
                  description={
                    service?.description?.[language] ||
                    service?.shortDescription?.[language]
                  }
                  image={
                    service?.images?.length
                      ? service?.images[0]
                      : service?.image || ""
                  }
                  price={service?.price || service?.price?.[language]}
                  shortDescription={service?.shortDescription?.[language]}
                  service={service}
                />
              );
            })}
        </Carousel>
      </FlexColumn>
    </Container>
  );
};

export default Directory;
