import { useState, useEffect } from "react";
import { Address } from "types/common";
import { getUserAddresses } from "services/user/updateInfo/service-update-user-info";

const useUserAddressesListener = (userUid: string, hideBilling?: boolean): Address[] => {
  const [addresses, setAddresses] = useState<Address[]>([]);

  useEffect(() => {
    if (userUid) {
      let unsubscribe: (() => void) | null = null;

      try {
        const startListening = async () => {
          unsubscribe = await getUserAddresses(userUid, (updatedAddresses) => {
            const addressesOrdered = updatedAddresses.sort((a, b) => {
              return 0;
            });

            let addressesFiltered;

            if (hideBilling) {
              addressesFiltered = addressesOrdered.filter((address) => address.isShipping);
              setAddresses(addressesFiltered);
            } else {
              setAddresses(addressesOrdered);
            }
          });
        };
        startListening();
      } catch (error) {
        console.error('Error getting the addresses', error);
      }

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    } else {
      setAddresses([]);
    }
  }, [userUid, hideBilling]);

  return addresses;
};

export default useUserAddressesListener;
