import { useState, useEffect } from 'react' 
import MainLayout from 'views/layout/MainLayout/main-layout';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetProductsByMultiplesCategories } from 'hooks/products/useGetProductsByMultipleCategories';
import CategoriesCarousel from 'views/containers/SliderCategories'
import ContentProducts from "views/containers/Products/ContentProducts";
import ContentProductsFiltered from "views/containers/Products/ContentProductsFiltered";
import useCategories from 'hooks/useGetCategories/useGetCategories';
import { useGetFirestoreDoc } from 'hooks/useGetDocumentFirestore';
import { Container, Content } from "./styled"
import { FiltersProducts } from 'types';
import { DB_ROUTES } from "constants/routes";
import { useGetFirestoreCollection } from 'hooks/useGetCollectionFirestore/useGetCollectionFirestore';
import useAnalytics from 'hooks/useAnalytics/useAnalytics';
import { useTranslation } from 'react-i18next';
import { getLanguage, getCountry } from 'redux/common';
import { buildQueryFilterProducts } from '../main';
import { ProductStoreCategories } from 'constants/products-constants';
import SimpleImageBanner from 'ui/simple-image-banner';

const { CATEGORIES_PRODUCTS_STORE } = DB_ROUTES
const LIST_CATEGORIES: number[] = Object.values(ProductStoreCategories).filter((value) => typeof value === "number") as number[];

const StoreProducts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const language = useSelector(getLanguage)
    const country = useSelector(getCountry)
    const registerLog = useAnalytics();

    const COLLECTION_PATH = `${country}${DB_ROUTES.POSTS_PRODUCTS_STORE}`

    const { docData: bannersData, fetchDoc: fetchBanners } = useGetFirestoreDoc();
    const { fetchCollection: fetchProductsByFilters, collectionData: productsByFilters, clearCollectionData: clearSearchProductsByFilter } = useGetFirestoreCollection();
    const {  productsByMultipleCategories, } = useGetProductsByMultiplesCategories(
        {
            ids: LIST_CATEGORIES,
            collectionPath: COLLECTION_PATH
        }
    );

    const { categories } = useCategories(`${country}${CATEGORIES_PRODUCTS_STORE}`, [{
        field: 'active',
        value: true
    }]);

    const { selRoute, selRouteEs } = location?.state || "";

    const [category, setCategory] = useState("");
    const [bannerImages, setBannerImages] = useState<string[]>([]);
    const [filters, setFilters] = useState<FiltersProducts>({});

    const handleSelectCategory = (category: string) => {
        clearSearchProductsByFilter();
        navigate(`/products/category?uid=${category}`)
    }

    useEffect(() => {
        if (selRoute !== undefined && selRoute !== "") {
            setCategory(selRouteEs);
        }

        if (bannersData) {
            const imagesByResolution = () => {
                if (window.innerWidth < 768) {
                    return bannersData.imagesMobile
                } else {
                    return bannersData.imagesDesktop
                }
            }

            setBannerImages(imagesByResolution())
        } else {
            fetchBanners({ path: `${country}/Banners/ProductsBanners/Es/Main` })
        }
        registerLog({ screenName: 'Products store', pageTitle: 'Products store of whatdoyouneed7.com', pagePath: window.location.pathname, pageLocation: window.location.href });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersData]);

    const handleSetFilters = (filters: FiltersProducts) => {
        const queryData = buildQueryFilterProducts(filters)
        setFilters(filters);

        fetchProductsByFilters({
            path: `${country}${DB_ROUTES.POSTS_PRODUCTS_STORE}`,
            filters: queryData.query as any,
            orderFilter: queryData.orderBy
        })
    }

    return (
        <MainLayout type='products'>
            <Container>
                <CategoriesCarousel
                    category={category}
                    onSelectCategory={handleSelectCategory}
                    handleType={() => { }}
                    background={"linear-gradient(180deg, #B218FF 0%, #8900CE 100%)"}
                    type={language === 'es' ?  'productos' : 'products' }
                    data={categories}
                    images={bannerImages}
                />
                <Content>
                    {
                        productsByFilters && productsByFilters.length > 0 ? (
                            <ContentProductsFiltered
                                data={productsByFilters as any}
                                categories={categories}
                                setFilters={handleSetFilters}
                                filters={filters}
                            />
                        ) : (
                            <ContentProducts
                                type='store'
                                setRoute={setCategory}
                                categories={categories}
                                setFilters={handleSetFilters}
                                data={productsByMultipleCategories}
                                titleSection={t('retail_store') || ''}
                                filters={filters}
                            />
                        )
                    }
                </Content>
            <SimpleImageBanner hrefLink='https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+ofertas+en+Gaming+y+Softwares+Digitales.&type=phone_number&app_absent=0' imageUrl='https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FOfertas%20en%20claves%20de%20windows%2C%20y%20programas%20digitales%2C%20juegos%20y%20membresias.webp?alt=media&token=7fd46d0e-edd3-41a1-98cd-ab723514cd9b'/>

            </Container>
        </MainLayout>
    )
}

export default StoreProducts
