import {createSelector} from '@reduxjs/toolkit'
import { Address, OfflineAddress } from 'types'

// TODO: import Store type and add reducer type in Store interface
const getCheckout = (state: any) => state.checkout

export const getShippingType = createSelector(getCheckout, (checkout) => checkout.shippingType)

export const getAddress = createSelector(getCheckout, (checkout) => checkout.address as Address)

export const getOfflineAddress = createSelector(getCheckout, (checkout) => checkout.offlineAddress as OfflineAddress)

export const getPaymentMethod = createSelector(getCheckout, (checkout) => checkout.paymentMethod)

export const getIsPaidSuccess = createSelector(getCheckout, (checkout) => checkout.isPaidSuccess)

export const getLastStepCheckout = createSelector(getCheckout, (checkout) => checkout.lastStep)

export const getUidCheckout = createSelector(getCheckout, (checkout) => checkout.uid)