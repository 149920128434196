
import { useDispatch, useSelector } from "react-redux";
import { setIpInfo } from "redux/user";
const IP_REGISTER_API_KEY = process.env.REACT_APP_IP_REGISTRY_API_KEY;
const URL_IP_REGISTER_API = 'https://api.ipregistry.co';


export interface IpGeolocation {
    ip?: string;
}

export interface IpGeolocationResponse {
    ip: string;
    type: string;
    hostname: string;
    carrier: {
        name: string;
        mcc: string;
        mnc: string;
    };
    company: {
        domain: string;
        name: string;
        type: string;
    };
    connection: {
        asn: number;
        domain: string;
        organization: string;
        route: string;
        type: string;
    };
    currency: {
        code: string;
        name: string;
        name_native: string;
        plural: string;
        plural_native: string;
        symbol: string;
        symbol_native: string;
        format: {
            negative: {
                prefix: string;
                suffix: string;
            };
            positive: {
                prefix: string;
                suffix: string;
            };
        };
    };
    location: {
        continent: {
            code: string;
            name: string;
        };
        country: {
            area: number;
            borders: string[];
            calling_code: string;
            capital: string;
            code: string;
            name: string;
            population: number;
            population_density: number;
            flag: {
                emoji: string;
                emoji_unicode: string;
                emojitwo: string;
                noto: string;
                twemoji: string;
                wikimedia: string;
            };
            languages: {
                code: string;
                name: string;
                native: string;
            }[];
            tld: string;
        };
        region: {
            code: string;
            name: string;
        };
        city: string;
        postal: string;
        latitude: number;
        longitude: number;
        language: {
            code: string;
            name: string;
            native: string;
        };
        in_eu: boolean;
    };
    security: {
        is_abuser: boolean;
        is_attacker: boolean;
        is_bogon: boolean;
        is_cloud_provider: boolean;
        is_proxy: boolean;
        is_relay: boolean;
        is_tor: boolean;
        is_tor_exit: boolean;
        is_vpn: boolean;
        is_anonymous: boolean;
        is_threat: boolean;
    };
    time_zone: {
        id: string;
        abbreviation: string;
        current_time: string;
        name: string;
        offset: number;
        in_daylight_saving: boolean;
    };
}


const useIpGeolocation = async ({ip}: IpGeolocation ) => {
    const dispatch = useDispatch();
    const ipInfo = useSelector((state: any) => state.user.ipInfo);
    if (!ipInfo) {
        try {
            const response = await fetch(`${URL_IP_REGISTER_API}${ip ? `/${ip}` : ''}?key=${IP_REGISTER_API_KEY}`)
            .then(response => response.json())
            .then(data => data as IpGeolocationResponse)
            .catch(error => console.log(error));
    
            dispatch(setIpInfo(response as IpGeolocationResponse));
            return response;
        }
    
        catch(error) {
            console.log('error in useIpGeolocation: ', error);
        }
    }
    
}

export default useIpGeolocation;