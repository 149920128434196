import styled from 'styled-components'

export const Container= styled.div`
  display: flex;
  gap: 0;
  background: #ffffff;
  margin-bottom: ${props => props.mbottom || 0};
  border-radius: 1rem 0 0 1rem;
  width: ${props => props.size || "85%"};;
  height: ${props => props.sizeV || "100%"};;
  justify-content: center;
  padding: 0rem 1rem;
  float: right;
  z-index: 99;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap: 0;
    float: none;
    width: 100%;
    padding: .5rem 0;
  }
  @media only screen and (max-width: 620px) {
    width: 100%;
    margin: 0;
    flex-direction: column;
    align-items: center;
    gap: 0;
    float: none;
    padding: .5rem 1rem 1rem 1rem;
    border-radius: 0;
  }
`

export const LinkContainer = styled.div`
  
  width: 100%;

  @media only screen and (min-width: 1340px) {
    margin-top: 8%;
  }

  @media only screen and (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: -34px;
  }
`


export const Link = styled.a`
  color: #3A36DB;
  margin-top: 20px;
  ${props => props.theme.typography.body.lg.bold};
  cursor: pointer;
  
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.md.bold};
  }
  &:hover {
    opacity: 0.8;
  }
`

export const TextCategory = styled.p`
  ${props => props.theme.typography.body.lg.bold};
  color: #0C121D;
  margin: 0;
  bottom: -16px;
  font-weight: 600;

  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
  }

  `; 

export const CategoriesWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width:50%;
  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 0 1rem;
  }

  @media only screen and (max-width: 620px) {
    padding: 0;
  }
`


export const CategoryModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CategoryModalTitle = styled.h1`
  ${props => props.theme.typography.heading.lg};
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.subheading.lg.bold};
  } 
`
export const Categories = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  @media only screen and (max-width: 768px) {
    gap: .5rem;
    padding: 0;
  }
`

export const Category = styled.div`
  padding: .75rem 1rem;
  white-space: ${props=>props.wrap || "normal"};
  ${props => props.theme.typography.subheading.sm.semibold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  // background color per props
  background-color: ${props => props.backgroundColor || "#E3F1FE"};
  border-radius: 1rem;
  box-shadow: 0 4px 8px 2px rgba(2, 64, 154, 0.16);
  transition: .3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.label.xs.medium};
  }
  @media only screen and (max-width: 992px) {
    width: 30%;
  }
`

export const ImageIcon = styled.img`
  width: 36px;
  height: 36px;
  margin-bottom: 6px;
  object-fit: contain;
`;


export const Browser = styled.div`
  display: flex;
  width: 50%;
  position: relative;
  background: #FFFFFF;
  border-radius: 1rem;
  border: 2px solid #0C121D;
  margin: 1rem auto;
  justify-content: space-between;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    border-radius: 1rem;
    width: 95%;
  }

  @media only screen and (max-width: 620px) {
    width: 100%;
    align-items: center;
    margin: 0.5rem 0 1rem 0;
    border-radius: 1rem;
  }
`

export const BrowserBody = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding:  1rem 1rem 0 1rem;
  overflow: hidden;
`
 export const ImageContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
`;

export const BrowserImg = styled.img`
  max-height: 180px;
`

export const BText = styled.h4`
  ${props => props.theme.typography.heading.md};
  font-family: Outfit, monospace;
  font-size: 2.2rem;

  @media only screen and (max-width: 1200px) {
    ${props => props.theme.typography.subheading.md};
  }

  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.heading.md};
  }

  @media only screen and (max-width: 768px) {
    ${props => props.theme.typography.subheading.md.bold};
  }

  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.subheading.md.bold};
    font-family: Outfit, monospace;
    text-align: center;
    font-size: 1.2rem;
  }
`

export const SubtitleText = styled.p`
  font-family: Outfit, monospace;
  font-size: 1.2rem;

  @media (max-width: 600px) {
    line-height: 1.2rem;
    text-align: center;
    font-size: 1rem;
  }
`

export const TransButton = styled.button`
  display: block;
  width: 100%;
  height: 40px;
  background: ${props => props.fondo || "#ffffff"};
  color: ${props => props.fondo === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1rem;
  border: 1px solid #406385;
  margin-bottom: 10px;

  animation: ${props => props.fondo === '#3A36DB' ? "heartBeat 4s infinite" : "none"};
  @keyframes heartBeat {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.05);
    }
    40% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.05);
    }
    80% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }
  

  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 1200px) {
    ${props => props.theme.typography.body.sm.bold};
  }
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.body.lg.bold};
  }
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.body.md.bold};
  }
  @media only screen and (max-width: 500px) {
    ${props => props.theme.typography.body.sm.bold};
  }
`

export const FakeBrowsersButtonsContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
`

export const FakeBrowsersButton = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ffffff;
  z-index: 100;
`
  