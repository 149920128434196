import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore/lite";
import { firestore } from "config/firebase";
import MainLayout from "views/layout/MainLayout";
import {
  Container,
  Content,
  Header,
  HeaderSubtitle,
  HeaderTitle,
} from "./styled";
import NewClassifieds from "views/containers/Classifieds/NewClassified";
import { ThemeProvider } from "@mui/material";
import { theme } from "libs/theme";
import { registerLog } from 'libs/analytics';
import { getCountry } from 'redux/common';

const NewClassified = () => {
  const { t } = useTranslation();
  const country = useSelector(getCountry);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // setting categories
    if (categories.length === 0) {
      const querySnapshot = getDocs(
        collection(firestore, `${country}/Classifieds/Categories`)
      );
      querySnapshot.then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // @ts-ignore
          // order and only show actives
          setCategories((categories) => [...categories, doc.data()].sort((a, b) => a.order - b.order).filter((category) => category.active));
          window.scrollTo(0, 0);
        });
      });
    }
    // analytics
    registerLog(
      "New Classified screen",
      {
        page_title: "Create classified",
        page_location: window.location.href,
        page_path: window.location.pathname,
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <MainLayout type="classifieds">
        <ThemeProvider theme={theme}>
          <Header>
            <HeaderTitle>{t('new_classified') || ''}</HeaderTitle>
            <HeaderSubtitle>
              {t('enter_the_information_of_your_new_classified') || ''}
            </HeaderSubtitle>
          </Header>
        </ThemeProvider>
        <Content>
          <NewClassifieds data={categories} />
        </Content>
      </MainLayout>
    </Container>
  );
};

export default NewClassified;
