import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import 'dayjs/locale/es'
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { cleanCartSagasTrigger } from "redux/cart";
import { getAddress } from "redux/checkout";
// import { userInfo } from 'redux/user';
// import { User } from "types";
import {
    TransButton,
    Summary,
    SummaryTitle,
    SummarySubTitle,
    SummaryTotal,
    // SumFinalText,
    SummaryText,
    ItemDesc,
    Item,
    ItemImg,
    ItemName,
    ItemQty,
    SubtitleSpan,
    // SpaceBetween,
    // SummarySubtotal,
    SummaryFinalPrice,
    ItemPrice,
    FooterItem,
    FooterItemTitle,
    FooterItemDesc,
    FooterItemSpan,
    FinalFooter
} from "../styled";
import { getTypePaymentMethod } from "../main";
import { getLanguage, getCurrency } from "redux/common";
import { getOfflineAddress, setUidCheckout, clearCheckout } from "redux/checkout";

interface StepFiveProps {
    onSubmit: (step: number) => void;
    data: any;
}

const StepFive = (props: StepFiveProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);
    const currency = useSelector(getCurrency);
    console.log('data order', data)

    const navigate = useNavigate();
    // const userData: User = useSelector(userInfo as any);
    const address = useSelector(getAddress)
    const offlineAddress = useSelector(getOfflineAddress);


    
    useEffect(() => {
        if (data.paymentMethod === "card" || data.paymentMethod === "paypal") {
            dispatch(cleanCartSagasTrigger.run({}));
            dispatch(setUidCheckout(null));
            dispatch(clearCheckout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.paymentMethod, dispatch]);

    return (
        <>
            <Summary>
                <SummaryTitle>{t('order_detail')}</SummaryTitle>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '1rem' }}>
                    <p>#{data?.id}</p>
                </div>
                <SummarySubTitle>{t('date')}: {dayjs(data.date).locale(language).format('DD MMMM YYYY')} &nbsp;&nbsp;&nbsp;&nbsp; {t('status')}: <SubtitleSpan>{t('processing')}</SubtitleSpan></SummarySubTitle>
                <div style={{ marginBottom: '2rem' }}>
                    {data?.itemsCart?.length > 0 ?
                        data.itemsCart.map((item, index) => {
                            return <Item key={index}>
                                <ItemDesc>
                                    <ItemImg src={item.image} />
                                    <ItemQty>{item.count}</ItemQty>
                                    <ItemName>{item.name}</ItemName>
                                </ItemDesc>
                                <ItemPrice>{currency}{ item?.price  ? parseFloat(item?.price).toFixed(2)  : 'ERROR' }</ItemPrice>
                            </Item>
                        })
                        :
                        <h2 style={{ textAlign: "center", marginTop: '20px' }}>{t('no_products_in_cart')}</h2>
                    }
                </div>
                {/* <SpaceBetween>
                    <SumFinalText>Resumen</SumFinalText>
                    <SummarySubtotal>
                        <SummaryText>SubTotal: </SummaryText>
                        <SummaryFinalPrice>${data?.totalAmount.toFixed(2) || 'Error subtotal'}</SummaryFinalPrice>
                    </SummarySubtotal>
                </SpaceBetween>
                {/* <SummaryTotal>
                    <SummaryText>Envio: </SummaryText>
                    <SummaryFinalPrice>${data.shippingMethod.send === "free" ? "0.00" : SHIPPING_COST}</SummaryFinalPrice>
                </SummaryTotal> */}
                <hr />
                <SummaryTotal>
                    <SummaryText>Total: </SummaryText>
                    <SummaryFinalPrice>{currency}{data?.totalAmount?.toFixed(2) || 'error subtotal'}</SummaryFinalPrice>
                </SummaryTotal>
                <hr />
            </Summary>
            <FinalFooter>
                <FooterItem>
                    <FooterItemTitle>{t('payment_method')}</FooterItemTitle>
                    <FooterItemDesc>{getTypePaymentMethod(data.paymentMethod)}</FooterItemDesc>
                </FooterItem>
                <FooterItem>
                    <FooterItemTitle>{t('shipment_information')}</FooterItemTitle>
                    <FooterItemDesc>
                        <div>
                            {t('full_name')}: <FooterItemSpan>{address?.fullName || offlineAddress?.fullName}</FooterItemSpan>
                        </div>
                        <div>
                            {t('country')}: <FooterItemSpan>{address?.country || offlineAddress?.country}</FooterItemSpan>
                        </div>
                        <div>
                            {t('city')}: <FooterItemSpan>{address?.city || offlineAddress?.city}</FooterItemSpan>
                            <div>
                                {t('address')}: <FooterItemSpan>{address?.street || offlineAddress?.street}</FooterItemSpan>
                            </div>
                            <div>
                                {t('phone')}: <FooterItemSpan>{address?.phone || offlineAddress?.phone}</FooterItemSpan>
                            </div>
                            <div>
                                {t('zip_code')}: <FooterItemSpan>{address?.postalCode || offlineAddress?.postalCode}</FooterItemSpan>
                            </div>
                        </div>
                    </FooterItemDesc>
                </FooterItem>
                {/* <FooterItem>
                    <FooterItemTitle>Dirección de facturación</FooterItemTitle>
                    <FooterItemDesc>
                        <div>
                            Nombre: <FooterItemSpan>{userData?.firstName}</FooterItemSpan>
                        </div>
                        <div>
                            Apellido: <FooterItemSpan>{userData.firstSurname}</FooterItemSpan>
                        </div>
                        <div>
                            Pais: <FooterItemSpan>{address.country}</FooterItemSpan>
                        </div>
                        <div>
                            Ciudad: <FooterItemSpan>{address.city}</FooterItemSpan>
                        </div>
                        <div>
                            Direccion: <FooterItemSpan>{address.street}</FooterItemSpan>
                        </div>
                        <div>
                            Telefono: <FooterItemSpan>{userData.phone}</FooterItemSpan>
                        </div>
                    </FooterItemDesc>
                </FooterItem> */}
            </FinalFooter>
            <Summary>
                <hr />
            </Summary>
            <TransButton onClick={() => navigate("/")} background={"#3A36DB"}>{t('go_to_home')}</TransButton>
        </>
    )
}

export default StepFive;