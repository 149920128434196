import { useEffect, useState } from 'react'
import MainLayout from 'views/layout/MainLayout'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Content } from "./styled";
import BannerAndCategories from "views/containers/SliderCategories";
import ContentClassifieds from "views/containers/Classifieds/ContentClassifieds";
import FloatButton from 'components/float-button';
import useCategories from 'hooks/useGetCategories/useGetCategories';
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import { useNotification } from 'libs/context/AlertContext';
import { useGetFirestoreCollection } from 'hooks/useGetCollectionFirestore/useGetCollectionFirestore';
import { useGetFirestoreDoc } from 'hooks/useGetDocumentFirestore';
import { registerLog } from 'libs/analytics';
import { getCountry, getLanguage } from 'redux/common';
import { ClassifiedsRoutes, ClassifiedCategories } from 'constants/classifieds';
import { capitalize } from 'lodash'
import { MAIN_ROUTES } from 'constants/routes';


const Classifieds = () => {

    const isAuth = useIsAuth();
    const country = useSelector(getCountry);
    const language = useSelector(getLanguage);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showWarning } = useNotification();
    const { categories } = useCategories(`${country}/Classifieds/Categories`, [{ field: 'active', value: true }], { field: 'order', direction: 'asc', });
    const { docData: bannersData, fetchDoc: fetchBanners } = useGetFirestoreDoc();
    const { collectionData: suggestedClassifiedsData, fetchCollection: fetchSuggestedClassifieds } = useGetFirestoreCollection();
    const { collectionData: RealEstateClassifiedsData, fetchCollection: fetchRealStateClassifieds } = useGetFirestoreCollection();
    const { collectionData: VehicleClassifiedsData, fetchCollection: fetchVehicleClassifieds } = useGetFirestoreCollection();
    const { collectionData: EmploymentClassifiedsData, fetchCollection: fetchEmploymentClassifieds } = useGetFirestoreCollection();
    const { collectionData: TechClassifiedsData, fetchCollection: fetchTechClassifieds } = useGetFirestoreCollection();
    const { collectionData: ConstructionClassifiedsData, fetchCollection: fetchConstructionClassifieds } = useGetFirestoreCollection();
    const { collectionData: HomeClassifiedsData, fetchCollection: fetchHomeClassifieds } = useGetFirestoreCollection();
    const { collectionData: PetsClassifiedsData, fetchCollection: fetchPetsClassifieds } = useGetFirestoreCollection();
    const { collectionData: MusicClassifiedsData, fetchCollection: fetchMusicClassifieds } = useGetFirestoreCollection();
    const { collectionData: SportsClassifiedsData, fetchCollection: fetchSportsClassifieds } = useGetFirestoreCollection();

    const [route, setRoute] = useState("");
    const [, setTypeCategory] = useState("");
    const [bannerImages, setBannerImages] = useState<string[]>([]);

    useEffect(() => {

        fetchSuggestedClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }],
        })

        fetchRealStateClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.RealState }],
        })

        fetchVehicleClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Vehicles }],
        })

        fetchEmploymentClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Employments }],
        })

        fetchTechClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Tech }],
        })

        fetchConstructionClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Construction }],
        })

        fetchHomeClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Home }],
        })

        fetchPetsClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Pets }],
        })

        fetchMusicClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Music }],
        })

        fetchSportsClassifieds({
            path: `${country}/${ClassifiedsRoutes.Posts}`,
            filters: [{ field: 'status', operator: '==', value: 'approved' }, { field: 'categoryType', operator: '==', value: ClassifiedCategories.Sports }],
        })

        registerLog(
            'Classifieds screen',
            {
                page_title: 'Classifieds Home Screen',
                page_location: window.location.href,
                page_path: window.location.pathname
            }
        );

        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // banners
    useEffect(() => {

        if (bannersData) {
            const imagesByResolution = () => {
                if (window.innerWidth < 768) {
                    return bannersData.imageMobile
                } else {
                    return bannersData.imageDesktop
                }
            }

            setBannerImages(imagesByResolution())
        } else {
            fetchBanners({ path: `${country}/Classifieds/MainBanners/${capitalize(language)}/Banners` })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersData]);


    function handleSelectCategory(category: string) {
        setRoute(category);
        navigate(`/classifieds/category/${category}`);
    }


    return (
        <MainLayout type='classifieds'>
            <Container>
                <BannerAndCategories
                    category={route}
                    onSelectCategory={handleSelectCategory}
                    handleType={setTypeCategory}
                    background={"linear-gradient(180deg, #08A791 0%, #016D52 100%)"}
                    type={t('classifieds')}
                    data={categories}
                    images={bannerImages}
                />
                <Content>
                    <ContentClassifieds
                        suggestedClassifieds={suggestedClassifiedsData || []}
                        realStateClassifieds={RealEstateClassifiedsData || []}
                        vehicleClassifieds={VehicleClassifiedsData || []}
                        employmentClassifieds={EmploymentClassifiedsData || []}
                        techClassifieds={TechClassifiedsData || []}
                        constructionClassifieds={ConstructionClassifiedsData || []}
                        homeClassifieds={HomeClassifiedsData || []}
                        petsClassifieds={PetsClassifiedsData || []}
                        musicClassifieds={MusicClassifiedsData || []}
                        sportsClassifieds={SportsClassifiedsData || []}
                    />
                </Content>
                {
                    isAuth ? (
                        <Link to={MAIN_ROUTES.ClassifiedNew}>
                            <FloatButton />
                        </Link>
                    ) : (
                        <FloatButton onClick={() => showWarning(t('login_to_create_your_classified'))} />
                    )
                }
            </Container>
        </MainLayout>
    )
}

export default Classifieds
