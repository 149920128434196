import styled from 'styled-components'

export const ContentProductsContainer = styled.div`
  width: 100%;
`;

export const Container = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    background-color: #FDFDFE;
`

export const ListProductsContainer = styled.div`
  height: 100%;
  justify-items: center;
  background-color: #F5F5F6;
  border-radius: 16px;
  width: 100%;
  margin: 20px 0;
  padding: 3%;

  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(5, 90fr);
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 90fr);
  }
  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 90fr);
    border-radius: 0;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CarouselProductsContainer = styled.div`
  display: flex;
  width: 95%;
  margin: 20px 0;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-start;
  color:#406385;
`
export const Amount = styled.span`
  ${props => props.theme.typography.subheading.md.semibold};
`
export const TextFilter = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: .2rem;
  justify-content: flex-end;
  ${props => props.theme.typography.subheading.md.semibold};
`

export const SpaceBetween = styled.div`
  display: flex;
  margin: 1rem 0rem;
  justify-content: space-between;
`
export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 12fr);
    grid-gap: .5rem;
    padding: 1rem 0rem 0rem 0rem;
    margin-top: 2rem;
    overflow-y: visible;

    @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(4, 12fr);
    }
    @media only screen and (max-width: 992px) {
        grid-template-columns: repeat(3, 12fr);
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 32fr);
    }

    @media only screen and (max-width: 300px) {
        grid-template-columns: repeat(1, 80fr);
    }
`



