import * as Yup from "yup";

export const addressSchema = Yup.object().shape({
    country: Yup.string()
        .required("El país es obligatorio")
        .max(50, 'El país debe tener menos de 50 caracteres'),
    city: Yup.string()
        .required("La ciudad es obligatoria")
        .max(50, 'La ciudad debe tener menos de 50 caracteres'),
    state: Yup.string()
        .when('country', (country, schema) => {
            return (country)
                ? schema.required("El estado es obligatorio")
                : schema;
        })
        .max(50, 'El estado debe tener menos de 50 caracteres'),
    street: Yup.string()
        .required("La dirección es obligatoria")
        .max(100, 'La dirección debe tener menos de 100 caracteres'),
    fullName: Yup.string()
        .required("El nombre completo es obligatorio")
        .min(5, 'El nombre completo debe tener al menos 5 caracteres')
        .max(100, 'El nombre completo debe tener menos de 100 caracteres'),
    phone: Yup.string()
        .required("El teléfono es obligatorio")
        .matches(
            /^(\+?\d{1,3}[- .]?)?\(?\d{3}\)?[- .]?\d{3}[- .]?\d{4}$/,
            "El número de teléfono no es válido"
        ),
    postalCode: Yup.string()
        .matches(/^\d{5}(-\d{4})?$/, 'El código postal no es válido'),
    additionalComments: Yup.string()
        .max(200, 'Los comentarios adicionales deben tener menos de 200 caracteres'),
    isShipping: Yup.boolean().required("El tipo de dirección es obligatorio"),
    isBilling: Yup.boolean().required("El tipo de dirección es obligatorio"),
    isPrimaryBilling: Yup.boolean().required("El tipo de dirección es obligatorio"),
    isPrimaryShipping: Yup.boolean().required("El tipo de dirección es obligatorio"),
});