import styled from 'styled-components'

const Container = styled.div`
  cursor: pointer;
`

interface IProps {
  width?: string
  height?: string
  onClick?: () => void
}

const ArrowLeftIcon = (props: IProps) => {
  const { onClick } = props

  return (
    <Container onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6035_5314)">
          <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
          <path
            d="M16.2461 12.25H7.24609"
            stroke="#2D749C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.7461 7.75L7.24609 12.25L11.7461 16.75"
            stroke="#2D749C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_6035_5314">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Container>
  )
}

export default ArrowLeftIcon
