import { useState, useEffect } from 'react'
import MainLayout from 'views/layout/MainLayout/main-layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetProductsByMultiplesCategories } from 'hooks/products/useGetProductsByMultipleCategories';
import CategoriesCarousel from 'views/containers/SliderCategories'
import ContentProducts from "views/containers/Products/ContentProducts";
import ContentProductsFiltered from "views/containers/Products/ContentProductsFiltered";
import useCategories from 'hooks/useGetCategories/useGetCategories';
import { useGetFirestoreDoc } from 'hooks/useGetDocumentFirestore';
import { Container, Content } from "./styled"
import { DBCountryReference, FiltersProducts } from 'types';
import { DB_ROUTES } from "constants/routes";
import { useGetFirestoreCollection } from 'hooks/useGetCollectionFirestore/useGetCollectionFirestore';
import useAnalytics from 'hooks/useAnalytics/useAnalytics';
import { buildQueryFilterProducts } from '../main';
import { ProductsMachineryCategories } from 'constants/products-constants'

const { CATEGORIES_MACHINERY } = DB_ROUTES
const LIST_CATEGORIES: number[] = Object.values(ProductsMachineryCategories).filter((value) => typeof value === "number") as number[];
const COLLECTION_PATH = `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_MACHINERY}`

const StoreProducts = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const registerLog = useAnalytics();

    const { docData: bannersData, fetchDoc: fetchBanners } = useGetFirestoreDoc();
    const { fetchCollection: fetchProductsByFilters, collectionData: productsByFilters, clearCollectionData: clearSearchProductsByFilter } = useGetFirestoreCollection();
    const {  productsByMultipleCategories, } = useGetProductsByMultiplesCategories({
        ids: LIST_CATEGORIES,
        collectionPath: COLLECTION_PATH
    });
    const { categories } = useCategories(`${DBCountryReference.PAN}${CATEGORIES_MACHINERY}`);

    const { selRoute, selRouteEs } = location?.state || "";

    const [category, setCategory] = useState("");
    const [bannerImages, setBannerImages] = useState<string[]>([]);
    const [filters, setFilters] = useState<FiltersProducts>({});

    const handleSelectCategory = (category: string) => {
        clearSearchProductsByFilter();
        navigate(`/products/category?uid=${category}`)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsByMultipleCategories]);

    useEffect(() => {
        if (selRoute !== undefined && selRoute !== "") {
            setCategory(selRouteEs);
        }

        if (bannersData) {
            const imagesByResolution = () => {
                if (window.innerWidth < 768) {
                    return bannersData.imagesMobile
                } else {
                    return bannersData.imagesDesktop
                }
            }

            setBannerImages(imagesByResolution())
        } else {
            fetchBanners({ path: `${DBCountryReference.PAN}/Banners/ProductsBanners/Es/Main` })
        }
        registerLog({ screenName: 'Products machinery', pageTitle: 'Products machinery of whatdoyouneed7.com', pagePath: window.location.pathname, pageLocation: window.location.href });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersData]);

    const handleSetFilters = (filters: FiltersProducts) => {
        const queryData = buildQueryFilterProducts(filters)
        setFilters(filters);

        fetchProductsByFilters({
            path: `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_MACHINERY}`,
            filters: queryData.query as any,
            orderFilter: queryData.orderBy
        })
    }

    return (
        <MainLayout type='products'>
            <Container>
                <CategoriesCarousel
                    category={category}
                    onSelectCategory={handleSelectCategory}
                    handleType={() => { }}
                    background={"linear-gradient(180deg, #B218FF 0%, #8900CE 100%)"}
                    type={"Productos"}
                    data={categories}
                    images={bannerImages}
                    hideSlideCategories
                />
                <Content>
                    {
                        productsByFilters && productsByFilters.length > 0 ? (
                            <ContentProductsFiltered
                                data={productsByFilters as any}
                                categories={categories}
                                setFilters={handleSetFilters}
                                filters={filters}
                            />
                        ) : (
                            <ContentProducts
                                type='machinery'
                                setRoute={setCategory}
                                categories={categories}
                                setFilters={handleSetFilters}
                                data={productsByMultipleCategories}
                                titleSection='Maquinaria'
                                filters={filters}
                            />
                        )
                    }
                </Content>
            </Container>
        </MainLayout>
    )
}

export default StoreProducts
