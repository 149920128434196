import axios from 'axios';
const PATH_CLOUD_FUNCTION = process.env.REACT_APP_EXIST_TRANSACTION_URL;

const useExistOrderUid = () => {
    
    const existOrderUid = async (uid: string):Promise<boolean> => {
        try {
            const response = await axios.post(`${PATH_CLOUD_FUNCTION}`, {
                transactionUid: uid
            });
            return response.data.data.exists;
        } catch (error) {
            // throw error;
            return false;
        }
    }

    return {
        verifyOrderUid: existOrderUid
    }
}

export default useExistOrderUid;

