import { createTheme } from "@mui/material";

// THEME
export const THEME = {
    colors : {
        primary: '#3A36DB',
        products: '#B218FF',
        services: '#0089FF',
        classifieds: '#08A791',
        classifieds200: '#016D52',
        classifieds80: '#50C0B0',
        classifieds60: '#7FDCCD',
        classifieds40: '#A9E7DE',
        classifieds10: '#E7F9F6',
        offers: '#F9B959',
        business: '#F72356',
        pays: '#FDC2BC',
        white: '#FFFFFF',
        positive60: '#66B87B',
        positive80: '#34A050',
        positive100: '#18ED94',
        positive200: '#016547',
        positive300: '#0F1210',
        negative100: '#ED1212',
        warning300: '#811F00',
        gray200: '#102146',
        gray100: '#406385',
        gray80: '#5C7C9A',
        gray60: '#809FB8',
        gray40: '#E7EDF5',
        gray20: '#FDFDFE',
        black200: '#0C121D',
        black100: '#374355',
        black80: '#69727F',
        black60: '#9BA1AA',
        black40: '#CDD0D4',
        black20: '#F5F5F6',
        alert200: '#DA0101',
        alert100: '#F72C1E',
        alert80: '#FC6A57',
        alert60: '#FB8678',
        alert40: '#FCA499',
        alert20: '#FDC2BC',
        blueLight: '#E3F1FE',
    }
}


export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});