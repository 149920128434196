import styled from "@emotion/styled";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FDFDFE;
` 

export const VideoPlayerContainer = styled.div`
    margin-top: 1rem;
    margin-bottom: -1rem;
    
    @media only screen and (max-width: 768px) {
        margin-bottom: -1rem;
        height: 60vw; 
    }
`