import React, { FC } from 'react'
import Carousel from "react-multi-carousel";
import { useTranslation } from 'react-i18next';
import Skeleton from "@mui/material/Skeleton";

import {
  Container,
  TextCategoriesContainer,
  DesktopCategoriesContainer,
} from "./styled";
import SliderCarousel from "components/SliderCarousel";
import Categories from "views/containers/Categories";
import PopupDesktopCategories from "components/PopupDesktopCategories";
import { H3 } from "ui/Text";
import { ImageObject } from 'types';
import { SCREEN_SIZE_BREAKPOINTS } from "../../constants";

interface SliderCategoriesDesktopProps {
  data: any[];
  onSelectCategory: (category: string) => void;
  handleType: any;
  images: string[] | ImageObject[];
  type: string;
  category: string;
  numberSkeletons: number[];
  hideSlideCategories?: boolean;
}

const SliderCategoriesDesktop: FC<SliderCategoriesDesktopProps> = ({
  data,
  onSelectCategory,
  handleType,
  images,
  type,
  category,
  numberSkeletons,
  hideSlideCategories,
}) => {

  const { t } = useTranslation();
  return (
    <>
      <SliderCarousel images={images} />
      {
        !hideSlideCategories && (
          <DesktopCategoriesContainer>
            <TextCategoriesContainer id="popup-root">
              <H3 color="#FFF" family="Outfit">
                {t('explore_categories')}
              </H3> 
              <PopupDesktopCategories
                data={data}
                position={"bottom right"}
                text={t('view_more')}
                type={type}
                handleType={handleType}
                onSelectCategory={onSelectCategory}
              />
            </TextCategoriesContainer>
            {data.length ? (
              <Categories
                type={type}
                onSelectCategory={onSelectCategory}
                handleType={handleType}
                data={data}
              />
            ) : (
              <Container>
                <div style={{ width: "100%" }}>
                  <Carousel
                    swipeable
                    draggable
                    showDots={false}
                    arrows={false}
                    responsive={SCREEN_SIZE_BREAKPOINTS}
                    ssr={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={1100}
                    keyBoardControl={true}
                    transitionDuration={2000}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {numberSkeletons.map((item, index) => (
                      <Skeleton
                        key={index}
                        variant={"rounded"}
                        width={"60%"}
                        height={"8rem"}
                      />
                    ))}
                  </Carousel>
                </div>
              </Container>
            )}
          </DesktopCategoriesContainer>
        )
      }
    </>
  )
}

export default SliderCategoriesDesktop
