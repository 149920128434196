import { useState, useEffect} from "react";
import { useLocation, useNavigate} from "react-router-dom";
import MainLayout from "views/layout/MainLayout/main-layout";
import { Container, HeaderContent } from "./styled";
import ContentProductsFiltered from "views/containers/Products/ContentProductsFiltered";
import ContentProductsByCategory from "views/containers/Products/ContentProductsByCategory";
import { BackButton } from "ui/Buttons";
import useCategories from 'hooks/useGetCategories/useGetCategories';
import { useGetFirestoreDoc } from 'hooks/useGetDocumentFirestore';
import { useGetFirestoreCollection } from 'hooks/useGetCollectionFirestore/useGetCollectionFirestore';
import useAnalytics from 'hooks/useAnalytics/useAnalytics';
import { Product, DBCountryReference, FiltersProducts } from "types";
import { DB_ROUTES } from "constants/routes";
import { buildQueryFilterProducts } from '../main';

const {CATEGORIES_PRODUCTS_STORE, POSTS_PRODUCTS_STORE} = DB_ROUTES

const ProductsByCategory = () => { 
    const location = useLocation();
    const navigate = useNavigate();
    const registerLog = useAnalytics();
    
    const { categories } = useCategories( `${DBCountryReference.PAN}${CATEGORIES_PRODUCTS_STORE}`);
    const {docData: categoryInfo, fetchDoc: fetchCategoryInfo} = useGetFirestoreDoc();
    const { collectionData: productsByCategory, fetchCollection: fetchProductsByCategory } = useGetFirestoreCollection();
    const {fetchCollection: fetchProductsByFilters,collectionData: productsByFilters, } = useGetFirestoreCollection(); // clearCollectionData: clearSearchProductsByFilter

    const [categoryUid, setCategoryUid] = useState<string>('');
    const [filters, setFilters] = useState<FiltersProducts>({});
      
    useEffect(() => {
        const uidCategory = new URLSearchParams(location.search).get('uid');
        fetchCategoryInfo({path: `${DBCountryReference.PAN}${CATEGORIES_PRODUCTS_STORE}`, uid: uidCategory || ''})
        setCategoryUid(uidCategory || '');

        registerLog({screenName: `Products by category ${uidCategory}`, pageTitle: `Products by category $${uidCategory} of whatdoyouneed7.com`, pagePath: window.location.pathname, pageLocation: window.location.href});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ categoryUid, fetchCategoryInfo]);

    useEffect(() => {
        if (categoryInfo) {
            setFilters({categoryId: categoryInfo.id})
            fetchProductsByCategory({path: `${DBCountryReference.PAN}${POSTS_PRODUCTS_STORE}`, filters: [{field: 'listIdCategory', operator: 'array-contains-any', value: [categoryInfo.id]}]})
        }
    }, [categoryInfo, fetchProductsByCategory]);


    const handleSetFilters = (filters: FiltersProducts ) => {
        const queryData = buildQueryFilterProducts(filters)
        setFilters(filters);

        fetchProductsByFilters({
            path: `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_STORE}`,
            filters: queryData.query as any,
            orderFilter: queryData.orderBy
        })
    }

    return (
        <MainLayout type="products">
            <HeaderContent>
                <BackButton onClick={() => navigate(-1)} />
            </HeaderContent>
            <Container>
                {
                    productsByFilters && productsByFilters.length > 0 ? (
                        <ContentProductsFiltered  
                            data={productsByFilters as any}
                            categories={categories}
                            setFilters={handleSetFilters} 
                            filters={filters}
                        />
                    ) : (
                            <ContentProductsByCategory 
                                setFilters={handleSetFilters}  
                                categories={categories} 
                                data={productsByCategory as Product[]}
                                filters={filters}
                            />
                        )
                }
            </Container>
        </MainLayout>
    )
}

export default ProductsByCategory;