import styled, { css } from "styled-components";

interface ItemProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h6 {
    margin: 0px;
  }
`;

export const GridLanguage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin: 1rem 0rem;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #000000;
`;

export const FlagImage = styled.img`
  width: 60px;
`;

export const ItemLanguage = styled.div<ItemProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 5rem;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 0 5px;
  cursor: pointer;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: #d9d9d933;
      border-radius: 0;
    `}

  &:hover {
    background-color: #d9d9d933;
    border-radius: 0;
  }
`;

export const Row = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5rem;
`;
