import {Container, ImageIcon, Text} from "./styled";
import {useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { getLanguage } from "redux/common";

const Category = (props: any) => {
    const {data, handleCat,handleType, type} = props;
    const language = useSelector(getLanguage);

    const {image, name, color, id, uid} = data;

    const navigate = useNavigate();
    return (
        <Container onClick={() => {
            if (type === "Servicios"){
                navigate(name.en.toLowerCase().replaceAll(" ","-"));
            }else {
                handleCat(uid);
                handleType(id || 0);
            }
        }
        } style={{backgroundColor: color}}>
            <ImageIcon src={image} loading="lazy" alt={name[language]} title={name[language]} />
            <Text>{name[language]}</Text>
        </Container>
    )
};

export default Category;