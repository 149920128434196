import { Container, } from "./styled";
import Category from "ui/Category";
import SlickCarousel from "components/SlickCarousel";

const Categories = (props: any) => {
    const { data, onSelectCategory, handleType, type } = props;

    return (
        <Container>
            <SlickCarousel itemsToShow={9} itemsTablet={5} handleType={handleType} handleCat={onSelectCategory} items={data} type={type} renderItem={Category} />
        </Container>
    )
};

export default Categories;
