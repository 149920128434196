/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled from "styled-components";
import MuiSelect from '@mui/material/Select'



//@ts-ignore
export const SelectStyled = styled(MuiSelect)((props) => ({
    '& .MuiSelect-select': { 
      textAlign: 'left',
      width: '100%',
      height: '16px',
      borderRadius: '12px',
      backgroundColor:  props.disabled ? '#F5F5F5' : '#FFFFFF',
      border: 'none!important',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      '&:focus': {
        border: 'none!important',
      },
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 12px)',
      right: 12,
    },
  }))