import styled from "styled-components";
import { THEME } from "constants/theme/theme";

export const Container = styled.div`
  min-width: 50vw;
  color: ${THEME.colors.black100};
`;

interface IGrid {
  isOtherSelected: boolean;
  isSelected: boolean;
}

export const Grid = styled.div<IGrid>`
  width: 100%;
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr;
  margin: 1rem 0rem;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const GridItem = styled.div<IGrid>`
  width: 100%;
  display: ${(props) => (props.isOtherSelected ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  min-height: ${(props) => (props.isSelected ? "5rem" : "10rem")};
  background: ${(props) => (props.isSelected ? "none" : "#d9d9d9")};
  color: black;
  border-radius: 2px;
`;

export const SubItemGrid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  color: ${THEME.colors.black100};
  font-weight: 500;
  cursor: pointer;
`;

export const ContentChoiceConfig = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
