import { useState } from "react";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import RadioGroup from '@mui/material/RadioGroup';
import Input from "ui/TextInput";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import SideDrawer from 'components/drawers/side-drawer/side-drawer';
import { FiTrash2 } from "react-icons/fi";
import { PanelContent, PaneTitle, SpaceBetween, PaneSection, PaneFooter, PaneFooterContainer } from "./styled";
import { styleAccordion, MIN_DISTANCE, lightTheme } from "./main";
import { Button } from "ui/Buttons";
import { theme } from "libs/theme";
import { ProductCategory as ProductCategoryType, FiltersProducts, OrderTypeProduct} from 'types';
import { getLanguage } from "redux/common";
import { MAX_PRICE, DEFAULT_MAX_PRICE } from 'constants/products-constants';
interface Props {
    open: boolean;
    setOpen: () => void;
    categories: ProductCategoryType[];
    onSetFilters : (filters: FiltersProducts) => void;
    filters: FiltersProducts;
}

const ProductsSidebarFilter = (props: Props) => {
    const {open, setOpen, onSetFilters,categories, filters} = props;
    const {priceRange, categoryId} = filters;
    const { t } = useTranslation();
    const language = useSelector(getLanguage);

    const [priceRangeValue, setPriceRangeValue] = useState<Array<number | string>> ( priceRange ||  [0, DEFAULT_MAX_PRICE]);
    const [categorySelected, setCategorySelected] = useState<string | number | undefined>(categoryId || undefined);
    const [order, setOrder] = useState<OrderTypeProduct| undefined>();

    const handleSliderChange =  (
        event: Event,  
        newValue: number | number[],
        activeThumb: number,
    ) => {

        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < MIN_DISTANCE) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - MIN_DISTANCE);
                setPriceRangeValue([clamped, clamped + MIN_DISTANCE]);
            } else {
                const clamped = Math.max(newValue[1], MIN_DISTANCE);
                setPriceRangeValue([clamped - MIN_DISTANCE, clamped]);
            }
        } else {
            setPriceRangeValue(newValue as number[]);
        }
    };

    const clearFilters = () => {
        setPriceRangeValue([0, DEFAULT_MAX_PRICE]);
        setCategorySelected(undefined);
    }

    const handleSubmit = (values: FiltersProducts) => {
        Object.keys(values).forEach(key => {
            if (values[key] === undefined || values[key] === null || values[key] === '') {
                delete values[key];
            }
        });

        if (values.priceRange && values.priceRange[0] === 0 && values.priceRange[1] === DEFAULT_MAX_PRICE) {
            delete values.priceRange;
        }
        
        onSetFilters(values);
        setOpen();
    }

    const handleOrder = (value: OrderTypeProduct) => {
        setOrder(value);
    }

const handleMinChange = (e) => {
    if (typeof priceRangeValue[1] !== 'number') return;

    let value = Math.min(Math.max(parseFloat(e.target.value) || 0, 0), priceRangeValue[1] - MIN_DISTANCE);
    setPriceRangeValue([value, priceRangeValue[1]]);
};

const handleMaxChange = (e) => {
    if (typeof priceRangeValue[0] !== 'number') return;

    let value = Math.max(Math.min(parseFloat(e.target.value) || 1000, MAX_PRICE), (priceRangeValue[0] + MIN_DISTANCE));
    setPriceRangeValue([priceRangeValue[0], value]);
};

    return (
            <ThemeProvider theme={lightTheme}>
                <SideDrawer 
                    buttonLabel={t('filter') as string} 
                    side='right' 
                    isOpen={open}
                    setIsOpen={setOpen}
                    content={
                                <PanelContent>
                                    <PaneTitle>{t('filter_by')}</PaneTitle>
                                    <hr/>
                                    <Accordion style={styleAccordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content1"
                                            id="panel1a-1"
                                        >
                                            <Typography>{t('category')} {categorySelected? ' - ' : " "}  {`${categorySelected? categories.find((category) => category.id === categorySelected)?.name[language] : '' }`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            { categories && categories.map((item, index) => {
                                                return (
                                                    <PaneSection 
                                                        style={{background: categorySelected === item.id ? theme.colors.primary100 : 'transparent', color: categorySelected === item.id ? theme.colors.white : theme.colors.neutral200}}
                                                        onClick={() =>{
                                                            if (categorySelected === item.id) {
                                                                setCategorySelected(undefined);
                                                            } else {
                                                                setCategorySelected(item.id)
                                                            }
        
                                                        }} key={index}>{
                                                                typeof item.name === 'object' && item.name.hasOwnProperty(language) ? item?.name[language] : item.name || ''
                                                            }</PaneSection>)
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion style={styleAccordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content2"
                                            id="panel1a-2"
                                        >
                                            <Typography>{t('price_range')} {priceRangeValue[0] !== 0 || priceRangeValue[1] !== 1000 ? ` - $${priceRangeValue[0]} - $${priceRangeValue[1]}` : ` - ${'all'}`} </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <PaneSection>
                                                <SpaceBetween style={{"margin": 0}}>
                                                    <Input
                                                        label={t('min')}
                                                        value={priceRangeValue[0]}
                                                        onChange={handleMinChange}
                                                        type="number"
                                                        style={{ textAlign: "center", width: '100px'}}
                                                        prefix="$"
                                                    />
                                                    <Input
                                                        label={t('max')}
                                                        value={priceRangeValue[1]}
                                                        onChange={handleMaxChange}
                                                        type="number"
                                                        style={{ textAlign: "center", width: '100px'}}
                                                        prefix="$"
                                                    />
                                                    
                                                    {/* <p>${priceRangeValue[1]}</p> */}
                                                </SpaceBetween>
                                                <Slider
                                                    getAriaLabel={() => 'Minimum distance'}
                                                    value={priceRangeValue as any}
                                                    onChange={handleSliderChange}
                                                    valueLabelDisplay="auto"
                                                    min={0.49}
                                                    max={10000}
                                                    getAriaValueText={() => 'Minimum distance'}
                                                    disableSwap
                                                />
                                            </PaneSection>
                                        </AccordionDetails>
                                    </Accordion>
                                    <PaneTitle>{t('order_by')}</PaneTitle>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-form-control-label-placement"
                                            name="order"
                                            defaultValue=""
                                        >
                                            <FormControlLabel
                                                onChange={() => handleOrder('highRating')}
                                                value="highRating"
                                                control={<Radio/>}
                                                label={t('order_by_score')}
                                                labelPlacement="start"
                                                style={{width: "100%", justifyContent: "space-between", borderBottom: "1px solid #ebebf6", margin: "1rem auto", paddingBottom: "1rem"}}
                                            />
                                            <FormControlLabel
                                                onChange={() => handleOrder('lowPrice')}
                                                value="lowPrice"
                                                control={<Radio/>}
                                                label={t('order_by_lowest_price')}
                                                labelPlacement="start"
                                                style={{width: "100%", justifyContent: "space-between", borderBottom: "1px solid #ebebf6", margin: "1rem auto", paddingBottom: "1rem"}}
                                            />
                                        </RadioGroup>
                                        <PaneFooter>
                                            <PaneFooterContainer>
                                                <FiTrash2 style={{cursor: 'pointer'}} onClick={clearFilters} size={28} color={"red"}/>
                                                <Button  onClick={() => handleSubmit({priceRange: priceRangeValue, categoryId: categorySelected, order: order})} type="button">{t('apply')}</Button>
                                            </PaneFooterContainer>
                                        </PaneFooter>                   
                                </PanelContent>
                        } 
                            />
                </ThemeProvider>
    )
}

export default ProductsSidebarFilter;
