import ReactPlayer from 'react-player/lazy';
import { Container, PlayerContainer, PlayerSubContainer,  FormSubContainer,Titles } from './styled';
import { BREAKPOINTS } from 'constants/index';


interface VideoPlayerProps {
  url: string;
  showTitle?: boolean;
  Form?: React.FC;
  volume?: number;
  playing?: boolean;
  controls?: boolean; 
  stylePlayerContainer?: React.CSSProperties;
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const { url, showTitle, Form, controls, stylePlayerContainer } = props;

  return (
    <Container>
      {(window.innerWidth < BREAKPOINTS.lg && showTitle) && <Titles size={"1.5rem"}>Experiencias reales</Titles>}
      <PlayerContainer style={stylePlayerContainer}
      >
        <PlayerSubContainer useForm={Form}>
          <ReactPlayer
            controls={controls}
            width="100%"
            // height="99%"
            {...props}
            loop={true}
            url={url}
            style={{
              objectFit: 'contain',
            }}
        />
        </PlayerSubContainer>
        {
          Form && (
            <FormSubContainer>
              <Form/>
            </FormSubContainer>
          )
        }
      </PlayerContainer>
    </Container>
  );
};

export default VideoPlayer;
