export enum ProductStoreCategories {
    home = 60,
    offers = 235,
    footwear= 1280,
    inmediateDelivery = 1277,
    // vehicleAccessories = 1147,
    art = 219,
    // craft = 217,
    man = 35,
    kids = 130,
    // featured = 198,
    forMan = 224,
    sports = 53,
    smartAssistants = 1269,
    // childrenFootwear = 308,
    // earphones = 1270,
    domotic = 1266,
    electronics = 131,
    // toys = 306,
    // perfumes = 206,
    // healthAndBeauty = 186,
    mans = 224,
    // womans = 310,
    // toysForGirls = 312,
    productsFeatured = 243,
    // connectors = 1223,

}

export enum ProductWholeSaleCategories {
    main = 1289,
}

export enum ProductsMachineryCategories {
    main = 1301,
    machinery= 1092,
    agricultural = 1147,
}

export const LOW_PRICE_FILTER = 'lowPrice';
export const HIGH_RATING_FILTER = 'highRating';
export const FILTER_ORDER_PRODUCTS_OPTIONS = {
    asc: 'asc',
    desc: 'desc',
}

// Prices

export const MAX_PRICE = 1000000;
export const MIN_PRICE = 0;
export const DEFAULT_MAX_PRICE = 1000;