import { useState, useCallback } from 'react';
import { collection, getDocs, doc, getDoc, query, where, DocumentData } from "firebase/firestore";
import { WhereFilterOp, FieldPath } from "firebase/firestore";
import { firestoreFull as firestore } from 'config/firebase'

interface FirestoreDoc extends DocumentData {
  // Aquí va la forma de tus documentos
  // Por ejemplo:
  // id: string;
  // name: string;
  // ...
}

interface Filter {
  field: string;
  operator: WhereFilterOp;
  value: string | number | boolean | null | FieldPath;
}

interface FetchOptions {
  path: string;
  filters?: Filter[];
  uid?: string;
}

export const useGetFirestoreDoc = () => {
  const [loadingGetFirestoreDoc, setLoading] = useState<boolean>(true);
  const [errorGetFirestoreDoc, setError] = useState<Error | null>(null);
  const [docData, setDocData] = useState<FirestoreDoc | null>(null);

  const fetchDoc = useCallback(async (options: FetchOptions) => {
    setLoading(true);
    setError(null);



    try {
      const { path, filters = [], uid } = options;
      const db = firestore;

      if (uid) {
        const document = doc(db, path, uid);

        const documentSnapshot = await getDoc(document);

        if (documentSnapshot.exists()) {
          const data = documentSnapshot.data() as FirestoreDoc;
          setDocData(data);
        } else {
          throw new Error('Document does not exist');
        }
      } else {
        let firestoreQuery = collection(db, path);
        filters.forEach((filter: Filter) => {
          const { field, operator, value } = filter;
          firestoreQuery = query(firestoreQuery, where(field, operator, value)) as any;
        });

        const querySnapshot = await getDocs(firestoreQuery);

        const data = querySnapshot.docs[0]?.data() as FirestoreDoc;
        setDocData(data);
      }

      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  }, []);

  return { loadingGetFirestoreDoc, errorGetFirestoreDoc, docData, fetchDoc };
};