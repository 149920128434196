import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  
`


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  align-self: center;
  border-radius: 16px;
  padding:1rem;
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 400px) {
    padding: 4% 0;
  }
`

export const ContentHeader = styled.div`
  display: flex;
  width: 95%;
  margin: 1rem auto;
  align-items: center;
`

export const ListTitle = styled.p`
  text-align: center;
  ${props=>props.theme.typography.subheading.md.bold};
  @media only screen and (max-width: 600px){
    ${props=>props.theme.typography.body.lg.medium};
  }
`
export const ContentHeaderTitle = styled.p`
  text-align: center;
  flex: 1;
  margin: 0;
  ${props=>props.theme.typography.heading.md};
  @media only screen and (max-width: 400px){
    ${props=>props.theme.typography.heading.sm.bold};
  }
`

export const Options = styled.div`
  margin-top: 1rem;
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(4, 8fr);
  cursor: pointer;
  gap: 2rem;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px){
    grid-template-columns: repeat(3, 8fr);
    gap: .5rem;
    padding: 0px 10px;
  }

`
export const Option = styled.div`

  background: ${props => props.color};
  box-shadow: 0 1px 2px 1px rgba(2, 64, 154, 0.05);
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: .5rem;
  min-width: 100px;
  align-items: center;
  gap: 1rem;
  transition: .2s;
  justify-content: center;
  &:hover{
    transform: scale(1.05);
  }
`


export const OptionImage = styled.img`
  max-width: 40px;
  max-height: 48px;

  @media only screen and (max-width: 500px){
    width: 70%;
  }
`

export const NeedTitle = styled.span`
  ${props=>props.theme.typography.body.lg.bold};
  font-size: 10px;
  
  text-align: center;
  @media only screen and (max-width: 600px){
    ${props=>props.theme.typography.body.sm.bold};
  }
  
`
export const Form = styled.div`
padding: 0 1rem;
`

export const FormTitle = styled.p`
  margin-left: .5rem;
  ${props=>props.theme.typography.body.md.bold};
`

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  appearance: none;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Farrowdown.webp?alt=media&token=7dd41691-6faf-42d6-a14b-59247ee56365');
  background-position: 98%;
  background-repeat: no-repeat;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: 1rem;
  height: 3.5rem;
  @media only screen and (max-width: 600px){
    font-size: .8rem;
  }
  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const TextArea = styled.textarea`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  font-family: inherit;
  font-size: inherit;
  color: #000000;
  outline: none;
  padding: .8rem;
  background: white;

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`



export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: 1rem 2rem;
  margin: 1rem auto;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 600px){
    ${props => props.theme.typography.body.sm.bold};
  }
`

export const Input = styled.input`
  position: relative;
  top: 0;
  left: 0;
  height: 3.5rem;
  width: 100%;
  padding: .8rem;
  border: 2px solid #3A36DB;
  border-radius: 1rem;
  ${props => props.theme.typography.body.lg.input};
  color: #000000;
  outline: none;
  background: white;
  

  &:focus {
    border-color: #3A36DB;
  }

  &:focus ~ .form__label,
  &:not(:placeholder-shown)&:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
`

export const InputLabel = styled.label`
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  padding: 0 0.5rem;
  color: #000000;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  background-color: #ffffff;
  @media only screen and (max-width: 600px){
    font-size: .8rem;
    top: 1.2rem;
  }
  
`

export const InputWrap = styled.div`
  position: relative;
  width: ${props => props.size || "80%"};
  z-index: 1;
  margin: 1rem 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`


export const OptionTitle = styled.p`
  margin-top: 2rem;
  text-align: center;
  ${props => props.theme.typography.body.lg.regular};
  @media only screen and (max-width: 600px){
    text-align: left;
    ${props => props.theme.typography.body.sm.regular};
  }
`

export const Categories = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  cursor: pointer;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
`
export const Category = styled.div`
  width: 46%;
  background: #ebeafd;
  border:1px solid #dedde3;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: 1.5rem 0;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  @media only screen and (max-width: 600px){
    font-size: .8rem;
  }
`
export const Confirm = styled.div`
padding: 1rem;
`

export const ClientName = styled.span`
  ${props => props.theme.typography.body.lg.bold};
  @media only screen and (max-width: 400px){
    ${props => props.theme.typography.body.sm.bold};
  }
`

export const ClientMsg = styled.p`
  ${props => props.theme.typography.body.lg.regular};
  @media only screen and (max-width: 400px){
    ${props => props.theme.typography.body.sm.regular};
  }
`
export const ConfirmBody = styled.div`   
  min-height: 90vh;
  padding-top: 10rem;
`

export const ContactMessage = styled.p`
  text-align: center;
  ${props => props.theme.typography.body.md.regular};
  @media only screen and (max-width: 400px){
    ${props => props.theme.typography.body.sm.regular};
  }
`

export const Confirmed = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
`
export const ConfirmedText = styled.p`
  color: white;
`


export const ConfirmedMsg= styled.div`
  background: ${props=>props.color};
  width: 100%;
  height: 30vh;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;

`
export const ConfirmedImg = styled.img`
  width: 100%;
`

// div que va a tener un icono de carga con una animacion de rotacion infinita
export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  // hacer que este div gire a 360 grados en sentido de las manecillas del reloj y que se repita infinitamente
  animation: spin 1.5s linear infinite;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;

  // definir la animacion
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  // definir el tamaño del icono

`

