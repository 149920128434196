import { Browser, BrowserDesc, FlexColumn, SmallSection} from "./styled";
import { Button } from "ui/Buttons";
import { useTranslation } from "react-i18next";
import { H3 } from "ui/Text";
import { GENERAL_PANAMA_PHONE_NUMBER } from "constants/constants";

interface PropsBannerBrowser {
    text?: string
}

const message = 'Hola quiero registrarme en quenecesitas7 como aliado comercial.';


export const BannerBrowser = (props: PropsBannerBrowser) => {
    const {text} = props;
    const { t } = useTranslation();

    return (
        <SmallSection background="#ffffff" pav="1rem" pah="1rem">
            <FlexColumn>
                <Browser>
                    <BrowserDesc>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <H3>{text}</H3>
                            <Button
                                onClick={() => 
                                    // navigate to url whatsapp
                                    window.open(`https://wa.me/${GENERAL_PANAMA_PHONE_NUMBER}?text=${encodeURIComponent(`${message}`)}`)
                                }
                            style={{ alignSelf: 'center', marginTop: '30px' }} width="50%">{t('register_here')}</Button>
                        </div>
                    </BrowserDesc>
                </Browser>
            </FlexColumn>
        </SmallSection>
    );
};
