import { doc, getDoc, collection, getDocs, query, where  } from "firebase/firestore/lite";
import { firestore } from "config/firebase";

/**
 * @description Function to get a service by id from firestore
 * @param {number} id
 * @returns {Promise<any>}
*/
export const getServiceById = async (uid) => {
  try {
    const productRef = doc(firestore, 'PANAMA/Services/Posts', uid.toString());
    const productSnap = await getDoc(productRef);

    if (productSnap.exists()) {
      return { ...productSnap.data(), id: productSnap.id };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.log('Error getting document:', error);
    return null;
  }
};


/**
 * @description Function to get all services from firestore
 * @returns {Promise<any>}
 * @example getServices()
 * */
export async function getMostDemandedServices(): Promise<any> {
    try {
        const servicesRef = collection(firestore, 'PANAMA/Services/Categories/Yatchs/Posts');
        const servicesSnap = await getDocs(servicesRef);
        const services: any[] = [];
        servicesSnap.forEach((doc: any) => {
            services.push({ id: doc.id, ...doc.data() });
        });
        return services;
    }
    catch (error) {
        console.log('Error getting documents:', error);
        return null;
    }   
}



/**
 * @description Function to get service by id category from firestore
 * @param {number} id
 * @returns {Promise<any>}
 **/
export const getServicesByCategory = async (id: number): Promise<any> => {

    try {
        const servicesRef = collection(firestore, 'PANAMA/Services/Posts');
        const q = query(servicesRef, where('idCategory', '==', id));
        const servicesSnap = await getDocs(q);
        const services: any[] = [];
        servicesSnap.forEach((doc: any) => {
            services.push({ id: doc.id, ...doc.data() });
        });
        return services;
    }

    catch (error) {
        console.log('Error getting documents:', error);
        return null;
    }
    

}


  
