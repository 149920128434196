import styled from "styled-components";

export const Container = styled.div`
    width: 180px;
    height: 100px;
    background-color: #E3F1FE;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }
    @media (max-width: 1300px) {
        width: 160px;
    }

    @media (max-width: 1100px) {
        width: 140px;
    }

    @media (max-width: 992px) {
        width: 20vw;
    }

    @media (max-width: 768px) {
        width: 25vw;
    }

`;

export const SpanMiddle = styled.span`
  vertical-align: middle;
`
export const SponsorProduct = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #eeeeee;
  text-align: left;
  padding: .4rem;
  border-radius: 1rem;
  width: ${props=>props.size || "auto"};
  @media only screen and (max-width: 992px){
    width: ${props=>props.sizeSm || "auto"};
    padding: .2rem;
  }
`

export const SponsorImg = styled.img`
  width: 100%;
  border-radius: .5rem;
  object-fit: cover;
  height: 14rem;
  margin-bottom: .5rem;
`
export const SponsorPrice = styled.span`
  color: #038A25;
  ${props => props.theme.typography.body.sm.bold};
`

export const SponsorTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`
export const SponsorDesc = styled.span`
  padding: .4rem 0;
  ${props => props.theme.typography.label.xs.regular};
  line-height: 14.63px;
`
export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: ${props => props.background || "#ffffff"};
  color: ${props => props.background === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50px;
  padding: .4rem 1rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
  
  @media only screen and (max-width: 500px){
    &:first-child{
      padding: .2rem 1rem;  
    }
    ${props => props.theme.typography.body.sm.bold};
    padding: .2rem .5rem;
  }
`
export const SponsorBuy = styled.div`
  ${props => props.theme.typography.body.md.bold};
  display: flex;
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
`