import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { H3, P } from "ui/Text";
import { Container, ChatViewContainer, SendChatContainer, ChatListItemsContainer, ChatItemContainer, InputChat, DateMessage } from './styled';
import { userSelector } from 'redux/user/selectors';
import useSendMessageToClassifiedChat from 'hooks/modules/classifieds/use-send-message-to-classified-chat/use-send-message-to-classified-chat';
import { formatDate } from 'utils/format-date';
import { ChatButton } from 'ui/Buttons';
import { THEME } from 'constants/theme/theme';

const ChatItem = ({ message, senderId, createdAt }) => {
    const userId = useSelector(userSelector)?.userData?.id;
    const isCurrentUser = userId === senderId;

    return (
        <ChatItemContainer alignSelf={isCurrentUser ? 'flex-end' : 'flex-start'} isOwner={isCurrentUser}>
            <P>{message}</P>
            <DateMessage>{formatDate(createdAt)}</DateMessage>
        </ChatItemContainer>
    );
};

type MessageChatClassified = {
    text: string,
    senderId: string,
    createdAt: string
};

interface IChatSessionClassifiedContainerProps {
    listMessages: MessageChatClassified[] | [];
    classifiedId: string;
    consultantId: string;
    ownerClassifiedId: string;
    country: string;
    ownerClassifiedName: string;
    consultantName: string;
}

// Main component
const ChatSessionClassifiedContainer = (props: IChatSessionClassifiedContainerProps) => {
    const { listMessages, classifiedId, consultantId, ownerClassifiedId, country, ownerClassifiedName, consultantName } = props;
    const userId = useSelector(userSelector)?.userData?.id; // El ID del usuario actual
    const [text, setText] = useState('');
    const { sendMessage, loading, error, success } = useSendMessageToClassifiedChat();
    const isOwner = userId === ownerClassifiedId;

    // Sincronizar los mensajes del componente padre
    useEffect(() => {
        // setMessages(listMessages);
    }, [listMessages]);

    const handleSendMessage = async () => {
        if (!text.trim()) return; // No enviar mensajes vacíos

        const messageData = {
            classifiedId,
            consultantId,
            ownerClassifiedId,
            country,
            senderId: userId, // El usuario actual envía el mensaje
            text,
        };

        const response = await sendMessage(messageData);

        if (response && response.success) {
            setText('');
        }
    };

    return (
        <Container>
            <ChatViewContainer>
                <H3>Chat con {isOwner ? consultantName : ownerClassifiedName}</H3>
                <ChatListItemsContainer>
                    {listMessages.length === 0 ? (
                        <P>No hay mensajes aún</P>
                    ) : (
                        listMessages.map((message, index) => (
                            <ChatItem
                                createdAt={message.createdAt}
                                key={index}
                                message={message.text}
                                senderId={message.senderId}
                            />
                        ))
                    )}
                </ChatListItemsContainer>
            </ChatViewContainer>

            <SendChatContainer>
                <InputChat
                    type="text"
                    placeholder="Escribe un mensaje"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    disabled={loading}
                />
                <div>
                    <ChatButton onClick={handleSendMessage} disabled={loading || !text.trim()} backgroundColor={THEME.colors.classifieds}>
                        {loading ? 'Enviando...' : 'Enviar'}
                    </ChatButton>
                    {error && <P style={{ color: 'red', fontSize: '.6rem' }}>Error: {error}</P>}
                    {success && <P style={{ color: 'green', fontSize: '.6rem' }}>Mensaje enviado con éxito</P>}
                </div>
            </SendChatContainer>
        </Container>
    );
};

export default ChatSessionClassifiedContainer;
