import styled from "styled-components";
import { THEME } from 'constants/theme/theme'

interface IDiv {
  backgroundColor?: string;
  color?: string;
  size?: string;
  width?: string;
  margin?: string;
}

export const Container = styled.div<IDiv>`
    width: ${props => props.width || "100%"};
    max-width: 480px;
    background-color: ${props => props.backgroundColor || "#FAFAFA"};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);

    &:hover {
        opacity: 0.8;
    }

`;

export const SponsorProduct = styled.div`
  display: flex;
  margin-bottom: 1rem;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: #FDFDFE;
  text-align: left;
  margin-right: 1rem;
  padding: 1rem 1rem 0rem 1rem;
  border-radius: 1rem;
  width: ${props=>props.size || "auto"};

  @media only screen and (min-width: 1024px){
    height: 47vh;
  }
  @media only screen and (max-width: 992px){
    height: 43vh;
  }
  @media only screen and (max-width: 768px){
    height: 42vh;
  }
  @media only screen and (max-width: 465px){
    height: 38vh;
  }
  &:before {
    ${({contenido}) => contenido && `
    background: #038A25;
    `}
    content: '${props => props.contenido}';
    padding: .5rem;
    color: #ffffff;
    border-radius: 0 1rem 1rem 0;
    position: absolute;
    top: .5rem;
    left: 0;
    @media only screen and (max-width: 500px){
      ${props => props.theme.typography.body.sm.regular};
    }
  }
`

export const ProductImage = styled.img`
  width: ${props => props.size || "100%"};
  border-radius: .5rem;
  height: 200px;
  margin-bottom: .5rem;
  object-fit: contain;
  @media only screen and (max-width: 760px){
    height: 180px;
  }

  @media only screen and (max-width: 560px){
    height: 160px;
  }

  @media only screen and (max-width: 420px){
    height: 150px;
  }
  
`

export const ProductPriceContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: .1rem;
`

export const PriceRegular = styled.span`
  ${props => props.theme.typography.body.sm.regular};
  color: gray;
  font-size: .7rem;
  text-decoration: line-through;
  margin-right: .5rem;

  @media only screen and (max-width: 420px){
    font-size: .6rem;
  }
`

interface IProductPrice {
  isDiscount?: boolean;
}

export const PriceDiscount = styled.span`
  ${props => props.theme.typography.body.sm.bold};
  color: #0C121D;
  font-size: ${props => props.isDiscount ? ".6rem" : ".8rem"};

  @media only screen and (max-width: 420px){
    font-size: .7rem;
  }
`

export const DiscountBatch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;
  padding: .2rem .3rem;
  background: ${THEME.colors.negative100};
  color: #ffffff;
  margin: 6px 0 0px 6px;
  font-size: .5rem;
  position: absolute;
  font-weight: 600;
  top: 0;
  left: 0;
`
  

interface IProductPrice {
  color?: string;
  size?: string;
  isDiscount?: boolean;
}

export const Price = styled.p<IProductPrice>`
  ${props => props.theme.typography.body.sm.bold};
  color: ${props => props.color || THEME.colors.positive200};
  font-size: ${props => props.size || props.isDiscount ? "1rem" : "1.2rem" };

  @media only screen and (max-width: 420px){
    font-size: ${props => props.isDiscount ? ".8rem" : "1rem" };
  }
`

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
`
export const ProductDescription = styled.span`
  ${props => props.theme.typography.label.xs.regular};
  /* padding: 8px 0; */
  color: #0C121D;
  font-size: 12px; 
  line-height: 14px;
  hyphens:manual;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: -.7rem;
  height: 46px;
`
export const TransButton = styled.button`
  padding: .5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:${props => props.height || "auto"};
  width: ${props => props.size || "auto"};
  background: ${props => props.bgColor || "#ffffff"};
  color: ${props => props.bgColor === '#3A36DB' ? "#F5F5F6" : "#3A36DB"};
  border-radius: 50%;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};

  &:hover{
    transform: scale(1.05);
  }
`
export const ButtonsContainer = styled.div`
  ${props => props.theme.typography.body.md.bold};
  width: 100%;
  display: flex;
  margin-top: .6rem;
  font-family: Montserrat, monospace !important;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
  gap: .5rem;
`

export const ProductInfoContainer = styled.div`
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
  /* padding: 0 .5rem; */
  min-height: 120px;
`
