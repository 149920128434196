import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { FC } from "react";
import { GiRoundStar } from "react-icons/gi";
interface BasicRatingProps {
  value: number;
  size?: "small" | "medium" | "large";
}

const BasicRating: FC<BasicRatingProps> = ({ value, size = "small" }) => {
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <Rating
        icon={<GiRoundStar />}
        size={size}
        sx={{ color: "#3A36DB", borderRadius: "10px" }}
        name="read-only"
        value={value || 0}
        readOnly
      />
    </Box>
  );
};

export default BasicRating;
