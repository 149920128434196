import styled from 'styled-components'

export const ContentProductsContainer = styled.div`
  width: 100%;
`;

export const ListProductsContainer = styled.div`
  width: 100%;
  margin: 20px 0px;
  background-color: ${props => props.color || "#F5F5F6"};
  border-radius: 16px;
  padding: 1rem;

  @media only screen and (max-width: 1200px) {
    padding: 20px;
  }
  @media only screen and (max-width: 992px) {
    border-radius: 0;
    padding: 0rem .5rem;
  }
  
`;

export const LinkMore = styled.a`
  color: #3A36DB;
  margin-right: 16px;
  cursor:pointer;
  ${props => props.theme.typography.body.lg.bold};
  display: block;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const SpaceBetween = styled.div`
  display: flex;
  margin: .5rem 0rem 0rem 0rem;
  justify-content: space-between;
`

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;
  color:#406385;
`

export const TextFilter = styled.span`
  cursor: pointer;
  margin-right: 16px;
  ${props => props.theme.typography.subheading.md.semibold};
  @media only screen and (max-width: 768px){
    ${props => props.theme.typography.subheading.xs.semibold};
  }
`
export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  overflow: scroll;
`
export const PaneTitle = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: #000000;
  font-family: Outfit, monospace;
  font-weight: 700;
  font-size: 1.2rem;
  
`

export const TransButton = styled.button`
  display: block;
  width: ${props => props.size || "auto"};
  background: #ffffff;
  color: #406385;
  border-radius: 50px;
  padding: .2rem 2rem;
  border: 1px solid #406385;
  ${props => props.theme.typography.body.md.bold};
`
export const PaneSection = styled.div`
  padding: .5rem 3rem;
  margin-bottom: .3rem;
  width: 100%;
`
export const PaneFooter = styled.div`
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
`


export const PaneFooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap:1rem;
`

type RowType = React.HTMLAttributes<HTMLDivElement> 

export const Row = styled.div<RowType>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  flex: ${({ flex }) => flex || '0 1 auto'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  @media only screen and (max-width: 992px){
    padding: 1rem 1rem 0 1rem;
  }
  `;

export const GridContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 12fr);
  grid-gap: 1rem;
  padding: 1rem 0rem;

  @media only screen and (max-width: 1200px) {
      grid-template-columns: repeat(4, 12fr);
      padding: 1rem;
  }
  @media only screen and (max-width: 992px) {
      grid-template-columns: repeat(3, 12fr);
  }
  @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 32fr);
  }

`


// @media only screen and (max-width: 380px) {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   /* grid-template-columns: repeat(1, 60fr);
//   // center
//   justify-content: center;
//   align-items: center; */
// }