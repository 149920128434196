import { FC, useState, useEffect, useRef } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  SubContainer,
  Grid,
  GridItem,
  FlexColumn,
  FlexRow,
  ImgNextButton,
  InputLabel,
  InputWrap,
  Message,
  OptionTitle,
  ProductImg,
  Description,
  Container,
  TextArea,
  LabelArrow,
  ContainerArrowRight,
  AnimatedIcon,
  WrapperGrid,
} from "./styled";
import { isMobile } from "utils/common";
import asesora from "assets/asesora.png";
import { Button } from "ui/Buttons/";
import { Titles } from "modules/services/components/Properties/styled";
// import ModalSelector from "modules/services/pages/detail/components/modal-selector";
import ModalLoader from "ui/ModalLoader";
import UrgencyLevelInput from "components/urgency-level-input/urgency-level-input";
import { useNotification } from "libs/context/AlertContext";
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import useCreateLandingNeedRequest from "hooks/needs/use-create-need-real-state-request/use-create-need-real-state-request";
import { userSelector } from "redux/user/selectors";
// import { getLanguage } from "redux/common";
import { CreateNeedRealStateLandingType } from "types";
import ModalBasicUserData from "components/modals/modal-basic-user-data/modal-basic-user-data";
import { useTranslation } from "react-i18next";

interface ServicesFormProps {
  options: string[];
  titleFormDescription: string;
  descriptionForm: string;
  category: string;
}

const validationSchema = Yup.object().shape({
  options: Yup.array().min(1, "Debe seleccionar al menos una opción"),
  urgencyLevel: Yup.string().required("Campo requerido"),
  needDescription: Yup.string().required("Campo requerido"),
});

const ServicesForm: FC<ServicesFormProps> = ({
  category,
  options,
  titleFormDescription,
  descriptionForm,
}) => {
  const { showWarning, showSuccess, showError } = useNotification();
  const {
    createLandingNeedRequest,
    isLoading: loadingCreateNeedRealStateRequest,
    error: errorCreateNeedRealStateRequest,
    isSuccess: successCreateNeedRealStateRequest,
  } = useCreateLandingNeedRequest();
  const isAuth = useIsAuth();
  const { t } = useTranslation();
  //const language = useSelector(getLanguage);

  const { userData } = useSelector(userSelector);

  const formikRef = useRef<FormikHelpers<any>>();

  // const [showContactModal, setShowContactModal] = useState(false);
  const [showBasicUserDataModal, setShowBasicUserDataModal] = useState(false);

  function sendNeedRealStateRequest(values: CreateNeedRealStateLandingType) {
    const { options: optionsValues, urgencyLevel, needDescription } = values;
    const optionsString = optionsValues.join(", ");

    const data = {
      category,
      options: optionsString,
      urgencyLevel,
      needDescription,
      firstName: userData?.firstName ? userData?.firstName : values.firstName,
      firstSurname: userData?.firstSurname
        ? userData?.firstSurname
        : values.firstSurname,
      email: userData?.email ? userData?.email : values.email,
      phone: userData?.phone ? userData?.phone : values.phoneNumber,
    };
    createLandingNeedRequest(data);
  }

  useEffect(() => {
    if (successCreateNeedRealStateRequest) {
      showSuccess("Se ha enviado tu solicitud correctamente");
      setShowBasicUserDataModal(false);
      formikRef.current?.resetForm();
    }

    if (errorCreateNeedRealStateRequest) {
      showError("Ha ocurrido un error, por favor intenta de nuevo");
    }
  }, [
    successCreateNeedRealStateRequest,
    showError,
    showSuccess,
    errorCreateNeedRealStateRequest,
  ]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        options: [] as string[],
        urgencyLevel: "",
        needDescription: "",
      }}
      onSubmit={(values, actions) => {
        if (!loadingCreateNeedRealStateRequest && isAuth) {
          sendNeedRealStateRequest(values);
        } else if (!loadingCreateNeedRealStateRequest && !isAuth) {
          setShowBasicUserDataModal(true);
        }
        actions.setSubmitting(false);
        formikRef.current = actions;
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        errors,
      }) => {
        return (
          <Form id="filterForm" onSubmit={handleSubmit}>
            <Container>
              <SubContainer>
                <Titles alignM={"center"} size={"1.5rem"} align="center">
                  {titleFormDescription}
                </Titles>
                <Description>{descriptionForm}</Description>
                <Description>
                  {t(
                    "to_offer_you_a_personalized_experience_we_invite_you_to_complete_the_form"
                  )}
                  {` ${isMobile() ? t("below") : t("on_the_right")}. `}{" "}
                  {t(
                    "there_you_can_specify_your_real_estate_needs_and_the_level_of_urgency"
                  )}
                  .
                </Description>
                <LabelArrow>
                  {t("fill_out_the_form")}{" "}
                  {` ${isMobile() ? t("below") : t("on_the_right")} `}{" "}
                  {t("for_personalized_attention")}
                </LabelArrow>
                <ContainerArrowRight>
                  <AnimatedIcon />
                </ContainerArrowRight>
              </SubContainer>
              <SubContainer>
                <Titles alignM={"center"} size={"1.5rem"} align="center">
                  {t("choose_the_option_that_best_suits_your_needs")}
                </Titles>
                <FlexColumn fondo={"#E7F7F6"}>
                  <WrapperGrid>
                    <Grid>
                      {options.map((option: string) => (
                        <GridItem
                          key={option}
                          isSelected={values.options.includes(option)}
                          onClick={() => {
                            if (values.options.includes(option)) {
                              setFieldValue(
                                "options",
                                values.options.filter(
                                  (el: any) => el !== option
                                )
                              );
                            } else if (values.options.length < 3) {
                              setFieldValue("options", [
                                ...values.options,
                                option,
                              ]);
                            } else {
                              showWarning(
                                "No puedes seleccionar más de 3 opciones"
                              );
                            }
                          }}
                        >
                          <p style={{ margin: "0px 6px" }}>{option}</p>
                        </GridItem>
                      ))}
                    </Grid>
                  </WrapperGrid>
                  <OptionTitle>{t("urgency_level")}</OptionTitle>
                  <UrgencyLevelInput
                    value={values.urgencyLevel}
                    onChange={(value) => setFieldValue("urgencyLevel", value)}
                  />
                  <OptionTitle>
                    {t("write_in_detail_what_you_need")}
                  </OptionTitle>
                  <InputWrap size={"100%"}>
                    <TextArea
                      id="need"
                      value={values.needDescription}
                      className="form__input"
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange("needDescription")(e);
                      }}
                      onBlur={handleBlur}
                    ></TextArea>
                    <InputLabel htmlFor="need" className="form__label">
                      {t("describe_your_need_here")}
                    </InputLabel>
                  </InputWrap>
                  <FlexRow>
                    <ImgNextButton>
                      {window.innerWidth > 768 && (
                        <Button
                          disabled={Object.keys(errors).length > 0}
                          onClick={() => handleSubmit()}
                          onDisabledClick={() => {
                            showWarning(
                              "Debes completar el formulario para enviar tu solicitud"
                            );
                          }}
                          type="button"
                        >
                          {t("send_need")}
                        </Button>
                      )}
                      {window.innerWidth < 768 && (
                        <ProductImg
                          className={"img-fluid"}
                          src={asesora}
                          loading="lazy"
                          alt="Asesora"
                          title="Asesora"
                        />
                      )}
                      {window.innerWidth < 768 && (
                        <FlexColumn>
                          <Message>{t("contact_an_advisor")}</Message>
                          <Button
                            disabled={Object.keys(errors).length > 0}
                            onClick={() => handleSubmit()}
                            onDisabledClick={() => {
                              showWarning(
                                "Debes completar el formulario para enviar tu solicitud"
                              );
                            }}
                            type="button"
                          >
                            {t("send_need")}
                          </Button>
                        </FlexColumn>
                      )}
                    </ImgNextButton>
                  </FlexRow>
                </FlexColumn>
              </SubContainer>
              {/* {values?.options ? (<ModalSelector
                docData={values.options}
                showContactModal={showContactModal}
                setShowContactModal={setShowContactModal}
                extraServiceModalTitle="Formulario de Servicios"
                language={language}
              />) : null} */}
              <ModalBasicUserData
                isOpen={
                  showBasicUserDataModal && !loadingCreateNeedRealStateRequest
                }
                onClose={() => setShowBasicUserDataModal(false)}
                onSubmit={(e) => sendNeedRealStateRequest({ ...values, ...e })}
              />
            </Container>
            <ModalLoader isLoading={loadingCreateNeedRealStateRequest} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ServicesForm;
