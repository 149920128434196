import { useState, useEffect } from "react";
import {
  Direction,
  DirectionBody,
  DirectionDesc,
  DirectionHeader,
  DirectionItem,
  DirectionItemDesc,
  AddresInfoItem,
  DirectionLabelName,
  DirectionName,
  Directions,
  TransButton,
  ButtonViewMoreContainer,
  EmptyDirections,
  Badge,
} from "../../styled";
import { Tab, Tabs } from "@mui/material";
import { HiOutlinePencil } from "react-icons/hi";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, ButtonViewMoreAndLess } from "ui/Buttons";
import ModalConfirm from "components/modals/modal-confirm";
import ModalLoader from "ui/ModalLoader";
import { H4 } from "ui/Text";
import ModalAddress from "components/modals/modal-form-new-address/modal-form-new-address";
import Skeleton from "@mui/material/Skeleton";
import { Address } from "types";
import { orderAddresses } from "modules/Profile/main";
import useCreateAddress from "hooks/user/useCreateAddress/use-create-address";
import { useNotification } from "libs/context/AlertContext";
import { updateAddress } from "services/user/updateInfo/service-update-user-info";
import useDeleteAddress from "hooks/user/useDeleteSingleUserAddress/use-delete-single-user-address";
import { useTranslation } from "react-i18next";

import _ from "lodash";

interface UserAddressesProps {
  listAddress: Address[];
  userId: string;
}

const UserAddresses = (props: UserAddressesProps) => {
  const { showSuccess, showError } = useNotification();
  const { t } = useTranslation();
  const {
    createAddress,
    loading: loadingCreateAddress,
    success,
    error,
  } = useCreateAddress();
  const { deleteAddressById } = useDeleteAddress();
  const { listAddress, userId } = props;
  const listAddressUniq = _.uniqBy(orderAddresses(listAddress), "id");
  const [tabIndex, setTabIndex] = useState(0);
  const [idAddressSelected, setIdAddressSelected] = useState<string>("");
  const [loadingDeleteAddress, setLoadingDeleteAddress] =
    useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showModalConfirmDeleteAddress, setShowModalConfirmDeleteAddress] =
    useState<boolean>(false);
  const [addressIdToDeleteId, setAddressIdToDeleteId] = useState<string>("");
  const [infoAddressToEdit, setInfoAddressToEdit] = useState<Address | null>(
    null
  );

  const handleCreateAddress = (addressData: Address) => {
    createAddress(userId, addressData);
  };

  const handleEditAddress = (addressData: Address) => {
    setInfoAddressToEdit(addressData);
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  useEffect(() => {
    if (success) {
      showSuccess("Dirección creada con éxito");
      setOpenModal(false);
    } else if (error) {
      showError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error]);

  const handleUpdateAddress = async (values: any): Promise<any> => {
    updateAddress(userId, values)
      .then((res) => {
        showSuccess("Dirección actualizada correctamente");
      })
      .catch((err) => {
        console.error("error updating the address", err);
        showError(`Error al actualizar la dirección ${err}`);
      })
      .finally(() => {});
  };

  const handleDeleteUserAddressById = async (
    userId: string,
    addressId: string
  ) => {
    setLoadingDeleteAddress(true);
    const result = await deleteAddressById(userId, addressId);
    if (result.success) {
      showSuccess("Dirección eliminada correctamente");
      setLoadingDeleteAddress(false);
    } else {
      setLoadingDeleteAddress(false);
      showError("Error eliminando la dirección");
      console.error("Error deleting address:", result.error);
    }
  };

  return (
    <>
      <p>{t("billing_and_order_receipt_address")}</p>
      <Tabs
        style={{ marginBottom: "1rem" }}
        variant="fullWidth"
        value={tabIndex}
        onChange={handleTabChange}
      >
        <Tab label={t("shipment")} />
        <Tab label={t("billing")} />
      </Tabs>
      {tabIndex === 0 && (
        <Directions>
          {listAddressUniq?.length ? (
            listAddressUniq.map(
              (item, index) =>
                item.isShipping ? (
                  <Direction key={index}>
                    {item.isPrimaryShipping && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Badge>
                          <p>{t("main")}</p>
                        </Badge>
                      </div>
                    )}
                    <DirectionHeader>
                      <DirectionName>
                        <DirectionLabelName>{t("address")}:</DirectionLabelName>
                        <DirectionDesc>{` ${item?.street}`}</DirectionDesc>
                      </DirectionName>
                    </DirectionHeader>
                    <DirectionItem>
                      <DirectionBody>
                        <DirectionItemDesc>
                          {t("name")}:
                          <AddresInfoItem>{` ${item?.fullName}`}</AddresInfoItem>
                          {t("country")}:
                          <AddresInfoItem>{` ${item?.country}`}</AddresInfoItem>
                          {t("city")}:
                          <AddresInfoItem>{item?.city} </AddresInfoItem>
                          {idAddressSelected === item.id ? (
                            <>
                              {t("street")}:
                              <AddresInfoItem>{item?.street}</AddresInfoItem>
                              {item?.additionalComments?.length > 0 && (
                                <>
                                  {t("comment")}:
                                  <AddresInfoItem>
                                    {item?.additionalComments}
                                  </AddresInfoItem>
                                </>
                              )}
                              {t("zip_code")}:
                              <AddresInfoItem>
                                {item?.postalCode || "N/A"}
                              </AddresInfoItem>
                              <ButtonViewMoreContainer>
                                <ButtonViewMoreAndLess
                                  type="less"
                                  onClick={() => {
                                    setIdAddressSelected("");
                                  }}
                                />
                              </ButtonViewMoreContainer>
                            </>
                          ) : (
                            <ButtonViewMoreContainer>
                              <ButtonViewMoreAndLess
                                type="more"
                                onClick={() => {
                                  setIdAddressSelected(item.id);
                                }}
                              />
                            </ButtonViewMoreContainer>
                          )}
                        </DirectionItemDesc>
                      </DirectionBody>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "10px",
                        }}
                      >
                        <TransButton
                          onClick={() => handleEditAddress(item)}
                          margin={"0"}
                        >
                          <HiOutlinePencil /> {t("edit")}
                        </TransButton>
                        <TransButton
                          style={{ color: "red" }}
                          onClick={() => {
                            setAddressIdToDeleteId(item.id);
                            setShowModalConfirmDeleteAddress(true);
                          }}
                          margin="0px -4px"
                        >
                          <DeleteIcon style={{ fontSize: "16px" }} />
                          {t("delete")}
                        </TransButton>
                      </div>
                    </DirectionItem>
                  </Direction>
                ) : null // No mostramos direcciones que no sean de envío
            )
          ) : (
            <EmptyDirections>
              <H4 textAlign="center">{t("no_shipping_address_registered")}</H4>
            </EmptyDirections>
          )}
        </Directions>
      )}

      {tabIndex === 1 && (
        <Directions>
          {listAddress ? (
            listAddressUniq.map((item, index) =>
              item.isBilling ? (
                <Direction key={index}>
                  {item.isPrimaryBilling && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Badge>
                        <p>{t("main")}</p>
                      </Badge>
                    </div>
                  )}
                  <DirectionHeader>
                    <DirectionName>
                      <DirectionLabelName>{t("address")}:</DirectionLabelName>
                      <DirectionDesc>{` ${item?.street}`}</DirectionDesc>
                    </DirectionName>
                  </DirectionHeader>
                  <DirectionItem>
                    <DirectionBody>
                      <DirectionItemDesc>
                        {t("name")}:
                        <AddresInfoItem>{` ${item?.fullName}`}</AddresInfoItem>
                        {t("country")}:
                        <AddresInfoItem>{` ${item?.country}`}</AddresInfoItem>
                        {t("city")}:
                        <AddresInfoItem>{item?.city}</AddresInfoItem>
                        {idAddressSelected === item.id ? (
                          <>
                            {t("street")}:
                            <AddresInfoItem>{item?.street}</AddresInfoItem>
                            {item?.additionalComments?.length > 0 && (
                              <>
                                {t("comment")}:
                                <AddresInfoItem>
                                  {item?.additionalComments}
                                </AddresInfoItem>
                              </>
                            )}
                            {t("zip_code")}:
                            <AddresInfoItem>
                              {item?.postalCode || "N/A"}
                            </AddresInfoItem>
                            <ButtonViewMoreContainer>
                              <ButtonViewMoreAndLess
                                type="less"
                                onClick={() => {
                                  setIdAddressSelected("");
                                }}
                              />
                            </ButtonViewMoreContainer>
                          </>
                        ) : (
                          <ButtonViewMoreContainer>
                            <ButtonViewMoreAndLess
                              type="more"
                              onClick={() => {
                                setIdAddressSelected(item.id);
                              }}
                            />
                          </ButtonViewMoreContainer>
                        )}
                      </DirectionItemDesc>
                    </DirectionBody>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <TransButton
                        onClick={() => handleEditAddress(item)}
                        margin={"0"}
                      >
                        <HiOutlinePencil /> {t("edit")}
                      </TransButton>
                      <TransButton
                        style={{ color: "red" }}
                        onClick={() => {
                          setAddressIdToDeleteId(item.id);
                          setShowModalConfirmDeleteAddress(true);
                        }}
                        margin="0px -4px"
                      >
                        <DeleteIcon style={{ fontSize: "16px" }} />
                        {t("delete")}
                      </TransButton>
                    </div>
                  </DirectionItem>
                </Direction>
              ) : (
                <></>
              )
            )
          ) : (
            <Direction>
              <Skeleton variant={"rounded"} width={"100%"} height={300} />
            </Direction>
          )}
        </Directions>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "32px 0px 0px 0px",
        }}
      >
        <Button onClick={() => setOpenModal(true)}>
          {t("create_a_new_address")}
        </Button>
      </div>
      {openModal && (
        <ModalAddress
          isOpen={openModal}
          onSubmit={handleCreateAddress}
          onClose={() => {
            setInfoAddressToEdit(null);
            setOpenModal(false);
          }}
          isFirstAddress={!listAddress?.length}
          tabIndex={tabIndex}
        />
      )}
      {infoAddressToEdit && (
        <ModalAddress
          isOpen={true}
          onClose={() => setInfoAddressToEdit(null)}
          onSubmit={(e) => handleUpdateAddress(e)}
          initialValues={infoAddressToEdit}
          isEditing={true}
        />
      )}
      {showModalConfirmDeleteAddress && (
        <ModalConfirm
          isOpen={showModalConfirmDeleteAddress}
          onClose={() => setShowModalConfirmDeleteAddress(false)}
          onSubmit={() => {
            handleDeleteUserAddressById(userId, addressIdToDeleteId);
            setShowModalConfirmDeleteAddress(false);
          }}
          description={
            t("label_are_you_sure_you_want_to_delete_this_address") || ""
          }
          titleButton={t("delete") || ""}
          title=""
        />
      )}
      <ModalLoader isLoading={loadingCreateAddress || loadingDeleteAddress} />
    </>
  );
};

export default UserAddresses;
