import { useEffect} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ContainerSlider,
  GridContainer,
  Section,
  Grid,
  SectionSky,
  FormWrapper,
  TextWrapper,
} from "./styled";
import MainLayout from "views/layout/MainLayout";
import SliderCarousel from "components/SliderCarousel";
import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { useTranslation } from 'react-i18next';
import { getCountry, getLanguage } from "redux/common";
import FormEarnMoney from "components/Forms/earn-money-form/earn-money-form";
import { TopCardEarnMoney } from "./components";
import { CardEarnMoney } from "./components";
import { PaySafeEarnMoney } from "./components";
// import { Extras } from "modules/services/components/Extras/extras";
import { MAIN_ROUTES } from "constants/routes";
import { H2 } from "ui/Text";


const gradients = [
  'radial-gradient(49.06% 58.53% at 78.92% 57.51%, rgba(11, 74, 83, 1) 0%, rgba(0, 0, 0, 0.88) 100%)',
  'radial-gradient(33.02% 46.96% at 16.98% 86.08%, rgba(8, 58, 28, 0.85) 5.26%, rgba(0, 0, 0, 0.88) 100%)',
  'radial-gradient(38.42% 38.42% at 27.4% 61.58%, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.88) 100%)',
  'radial-gradient(32.5% 46.49% at 49.88% 10.12%, rgba(71, 87, 7, 0.8) 0%, rgba(0, 0, 0, 0.88) 100%)',
  'radial-gradient(34.79% 30.16% at 49.88% 37.58%, rgba(175, 175, 175, 1) 0%, rgba(0, 0, 0, 0.88) 100%)',
  'radial-gradient(32.5% 46.49% at 49.88% 10.12%, rgba(71, 87, 7, 0.8) 0%, rgba(0, 0, 0, 0.88) 100%)',
];

const EarnMoneyScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const country = useSelector(getCountry);
  const currentLanguage = useSelector(getLanguage);

  const { collectionData: ads, fetchCollection: fetchAds } =
    useGetFirestoreCollection();
  const { collectionData: posts, fetchCollection: fetchPosts } =
    useGetFirestoreCollection();
  const { collectionData: banners, fetchCollection: fetchBanners } =
    useGetFirestoreCollection();

  useEffect(() => {
    fetchAds({
      path: `${country}/Earnmoney/Ads/`
    });
    fetchBanners({
      path: `${country}/Earnmoney/Banners/`,
    });
    fetchPosts({
      path: `${country}/Earnmoney/Posts/`
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function orderByOrder(items) {
    if (items) {
      items.sort((a, b) => a.order - b.order);
    }
    return items;
  }

  function getImageByScreenWidth(images) {
    if (window.innerWidth < 768) {
      return images[0].imagesMobile?.[0][currentLanguage]
    } else {
      return images[0].imagesDesktop[0][currentLanguage]
    }
  }

  return (
    <MainLayout type="earn-money">
      <ContainerSlider>
        <SliderCarousel padding="0" images={banners ? [getImageByScreenWidth(banners)] : []} />
      </ContainerSlider>

        <SectionSky>
            <TextWrapper>
                <H2>{t('open_your_account_with_us')}</H2>
            </TextWrapper>
            <TextWrapper>
                <H2>{t('start_generating_extra_income')}</H2>
            </TextWrapper>
        </SectionSky>

        <GridContainer>
        <Section>
          <Grid>
            {
              orderByOrder(ads)?.map((item, index) => {
                return (
                  <TopCardEarnMoney
                    key={index}
                    image={item.images[0]}
                    title={item.name[currentLanguage]}
                    shortDescription={item.shortDescription[currentLanguage]}
                  />
                )
              })
            }
          </Grid>
          <Grid>
          {orderByOrder(posts)?.map((item, index) => (
              <CardEarnMoney
                key={index}
                image={item.images[0]}
                title={item.name[currentLanguage]}
                shortDescription={item.shortDescription[currentLanguage]}
                onView={() => {
                  navigate(`${MAIN_ROUTES.DetailEarnMoney}?id=${item.id}`);
                }}
                gradient={gradients[index % gradients.length]} // Asigna gradientes cíclicamente
              />
            ))}
          </Grid>
        </Section>
        {/* <Extras data={data} hasRedirectToDetail={true} /> */}
      </GridContainer>
      <FormWrapper>
        <FormEarnMoney />
      </FormWrapper>
      <PaySafeEarnMoney
        image="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FServices%2Fcategories%2FearnMoney%2Fsecure%20payments.webp?alt=media&token=e3152d6f-d29b-450b-81e2-6629a751f4c9"
        title={t('secure_payments') as string}
        shortDescription={t('all_direct_payment_transactions') as string}
        text={t('guarantee_your_product_and_money') as string}
      />
    </MainLayout>
  );
};

export default EarnMoneyScreen;
