import { useState, useEffect } from 'react';
import {Button, BackButton} from 'ui/Buttons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Container, MessageContainer, UserMessage, BotMessage, Form, Input } from './styled';
import { H2 } from 'ui/Text';
import Spinner from 'ui/Spinner/spinner';

interface Message {
  role: string;
  content: string;
}

const validationSchema = yup.object({
  message: yup
    .string()
    .required('El mensaje es requerido'),
}); 

// const FIRST_MESSAGE = { role: 'assistant', content: 'Hola, soy Perry, tu asistente virtual. ¿En qué puedo ayudarte?' };

const ChatApp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);

  const speech = new SpeechSynthesisUtterance();

  const speak = (text: string) => {
    speech.text = text;
    // window.speechSynthesis.speak(speech);
  };

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && (lastMessage.role === 'assistant' || lastMessage.role === 'function')) {
      speak(lastMessage.content);
    }

    // eslint-disable-next-line
  }, [messages]);

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const newMessage = { role: 'user', content: values.message };
      setMessages([...messages, newMessage]);
      try {
        const response = await axios.post('https://us-central1-wdyn7-app.cloudfunctions.net/interactWithChatBot', { messages: [...messages, newMessage] });
        const lastAssistantMessage = response.data.messages.reverse().find((msg: Message) => (msg.role === 'assistant' || msg.role === 'function'));
        if (lastAssistantMessage && lastAssistantMessage.content !== null) {
          setMessages(prevMessages => [...prevMessages, lastAssistantMessage]);
        }
      } catch (error) {
        console.error('Error al interactuar con el chatbot:', error);
      }
      resetForm({});
      setLoading(false);
    },
  });

  return (
    <Container>
      <H2 margin='2rem 0px' >{t('chat_with_perry')}</H2> 
      <MessageContainer>
          {messages.map((msg, index) => (
            msg.role === 'user'
            ? <UserMessage key={index}>{`${t('you')}: ${msg.content}`}</UserMessage>
            : <BotMessage key={index}>{`Perry: ${msg.content}`}</BotMessage>
          ))}
      </MessageContainer>
      <Form onSubmit={formik.handleSubmit}>
        <Input 
          id="message"
          name="message"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.message}
          placeholder="Escribe aquí" 
        />
        <Button 
          type="submit"
          onClick={formik.handleSubmit}
        >
          {loading ? <Spinner color='#fff' /> : `${t('send')}`}
        </Button>
      </Form>
      <div style={{width: '100%', padding: '0rem 1rem'}}>
        <BackButton onClick={() => navigate(-1)} />
      </div>
    </Container>
  );
};

export default ChatApp;
