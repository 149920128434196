import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface SpinnerProps {
  color?: string;
}

const Spinner = ({color}: SpinnerProps) => {
  return (
    <Box sx={{ display: 'flex',  }}>
      <CircularProgress  style={{width: '20px', height: '20px', color}}/>
    </Box>
  );
}

export default Spinner;