/**
 * This file is generated by plopfile
 * avoid modifications and DO NOT remove the comments
 * comments above is used by plopfile to localize the line which  id going to generate the code
 */

// append_reducer_import
import {combineReducers} from '@reduxjs/toolkit'
import {userReducer as user} from 'redux/user'
import {authReducer as auth} from 'redux/auth'
import {cartReducer as cart} from 'redux/cart'
import { checkoutReducer as checkout } from 'redux/checkout'
import { serviceDetailReducer as serviceDetail } from 'modules/services/store'
import { commonReducer as common } from 'redux/common'

export const rootReducer = combineReducers({
  user,
  auth,
  cart,
  checkout,
  serviceDetail,
  common
})
