import React from "react";
import { Container, Text, Image, Hr } from "./styled";

interface Props {
    text: string;
    image: string;
}

const BlogTextType = (props: Props) => {
    const { text, image } = props;

    return (
        <div>
            <Container>
                <Text>{text}</Text>
                <Image src={image} loading="lazy" alt={text} title={text} />

            </Container>
            <Hr />
        </div>
    );
};

export default BlogTextType;
