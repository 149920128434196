import { useTranslation } from "react-i18next";

// Definimos las opciones con traducción

/* export enum servicesTitles {
  washingMachines = 'SERVICIO DE LAVADORAS',
  refrigeratorAndHeaterServices = 'SERVICIO DE REFRIGERADORES Y CALENTADORES',
  lampAndFanServices = 'SERVICIOS DE LÁMPARAS Y VENTILADORES',
  airConditioning = 'SERVICIO DE AIRE ACONDICIONADO',
  computerServices = 'SERVICIO DE COMPUTADORAS',
  starlink = 'Starlink',
}; */

export enum servicesTitles {
  washingMachines = "servicesTitles.washingMachines",
  refrigeratorAndHeaterServices = "servicesTitles.refrigeratorAndHeaterServices",
  lampAndFanServices = "servicesTitles.lampAndFanServices",
  airConditioning = "servicesTitles.airConditioning",
  computerServices = "servicesTitles.computerServices",
  starlink = "servicesTitles.starlink",
}

export const basicCardServiceMock = {
  id: 1,
  name: {
    es: "ADMINISTRAMOS TU PROPIEDAD",
    en: "WE MANAGE YOUR PROPERTY",
  },
  description: {
    es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
    en: "Whether commercial or residential, we can help you with everything related to your property",
  },
  price: {
    es: "$100",
    en: "$100",
  },
  currency: {
    es: "USD",
    en: "USD",
  },
  images: [
    "https://cdn.pixabay.com/photo/2016/07/30/00/03/winding-road-1556177_1280.jpg",
  ],
  shortDescription: {
    es: "Sea comercial o residencial te podemos ayudar con todo lo referente a tu propiedad",
    en: "Whether commercial or residential, we can help you with everything related to your property",
  },
};

export const docDataBusinessMock = {
  name: {
    es: "información más detallada",
    en: "more information",
  },
  shortDescription: {
    es: "Vengo de la página de negocios y deseo recibir más información",
    en: "I come from the business page and I would like to receive more information",
  },
};

export const docDataServicePartnerMock = {
  name: {
    es: "¿Cómo puedo registrarme?",
    en: "How can I register?",
  },
  shortDescription: {
    es: "Vengo de la página de servicios y deseo recibir más información sobre cómo registrarme",
    en: "I come from the services page and I would like to receive more information on how to register",
  },
};

export const technicalServiceCategoriesDataMock = [
  { categoryName: "washingMachines", uid: "8wvjuqGlJSxD4Or35J2I" },
  {
    categoryName: "refrigeratorAndHeaterServices",
    uid: "BtpPNm8cGAss6YcJfcor",
  },
  { categoryName: "lampAndFanServices", uid: "GUgXzxMLAF6dqEqtSkSo" },
  { categoryName: "airConditioning", uid: "am4GI2lvBoabFHeWnK2" },
];

export const contentServiceMock = [
  basicCardServiceMock,
  basicCardServiceMock,
  basicCardServiceMock,
  basicCardServiceMock,
  basicCardServiceMock,
  basicCardServiceMock,
  basicCardServiceMock,
  basicCardServiceMock,
];

export const directoryMock = {
  id: 1,
  title: "SERVICIO DE AIRE ACONDICIONADO",
  services: contentServiceMock,
};

export const contentDirectoryMock = [
  directoryMock,
  directoryMock,
  directoryMock,
  directoryMock,
  directoryMock,
  directoryMock,
  directoryMock,
  directoryMock,
];
