import { useState, useEffect } from "react";
import { getUserOrders } from "services/user/fetchOrders/fetch-orders";
import { UserOrder } from "types";

const useUserOrdersListener = (userUid: string): UserOrder[] => {
    const [orders, setOrders] = useState<UserOrder[]>([]);

    useEffect(() => {
        const unsubscribe = getUserOrders(userUid, setOrders);
        return () => {
            if (unsubscribe) {
            unsubscribe();
            }
        };
        }, [userUid]);
    return orders;
  };
  
  export default useUserOrdersListener;