import { BREAKPOINTS } from "constants/index";
import {
  Container,
  Content,
  Description,
  Image,
  ImageContainer,
  TextContainer,
  Title,
} from "./styled";
import { useTranslation } from "react-i18next";


const BannerOnlyText = () => {
  const { t } = useTranslation();

  return (
    <Container fondo="#EBEAFC" pav="1rem" pah="8rem">
      <Content>
        <TextContainer>
          <Title family={"Outfit, monospace"} weight={700} color={"#0C121D"}>
            {t('benefits_of_working_with_us')}
          </Title>
          <Description>
            {t('we_offer_our_clients_the_possibility_of_entering_our_sales_funnel_for_free')}
          </Description>
        </TextContainer>
        {window.innerWidth > BREAKPOINTS.lg && (
          <ImageContainer>
            <Image
              src="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Fimages%2Fmarketing%20digital.webp?alt=media&token=b9003909-05ea-43eb-b3cf-7c916fa4e83c"
              loading="lazy"
              alt="Laptop de marketing digital" 
              title="Laptop de marketing digital"
            />
          </ImageContainer>
        )}
      </Content>
    </Container>
  );
};

export default BannerOnlyText;
