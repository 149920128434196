
import { Container, Label, Option, Options, OptionText } from './styled';
import { useTranslation } from 'react-i18next';



const YesOrNot = (props) => {
    const { label, isYes, onChange } = props;
    const { t } = useTranslation();

    return (
        <Container>
            <Label>{label}</Label>
            <Options>
                <Option active={isYes} onClick={() => onChange(true)} selected={isYes}>
                    <OptionText>{t('yes')}</OptionText>
                </Option>
                <Option onClick={() => onChange(false)} active={!isYes}  selected={!isYes}>
                    <OptionText>{t('no')}</OptionText>
                </Option>
            </Options>
        </Container>
    )
}

export default YesOrNot;