import styled from 'styled-components'

export const Container = styled.div`
  clear: both;
  background: ${props => props.background || "#ffffff"};
  justify-content: space-between;
  padding: ${props => props.pav} ${props => props.pah};
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 768px){
    padding: 0 .5rem;
  }
`

export const Cuad = styled.div`
  display: grid;
  margin: 0 2rem;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  @media only screen and (max-width: 992px){
    margin: 0 .5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 768px){
    grid-template-columns: repeat(1, 1fr);
    margin: .5rem auto;
    gap: .5rem;
    padding: 0 0.2rem;
  }
`

export const Titles = styled.h2`
  padding: .5rem 0 0 2.5rem;
  text-align: ${props => props.align || "left"};
  font-family: Outfit, monospace;
  font-weight: ${props => props.weight || "700"};
  font-size: ${props => props.size || "2.5rem"};
  @media only screen and (max-width: 992px) {
    ${props => props.theme.typography.subheading.sm.bold};
    text-align:center;
    width: 100%;
  }
`
