/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect } from 'react'
import { useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { ThemeProvider } from "styled-components";
import { theme } from "libs/theme/index";
import { THEME } from 'constants/theme/theme';
import { userLoginSagas, loginGoogleSagas } from "redux/user";
import { userSelector } from 'redux/user/selectors';
import { loadingLoginUserSelector } from 'redux/user/selectors';
import ModalLoader from 'ui/ModalLoader';
import MainLayout from 'views/layout/MainLayout';
import { Container, ContainerBar, Disclaimer, FormSubtitle, FormTitle, InputForm, InputWrap, Link, LoginContainer, LoginForm, LoginImg, LoginImgWrap, LoginWrap, Links, Paragraph, PlatformLogo, LoginSocial, SpanMiddle, TransButton, LogoContainer, ButtonContainer, TextError } from './styled'
import { Sidebar } from "components/Sidebar/Sidebar";
import { useNotification } from 'libs/context/AlertContext';
import Input from 'ui/TextInput';
import { Button } from 'ui/Buttons';
import loginSide from "assets/loginSide.png";
import { MdEmail, MdLock, MdPerson } from "react-icons/md";
import { MAIN_ROUTES } from 'constants/routes'
import { useTranslation } from "react-i18next";

const SelectTypeLogin = (props: any) => {
  const dispatch = useDispatch();
  const auth = getAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const { onChange } = props;

  function handleGoogleLogin() {
    
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        try {
          dispatch(loginGoogleSagas.run({ credential: credential?.toJSON() }));
          navigate(MAIN_ROUTES.Home);
        }
        catch (error) {
          console.error(t('error_login_google'));
        }
      })
      .catch((error) => {
        // Handle Errors here.
        console.error(t('error_login_google'));
      });
  }

  return (
    <>
      <FormTitle>{t('hello_again')}</FormTitle>
      <FormSubtitle>{t('sign_in_with')}:</FormSubtitle>

      <TransButton type='button' onClick={() => onChange('email')}> <MdEmail style={{fontSize: '1.3rem', margin: '0 .5rem'}} color={THEME.colors.primary} />
        <SpanMiddle>{t('email_and_password')}</SpanMiddle>
      </TransButton>
      
      {/* <Paragraph className={"py-1"}>o</Paragraph>

      <LoginSocial>
        <LogoContainer>
          <PlatformLogo onClick={handleGoogleLogin} src='https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/assets%2Ficons%2Flogos%2Fgoogle-logo.webp?alt=media&token=07342863-7ca0-431f-a9fb-47f072f7ba54' alt='Logo de google' />
        </LogoContainer>
        <H6>{t('sign_in_with')} Google</H6>
      </LoginSocial> */}

      <Disclaimer>{t('we_will_not_share_your_personal_information_with_anyone')}</Disclaimer>
      <Links>
        <span>{t('do_you_not_have_an_account')}</span>
        <Link onClick={() => {
          navigate(MAIN_ROUTES.CreateAccount);
        }}><MdPerson size={20} /> <SpanMiddle>{t('register')}</SpanMiddle></Link>
      </Links>
    </>
  )
}


function getValidationSchema(translator) {
  
  const LoginEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(translator('invalid_email_address'))
      .required(translator('email_is_required')),
    password: Yup.string()
      .min(6, translator('the_password_must_be_at_least_6_characters'))
      .required(translator('password_is_required')),
  });

  return LoginEmailSchema;
}

interface ILoginEmail {
  setIsSubmitting: (e: boolean) => void;
}

const LoginEmail = ({setIsSubmitting}: ILoginEmail) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showError } = useNotification();

  const handleLogin = (values: any): void => {
    setIsSubmitting(true);
    try { 
      dispatch(userLoginSagas.run(values));
    }
    catch (error) {
      showError(t('error_login_email_password'));
      setIsSubmitting(false);
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 2000);
    }
  };

  return (
    <div>
      <FormTitle>{t('login')}</FormTitle>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={getValidationSchema(t)}
        onSubmit={(values, { setSubmitting }) => {
          handleLogin(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors, touched, handleSubmit }) => (
          <Form>
            <InputForm>
              <InputWrap>
                <Field type="email" name="email" as={Input} label={t('email')} />
                {
                  errors.email && touched.email && <TextError>{errors.email}</TextError>
                }
              </InputWrap>
              <InputWrap>
                <Field type="password" name="password" as={Input} label={t('password')} />
                {
                  errors.password && touched.password && <TextError>{errors.password}</TextError>
                }
              </InputWrap>
            </InputForm>
            <ButtonContainer>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={Object.keys(errors).length > 0 || isSubmitting}
              >
                {t('login')}
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      <Links>
        <span>{t('forgot_your_password')}</span>
        <Link
          onClick={() => {
            navigate(MAIN_ROUTES.ForgotPassword);
          }}
        >
          <MdLock size={20} /> <SpanMiddle>{t('recover')}</SpanMiddle>
        </Link>
      </Links>
      <Links>
        <span>{t('do_you_not_have_an_account')}</span>
        <Link
          onClick={() => {
            navigate(MAIN_ROUTES.CreateAccount);
          }}
        >
          <MdPerson size={20} /> <SpanMiddle>{t('register')}</SpanMiddle>
        </Link>
      </Links>
    </div>
  );
};


/**
 * @description Login page
 */
const Login: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  type ILoginType = 'email' | 'google' | 'facebook' | null;
  //:::::::::::STATE::::::::::::
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [typeOfLogin, setTypeOfLogin] = useState<ILoginType>(null);
  const { isAuth } = useSelector((state: any) => state.auth);
  const loadingLogin = useSelector(loadingLoginUserSelector);
  console.log('loadingLogin', loadingLogin);  
  console.log('issubmitting', isSubmitting);
  const user = useSelector(userSelector)?.userData

  useEffect(() => {
    if (user?.uid) {
      navigate(MAIN_ROUTES.Home);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [user, user?.uid]);

  return (
    <MainLayout hideSearch={true}  >
      <Container>
        {isAuth && <Sidebar pageWrapId={'home-wrap'} outerContainerId={'home-container'} />}
        <ContainerBar id="home-wrap">
          <ThemeProvider theme={theme}>
            <LoginContainer>
              <LoginWrap>
                <LoginImgWrap>
                  <LoginImg src={loginSide} />
                </LoginImgWrap>
                <LoginForm>
                  {typeOfLogin === 'email' ? <LoginEmail setIsSubmitting={(e) => {
                    setIsSubmitting(e)
                  } } /> :
                    <SelectTypeLogin onChange={(e) => setTypeOfLogin(e)} />}
                </LoginForm>
              </LoginWrap>
            </LoginContainer>
          </ThemeProvider>
        </ContainerBar>
      </Container>
      <ModalLoader text={t('logging_in') || ''}  isLoading={isSubmitting || loadingLogin} />
    </MainLayout>
  )
}

export default Login
