import { useDispatch, useSelector } from 'react-redux';
import { addToCartSagasTrigger} from 'redux/cart/reducer/reducer-cart';
import { useNotification } from 'libs/context/AlertContext';
import { getLanguage } from 'redux/common/selector';

interface Language {
    en: string;
    es: string;
}

interface CartItem {
    id: string;
    name: Language;
    price: string;
    images: string[];
}

const useAddToCart = () => {
  const dispatch = useDispatch();
  const { showSuccess } = useNotification();
  const language = useSelector(getLanguage);

  const handleAddToCart = async (data: CartItem) => {
    const { id, name, price, images } = data;
    const count = 1;
    const image =   images[0] || '';
    const nameString = name[language] || '';

    dispatch(addToCartSagasTrigger.run({ id, count, name: nameString, price, image }));
    showSuccess(`${name.es} agregado al carrito`);
  };

  return handleAddToCart;
};

export default useAddToCart;
