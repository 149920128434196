import { Container, SaleProductContainer, Price, RegularPrice, SalePrice, Row, TextRegularPrice } from './styled';
import { useTranslation } from "react-i18next";


interface PropsPricesDetailProduct {
    price?: number | null;
    regularPrice?: number | null;
    salePrice?: number | null;
    currencySymbol: string;
    pricePrefix?: string;
}


const PricesDetailProduct = ({price, regularPrice, salePrice, currencySymbol, pricePrefix}: PropsPricesDetailProduct) => {
    const { t } = useTranslation();

    return (
        <Container>
            {
                (salePrice && regularPrice)? (
                    <SaleProductContainer>
                        <div>
                            <SalePrice>{`${currencySymbol}${salePrice}`}</SalePrice>
                        </div>
                        <Row alignItems='flex-end'>
                            <TextRegularPrice>{t('regular_price')}</TextRegularPrice>
                            <RegularPrice>{`${currencySymbol}${regularPrice}`}</RegularPrice>
                        </Row>
                    </SaleProductContainer>
                ) : (
                    <Price>{`${pricePrefix} ${currencySymbol}${price? price : ''}`}</Price>
                )
            }
        </Container>
    )
}

export default PricesDetailProduct;