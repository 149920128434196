import { useState } from "react";
import { IEditClassified } from "types";
import  editClassifiedService  from "services/classifieds/edit-classified-service/edit-classified-service";

const useEditClassified = () => {
    const [isUpdating, setLoading] = useState(false);
    const [updateError, setError] = useState<string | null>(null);
    const [dataClassifiedEdited, setData] = useState<any>(null);

    const editClassified = async (props: IEditClassified) => {
        setLoading(true);
        setError(null);
        try {
            const response = await editClassifiedService(props);

            if (response.status !== "OK" || response.success !== true) {
                throw new Error(response.message);
            } else {
                setData(response);
            }

            
        } catch (error: any) {
            setError(error.message || "Error al editar el clasificado");
        } finally {
            setLoading(false);
        }
    };

    return { editClassified, isUpdating, updateError, dataClassifiedEdited };
};

export default useEditClassified;
