export function calculeTotalPrice(quantity: number, price: number) {
    return (quantity * price)?.toFixed(2);
}


export const PRODUCT_MOCKUP = {
    id: 1,
    name: 'Product 1', 
    price: 8.99,
    image: 'https://via.placeholder.com/300x300'
}