import styled from 'styled-components';
import {THEME} from 'constants/theme/theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    background-color: #f5f5f5;
    color: ${THEME.colors.black100};
    height: 110px;
    
    :hover {
        cursor: pointer;
        background-color: #f5f5f5;
        transform: scale(1.01);
    }
`;

export const SubContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const ImageContainer = styled.div`
    margin-right: 1rem;
    overflow: hidden;
    img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 10px;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const TopInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    gap: 1rem;
`;