import { useState, useCallback } from "react";
import initClassifiedChatService from "services/classifieds/init-classified-chat-service/init-classified-chat-service";
import { IInitClassifiedChat, ServiceResponse } from "types";

const useInitClassifiedChat = () => {
  const [chatData, setChatData] = useState<ServiceResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const initClassifiedChat = useCallback(async (props: IInitClassifiedChat) => {
    setLoading(true);
    setError(null);

    try {
      const response = await initClassifiedChatService(props);
      setChatData(response);
    } catch (err: any) {
      setError(err.message || "Error al inicializar el chat clasificado");
    } finally {
      setLoading(false);
    }
  }, []);

  return { chatData, loading, error, initClassifiedChat };
};

export default useInitClassifiedChat;
