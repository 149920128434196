import MainLayout from "views/layout/MainLayout";
import { Container, Image } from "./styled";
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <Container>
        <h1>Upss.. {t('page_not_found')}</h1>
        <div style={{ width: '240px' }}>
          <a href="/">{t('return_to_home')}</a>
        </div>
        <Image src="https://media4.giphy.com/media/oQo9FZCAidz7R0hztb/giphy.gif?cid=ecf05e470xpqvvfumpb35yciz1d8mq9ev0b77e9onzrdl5w9&rid=giphy.gif&ct=s" loading="lazy" alt="Error" title="Error" />
      </Container>
    </MainLayout>
  )
};

export default PageNotFound;