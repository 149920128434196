import * as Yup from 'yup';

export const classifiedRealStateFormSchema = Yup.object().shape({
    title: Yup.string().required('El título es obligatorio'),
    description: Yup.string().required('La descripción es obligatoria'),
    typeOfOperation: Yup.string().required('El tipo de operación es obligatorio'),
    typeOfProperty: Yup.string().required('El tipo de propiedad es obligatorio'),
    location: Yup.object().shape({}).required('La ubicación es obligatoria'),
    price: Yup.number().required('El precio es obligatorio'),
    sellerType: Yup.string().required('El tipo de vendedor es obligatorio'),
    images: Yup.array().of(Yup.mixed()).required('Debe subir por lo menos una imagen'),
});


export const classifiedVehicleFormSchema = Yup.object().shape({
    title: Yup.string().required('El título es obligatorio'),
    description: Yup.string().required('La descripción es obligatoria'),
    category: Yup.string().required('La categoría es obligatoria'),
    fuelType: Yup.string().required('El tipo de combustible es obligatorio'),
    brand: Yup.string().required('La marca es obligatoria'),
    model: Yup.string().required('El modelo es obligatorio'),
    year: Yup.string().required('El año es obligatorio'),
    mileage: Yup.string().required('El kilometraje es obligatorio'),
    numberOfDoors: Yup.string().required('El número de puertas es obligatorio'),
    numberOfSeats: Yup.string().required('El número de asientos es obligatorio'),
    transmissionType: Yup.string().required('El tipo de transmisión es obligatorio'),
    hasCrashed: Yup.boolean().required('Este campo es obligatorio'),
    location: Yup.object().required(),
    price: Yup.number().required('El precio es obligatorio'),
    images: Yup.array().of(Yup.mixed()).required('Debe subir por lo menos una imagen'),
});

export const classifiedEmploymentFormSchema = Yup.object().shape({
    title: Yup.string().required('El título es obligatorio'),
    description: Yup.string().required('La descripción es obligatoria'),
    category: Yup.string().required('La categoría es obligatoria'),
    salary: Yup.string().required('El salario es obligatorio'),
    images: Yup.array().of(Yup.mixed()).required('Debe subir por lo menos una imagen'),
    location: Yup.object().required(),
});


export const classifiedTechFormSchema = Yup.object().shape({
    title: Yup.string().required('El título es obligatorio'),
    description: Yup.string().required('La descripción es obligatoria'),
    category: Yup.string().required('La categoría es obligatoria'),
    price: Yup.number().required('El precio es obligatorio'),
    images: Yup.array().of(Yup.mixed()).required('Debe subir por lo menos una imagen'),
    isNew: Yup.boolean().required('Este campo es obligatorio'),
    brand: Yup.string(),
    model: Yup.string(),
    year: Yup.string(),
    location: Yup.object().shape({}).required('La ubicación es obligatoria'),
    categoryType: Yup.string()
});



export const OPTIONS_TYPE_SELLER = [
    { value: 'Particular', label: 'Particular' },
    { value: 'Inmobiliaria', label: 'Inmobiliaria' },
];

export const OPTIONS_TYPE_PROPERTY = [
    { value: 'Casa', label: 'Casa' },
    { value: 'Apartamento', label: 'Apartamento' },
    { value: 'Local comercial', label: 'Local comercial' },
    { value: 'Oficina', label: 'Oficina' },
    { value: 'Terreno', label: 'Terreno' },
    { value: 'Finca', label: 'Finca' },
    { value: 'Bodega', label: 'Bodega' },
    { value: 'Edificio', label: 'Edificio' },
    { value: 'Otro', label: 'Otro' },
];
