import styled from 'styled-components';

export const Form = styled.form`
    padding: 0 1rem;
`

export const FormTitle = styled.p`
    text-align: center;
    ${props=>props.theme.typography.body.lg.bold};
`

export const InputWrap = styled.div`
    position: relative;
    width: ${props => props.size || "80%"};
    z-index: 1;
    margin: 1rem 0;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`

export const OptionTitle = styled.p`
    margin-top: 2rem;
    text-align: center;
    ${props => props.theme.typography.body.lg.regular};
    @media only screen and (max-width: 600px){
        text-align: left;
        ${props => props.theme.typography.body.sm.regular};
    }
`

export const InputLabel = styled.label`
    position: absolute;
    left: 1rem;
    top: 0.8rem;
    padding: 0 0.5rem;
    color: #000000;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    background-color: #ffffff;
    @media only screen and (max-width: 600px){
        font-size: .8rem;
        top: 1.2rem;
    }
`

export const Categories = styled.div`
    margin-top: 1rem;
    display: flex;
    width: 100%;
    cursor: pointer;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
`
export const Category = styled.div`
    width: 46%;
    background: #ebeafd;
    border:1px solid #dedde3;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 1.5rem 0;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    @media only screen and (max-width: 600px){
        font-size: .8rem;
    }
`

export const BottomButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
`
export const ErrorContainer = styled.div`
    width: 100%;
    margin-top: .5rem;
`;
