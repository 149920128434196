import styled from 'styled-components'

export const CarImg = styled.img`
  width: 99%;
  align-self: center;
  padding: 1rem;
  margin-top: 2px;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`
export const TopSection = styled.div` 
  width: 100%;
  padding-bottom: 10rem;
  background: ${(props) => props.background || "#3A36DB"};
  background-image: linear-gradient(180deg, rgba(58, 54, 219, 0.5) 0%, rgba(38, 36, 148, 0.5) 100%);
  background-repeat: no-repeat;
  margin-bottom: -10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  z-index: 4;
  

  @media only screen and (max-width: 992px) {
    min-height: auto;
    padding-bottom: .5rem;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  @media only screen and (max-width: 620px) {
    margin-bottom: 0px;
  }
`
