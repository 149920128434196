import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ImagesAddedGrid, ImageAdded } from './styled'
import DeleteIcon from '@mui/icons-material/Delete';
import { ICON_UPLOAD_IMAGE } from 'constants/images';

const ImagePickerContainer = styled.div`
    width: 100%;
    display: inline-block;
    text-align: center;
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    color: #000;
    border-radius: 12px;
`;

const ImageInput = styled.input`
    display: none;
`;

interface ImagePickerProps {
    onChange?: (file: File[] | string[] | []) => void;
    maxImages?: number;
    maxSingleImageSize?: number;
    listImages: File[] | string[] | [];
}

const ImagePicker: React.FC<ImagePickerProps> = ({ onChange, maxImages = 12, listImages }) => {
    const handleSetImages = useCallback((files: File[] | string[] | []) => {
        onChange && onChange(files);
    }, [onChange]);

    const handleImageChange = useCallback((e: File | any) => {
        if (listImages.length >= maxImages) {
            alert(`El máximo de imágenes permitidas es ${maxImages}`);
            return;
        }

        if (e.size > 5000000) {
            alert('El tamaño de la imagen no puede ser mayor a 5 mb');
            return;
        }

        handleSetImages([...listImages, e]);
    }, [listImages, maxImages, handleSetImages]);

    const ImageByType = useCallback(({ image }) => {
        return (
            <ImageAdded src={typeof image === 'string' ? image : URL.createObjectURL(image)} loading="lazy" alt="Image Added" />
        )
    }, []);


    function handleDeleteImage(index: number) {

        //@ts-ignore
        const newList: any = listImages.filter((item, i) => i !== index) || []
        handleSetImages(newList)
    }


    return (
        <div>
            {
                maxImages && listImages.length < maxImages && (
                    <ImagePickerContainer>
                        <ImagesAddedGrid>
                            {
                                listImages.map((image, index) => (
                                    <div key={index}>
                                        <DeleteIcon
                                            style={{
                                                color: 'red',
                                                position: 'absolute',
                                                alignSelf: 'flex-end'
                                            }}
                                            onClick={() => { handleDeleteImage(index) }} />
                                        <ImageByType image={image} />
                                    </div>
                                ))
                            }
                        </ImagesAddedGrid>
                        <img
                            style={{ width: '80px', height: '80px' }}
                            src={ICON_UPLOAD_IMAGE}
                            alt="Upload Icon"
                            onClick={() => document.getElementById('image-input')?.click()}
                        />
                        <ImageInput
                            id="image-input"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                const file = e.target.files?.[0];
                                if (file) {
                                    handleImageChange(file);
                                }
                            }}
                        />
                        <div onClick={() => document.getElementById('image-input')?.click()}>Click para subir una foto o imagen</div>
                    </ImagePickerContainer>
                )
            }
        </div>
    );
};

export default React.memo(ImagePicker);
