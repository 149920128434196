import { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { MdFilter } from "react-icons/md";
import { createTheme, ThemeProvider } from "@mui/material";
import {
  Container,
  ListProductsContainer,
  SpaceBetween,
  GridContainer,
  Filter,
  TextFilter,
} from "./styled";
import ProductsSidebarFilter from "views/containers/SidebarFilters/Products";
import { useTranslation } from "react-i18next";
import { H3 } from "ui/Text";
import { STORE_PRODUCTS_CATEGORIES } from "constants/index";
import Product from "ui/Products";
import {
  Product as ProductType,
  FiltersProducts,
  ProductCategory as ProductCategoryType,
} from "types";

const ItemSkeletons = [1, 2, 3, 4, 5, 6, 7, 8];

interface Props {
  data: ProductType[];
  categories: ProductCategoryType[];
  setFilters: (filters: FiltersProducts) => void;
  filters: FiltersProducts;
}

function getCategoryName(id: number) {
  const category = STORE_PRODUCTS_CATEGORIES.find(
    (category) => category.id === id
  );
  return category?.name.es || "";
}

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const ContentProuctsByCategory = (props: Props) => {
  const { data, setFilters, categories, filters } = props;

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleSetFilters = (filters: FiltersProducts) => {
    setFilters(filters);
  };

  return (
    <ThemeProvider theme={lightTheme}> 
      <Container>
        <ListProductsContainer>
          <SpaceBetween>
            <H3>{getCategoryName(parseInt(filters.categoryId as any) || 0)}</H3>
            <Filter>
              <TextFilter onClick={() => setOpen(true)}>
                <MdFilter /> {t('filter')}
              </TextFilter>
            </Filter>
          </SpaceBetween>
          <GridContainer>
            {data?.length > 0
              ? data?.map((item) => <Product data={item} />)
              : ItemSkeletons.map((item, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width='100%'
                  height={240}
                />
              ))}
          </GridContainer>
        </ListProductsContainer>
        {/* <BannerBrowser /> */}
      </Container>
      <ProductsSidebarFilter
        onSetFilters={handleSetFilters}
        filters={filters}
        categories={categories}
        open={open}
        setOpen={() => setOpen(false)}
      />
    </ThemeProvider>
  );
};

export default ContentProuctsByCategory;
