
import {TabSideComponent, StepDetails,StepInfo, StepTitle}  from '../styled'
import { IoIosArrowForward } from "react-icons/io";
import { MAIN_ROUTES } from 'constants/routes';

const items = [{
    name: 'Mis clasificados',
    icon: 'fa fa-list',
    url: '/my-classifieds',
    type:  'list-classifieds'
},
//  {
//     name: 'Guardados',
//     icon: 'fa fa-heart',
//     url: '/my-classifieds/saved',
//     type:  'saved-classifieds'
// },
 {
    name: 'Chats',
    icon: 'fa fa-comments',
    url: MAIN_ROUTES.ChatClassifieds,
    type:  'chats-classifieds'
}
]


const TabComponentClassifieds = ({name, type ,onSelect, active}) => {


    console.log('active', active, type)
    return (
        <TabSideComponent onClick={() => onSelect(type)}  active={active}>
            <StepDetails>
                {/* <img src={profile} alt="" /> */}
                <StepInfo>
                    <StepTitle>{name}</StepTitle>
                    {/* <StepDescription>
                        {`${t('name')}, ${t('last_name')}, ${t('phone')}, ${t('email_and_password')}`}
                    </StepDescription> */}
                </StepInfo>
            </StepDetails>
            <IoIosArrowForward size={30} />
    </TabSideComponent>
    )

}


// Main
const SideComponentClassifieds = ({onChange, value}) => {

    function onSelect(type) {
        onChange(type)
    }

    return (
        <div>
            {
                items.map((item, index) => (
                    <TabComponentClassifieds name={item.name} key={index} type={item.type} onSelect={onSelect} active={value === item.type} />
                ))
            }
        </div>
    )

}

export default SideComponentClassifieds