import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FDFDFE;
`

export const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0rem 2rem;
    @media only screen and (max-width: 992px) {
        padding: 1rem 1rem 0rem 1rem;
    }
`



