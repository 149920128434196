const LockIcon = () => {
  return (
    <svg
      width="33"
      height="30"
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 15V25H24.5V15H8.5ZM7.5 13C6.94772 13 6.5 13.4477 6.5 14V26C6.5 26.5523 6.94772 27 7.5 27H25.5C26.0523 27 26.5 26.5523 26.5 26V14C26.5 13.4477 26.0523 13 25.5 13H7.5Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.423 8.79627C11.6417 10.4783 11.442 12.5529 11.5134 13.9489C11.5416 14.5005 11.1173 14.9705 10.5658 14.9987C10.0142 15.0269 9.5442 14.6027 9.51599 14.0511C9.43394 12.4471 9.64854 10.0217 10.6091 7.95373C11.5918 5.83824 13.4221 4 16.4999 4C19.5777 4 21.4081 5.83824 22.3907 7.95372C23.3513 10.0217 23.566 12.4471 23.4839 14.0511C23.4557 14.6026 22.9857 15.0269 22.4342 14.9987C21.8826 14.9705 21.4583 14.5005 21.4865 13.9489C21.5579 12.5529 21.3582 10.4783 20.5769 8.79628C19.8176 7.16176 18.5786 6 16.4999 6C14.4212 6 13.1822 7.16176 12.423 8.79627Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 20.7324C18.0978 20.3866 18.5 19.7403 18.5 19C18.5 17.8954 17.6046 17 16.5 17C15.3954 17 14.5 17.8954 14.5 19C14.5 19.7403 14.9022 20.3866 15.5 20.7324V22.5C15.5 23.0523 15.9477 23.5 16.5 23.5C17.0523 23.5 17.5 23.0523 17.5 22.5V20.7324Z"
        fill="black"
      />
    </svg>
  );
};

export default LockIcon;
