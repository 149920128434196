import { useEffect, useState } from "react";
import { registerLog } from "libs/analytics";
import MainLayout from "views/layout/MainLayout";
import { Container, VideoPlayerContainer } from "./styled";
import VideoPlayer from "components/VideoPlayer";
import FormInquiryYachts from 'views/containers/Forms/Services/Yachts/form-inquiry-yachts';
import SliderCarousel from "components/SliderCarousel";
import { Banner } from "views/containers/Services/Tourism/Banner/banner";
import TopDestinations from "views/containers/Services/Tourism/TopDestinations";
import { BANNERS_PATH, EXTRAS_PATH, TOURS_PATH } from "./constants";
import { Extras } from "views/containers/Services/Tourism/Extras/extras";
import { ListTours } from "views/containers/Services/Tourism/ListTours/list-tours";
import { ListYatchs } from "views/containers/Services/Tourism/ListYatchs/list-yatchs";
import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import BannerFooterMobile from "components/banner-footer-mobile/banner-footer-mobile";
import { BREAKPOINTS } from "constants/index";
import { MESSAGE_FOOTER_BANNER_TOURISM } from "../technical-service/constants";
import SimpleImageBanner from 'ui/simple-image-banner';


const Tourism = () => {

  const { collectionData: banners, fetchCollection: fetchBanners } = useGetFirestoreCollection();
  const { collectionData: listYatchsAvailables, fetchCollection: fetchYatchsAvailables} = useGetFirestoreCollection();
  const { collectionData: destinationsList, fetchCollection: fetchDestinations} = useGetFirestoreCollection();
  const { collectionData: toursList, fetchCollection: fetchTours } = useGetFirestoreCollection();
  const { collectionData: extraServicesData, fetchCollection: fetchExtraServiceData} = useGetFirestoreCollection();

  const [bannerImages, setBannerImages] = useState<string[]>([]);

  useEffect(() => {
    fetchBanners({
      path: BANNERS_PATH,
    });

    fetchDestinations({
      path: "PANAMA/Services/Categories/Tourism/Posts",
      filters: [
        {
          field: "type",
          operator: "==",
          value: "destination",
        }
      ],
    });

    fetchExtraServiceData({
      path: EXTRAS_PATH,
    });

    fetchTours({
      path: TOURS_PATH,
      filters: [
        {
          field: "type",
          operator: "==",
          value: "tour",
        }
      ],
    });

    fetchYatchsAvailables({
      path: "PANAMA/Services/Categories/Yatchs/Posts",
      filters: [
        {
          field: "idCategory",
          operator: "==",
          value: 205,
        },
      ],
    });

    registerLog("Yacht main screen", {
      page_title: "Yacht services screen",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];

      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          listBanners.push(doc.imagesMobile.es);
        } else {
          listBanners.push(doc.imagesDesktop.es);
        }
      });
      setBannerImages(listBanners);
    }
  }, [banners]);

  return (
    <MainLayout type="services">
      <Container>
        <SliderCarousel images={bannerImages} />
        { <VideoPlayerContainer>
          <VideoPlayer
            volume={0} 
            url='https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FServices%2FTours%2Ftours%20pero%20de%20otro%20taman%CC%83o.mp4?alt=media&token=37757991-3090-4576-a9fe-55c68aade2cb' 
            playing={true}
            Form={FormInquiryYachts} 
            stylePlayerContainer={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            />
        </VideoPlayerContainer> }
        <Banner />
        <TopDestinations destinations={destinationsList} />
        <Extras data={extraServicesData} /> 
          <SimpleImageBanner hrefLink='https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+los+descuentos+en+la+saga+de+videojuegos+Fallout.&type=phone_number&app_absent=0' imageUrl='https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FFallout%20consigue%20descuentos%20en%20videojuegos.webp?alt=media&token=40d7fe10-31fe-4fe9-9d31-f6efc84de040'/>
        <ListTours tours={toursList} />
        <ListYatchs carousel={true} yatchs={listYatchsAvailables} />
        {window.innerWidth < BREAKPOINTS.lg && (
          <BannerFooterMobile
            data={MESSAGE_FOOTER_BANNER_TOURISM}
            secondaryText="promociona tus tours en nuestra plataforma y ¡Haz que tus tours destaquen y lleguen a más viajeros!"
            text="¿Eres un operador de turismo?"
          />
        )}
      </Container>
    </MainLayout>
  );
};

export default Tourism;
