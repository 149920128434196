import styled from 'styled-components';
import { THEME } from 'constants/theme/theme';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
`;

export const ContentForm = styled.div`
    width: 100%;
    padding: 40px 0px 0px 0px;
    max-width: 800px;
`;

export const InputsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .5rem;
`;

export const InputWrap = styled.div`
    position: relative;
    width: ${props => props.size || "100%"};
    z-index: 1;
    color: #000;
    margin-top: .5rem;
    @media only screen and (max-width: 768px) {
    width: 100%;
    }
`

export const Error = styled.p`
    color: #F1851F;
    ${props => props.theme.typography.body.sm.regular};
    margin: 8px 0px 10px 0px;
`;

// Container Section

export const SectionTitleWithBackground = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${THEME.colors.classifieds80};
    font-weight: 500;
    border-radius: 10px;
    padding: 10px 0px;
`;



interface GridProps {
    columns: number;
    gap: string;

}

export const Grid = styled.div<GridProps>`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${props => props.columns || 2}, 1fr);
    gap: 1rem;
    margin-top: .5rem;
    justify-content: space-between;
`;