import { httpsCallable } from "firebase/functions";
import { functions } from "config/firebase"; // Asegúrate de que la configuración de Firebase esté correctamente importada
import { IMessageToClassifiedChat, ServiceResponse } from "types";

// Servicio para enviar un mensaje en el chat clasificado
const sendMessageToClassifiedChatService = async (
  props: IMessageToClassifiedChat
): Promise<ServiceResponse> => {
  const sendMessageFunction = httpsCallable(
    functions,
    "sendMessageToClassifiedChat"
  );

  try {
    const response: any = await sendMessageFunction(props);

    if (response.data.status === "ERROR" || !response.data.success) {
      throw new Error(response.data.message);
    }

    return {
      status: "OK",
      message: response.data.message,
      success: true,
    };
  } catch (error: any) {
    return {
      status: "ERROR",
      message: error?.message || "Error al enviar el mensaje al chat",
      code: error?.code || 500,
    };
  }
};

export default sendMessageToClassifiedChatService;
