import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FDFDFE;
  width: 45%;
  margin: 2rem auto;
  @media only screen and (max-width: 992px) {
    margin: 3rem auto;
    width: 100%;
  }
  
`


export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    background-color: #FDFDFE;
    align-self: center;
    border-radius: 16px;
    padding: 3% 5%;
    @media (max-width: 992px) {
        width: 100%;
    }
`

export const NeedsTitle = styled.p`
  text-align: center;
  ${props => props.theme.typography.heading.md};
  @media only screen and (max-width: 600px) {
    ${props => props.theme.typography.subheading.lg.bold};
    margin: -20px 0px -10px 0px;
  }
`

export const NeedsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 2rem;
`
export const NeedContainer = styled.div`
  width: 100%;
  position: relative;
  background: ${props => props.color};
  box-shadow: 0 2px 8px 2px rgba(2, 64, 154, 0.16);
  display: flex;
  border-radius: 1rem;
  padding: 24px 1rem;
  align-items: center;
  height: 120px;
  justify-content: center;

span {
  color: black
}

  &:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }

  @media only screen and (max-width: 600px) {
    height: 100px;
    padding: 30px 1rem;
  }
`


export const NeedImage = styled.img`
  position: absolute;
  left: 15%;
  height: 70px;
  width: 70px;

  @media only screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
  }

  @media only screen and (max-width: 484px) {
    left: 10%;
  }
`

export const NeedTitle = styled.span`
  ${props=>props.theme.typography.subheading.lg.bold};
  @media only screen and (max-width: 600px) {
  ${props => props.theme.typography.body.lg.bold};
  font-size: 1.2rem;
  color: 'black';
}
`