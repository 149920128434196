type ICollection = Array<IItemCollection | object>;

interface IItemCollection {
    key?: object | string;
    label?: object | string;
    id?: string | number;
    value?: object | string;
    image?: string;
    category?: string;
}

export const languageCollectionTransform = (
    collection: ICollection,
    language: string,
): ICollection => {

    return collection && collection.map((item: any) => {
        const itemToReturn: IItemCollection = {};

        if ('id' in item) itemToReturn.id = item.id;
        if ('image' in item) itemToReturn.image = item.image;
        if ('category' in item) itemToReturn.category = item.category;

        // Asignar 'key' y 'label' basándose en si son objetos y si el idioma está disponible
        ['key', 'name'].forEach(prop => {
            if (prop in item) {
                const value = typeof item[prop] === 'object' ? item[prop][language] || item[prop]['en'] : item[prop];
                if (prop === 'name') {
                    itemToReturn.label = value;
                } else {
                    itemToReturn[prop] = value;
                }
            }
        });

        // Asignar 'value'
        if ('value' in item) {
            itemToReturn.value = typeof item.value === 'object' ? item.value[language] || item.value['en'] : item.value;
        } else if ('name' in item) {
            itemToReturn.value = typeof item.name === 'object' ? item.name[language] || item.name['en'] : item.name;
        }

        return itemToReturn;
    });
};


interface IItemToReturn {
    label: string;
    value: string;
}


export function transformItemNameToValueLabel(items: any[]) {

    const itemToReturn: IItemToReturn[] = [];

    items.forEach((item: any) => {
        const itemToPush: IItemToReturn = {
            label: '',
            value: '',
        };

        if (item.name && !item.value) {
            itemToPush.label = item.name;
            itemToPush.value = item.name;
        } else {
            itemToPush.label = item.name;
            itemToPush.value = item.value;
        }

        itemToReturn.push(itemToPush);
    });

    return itemToReturn;
}