import styled from 'styled-components';
import { THEME } from 'constants/theme/theme';


export const Container = styled.div`
    min-height: 60vh;
    padding: 1rem;

`;


export const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0px;

    @media (max-width: 992px) {
        flex-direction: column;
    }
`;


export const LeftSideContainer = styled.div`
    width: 25%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-height: 100vh;
    background-color: ${THEME.colors.gray20};
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    border-radius: 1rem;
    padding: 1rem;

    @media (max-width: 992px) {
        width: 100%;
    }
`;

export const RightSideContainer = styled.div`
    width: 72%;
    margin-bottom: 20px;
    height: 100%;

    @media (max-width: 992px) {
        width: 100%;
    }
`;

export const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1rem;
    // gap vertical
    grid-row-gap: .5rem;

    @media (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

// Side component

interface TabSideProps {
    active?: boolean;
}

export const TabSideComponent = styled.div<TabSideProps>`
  display: flex;
  gap: 1rem;
  border-radius: 1rem;
  border: ${props => props.active ? `2px solid ${THEME.colors.classifieds}` : 'none'};
  width: 100%;
  background: #f5f5f6;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  //  shadow
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

  // hover
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }

  &:last-child {
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

export const StepDetails = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const StepInfo = styled.div`
  display: flex;
  flex-direction: column;
`


export const StepTitle = styled.span`
  ${props => props.theme.typography.body.lg.medium};
`

export const StepDescription = styled.span`
  ${props => props.theme.typography.label.xs.regular};
  color: ${props => props.theme.colors.gray40};
`