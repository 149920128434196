import styled from "styled-components";

export const ContentProductsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  margin: 2rem 0;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const CartTitle = styled.h2`
  vertical-align: middle;
  ${(props) => props.theme.typography.subheading.md.semibold};
`;
export const SpanMiddle = styled.span`
  vertical-align: middle;
`;
export const ContentItems = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 1rem;
  }
`;

export const ContentDiscount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid #000000;
  padding: 15px 18px 40px 18px;
  border-radius: 1rem;
  max-height: 16.5rem;

  @media only screen and (max-width: 992px) {
    width: 100%;
    background: #cefff857;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: center;
    padding: 15px;
  }

  /*   @media only screen and (max-width: 992px) {
    width: 40%;
  }
 */

  /* @media only screen and (max-width: 600px) {
    display: none;
  } */
`;

export const SecurityContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 40px;
  border-radius: 1rem;
  max-height: 16.5rem;
  gap: 10px;
  @media only screen and (max-width: 992px) {
    padding: 0 1rem;
  }

  @media only screen and (max-width: 600px) {
    gap: 2px;
    padding: 10px;
  }
`;

export const SecurityContainerTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const SecurityContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media only screen and (max-width: 600px) {
    gap: 4px;
  }
`;

export const PaymentsMethods = styled.div`
  border-radius: 1rem;
  background: #3a36db;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    background: none;
    gap: 2px;
    padding: 4px;
  }
`;

export const Cart = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 0 1rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 0;
    margin: 0 0 -1rem 0;
  }
`;

export const CouponTitle = styled.div`
  ${(props) => props.theme.typography.body.lg.medium};
`;
export const CouponInput = styled.input`
  padding: 0 1rem;
  border-radius: 10px;
  border: 1px solid #69727f;
  width: 65%;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 1rem;
  }
`;

export const TransButton = styled.button`
  display: block;
  margin: 0 auto;
  width: ${(props) => props.size || "auto"};
  background: ${(props) => props.background || "#ffffff"};
  color: ${(props) => (props.background === "#3A36DB" ? "#F5F5F6" : "#3A36DB")};
  border-radius: 50px;
  padding: 0.4rem 1rem;
  border: 1px solid #406385;
  ${(props) => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 1200px) {
    margin: 0;
  }
`;

export const TransButtonS = styled.button`
  display: block;
  margin: 0 auto;
  align-self: center;
  width: ${(props) => props.size || "auto"};
  background: ${(props) => props.background || "#ffffff"};
  color: ${(props) => (props.background === "#3A36DB" ? "#F5F5F6" : "#3A36DB")};
  border-radius: 50px;
  padding: 1rem;
  border: 1px solid #406385;
  ${(props) => props.theme.typography.body.md.bold};
  @media only screen and (max-width: 1200px) {
    margin: 0;
  }
`;
export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    gap: 0;
  }
`;

export const SpaceBetweenS = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 100px;
`;

export const CuponBig = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
export const CuponSmall = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    align-self: flex-end;
    bottom: 0;
    flex-direction: column;
    background: #3a36db;
    padding: 1rem;
    display: none;
  }
`;

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 1rem;
  }
`;

export const PrincipalRow = styled.div`
  width: 100%;
  margin: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    padding-right: 30px;
    justify-content: space-between;
    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

export const DeleteButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const HideSmall = styled.div`
  /* width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -2rem; */
  display: block;

  hr {
    border: 1px solid #000000;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;
    gap: 5px;
    hr {
      width: 100%;
      border: 1px solid black;
      margin: 0;
    }
  }

  /*  @media only screen and (max-width: 600px) {
    display: none;
  } */
`;

export const SmallBack = styled.div`
  background: #fdfdfe;
  padding: 2rem;
  display: flex;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
`;

export const WarrantyTitle = styled.span`
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  color: white;
  @media only screen and (max-width: 600px) {
    color: #406385;
  }
`;

export const WarrantyParagraph = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: #06557a;
  text-align: justify;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const TotalText = styled.span`
  color: #406385;
  ${(props) => props.theme.typography.subheading.xs.semibold};
  @media only screen and (max-width: 600px) {
    ${(props) => props.theme.typography.subheading.sm.semibold};
  }
`;
export const Total = styled.span`
  ${(props) => props.theme.typography.subheading.sm.bold};
  @media only screen and (max-width: 600px) {
    color: #406385;
  }
`;
