import Modal from "ui/Modal/Modal";
import { H2, P } from "ui/Text";
import WhatsAppButton from "components/ButtonWhatsapp/button-whatsapp";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    businessName: string;
}

const YachtContactModal = (props: Props) => {
    const { isOpen, onClose, businessName } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <H2>Contacto</H2>
            <P margin='1rem 0rem'>Haz negocios y genera dinero desde cualquier lugar del mundo</P>
            < WhatsAppButton
                phoneNumber="+50764055638"
                link={window.location.href}
                message={`Hola, estoy interesado en mas información sobre ${businessName}`}
            />
        </Modal>
    );

}

export default YachtContactModal;
