import styled from 'styled-components'

export const Container = styled.div`
  clear: both;
  background: ${props => props.background || "#FDE7EE"};
  justify-content: space-between;
  padding: ${props => props.pav} ${props => props.pah};
  overflow: hidden;
  margin-bottom: 1rem;
  @media only screen and (max-width: 992px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 620px) {
    padding: 2rem;
  }
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
`


export const Title = styled.p`
  font-family: ${props=>props.family};
  color: ${props=>props.color};
  font-weight: ${props=>props.weight};
  font-size: ${props=>props.size};
  font-size: 2rem;
  line-height: 2.8rem;
  

  @media only screen and (max-width: 1080px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 2.0rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: start;
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.5rem;
    line-height: 1.4rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 1.5rem;
  }
`


export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.1rem;

  }
`

export const Description = styled.p`
  font-family: Outfit, monospace;
  font-size: 1.2rem;
  text-align: start;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: start;
    
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`
export const ImageContainer = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-end;

  
  @media only screen and (max-width: 992px) {
    width:40%;
    font-size: .6rem;
    float: right;
    margin-bottom: -36px;
  }

  @media only screen and (max-width: 768px) {
    width: 60%;
  }

  @media only screen and (max-width: 560px) {
    width: 60%;
  }
`

export const Image = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  float: right;

  @media only screen and (max-width: 992px) {
    max-height: 200px;
  }


`

