import styled from "styled-components";
import { THEME } from "constants/theme/theme";


export const Container = styled.div`
    width: 100%;
`;

export const Grid = styled.div`
    width: 100%;
    display: grid;
   // solo 2 columnas
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
`;

interface ICardItem {
    selected: boolean;
    disabled?: boolean;
}

export const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: ${(props: ICardItem) =>  props.disabled? `2px solid ${THEME.colors.black40}` :  props.selected ? `2px solid ${THEME.colors.primary}` : '2px solid #E5E5E5'};
`;