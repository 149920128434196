import React, { useState, useEffect } from "react";
import {
  SelectableDivContainer,
  SelectableDiv,
  SelectableComponentWrapper,
  SelectableButton
} from "./styled";


interface Option {
  id: number;
  label?: string;
  value?: string;
}

interface Category {
  id: number;
  label?: string;
  value?: string;
  icon?: string;
}

export const SelectableComponent = ({
  options,
  onSelect,
}: {
  options: Option[];
  onSelect: (value: string) => void;
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    console.log("SelectableComponent options:", options);
  }, [options]);

  const handleSelect = (option: Option) => {
    const newValue = option.value || null;
    setSelectedOption(newValue);
    onSelect(newValue || "");
    console.log("handleSelect called with option:", option);
    console.log("selectedOption is now:", newValue);
  };

  return (
    <SelectableComponentWrapper>
      {options.map(
        (option) =>
          option.label && (
            <SelectableButton
              key={option.id}
              onClick={() => handleSelect(option)}
              selected={selectedOption === option.value}
            >
              {option.label}
            </SelectableButton>
          )
      )}
    </SelectableComponentWrapper>
  );
};

export const SelectableDivs = ({
  categories,
  onSelect,
}: {
  categories: Category[];
  onSelect: (value: string) => void;
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  useEffect(() => {
    console.log("Current selectedValue:", selectedValue);
  }, [selectedValue]);

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    onSelect(value);
    console.log("handleSelect called with value:", value);
    console.log("selectedValue is now:", value);
  };

  if (categories.length === 0) {
    return null; // No renderizar nada si categories está vacío
  }

  return (
    <SelectableDivContainer>
      {categories.map((category) => (
        <SelectableDiv
          key={category.id}
          selected={selectedValue === category.value}
          onClick={() => handleSelect(category.value || "")}
        >
          {category.icon && (
            <img
              src={category.icon}
              loading="lazy"
              alt={category.label}
              title={category.label}
            />
          )}
          <span>{category.label}</span>
        </SelectableDiv>
      ))}
    </SelectableDivContainer>
  );
};
