import { httpsCallable } from "firebase/functions";
import {ServiceResponse} from 'types'
import {functions} from 'config/firebase'

interface CreateUserProps {
    email: string;
    password: string;
    nickname?: string;
    firstName: string;
    firstSurname: string;
    secondSurname?: string | null;
    secondName?: string | null;
    gender?: string | null;
    phone:string
    roleId: number;
    dateOfBirth: string;
    countryBorn: string;
}



const createUser = async (props: CreateUserProps): Promise<ServiceResponse> => {
    
    const createUser = httpsCallable(functions, 'createUser') 
    try {
        const response = await createUser(props)
        return response.data as ServiceResponse
    }
    catch (error: any) {
        return {
            status: error?.status || 'ERROR',
            message: error?.message || 'Error al crear el usuario',
            code: error?.code || 500
        }
    }
}

export default createUser
