import { useEffect, useState } from 'react';

const useIsLocalhost = () => {
    const [isLocalhost, setIsLocalhost] = useState(false);

    useEffect(() => {
        const host = window.location.hostname;
        setIsLocalhost(host === 'localhost' || host === '127.0.0.1');
    }, []);

    return isLocalhost;
};

export default useIsLocalhost;