import { useFormik } from 'formik';
import { Form, FormTitle, InputWrap, OptionTitle } from '../styled';
import Input from 'ui/TextInput';
import ImageUploader from 'react-images-upload';
import Select from 'ui/Select';
import { Button } from 'ui/Buttons';
import TextArea from 'ui/TextArea';
import { productsValidationSchema } from '../constants';
import { LEVELS_URGENCY_NEEDS } from 'constants/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  setStep: (step: number) => void;
  onSubmit: (values: any) => void;
}

const NeedsOthersForm: React.FC<Props> = ({ setStep, onSubmit }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      title: '',
      link: '',
      nivel: '',
      extraComments: '',
    },
    validationSchema: productsValidationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <FormTitle>{t('give_a_title_to_your_need')}</FormTitle>
        <InputWrap size="100%">
          <Input
            type="text"
            name="title"
            label="¿Qué producto estás buscando?"
            onChange={formik.handleChange}
            value={formik.values.title}
            onBlur={formik.handleBlur}
          />
          {formik.touched.title && formik.errors.title && (
            <div>{formik.errors.title}</div>
          )}
        </InputWrap>
        <OptionTitle>{t('upload_image_referring_to_the_need')}</OptionTitle>
        <ImageUploader
          singleImage={true}
          withLabel={true}
          label="No hay una imagen seleccionada"
          withPreview={true}
          labelStyles={{ color: '#406385', fontWeight: 800 }}
          buttonStyles={{
            backgroundColor: '#fff',
            color: '#233854',
            fontWeight: 700,
          }}
          withIcon={true}
          buttonText="Cargar una imagen"
          imgExtension={['.jpg', '.gif', '.png', '.gif']}
          maxFileSize={5242880}
        />
        <OptionTitle>{t('urgency_level')}</OptionTitle>
        <InputWrap size="100%">
          <Select
            options={LEVELS_URGENCY_NEEDS}
            value={formik.values.nivel}
            onChange={(e) => formik.setFieldValue('nivel', e)}
            label={t('urgency_level')}
            hideNeverChoice
          />
        </InputWrap>
        <OptionTitle>{t('write_in_detail_what_you_need')}</OptionTitle>
        <InputWrap size="100%">
          <TextArea
            name="extraComments"
            label={t('describe_your_need_here')}
          />
        </InputWrap>
        <Button type="submit" onClick={() => setStep(2)}>
          {t('next_step')}
        </Button>
      </Form>
    </div>
  );
};

export default NeedsOthersForm;
