import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  Header, LogoContainer, Logo, MenuItem, RightMenu, SpanMiddle, NameUser, SearchBarContainer, MainCategoriesContainer, LinkItemMainCategory, ClickableTopBar,
  StyledBadge, PopupConfigContainer, FlagCountrySelected, TextLanguageSelected, Separator
} from "./styled";
import { MdOutlineShoppingCart, MdPersonOutline } from "react-icons/md";
import SearchBarLayout from "views/layout/SearchBar";
import generalSearch from "services/search/generalSearch";
import Modal from "ui/Modal";
import ContentModalConfig from "./components/config-modal-content/content-modal-config";
import { totalCart, getCartSagasTrigger, totalItemsCart } from "redux/cart";
import { getLanguage } from "redux/common/selector";
import { userSelector } from "redux/user";
import { getBgColor, IProps } from "./main";
import { MAIN_ROUTES } from "constants/routes";
import { PANAMA_FLAG_URL } from "constants/images";
import { useTranslation } from "react-i18next";
import { LOGO_WHITE_ENGLISH, LOGO_WHITE_SPANISH } from 'constants/constants'

/**
 * @description TopBar component
 */
const TopBar = (props: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const { userData } = useSelector(userSelector);
  const totalCartAmount = useSelector(totalCart);
  const totalItems = useSelector(totalItemsCart);
  const totalCartAmountFixed = parseFloat(totalCartAmount.toFixed(2));
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    dispatch(getCartSagasTrigger.run({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);


  const [searchResults, setSearchResults] = useState<any>([]);
  const [showModalConfig, setShowModalConfig] = useState(false);

  const { type } = props;
  const { searchVisible, paddingBottom } = props;

  const handleSearch = (search: string) => {
    if (!isSearching) {
      setIsSearching(true);
      // query search
      const dataSearch = {
        terms: {
          category: search,
          title: search,
          "name.en": search,
          "name.es": search,
        },
        indices: [
          // "search-panama-classifieds",
          "search-panama-services",
          "search-panama-products",
        ],
        filters: [],
        sortField: "",
        sortOrder: "asc",
        from: 0,
        size: 10,
      };

      generalSearch(dataSearch)
        .then((result: any) => {
          setSearchResults(result.data.hits);
          setIsSearching(false);
        })
        .catch((error) => {
          console.log("error búsqueda general", error);
          setIsSearching(false);
        });
    }
  };

  return (
    <Header
      paddingBottom={paddingBottom || "6px"}
      background={getBgColor(type || "main")}
      shadow={type || "main"}
    >
      <LogoContainer>
        <Logo
          onClick={() => navigate("/")}
          src={language === 'es' ? LOGO_WHITE_SPANISH : LOGO_WHITE_ENGLISH}
          alt="Logo quenecesitas7" title="Logo quenecesitas7" width="220" height="auto" 
        />
        <ClickableTopBar onClick={() => { setShowModalConfig(true) }}>
          <PopupConfigContainer responsive style={{
            marginRight: '1rem',
            marginBottom: '.3rem',
            marginLeft: '-1rem',
          }} >
            <FlagCountrySelected src={PANAMA_FLAG_URL} alt="Panama flag" title="Panama flag" />
            <Separator />
            <TextLanguageSelected>{language}</TextLanguageSelected>
          </PopupConfigContainer>

          <StyledBadge badgeContent={totalItems} color="secondary">
            <MdOutlineShoppingCart size='20px'
              onClick={() => navigate(MAIN_ROUTES.Cart)}
            />
          </StyledBadge>
        </ClickableTopBar>
      </LogoContainer>
      {searchVisible ? (
        <SearchBarContainer>
          <SearchBarLayout
            isSearching={isSearching}
            results={searchResults}
            clearResults={() => setSearchResults([])}
            onChange={(e) => {
              if (e) {
                handleSearch(e.target.value)
              }
            }
            }
          />
          <MainCategoriesContainer>
            <Link to={MAIN_ROUTES.Products}>
              <LinkItemMainCategory>{t('products')}</LinkItemMainCategory>
            </Link>
            <Link to={MAIN_ROUTES.Services} onClick={() => window.scrollTo(0, 0)}>
              <LinkItemMainCategory>{t('services')}</LinkItemMainCategory>
            </Link>
            <Link to={MAIN_ROUTES.EarnMoney}>
                <LinkItemMainCategory>{t('earn_money')}</LinkItemMainCategory>
              </Link> 
            
              {/* <Link to={MAIN_ROUTES.Classifieds}>
                <LinkItemMainCategory>Clasificados</LinkItemMainCategory>
              </Link>
              <Link to={MAIN_ROUTES.Offers}>
                <LinkItemMainCategory>Ofertas</LinkItemMainCategory>
              </Link> */}
              
          </MainCategoriesContainer>
        </SearchBarContainer>
      ) : (
        <div style={{ flex: 1 }} />
      )}


      <PopupConfigContainer onClick={() => { setShowModalConfig(true); }}>
        <FlagCountrySelected src={PANAMA_FLAG_URL} alt="Panama flag" title="Panama flag" />
        <Separator />
        <TextLanguageSelected>{language}</TextLanguageSelected>
      </PopupConfigContainer>

      <RightMenu>
        <NameUser>
          {userData && (userData?.firstName || userData?.displayName)}
        </NameUser>
        {/* <PopupLanguage/> */}
        {!userData && (
          <MenuItem>
            <MdPersonOutline />
            <SpanMiddle onClick={() => navigate(MAIN_ROUTES.Login)}>
              {t('login')}
            </SpanMiddle>
          </MenuItem>
        )}

        {userData && (
          <MenuItem>
            <SpanMiddle onClick={() => navigate(MAIN_ROUTES.Cart)}>
              ${totalCartAmountFixed}
            </SpanMiddle>
            <MdOutlineShoppingCart />
          </MenuItem>
        )}
      </RightMenu>
      <Modal
        styleContent={{ zIndex: 10000 }}
        isOpen={showModalConfig}
        onClose={() => setShowModalConfig(false)}
        title={t('lang_country') || ''}
      >
        <ContentModalConfig />
      </Modal>
    </Header>
  );
};

export default TopBar;
