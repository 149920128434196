import {SpanMiddle, SponsorBuy, SponsorDesc, SponsorImg, SponsorProduct, TransButton} from "./styled";
import {MdShoppingCart} from "react-icons/md";
import {useNavigate} from "react-router-dom";

const Favorite = (props: any) => {
    const {data} = props;
    const {id, image, name} = data;
    const navigate = useNavigate();

    return (
        <SponsorProduct>
            <SponsorImg onClick={() => navigate(`/product/detail?uid=${id}`, {state: {route: data.stock_status}})} src={image}/>
            <SponsorDesc>{name.es}</SponsorDesc>
            <SponsorBuy>
                <TransButton>
                    <SpanMiddle>Comprar</SpanMiddle> </TransButton>
                <TransButton background={"#3A36DB"}><MdShoppingCart color={"#FDFDFE"}/></TransButton>
            </SponsorBuy>
        </SponsorProduct>
    )
};

export default Favorite;