import styled from "@emotion/styled";

export const Container = styled.div`
    width: 100%;
`;

export const TitleSection = styled.h2`
  font-family: Outfit, monospace;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
`
