import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const ContainerBar = styled.div`
  width: 70%;
  margin: 0 auto;
  position: relative;
  height: 100vh;
  padding: 2rem 0 0 0;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: rgba(255,255,255,0.8);
  border-radius: 2rem 2rem 0 0 ;
`
export const FooterTitle = styled.h1`
    ${props=>props.theme.typography.subheading.sm.bold}
`
export const FooterItem = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const FooterIcon = styled.div`
    width: 8%;
`
export const FooterDesc = styled.span`
  width:92%;
  ${props=>props.theme.typography.body.lg.input};
  @media only screen and (max-width: 992px) {
    ${props=>props.theme.typography.body.md.regular};
  }
`