
import Modal from 'ui/Modal'
import { useSelector } from 'react-redux'
import { Grid, KeyItemGrid, LabelKeyItemGrid, ValueKeyItemGrid, ContainerChatButton, Subcontainer } from './styled'
import { Link } from 'react-router-dom'
import { H3 } from 'ui/Text'
import { Row } from 'ui/Common/common-components'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {formatPhoneNumber} from 'utils/format-phone-number';
import {ChatButton} from 'ui/Buttons';
import {THEME} from 'constants/theme/theme';
import { MAIN_ROUTES } from 'constants/routes'
import { IClassified } from 'types';
import { userSelector } from 'redux/user/selectors'

interface IUserContactInfo {
    firstName: string | null,
    firstSurname: string | null,
    email: string | null,
    phone: string | null,
    id: string | null
}

interface IModalUserContactInfoProps {
    isOpen: boolean,
    onClose: () => void
    userData: IUserContactInfo
    classifiedData: IClassified
}

const ModalUserContactInfo = (props: IModalUserContactInfoProps) => {
    const { isOpen, onClose, userData, classifiedData} = props;
    const user = useSelector(userSelector)?.userData;

    return (
        
        <Modal styleContent={{minWidth: '70vw'}} isOpen={isOpen} onClose={onClose}>
            <div>
                <H3 style={{ marginBottom: '1rem' }}>Información de contacto del anunciante</H3>
                <hr />
                <Subcontainer>
                    <Grid>
                        <Row alignItems='center'>
                            <KeyItemGrid >
                                <PersonIcon style={{ marginRight: '0.5rem' }} />
                                <LabelKeyItemGrid>Nombre</LabelKeyItemGrid>
                            </KeyItemGrid>
                            <ValueKeyItemGrid> {userData?.firstName} {userData?.firstSurname}</ValueKeyItemGrid>
                        </Row>
                        <Row alignItems='center'>
                            <KeyItemGrid >
                                <EmailIcon style={{ marginRight: '0.5rem' }} />
                                <LabelKeyItemGrid>Email</LabelKeyItemGrid>
                            </KeyItemGrid>
                            <ValueKeyItemGrid>{userData?.email}</ValueKeyItemGrid>
                        </Row>
                        {
                            userData?.phone ? (
                                <Row alignItems='center'>
                                    <KeyItemGrid >
                                        <SmartphoneIcon style={{ marginRight: '0.5rem' }} />
                                        <LabelKeyItemGrid>Teléfono</LabelKeyItemGrid>
                                    </KeyItemGrid>
                                    <ValueKeyItemGrid>{formatPhoneNumber(userData?.phone)}</ValueKeyItemGrid>
                                </Row>
                            ) : null
                        }
                    </Grid>
                    <ContainerChatButton>
                        <Link to={MAIN_ROUTES.ChatSessionClassified} state={{
                            classifiedId: classifiedData.id,
                            consultantId: user.id,
                            ownerClassifiedId: classifiedData.userId
                        }}>
                            <ChatButton style={{ width: '100%' }} backgroundColor={THEME.colors.classifieds} onClick={() => {}}>Chat con el anunciante</ChatButton>
                        </Link>
                    </ContainerChatButton>
                </Subcontainer>
                {/* <hr />
                <p>Asegura esta transacción con el sistema garantizado de ALL SECURE de whatdoyouneed7.com</p>
                <button onClick={() => alert('all secure')} > All secure </button> */}
            </div>
        </Modal>
    )
}

export default ModalUserContactInfo;